import "./assets/styles.css";

import HomePage from "./components/HomePage";
import ReactGA from "react-ga";
import config from "./utils/config";
const TRACKING_ID = config.googleAnalyticsId;
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <>
      <HomePage />
    </>
  );
}

export default App;
