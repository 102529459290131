export interface Config {
  apiEndpoint: string;
  disableYouMemeDebugger: string;
  assetPath: string;
  memeCdn: string;
  categoryCdn: string;
  avatarCdn: string;
  backgroundCdn: string;
  eventCdn: string;
  stickerCdn: string;
  deploymentUrl: string;
  memeTemplateRenderEndpoint: string;
  oldestAllowedSessionStartTime: string;
  googleIdentityClientID: string;
  googleAnalyticsId: string;
  cdn: {
    meme: string;
    category: string;
    avatar: string;
    background: string;
    event: string;
    template: string;
    sticker: string;
  };
}

const config: Config = {
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT as string,
  disableYouMemeDebugger: process.env
    .REACT_APP_DISABLE_YOUMEME_DEBUGGER as string,
  assetPath: process.env.REACT_APP_IMAGE_ASSET_PATH ?? "/assets/",
  memeCdn: process.env.REACT_APP_MEME_CDN as string,
  categoryCdn: process.env.REACT_APP_CATEGORY_CDN as string,
  avatarCdn: process.env.REACT_APP_AVATAR_CDN as string,
  backgroundCdn: process.env.REACT_APP_BACKGROUND_CDN as string,
  eventCdn: process.env.REACT_APP_EVENT_CDN as string,
  stickerCdn: process.env.REACT_APP_STICKER_CDN as string,
  cdn: {
    meme: process.env.REACT_APP_MEME_SMART_CDN as string,
    category: process.env.REACT_APP_CATEGORY_SMART_CDN as string,
    avatar: process.env.REACT_APP_AVATAR_SMART_CDN as string,
    background: process.env.REACT_APP_BACKGROUND_SMART_CDN as string,
    event: process.env.REACT_APP_EVENT_SMART_CDN as string,
    template: process.env.REACT_APP_TEMPLATE_SMART_CDN as string,
    sticker: process.env.REACT_APP_STICKER_SMART_CDN as string,
  },
  deploymentUrl: process.env.REACT_APP_DEPLOYMENT_URL as string,
  memeTemplateRenderEndpoint: process.env
    .REACT_APP_MEME_TEMPLATE_RENDER_ENDPOINT as string,
  oldestAllowedSessionStartTime: process.env
    .REACT_APP_OLDEST_ALLOWED_SESSION_START_TIME as string,
  googleIdentityClientID: process.env
    .REACT_APP_GOOGLE_IDENTITY_CLIENTID as string,
  googleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID as string,
};

export default config;
