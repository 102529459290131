import { Box } from "@mui/material";
import vibeToday from "../../assets/svgs/vibe_today.png";
import vibeMobile from "../../assets/svgs/vibeMobile.png";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useMediaQuery } from "@material-ui/core";
import { useState } from "react";
import PublishMemeModal from "../PublishMemeModal";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";
const useStyles = makeStyles({
  container: {
    paddingTop: "12vw",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: "45px",
  },
  "@media screen and (max-width: 1296px)": {
    container: {
      paddingTop: "16.5vw",
    },
  },
  "@media screen and (max-width: 679px)": {
    container: {
      paddingTop: "12vw",
    },
  },
});
export default function Banner() {
  const gaEventTracker = useAnalyticsEventTracker("Create Meme");
  const classes = useStyles();
  const mobile = useMediaQuery("(max-width: 770px)");
  const tablet = useMediaQuery("(max-width: 1500px)");
  const [openMeme, setOpenMeme] = useState(false);
  return (
    <>
      <Box className={classes.container}>
        {!mobile ? (
          <Box
            margin={mobile ? "45px 0" : "80px 0 100px 0"}
            display="flex"
            justifyContent={"center"}
            width="100%"
          >
            <img
              style={{ width: "1420px", maxWidth: "75%" }}
              src={vibeToday}
              alt="vibe today"
            />
          </Box>
        ) : (
          <img
            style={{
              maxWidth: "80%",
              marginTop: "15px",
              marginBottom: "20px",
            }}
            src={vibeMobile}
            alt="vibe today"
          />
        )}
        <p
          className="body-text"
          style={{
            marginTop: mobile ? "20px" : "0px",
            marginBottom: mobile ? "26px" : "59px",
            maxWidth: !mobile ? "677px" : "90%",
            textAlign: "center",
          }}
        >
          Our meme generator makes it easy to create your own Pudgy Penguins
          themed memes.
        </p>
        {!mobile ? (
          <button
            onClick={() => {
              setOpenMeme(true);
              gaEventTracker("Open Meme editor");
            }}
            className="btn small white nav-trigger-desktop"
            style={{ height: "67px", marginBottom: mobile ? 0 : "50px" }}
          >
            <span>Create Meme</span>
          </button>
        ) : (
          <button
            onClick={() => {
              setOpenMeme(true);
              gaEventTracker("Open Meme editor");
            }}
            className="btn white mobile-only"
            style={{ height: "67px" }}
          >
            <span>Create Meme</span>
          </button>
        )}
      </Box>
      <PublishMemeModal open={openMeme} onClose={() => setOpenMeme(false)} />
    </>
  );
}
