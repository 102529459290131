import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MaterialIcon from "material-icons-react";
import { colors } from "../../../constants/colors";

const useStyles = makeStyles({
  root: {
    fontSize: "1rem",
    width: "68.375em",
    borderRadius: 15,
    background: colors.darkBlue,
    color: "white",
    display: "flex",
    padding: 25,
    boxSizing: "border-box",
  },
  wrapper: {
    width: "100%",
    position: "relative",
  },
  modalBtnGroup: {
    right: 0,
    position: "absolute",
    marginTop: 15,
    marginRight: 15,
    display: "flex",
  },
  closeIcon: {
    width: 44,
    height: 44,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#1C263A",
    justifyContent: "center",
    cursor: "pointer",
    marginLeft: 6,
    "&:hover": {
      background: "#1A1E28",
    },
  },
  backBtn: {
    width: 110,
    color: "#C2C9D4",
    backgroundColor: "#1C263A",
  },
  back: {
    display: "none",
  },
  titleWrapper: {
    paddingTop: 15,
    paddingLeft: 15,
  },
  wrapperMobile: {},
  title: {
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: 30,
    lineHeight: "27px",
    color: "#C2C9D4",
    paddingBottom: 10,
  },
  lawText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "0.75em",
    lineHeight: 1.192,
    letterSpacing: -0.025,
    position: "absolute",
    bottom: "-3em",
    width: "100%",
    left: 0,
    right: 0,
    textAlign: "center",

    "& > a": {
      textDecoration: "var(--gradient-fall-back) solid underline",
    },
  },
  closeIconNew: {
    width: "3.18664em",
    height: "3.18664em",
    borderRadius: "50%",
    position: "absolute",
    cursor: "pointer",
    background: "var(--gradient-fall-back)",
    display: "flex",
    fontSize: "0.82375em",
    alignItems: "center",
    justifyContent: "center",
    top: "-4.55em",
    right: "-4.17em",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.8)",
  },
  description: {
    fontStyle: "normal",
    fontSize: 16,
    lineHeight: "23px",
    color: "#808893",
    width: 641,
    display: "inline-flex",
  },
  purpleDesc: {
    color: "var(--gradient-fall-back)",
    margin: 0,
  },
  w_auto: {
    width: "auto",
  },
  linkTxt: {
    textDecoration: "underline",
    margin: 0,
    display: "contents",
  },
  homePopupWrapper: {
    padding: 0,

    "& > p": {
      fontStyle: "normal",
      fontWeight: 900,
      fontSize: "1.5em",
      lineHeight: "1.21em",
      marginBottom: "0.25em",
      letterSpacing: "-0.3px",
    },

    "& > span": {
      fontSize: "0.875em",
      lineHeight: "1.21em",
      letterSpacing: "-0.3px",
      color: "#808893",
      fontStyle: "normal",
      fontWeight: 500,
    },
  },
  "@media (max-width: 1160px)": {
    root: {
      width: "90vw",
    },

    closeIcon: {
      display: "none",
    },
  },
  "@media (max-height: 700px)": {
    root: {
      height: "31.875em !important",
    },
  },
  "@media (max-width: 760px)": {
    root: {
      width: "100vw",
      height: "100vh !important",
      padding: "20px !important",
      flexDirection: "column",
    },
    description: {
      width: "auto",
    },
    modalBtnGroup: {
      right: "unset",
      left: 0,
      top: 0,
      margin: 0,
    },
    closeIconNew: {
      display: "none",
    },

    purpleDesc: {
      display: "contents",
    },
    desktopOnly: {
      display: "none",
    },
    fixedBtn: {
      position: "fixed",
      left: 10,
      top: 50,
      zIndex: 1,
    },
    mobileCameraRoot: {
      padding: 0,
    },
    lawText: {
      position: "relative",
      bottom: "-0.5em",
      fontSize: "0.75em",
    },
    wrapperMobile: {
      flexGrow: 1,
    },
    scroll: {
      overflow: "auto !important",
      paddingRight: ".7em",
    },
    back: {
      display: "flex",
      marginLeft: 0,
      background: "#1A1E28",
    },
    titleWrapper: {
      paddingLeft: 0,
      marginTop: 60,
    },
  },
  "@media (max-width: 550px)": {
    lawText: {
      bottom: 0,
    },
    scroll: {
      marginTop: 20,
    },
  },
  mobileRoot: {
    width: "100%",
  },
});

const ImageUploadLayout = (props) => {
  const classes = useStyles();
  const { children, txt, onClose, handleTab, flag, mobile } = props;

  return (
    <Box
      className={`${classes.root} ${mobile && classes.mobileRoot} ${
        flag === 2 && classes.mobileCameraRoot
      }`}
      style={flag === 0 ? { height: "41.875em", padding: "1.875em" } : {}}
    >
      <Box
        className={`${classes.wrapper} ${
          flag === 0 && `${classes.scroll} ${classes.wrapperMobile}`
        }`}
      >
        <Box
          className={`${classes.modalBtnGroup} ${
            flag === 2 && classes.fixedBtn
          }`}
        >
          {flag !== 0 && (
            <Box
              className={`${classes.closeIcon} ${classes.backBtn}`}
              onClick={() => handleTab(0)}
            >
              <MaterialIcon icon="chevron_left" color="#546277" />
              Go back
            </Box>
          )}
          <Box className={classes.closeIconNew} onClick={() => onClose()}>
            <MaterialIcon icon="close" color="#fff" />
          </Box>
          <Box
            style={{ backgroundColor: "#1C263A" }}
            className={`${classes.closeIcon} ${classes.back}`}
            onClick={() => {
              if (flag === 0) onClose();
              else handleTab(0);
            }}
          >
            <MaterialIcon icon="arrow_back" color="#546277" />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box
            className={`${classes.titleWrapper} ${
              flag === 2 && classes.desktopOnly
            } ${flag === 0 && classes.homePopupWrapper}`}
          >
            <Typography
              className={`${flag !== 0 && classes.title} text-title `}
              style={{ fontFamily: "TTTrailers-Black", fontSize: "32px" }}
            >
              {txt.title}
            </Typography>
            <Typography
              component="span"
              className={`${flag !== 0 && classes.description} text-body`}
            >
              {txt.desc}
              {flag === 3 && (
                <p className={classes.linkTxt}>official licence</p>
              )}
              {flag === 3 && (
                <p style={{ margin: 0, display: "contents" }}>&nbsp;for.</p>
              )}
            </Typography>
          </Box>
        </Box>
        {children}
      </Box>
    </Box>
  );
};
export default ImageUploadLayout;
