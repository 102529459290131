import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  Popover,
  Slider,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MaterialIcon from "material-icons-react";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { ChromePicker } from "react-color";
import { FiRotateCw } from "react-icons/fi";
import {
  BrightnessIcon,
  ColorIcon,
  OpacityIcon,
  ThermostatIcon,
  WbsunnyIcon,
} from "../../../assets/sharedSVGS";
import useWindowSize from "../../../utils/useWindowSize";
import ContextMenuInteraction from "../components/ContextMenuInteraction";
import { EffectsTransformMemo } from "../components/EffectsTransform";
import { ImagesInteractionMemo } from "../components/ImagesInteraction";
import { StickersInteractionMemo } from "../components/StickersInteraction";
import { TextInteractionMemo } from "../components/TextInteraction";
import { isEmpty } from "../../../utils/utils";
import "./imageEdit.css";
import { WatermarkLogo } from "../config/WatermarkLogo";
import { fonts } from "../../../constants/fonts";
import { colors } from "../../../constants/colors";
import PPStickers from "../components/PPStickers";
const Filter = require("bad-words");
const pFilter = new Filter({ placeHolder: "x" });

const ContextMenuInteractionMemo = memo(ContextMenuInteraction);

const mobileActionBtns = [
  { icon: "title", title: "Text" },
  { icon: "transform", title: "Transform" },
  { icon: "tune", title: "Adjust" },
  { icon: "photo_filter", title: "Filters" },
  { icon: "bubble_chart", title: "Layers" },
  { icon: "settings_suggest", title: "Stickers" },
];
const transformBtns = [
  { icon: "rotate_left", title: "-90 Left" },
  { icon: "rotate_right", title: "+90 Right" },
  { icon: "flip", title: "Horizontal" },
  { icon: "flip", title: "Vertical" },
];

const defaultColors = [
  ["#D54177", "Purple"],
  ["#EF895B", "Silver"],
  ["#F0CD4E", "Yellow"],
  ["#5094CF", "Blue"],
  ["#8545C1", "Purple"],
  ["#FFFFFF", "White"],
  ["#000000", "Black"],
];

const PurpleTooltip = withStyles({
  arrow: {
    color: "var(--gradient-fall-back)",
  },
  tooltip: {
    backgroundColor: "var(--gradient-fall-back)",
    width: 32,
    height: 24,
  },
})(Tooltip);

const useStyles = makeStyles(() => ({
  root: {
    width: "100vw",
    fontFamily: "TTTrailers-Black",
    background: `${colors.darkBlue} 0% 0% no-repeat padding-box`,
    display: "flex",
    padding: "16px",
    boxSizing: "border-box",
    zIndex: 1000,
    height: "100vh",
    position: "relative",
  },
  warning: {
    color: "#FF5A5F",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "19px",
    textTransform: "capitalize",
    fontWeight: 400,

    "& span": {
      marginRight: 5,
    },
  },
  checkIconBack: {
    width: "16px",
    height: "16px",
    background: "white",
  },
  desktopSliderRoot: {
    width: 438,
    marginLeft: 10,
  },
  desktopRail: {
    backgroundColor: colors.blueBtn,
    height: "5px",
  },
  leftSide: {
    width: 512,
    height: "100%",
    background: `${colors.memeGenBlue} 0% 0% no-repeat padding-box`,
    borderRadius: 7,
    padding: "15px",
    boxSizing: "border-box",
    overflowY: "scroll !important",
    overflowX: "hidden !important",
    "&::-webkit-scrollbar": {
      width: 10,
      borderRadius: 20,
      backgroundColor: "#0E1017",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: colors.memeGenBlue50,
      borderRadius: 20,
    },
  },
  addDesc: {
    display: "flex",
    height: 15,
    border: 0,
    background: "transparent",
    color: "#E2E4E9",
    marginTop: 36,
    fontWeight: "bold",
    fontSize: 10,
    marginBottom: 15,
    fontFamily: "Inter",
    fontStyle: "normal",
  },
  tabBtn: {
    borderRadius: 7,
    background: `${colors.memeGenBlue50} 0% 0% no-repeat padding-box`,
    width: 145,
    height: 85,
    minWidth: 20,
    color: "#808893",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: "bold",
    zIndex: 1,
    marginRight: 3,
    "&:hover": {
      background: `${colors.darkBlueActive} 0% 0% no-repeat padding-box`,
      color: "#FFFFFF",
    },
  },
  tabBtn1: {
    background: colors.memeGenBlue50,
    borderRadius: 7,
    width: 142,
    height: 70,
    color: "#E2E4E9",
    fontSize: "14px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "17px",
    zIndex: 1,
    marginLeft: 6,
    "&:hover": {
      background: `${colors.darkBlueActive} 0% 0% no-repeat padding-box`,
      color: "#FFFFFF",
      opacity: 0.8,
    },
  },
  text: {
    fontStyle: "normal",
    padding: "5px 0px",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "15.73px",
    fontFamily: "Inter",
    color: "#A1ACBB",
    marginTop: "5px",
  },
  title: {
    color: "#E2E4E9",
    marginTop: 15,
    fontWeight: "bold",
    fontSize: 18,
    marginBottom: 15,
    fontFamily: "Inter",
    fontStyle: "normal",
    lineHeight: "22px",
  },
  mt_6: {
    marginTop: 67,
  },
  headTitle: {
    fontStyle: "normal",
    fontSize: "20px",
    fontFamily: "Inter",
    fontWeight: 800,
    lineHeight: "24.2px",
    color: "#E2E4E9",
  },
  tagInput: {
    width: "100%",
    "& input": {
      color: "#6C7B93",
      font: "normal normal normal 14px/21px Inter",
    },
  },

  tag: {
    background: colors.memeGenBlue50,
    borderRadius: 3,
    color: "#6C7B93",
    font: "normal normal normal 14px/21px Inter",
    margin: 2,
    padding: "3px 4px",
    "&:hover": {
      background: "var(--gradient-fall-back)",
      color: "#FFFFFF",
    },
  },
  taglist: {
    marginTop: 15,
    background: colors.darkBlue,
    borderRadius: 3,
    padding: 10,
  },
  mainImg: {
    width: "100%",
    padding: "15px 20px 20px 30px",
    boxSizing: "border-box",
  },
  btngroup: {
    bottom: 0,
    width: "98%",
    display: "flex",
    marginBottom: 10,
    justifyContent: "space-between",
  },
  btnItem: {
    background: colors.memeGenBlue50,
    borderRadius: 7,
    color: "#E2E4E9",
    font: "normal normal normal 9px/21px Poppins",
    margin: "2px 1px 2px 5px",
    minWidth: 50,
    padding: "0 15px",
    height: 50,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "17px",
    "&:hover": {
      opacity: 0.8,
      background: colors.memeGenBlue50,
    },
  },
  activeTabBtn: {
    background: "#80ABFF",
    color: "#FFFFFF",
    fontWeight: "900",
    "&:hover": {
      backgroundColor:
        "linear-gradient(268.65deg, var(--gradient-fall-back) -26.32%, #35A6EB 64.97%)",
      opacity: 0.8,
    },
  },
  disabled: {
    cursor: "default",
    pointerEvents: "none",
    color: "#2E3338",
  },
  tabBox: {
    display: "flex",
    flexWrap: "wrap",
    height: "146px",
    marginTop: 25,
  },

  tooltipcolor: {
    background: "#816AD6 0% 0% no-repeat padding-box",
  },
  emojitxt: {
    width: 100,
    marginTop: -35,
    marginLeft: 400,
    color: "#575F6A",
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    position: "absolute",
    zIndex: 5,
  },
  emoji: {
    paddingRight: 10,
    cursor: "pointer",
  },
  autoComplete: {
    width: "100%",
    color: "#A7A7BC",
    fontSize: 10,
    "& .MuiFilledInput-root": {
      background: "#FFF",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      alignItems: "start",
      background: colors.darkBlue,
    },
    "& span": {
      color: "#A7A7BC",
    },
    "& svg": {
      color: "#A7A7BC !important",
    },
    "& .MuiAutocomplete-tag": {
      borderColor: colors.border,
    },
    "& input": {
      color: "#A7A7BC",
    },
  },
  hobbyTag: {
    font: "normal normal normal 12px/27px Gotham Rounded",
    color: "#A7A7BC",
    "& input::placeholder": {
      fontSize: "14px",
      color: "#6C7B93",
      opacity: 1,
    },
  },
  popover: {
    borderRadius: 5,
    background: "transparent",
    boxShadow: "none",
  },
  filtertick: {
    position: "absolute",
    right: 0,
    borderRadius: 2,
  },
  iconContainer: {
    position: "relative",
    width: 15,
    height: 15,
  },
  icon: {
    position: "absolute",
    marginTop: -20,
    marginLeft: -3,
  },
  posttitle: {
    width: "100%",
    background: colors.darkBlue,
    height: "100%",
    marginBottom: "10px",
    paddingRight: 0,
    color: "#6C7B93",
    lineHeight: "17px",
    borderRadius: "5px !important",
    fontFamily: "'Roboto Mono', monospace",
    "& input": {
      zIndex: 2,
      display: "block",
      margin: 0,
      backgroundColor: colors.darkBlue,
      fontSize: 14,
      fontWeight: 500,
      paddingLeft: 20,
      fontStyle: "normal",
      borderRadius: "3px",
      "&::placeholder": {
        fontSize: 14,
        fontWeight: 500,
        color: "#6C7B93",
        opacity: 1,
      },
    },
    "& textarea": {
      padding: 5,

      "&::placeholder": {
        color: "#6C7B93",
        opacity: 1,
      },
      "&::-webkit-scrollbar": {
        width: 10,
        borderRadius: 20,
        backgroundColor: "#0E1017",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: colors.memeGenBlue50,
        borderRadius: 20,
      },
    },
  },
  postComment: {
    width: "100%",
    background: colors.darkBlue,
    height: 100,
    marginBottom: "10px",
    color: "#6C7B93",
    lineHeight: "17px",
    borderRadius: "5px",
    fontFamily: "'Roboto Mono', monospace",
    "& textarea": {
      zIndex: 2,
      width: 422,
      display: "block",
      position: "absolute",
      margin: 0,
      backgroundColor: "transparent",
      fontSize: 14,
      fontWeight: 500,
      paddingLeft: 20,
      fontStyle: "normal",
      overflow: "hidden",
      backgroundColor: colors.darkBlue,
      borderRadius: "3px",
      "&::placeholder": {
        fontSize: 14,
        fontWeight: 500,
        color: "#6C7B93",
        opacity: 1,
      },
    },
  },
  closeIcon: {
    width: 24,
    height: 24,
    marginLeft: "auto",
    marginRight: 48,
    marginBottom: "-10px",
    marginTop: "5px",
    background: "transparent",
    color: "white",
  },
  drawerCloseIcon: {
    width: 24,
    height: 24,
    marginRight: 16,
    marginBottom: 7,
    background: "transparent",
    color: "white",
  },
  tabwrapper: {
    height: "calc(100% - 390px)",
    overflowY: "auto !important",
    marginRight: -3,
    padding: "3px 8px 3px 3px",
    "&::-webkit-scrollbar": {
      width: 10,
      borderRadius: 20,
      backgroundColor: "#0E1017",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: colors.memeGenBlue50,
      borderRadius: 20,
    },
  },
  postSection: {
    height: "calc(100% - 80px)",
    marginRight: -3,
    paddingRight: 8,
    paddingBottom: 16,
    "&::-webkit-scrollbar": {
      width: 16,
      borderRadius: 20,
      backgroundColor: "#0E1017",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: colors.darkBlue,
      borderRadius: 20,
    },
  },
  showEdits: {
    display: "none",
    marginTop: 100,
  },
  mobileOnly: {
    display: "none",
  },
  "@media (max-width: 960px)": {
    root: {
      display: "flex",
    },
    mobileOnly: {
      display: "none",
    },
    mobile_root: {
      display: "none",
    },
  },

  desktopOnly: {
    display: "flex",
  },

  "@media screen and (max-width: 959px)": {
    root: {
      display: "none",
    },
    desktopOnly: {
      display: "none",
    },
    mobileOnly: {
      display: "flex",
    },
    mobile_root: {
      display: "flex",
    },
  },

  // style for mobile
  mobile_root: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "#11131B",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  drawerWrapper: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "#11131B",
    overflow: "scroll !important",
  },
  addTxt: {
    color: "#fff",
    fontSize: 24,
    position: "fixed",
    width: "100vw",
    height: "calc(100vh - 65px)",
    backgroundColor: "#11131B",
    zIndex: 2,
    "& input": {
      textAlign: "center",
    },
  },
  topSection: {
    padding: "35px 15px",
    paddingBottom: 0,
    height: "70vh",
  },
  flex: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  bottomSectionActions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    width: 40,
    height: 40,
    borderRadius: 7,
    backgroundColor: "#1A1E28",
    minWidth: 0,
    "&:hover": {
      backgroundColor: "#1A1E28",
      opacity: 0.8,
    },
  },
  continueBtn: {
    color: "#884BFF",
    fontWeight: "bold",
    fontSize: 14,
    width: 93,
  },
  postBtn: {
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: 14,
    width: 90,
    background:
      "linear-gradient(268.65deg, var(--gradient-fall-back) -26.32%, #35A6EB 64.97%)",
    borderRadius: 100,
    marginLeft: "auto",
  },
  postTxt: {
    marginBottom: 7,
  },
  postHead: {
    fontWeight: 800,
    fontSize: 20,
    lineHeight: "24.2px",
    letterSpacing: "-0.3px",
    color: "#E2E4E9",
  },
  postDesc: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: "15.73px",
    letterSpacing: "-0.3px",
    color: "#808893",
  },
  iconBtn: {
    width: 40,
    height: 40,
    background: "#2C333C",
    borderRadius: 5,
    marginTop: 10,
    marginLeft: 10,
  },
  m_1: {
    "&:nth-child(3)": {
      marginLeft: "3px",
    },
    "&:nth-child(2)": {
      marginLeft: "3px",
    },
    backgroundColor: "transparent",
  },
  mb_7: {
    marginBottom: 28,
    overflowX: "scroll !important",
    display: "flex",
    justifyContent: "space-between",
  },
  space_btn: {
    width: "100%",
    height: 70,
    color: "#6C7B93",
    fontSize: 8,
    borderRadius: 0,
  },
  imageContainer: {
    width: "100%",
    height: 320,
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  actionGroup: {
    backgroundColor: "#1A1E28",
    height: 70,
    width: 450,
    overflow: "auto !important",
    padding: "0 25px",
    marginBottom: 25,
  },
  bottomSection: {
    overflowX: "auto !important",
    overflowY: "hidden !important",
  },
  actionBtn: {
    cursor: "pointer",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  actionTxt: {
    fontSize: 12,
    color: "#6C7B93",
    textAlign: "center",
  },
  transformGroup: {
    width: "90%",
  },
  decorator: {
    width: 40,
    height: 6,
    borderRadius: 100,
    backgroundColor: "#6C7B93",
    margin: "auto",
    marginBottom: 8,
  },
  transforms: {
    backgroundColor: "#1A1E28",
    borderTopRightRadius: 25,
    borderTopLeftRadius: 25,
    height: 150,
    padding: 25,
    position: "relative",
    boxSizing: "border-box",
    boxShadow: "0px -7px 77px #000000",
  },
  adjustWrapper: {
    backgroundColor: "#1A1E28",
    borderTopRightRadius: 25,
    borderTopLeftRadius: 25,
    height: 150,
    padding: "25px 15px",
    position: "relative",
    boxSizing: "border-box",
  },
  selectBtn: {
    position: "absolute",
    top: 29,
    right: 25,
    color: "var(--gradient-fall-back)",
    fontSize: 14,
    fontWeight: "bold",
    cursor: "pointer",
  },
  transform_title: {
    color: "#C2C9D4",
    fontSize: 16,
    textAlign: "center",
    fontWeight: 800,
  },
  transformContainer: {
    marginTop: 25,
    justifyContent: "space-around",
  },
  transBtn: {
    transform: "rotate(90deg)",
  },
  slider_title: {
    color: "#C2C9D4",
    fontSize: 12,
    fontWeight: "bold",
    width: 70,
  },
  slider_value: {
    color: "#C2C9D4",
    fontSize: 12,
    fontWeight: "bold",
  },
  mobileRail: {
    backgroundColor: "#262E3B",
    height: 5,
  },
  track: {
    height: 5,
    backgroundColor: "var(--gradient-fall-back)",
  },
  thumb: {
    width: 16,
    height: 16,
    backgroundColor: "var(--gradient-fall-back)",
    "&:hover": {
      boxShadow: "0px 0px 0px 4px rgb(63 81 181 / 16%)",
    },
    "&:active": {
      boxShadow: "0px 0px 0px 4px rgb(63 81 181 / 16%)",
    },
    "&::after": {
      top: -4,
      left: -4,
      right: -4,
      bottom: -4,
    },
  },
  h_auto: {
    height: "auto",
    boxShadow: "0px -7px 77px #000000",
  },
  mobileSlderRoot: {
    marginTop: -2,
    width: "calc(100% - 110px)",
  },
  activeColor: {
    color: "var(--gradient-fall-back)",
    "&>i": {
      color: "rgb(194, 201, 212)",
    },
  },
  emoteBar: {
    borderRadius: 25,
    background: "#11131B",
    height: 48,
    marginLeft: 5,
    marginRight: 5,
    padding: "5px 27px",
    boxSizing: "border-box",
  },
  stickerWrapper: {
    background: "#1A1E28",
    borderRadius: "25px 25px 0px 0px",
    boxSizing: "border-box",
    paddingTop: 5,
  },
  tickBtn: {
    "& .MuiIconButton-label": {
      background: "var(--gradient-fall-back)",
      borderRadius: 15,
      width: 20,
      height: 20,
    },
  },
  inputContainer: {
    position: "relative",
  },
  trans_btn: {
    backgroundColor: "transparent",
  },
  textToolsContainer: {
    height: 48,
    borderRadius: 24,
    backgroundColor: "#11131B",
    width: "100%",
  },
  textToolsWrapper: {
    backgroundColor: "#1A1E28",
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    boxSizing: "border-box",
    padding: "5px 5px 10px",
  },
  txtIconContainer: {
    borderRadius: "100%",
    backgroundColor: "#6a21f9",
    height: 28,
    width: 28,
  },
  txtSettings: {
    margin: "15px 0 25px",
  },
  settingBtn: {
    height: 38,
    width: 38,
    borderRadius: "100%",
    backgroundColor: "#11131B",
    padding: 8,
  },
  mobile_spacing: {
    backgroundColor: "#11131B",
    borderRadius: 19,
    height: 38,
  },
  mobile_font_search: {
    backgroundColor: "#11131B",
    margin: "15px 0",
    height: 40,
    borderRadius: 20,
    width: "100%",
    padding: "0 25px",
    color: "#C2C9D4",
    fontSize: 14,
    "& input": {
      marginLeft: 10,
    },
  },
  mobile_slider: {
    padding: "0 10px",
    borderTop: "2px solid #262E3B",
    marginTop: 15,
    height: 60,
  },
  color_btn_group: {
    margin: "20px",
    marginBottom: 25,
    display: "flex",
  },
  outline_btn_group: {
    margin: "20px",
    marginBottom: 25,
    display: "flex",
  },
  color_btn: {
    color: "#6C7B93",
    fontSize: 12,
    fontWeight: "bold",
    cursor: "pointer",
    marginRight: 20,
    height: 30,
    paddingLeft: 15,
    paddingRight: 15,
  },
  active_color_btn: {
    background: "#fff",
    borderRadius: 15,
  },
  color_group: {
    display: "flex",
    margin: 20,
    marginBottom: 35,
  },
  color_item: {
    overflow: "auto !important",
    "&::-webkit-scrollbar": {
      width: 16,
      borderRadius: 20,
      backgroundColor: "#0E1017",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#1D212E",
      borderRadius: 20,
    },
  },
  color_item_title: {
    fontSize: 9,
    color: "#6C7B93",
    marginBottom: 12,
  },
  customize_color_wrapper: {
    width: 56,
    height: 56,
    borderRadius: 7,
    background: "linear-gradient(to right, #00FFF0,  #FF0000)",
    padding: 2,
    marginRight: 10,
    boxSizing: "border-box",
  },
  customize_color: {
    background: "#11131B",
    borderRadius: 7,
    width: "100%",
    height: "100%",
  },
  default_color_wrapper: {
    background: "var(--gradient-fall-back)",
    marginRight: 20,
  },
  default_color: {
    background: "#fff",
  },
  recommend_color_wrapper: {
    marginRight: 6,
    boxSizing: "border-box",
  },
  readyBtn: {
    position: "absolute",
    right: 15,
    top: 0,
    color: "var(--main-gradient)",
  },
  stickerContainer: {
    height: 246,
    boxSizing: "border-box",
  },
  adjustOption: {
    width: 65,
    height: 65,
  },
  sliderWrapper: {
    marginTop: 28,
  },
  filterWrapper: {
    padding: "25px 15px",
    height: 200,
  },
  adjustText: {
    marginTop: 10,
  },
  bottomScroll: {
    overflowX: "scroll !important",
  },
  verticalLine: {
    width: 20,
    height: 0,
    border: "1px solid #323544",
    transform: "rotate(90deg)",
    marginTop: 31,
  },
  notification: {
    position: "absolute",
    marginTop: -50,
    marginLeft: 375,
    zIndex: 100,
  },
  bottomScrollbar: {
    height: "auto",
    position: "fixed",
    bottom: 0,
    width: "100%",
    left: 0,
    display: "flex",
    justifyContent: "center",
  },
  titleCircle: {
    position: "absolute",
    marginTop: -42,
    marginLeft: 430,
    zIndex: 100,
  },
  fullList: {
    width: "auto",
  },
  drawerTopSection: {
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.25)",
    width: "100%",
    height: 74,
    background: "#1A1C22",
    padding: 16,
    alignItems: "end",
    boxSizing: "border-box",
  },
  wrapperContent: {
    padding: 16,
    background: "#1A1C22",
  },
  drawerTitle: {
    width: "calc(100% - 50px)",
    "& .MuiInputLabel-root": {
      color: "#808893",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid #30343E",
    },
    "& .MuiInputBase-input": {
      color: "#808893",
    },
  },
  "@media (max-width: 1381px)": {
    actionGroup: {
      width: "calc(100% - 50px)",
      minWidth: 450,
    },
  },
  "@media (max-width: 550px)": {
    imageContainer: {
      overflowY: "scroll",
      height: "auto",
      maxHeight: "50vh",
    },
  },
  "@media (max-width: 1024px)": {
    actionGroup: {
      width: "100%",
      minWidth: 450,
      marginBottom: 0,
    },
    bottomScroll: {
      width: "100%",
    },
  },
  "@media (max-width: 460px)": {
    actionGroup: {
      minWidth: 340,
    },
  },
  "@media (max-width: 370px)": {
    actionGroup: {
      minWidth: 300,
    },
  },
}));

const Typo = require("typo-js");

export default function Imageedit(props) {
  const classes = useStyles();
  const [btnTab, setBtnTab] = useState(false);
  const [editTab, setEditTab] = useState("1");
  const [ppPart, setPpPart] = useState("Body");
  const textAnchorMap = {
    0: "start",
    0.5: "middle",
    1: "end",
  };

  //Base canvas variables
  let [initImage, setInitImage] = useState("");
  let [initResolution, setInitResolution] = useState({ width: 0, height: 0 });
  let [scaledImage, setScaledImage] = useState({ data: "", height: 0 });
  let [svgResolution, setSvgResolution] = useState({
    width: 0,
    height: 0,
  });
  let [defImageResolution, setDefImageResolution] = useState({
    width: 0,
    height: 0,
  });
  let [imageResolution, setImageResolution] = useState({
    width: 0,
    height: 0,
  });
  let [imagePosition, _setImagePosition] = useState({ x: 0, y: 0 });
  let [spaceTopEnable, setSpaceTopEnable] = useState(0);
  let [spaceBotEnable, setSpaceBotEnable] = useState(0);
  //Layered Images variables
  let [layeredImage, setLayeredImage] = useState([]);
  let [layeredImagePosition, _setLayeredImagePosition] = useState([]);
  let [layeredImageResolution, _setLayeredImageResolution] = useState([]);
  let [scaledLayeredImage, setScaledLayeredImage] = useState([]);
  let [selectedLayeredImage, setSelectedLayeredImage] = useState(-1);
  //Text variables
  let [rectDimension, _setRectDimension] = useState([]);
  let [rectPosition, _setRectPosition] = useState([]);
  let [fontFamily, setFontFamily] = useState([]);
  let [fontWeight, setFontWeight] = useState([]);
  let [fontSize, setFontSize] = useState([]);
  let [fontStyle, setFontStyle] = useState([]);
  let [textRectHeight, _setTextRectHeight] = useState([]);
  let [textRectWidth, _setTextRectWidth] = useState([]);
  let [letterSpace, setLetterSpace] = useState([]);
  let [colorValue, setColorValue] = useState([]);
  let [strokeValue, setStrokeValue] = useState([]);
  //using cssproperties to declare type of Texttransform instead of string,
  //because of existing bug in react typescript in which texttransform css
  //property does not have string as accepted type, it only takes
  // "capitalize" | "full-size-kana" | "full-width" | "lowercase" | "none" | "uppercase"
  // and not "capitalize" | "full-size-kana" | "full-width" | "lowercase" | "none" | "uppercase | (string & {})
  // The scope of this type declaration can be made more broader to 'string' once
  // the bug is fixed.
  let [isUpperCase, setIsUpperCase] = useState([]);
  //use 0,0.5,1 for left, center and right for horizontal align
  let [hAlign, setHAlign] = useState([]);
  //use 0, 0.5, 1 for top, middle and bottom for vertical align
  let [vAlign, setVAlign] = useState([]);
  let [strokeWidth, setStrokeWidth] = useState([]);
  let [shadowWidth, setShadowWidth] = useState([]);
  let [textPlaceholder, setTextPlaceholder] = useState([]);
  let [textValue, setTextValue] = useState([]);
  //Transformation variables
  let currentImgDivPosition;
  let [spaceValue, setSpaceValue] = useState(0);
  let [spaceColor, setSpaceColor] = useState("#898989");
  let [filterWidth, setFilterWidth] = useState(0);
  let [filterHeight, setFilterHeight] = useState(0);
  let [brightnessScale, setBrightnessScale] = useState(0);
  let [contrastScale, setContrastScale] = useState(0);
  let [saturationScale, setSaturationScale] = useState(0);
  let [warmthScale, setWarmthScale] = useState(0);
  let [flipH, setFlipH] = useState(1);
  let [flipV, setFlipV] = useState(1);
  let [rotationScale, setRotationScale] = useState(0);
  let [rotationScaleL, setRotationScaleL] = useState([]);
  let [rotationScaleT, setRotationScaleT] = useState([]);
  let [imgDimensionScale, setImgDimensionScale] = useState(1);
  let [filterSelected, setFilterSelected] = useState("nofilter");
  let [anchorEl, setAnchorEl] = useState(null);
  let [anchorRef, setAnchorRef] = useState("");
  let scaledImageRatio = useRef(1);
  let [title, setTitle] = useState("");

  let mouseMoveTimeout = useRef(null);
  let timeout = useRef(null);
  let dictionary = useRef(null);
  let backDropRef = useRef(null);
  let [strokeType, setStrokeType] = useState([]);
  let windowDimension = useWindowSize();
  let isDesktop = windowDimension.width > 959;
  let windowWidth = windowDimension.width;
  let tempDebounceCounter = useRef(0);
  let tempDebounceVar = useRef(undefined);
  let initTrigger = new Array(7).fill(false);
  let [trigger, setTrigger] = useState(initTrigger);
  let [txtEditTools, setTxtEditTools] = useState([false, true, false, false]);
  let [mobile_font_size, setMobileFontSize] = useState(20);
  let [filterFontFamily, setFilterFontFamily] = useState(fonts);
  let [customColorPicker, setCustomColorPicker] = useState(false);
  let [letterSpacing, setLetterSpacing] = useState(0);
  let [outline, setOutline] = useState(4);
  let [shadow, setShadow] = useState(0);
  let [comment, setComment] = useState("");
  let [textColor, setTextColor] = useState([true, false]);
  let [outlineStyle, setOutlineStyle] = useState([true, false, false]);
  let [fontDefaultColor, setFontDefaultColor] = useState(false);
  let [textIndex, setTextIndex] = useState(0);
  let [openConfirmModal, setOpenConfirmModal] = useState(false);
  let [show, setShow] = useState(false);
  const handleClickOpen = () => {
    setOpenConfirmModal(true);
  };

  const handleConfirmModalClose = () => {
    setOpenConfirmModal(false);
  };

  const clickAction = (e) => {
    if (e === 0) {
      addText();
      setTextIndex(textValue.length);
    }
    const temp = initTrigger;
    temp[e] = true;
    setTrigger(temp);
  };
  const clickTransform = (e) => {
    const temp = initTrigger.slice(0, 4);
    temp[e] = true;
  };

  useEffect(() => {
    let mainDIV = document.getElementsByClassName("imageEditWrapper")[0];

    let availableHeight = mainDIV.offsetHeight - 20;
    let availableWidth = mainDIV.offsetWidth - 20;

    let originalHeight = svgResolution.height;
    let originalWidth = svgResolution.width;

    let scaleRatio = availableHeight / originalHeight;
    let newWidth = Math.round(originalWidth * scaleRatio);

    if (newWidth > availableWidth) {
      for (let i = availableHeight - 1; i > 0; i--) {
        let tempScaleRatio = i / originalHeight;
        newWidth = Math.round(tempScaleRatio * originalWidth);
        if (newWidth <= availableWidth) {
          availableHeight = i;
          scaleRatio = tempScaleRatio;
          break;
        }
      }
    }
    setSvgResolution({ width: newWidth, height: availableHeight });
    setDefImageResolution({
      width: defImageResolution.width * scaleRatio,
      height: defImageResolution.height * scaleRatio,
    });
    setImageResolution({
      width: imageResolution.width * scaleRatio,
      height: imageResolution.height * scaleRatio,
    });
    scaledImageRatio.current =
      scaleRatio * (originalHeight / initResolution.height);
    let newRectDimension = [];
    let newRectPosition = [];
    let newFontSize = [];
    let newShadowWidth = [];
    let newStrokeWidth = [];
    let newLetterSpace = [];
    let newTextRectWidth = [];
    let newTextRectHeight = [];

    for (let i = 0; i < rectDimension.length; i++) {
      let tempWidth = rectDimension[i].width * scaleRatio;
      let tempHeight = rectDimension[i].height * scaleRatio;
      let tempPositionX = rectPosition[i].x * scaleRatio;
      let tempPositionY = rectPosition[i].y * scaleRatio;
      let tempFontSize = Math.round(fontSize[i] * scaleRatio);
      let tempShadowWidth = Math.round(
        Number(shadowWidth[i].slice(0, -2)) * scaleRatio
      );
      let tempStrokeWidth = Math.round(
        Number(strokeWidth[i].slice(0, -2)) * scaleRatio
      );
      let tempLetterSpace = Math.round(
        Number(letterSpace[i].slice(0, -2)) * scaleRatio
      );
      let tempTextRectWidth = Math.round(textRectWidth[i] * scaleRatio);
      let tempTextRectHeight = Math.round(textRectHeight[i] * scaleRatio);
      newRectDimension.push({ width: tempWidth, height: tempHeight });
      newRectPosition.push({ x: tempPositionX, y: tempPositionY });
      newFontSize.push(tempFontSize);
      newShadowWidth.push(tempShadowWidth + "px");
      newStrokeWidth.push(tempStrokeWidth + "px");
      newLetterSpace.push(tempLetterSpace + "px");
      newTextRectHeight.push(tempTextRectHeight);
      newTextRectWidth.push(tempTextRectWidth);
    }
    setRectDimension(newRectDimension);
    setRectPosition(newRectPosition);
    setFontSize(newFontSize);
    setShadowWidth(newShadowWidth);
    setStrokeWidth(newStrokeWidth);
    setLetterSpace(newLetterSpace);
    setTextRectWidth(newTextRectWidth);
    setTextRectHeight(newTextRectHeight);

    let newLayeredImageResolution = [];
    let newLayeredImagePosition = [];
    for (let i = 0; i < layeredImageResolution.length; i++) {
      let tempWidth = layeredImageResolution[i].width * scaleRatio;
      let tempHeight = layeredImageResolution[i].height * scaleRatio;
      let tempPositionX = layeredImagePosition[i].x * scaleRatio;
      let tempPositionY = layeredImagePosition[i].y * scaleRatio;
      newLayeredImageResolution.push({
        width: tempWidth,
        height: tempHeight,
      });
      newLayeredImagePosition.push({ x: tempPositionX, y: tempPositionY });
    }
    setLayeredImageResolution(newLayeredImageResolution);
    setLayeredImagePosition(newLayeredImagePosition);

    setImagePosition({
      x: imagePosition.x * scaleRatio,
      y: imagePosition.y * scaleRatio,
    });
  }, [windowDimension, spaceValue, spaceBotEnable, spaceTopEnable, initImage]);

  const [adjustSetting, setAdjustSetting] = useState(0);
  const [font_val, setFontVal] = useState("");

  useEffect(() => {
    let isMounted = true;
    manipulateImage();
    return () => {
      window.removeEventListener("keydown", handleKeyboardShortcuts);
      isMounted = false;
    };
    function manipulateImage() {
      window.addEventListener("keydown", handleKeyboardShortcuts);
      if (props.sourceImg.type === "svg") {
        let dataobj = props.sourceImg.data;
        let mainDIV = document.getElementsByClassName("imageEditWrapper")[0];
        if (isDesktop || !isDesktop) {
          let availableHeight = mainDIV.offsetHeight - 20;
          let availableWidth = mainDIV.offsetWidth - 20;
          let originalHeight = dataobj.svgResolution.height;
          let originalWidth = dataobj.svgResolution.width;
          let scaleRatio = availableHeight / originalHeight;
          let newWidth = Math.round(originalWidth * scaleRatio);

          if (newWidth > availableWidth) {
            for (let i = availableHeight - 1; i > 0; i--) {
              let tempScaleRatio = i / originalHeight;
              newWidth = Math.round(tempScaleRatio * originalWidth);
              if (newWidth <= availableWidth) {
                availableHeight = i;
                scaleRatio = tempScaleRatio;
                break;
              }
            }
          }
          setSvgResolution({ width: newWidth, height: availableHeight });
          setDefImageResolution({
            width: dataobj.defImageResolution.width * scaleRatio,
            height: dataobj.defImageResolution.height * scaleRatio,
          });
          setImageResolution({
            width: dataobj.imageResolution.width * scaleRatio,
            height: dataobj.imageResolution.height * scaleRatio,
          });
          scaledImageRatio.current = scaleRatio;
          let newRectDimension = [];
          let newRectPosition = [];
          let newFontSize = [];
          let newShadowWidth = [];
          let newStrokeWidth = [];
          let newLetterSpace = [];
          let newTextRectWidth = [];
          let newTextRectHeight = [];

          for (let i = 0; i < dataobj.rectDimension.length; i++) {
            let tempWidth = dataobj.rectDimension[i].width * scaleRatio;
            let tempHeight = dataobj.rectDimension[i].height * scaleRatio;
            let tempPositionX = dataobj.rectPosition[i].x * scaleRatio;
            let tempPositionY = dataobj.rectPosition[i].y * scaleRatio;
            let tempFontSize = Math.round(dataobj.fontSize[i] * scaleRatio);
            let tempShadowWidth = Math.round(
              Number(dataobj.shadowWidth[i].slice(0, -2)) * scaleRatio
            );
            let tempStrokeWidth = Math.round(
              Number(dataobj.strokeWidth[i].slice(0, -2)) * scaleRatio
            );
            let tempLetterSpace = Math.round(
              Number(dataobj.letterSpace[i].slice(0, -2)) * scaleRatio
            );
            let tempTextRectWidth = Math.round(
              dataobj.textRectWidth[i] * scaleRatio
            );
            let tempTextRectHeight = Math.round(
              dataobj.textRectHeight[i] * scaleRatio
            );
            newRectDimension.push({ width: tempWidth, height: tempHeight });
            newRectPosition.push({ x: tempPositionX, y: tempPositionY });
            newFontSize.push(tempFontSize);
            newShadowWidth.push(tempShadowWidth + "px");
            newStrokeWidth.push(tempStrokeWidth + "px");
            newLetterSpace.push(tempLetterSpace + "px");
            newTextRectHeight.push(tempTextRectHeight);
            newTextRectWidth.push(tempTextRectWidth);
          }
          setRectDimension(newRectDimension);
          setRectPosition(newRectPosition);
          setFontSize(newFontSize);
          setShadowWidth(newShadowWidth);
          setStrokeWidth(newStrokeWidth);
          setLetterSpace(newLetterSpace);
          setTextRectWidth(newTextRectWidth);
          setTextRectHeight(newTextRectHeight);

          let newLayeredImageResolution = [];
          let newLayeredImagePosition = [];
          for (let i = 0; i < dataobj.layeredImageResolution.length; i++) {
            let tempWidth =
              dataobj.layeredImageResolution[i].width * scaleRatio;
            let tempHeight =
              dataobj.layeredImageResolution[i].height * scaleRatio;
            let tempPositionX = dataobj.layeredImagePosition[i].x * scaleRatio;
            let tempPositionY = dataobj.layeredImagePosition[i].y * scaleRatio;
            newLayeredImageResolution.push({
              width: tempWidth,
              height: tempHeight,
            });
            newLayeredImagePosition.push({
              x: tempPositionX,
              y: tempPositionY,
            });
          }
          setLayeredImageResolution(newLayeredImageResolution);
          setLayeredImagePosition(newLayeredImagePosition);

          setImagePosition({
            x: dataobj.imagePosition.x * scaleRatio,
            y: dataobj.imagePosition.y * scaleRatio,
          });
        }
        setFilterSelected(dataobj.filterSelected);
        setContrastScale(dataobj.contrastScale);
        setBrightnessScale(dataobj.brightnessScale);
        setSaturationScale(dataobj.saturationScale);
        setWarmthScale(dataobj.warmthScale);
        setSpaceValue(dataobj.spaceValue);
        setSpaceTopEnable(dataobj.spaceTopEnable);
        setSpaceBotEnable(dataobj.spaceBotEnable);
        setSpaceColor(dataobj.spaceColor);
        setImgDimensionScale(dataobj.imgDimensionScale);
        setFlipH(dataobj.flipH);
        setFlipV(dataobj.flipV);
        setRotationScale(dataobj.rotationScale);
        setInitImage(dataobj.initImage);
        setInitResolution({
          width: dataobj.svgResolution.width,
          height: dataobj.svgResolution.height,
        });
        setRotationScaleT(dataobj.rotationScaleT);
        setVAlign(dataobj.vAlign);
        setHAlign(dataobj.hAlign);
        setFontFamily(dataobj.fontFamily);
        setFontWeight(dataobj.fontWeight);
        setFontStyle(dataobj.fontStyle);
        setStrokeValue(dataobj.strokeValue);
        setStrokeType(dataobj.strokeType);
        setIsUpperCase(dataobj.isUpperCase);
        setColorValue(dataobj.colorValue);
        setTextValue(dataobj.textValue);
        setLayeredImage(dataobj.layeredImage);
        setRotationScaleL(dataobj.rotationScaleL);
        setScaledLayeredImage(dataobj.scaledLayeredImage);
        setScaledImage(dataobj.scaledImage);
        setTextPlaceholder(dataobj.textPlaceholder);
      } else {
        let mainDIV = document.getElementsByClassName("imageEditWrapper")[0];
        let availableHeight = mainDIV.offsetHeight - 20;
        let availableWidth = mainDIV.offsetWidth - 20;
        let originalHeight = props.sourceImg.imageResolution.height;
        let originalWidth = props.sourceImg.imageResolution.width;
        let scaleRatio = availableHeight / originalHeight;
        let newWidth = Math.round(originalWidth * scaleRatio);

        if (newWidth > availableWidth) {
          for (let i = availableHeight - 1; i > 0; i--) {
            let tempScaleRatio = i / originalHeight;
            newWidth = Math.round(tempScaleRatio * originalWidth);
            if (newWidth <= availableWidth) {
              availableHeight = i;
              scaleRatio = tempScaleRatio;
              break;
            }
          }
        }
        let imageResolution = { width: newWidth, height: availableHeight };
        let imageWidth = props.sourceImg.imageResolution.width;
        let imageHeight = props.sourceImg.imageResolution.height;
        let fontSizeUpdate = Math.round(availableHeight / 12);
        if (fontSizeUpdate < 12) {
          fontSizeUpdate = 12;
        }
        setFontSize([fontSizeUpdate, fontSizeUpdate]);
        setInitImage(props.sourceImg.data);
        setInitResolution({ width: originalWidth, height: originalHeight });
        setTextValue([[""], [""]]);
        setTextPlaceholder(["TOP TEXT", "BOTTOM TEXT"]);
        setFontWeight([400, 400]);
        setFontStyle(["normal", "normal"]);
        setFontFamily(["Anton", "Anton"]);
        setLetterSpace(["0px", "0px"]);
        setColorValue(["#ffffff", "#ffffff"]);
        setStrokeValue(["#000000", "#000000"]);
        setStrokeType([0, 0]);
        setIsUpperCase(["uppercase", "uppercase"]);
        setHAlign([0.5, 0.5]);
        setVAlign([0.5, 0.5]);
        setStrokeWidth(["4px", "4px"]);
        setShadowWidth(["0px", "0px"]);
        setSvgResolution(imageResolution);
        setDefImageResolution(imageResolution);
        setImageResolution(imageResolution);
        scaledImageRatio.current = scaleRatio;
        let twhElement = document.getElementById("twh");
        twhElement.style.fontSize = fontSizeUpdate + "px";
        twhElement.style.fontFamily = "Anton";
        twhElement.style.letterSpacing = "0px";
        twhElement.innerHTML = "test";
        let tempHeight = twhElement.getBoundingClientRect().height;
        setTextRectHeight([tempHeight, tempHeight]);
        setTextRectWidth([tempHeight, tempHeight]);
        let yInitialHeight = Math.floor(fontSizeUpdate * 3);
        setRectPosition([
          {
            x: 0,
            y: 0,
          },
          {
            x: 0,
            y: imageResolution.height - (yInitialHeight + 20),
          },
        ]);
        setRectDimension([
          {
            width: imageResolution.width,
            height: yInitialHeight,
          },
          {
            width: imageResolution.width,
            height: yInitialHeight,
          },
        ]);
        setRotationScaleT([0, 0]);
        var offScreenCanvas = document.createElement("canvas");
        var offScreenCanvasCtx = offScreenCanvas.getContext("2d");
        offScreenCanvas.width = 225;
        let newHeight = (225 / imageWidth) * imageHeight;
        offScreenCanvas.height = newHeight;
        let img = document.createElement("img");
        img.src = props.sourceImg.data;
        img.onload = function () {
          offScreenCanvasCtx.scale(225 / imageWidth, newHeight / imageHeight);
          offScreenCanvasCtx.drawImage(img, 0, 0);
          setScaledImage({
            data: offScreenCanvas.toDataURL(),
            height: newHeight,
          });
          for (let i = 0; i < 2; i++) {
            let inputElement = document.getElementById("font-size-input" + i);
            inputElement.value = fontSizeUpdate + "";
          }
        };
      }
    }
  }, []);

  useEffect(() => {
    deselectAllRect();
  }, [layeredImage, initImage, textPlaceholder]);

  //  undo-redo variables
  const functionStack = useRef([]);
  const counter = useRef(-1);
  const tempUndoVar1 = useRef(null);
  const tempUndoVar2 = useRef(null);
  const layeredImagePositionRef = useRef(layeredImagePosition);
  const imagePositionRef = useRef(imagePosition);
  const layeredImageResolutionRef = useRef(layeredImageResolution);
  const textRectWidthRef = useRef(textRectWidth);
  const textRectHeightRef = useRef(textRectHeight);
  const rectPositionRef = useRef(rectPosition);
  const rectDimensionRef = useRef(rectDimension);
  const isRectDragging = useRef(false);
  const isRectSelected = useRef(false);
  const isRectResizing = useRef(false);
  const isRectRotating = useRef(false);
  let cursorPosition = useRef({ x: 0, y: 0 });
  const selectedElementID = useRef("");
  const selectedIndex = useRef(-1);
  const setImagePosition = (x) => {
    imagePositionRef.current = x;
    _setImagePosition(x);
  };
  const setLayeredImagePosition = (x) => {
    layeredImagePositionRef.current = x;
    _setLayeredImagePosition(x);
  };
  const setLayeredImageResolution = (x) => {
    layeredImageResolutionRef.current = x;
    _setLayeredImageResolution(x);
  };
  const setRectPosition = (x) => {
    rectPositionRef.current = x;
    _setRectPosition(x);
  };
  const setRectDimension = (x) => {
    rectDimensionRef.current = x;
    _setRectDimension(x);
  };
  const setTextRectHeight = (x) => {
    textRectHeightRef.current = x;
    _setTextRectHeight(x);
  };
  const setTextRectWidth = (x) => {
    textRectWidthRef.current = x;
    _setTextRectWidth(x);
  };
  const addnewLayeredImage = useCallback(
    (imgBase64, imgResolution, displayName, ppStickers) => {
      // console.log('newLayeredImg', displayName);
      let newHeight = ppStickers
        ? Math.round(
            (defImageResolution.width / 3 / imgResolution.width) *
              imgResolution.height *
              2.25
          )
        : Math.round(
            (defImageResolution.width / 3 / imgResolution.width) *
              imgResolution.height
          );
      let newWidth = ppStickers
        ? Math.round(defImageResolution.width / 3) * 2.25
        : Math.round(defImageResolution.width / 3);

      console.log(newHeight, newWidth);
      let newPositionX = !ppStickers
        ? (defImageResolution.width - newWidth) / 2
        : newWidth < 500
        ? (defImageResolution.width - newWidth) / 2
        : (defImageResolution.width - 500) / 2;
      let newPositionY = ppStickers
        ? (defImageResolution.height - newHeight) / 2 -
          (isDesktop ? (newWidth > 550 ? -40 : 65) : 15)
        : (defImageResolution.height - newHeight) / 2;
      let offScreenCanvas = document.createElement("canvas");
      let offScreenCanvasCtx = offScreenCanvas.getContext("2d");
      let scaledWidth = 225;
      let scaledHeight =
        (scaledWidth / imgResolution.width) * imgResolution.height;
      let newScaledLayeredImage;
      let newRotationScaleL = [...rotationScaleL, 0];
      let newLayeredImageResolution = [
        ...layeredImageResolution,
        {
          width: !ppStickers
            ? newWidth
            : newWidth > 500 && isDesktop
            ? 500
            : newWidth,
          height: !ppStickers
            ? newHeight
            : newHeight > 500 && isDesktop
            ? 500
            : newHeight,
        },
      ];
      let newLayeredImagePosition = [
        ...layeredImagePosition,
        { x: newPositionX, y: newPositionY },
      ];
      let newLayeredImage = [...layeredImage, imgBase64];
      offScreenCanvas.width = 225;
      offScreenCanvas.height = scaledHeight;
      let scaleValueWidth = scaledWidth / imgResolution.width;
      let scaleValueHeight = scaledHeight / imgResolution.height;
      let img = document.createElement("img");
      img.src = imgBase64;
      img.onload = function () {
        offScreenCanvasCtx.scale(scaleValueWidth, scaleValueHeight);
        offScreenCanvasCtx.drawImage(img, 0, 0);
        newScaledLayeredImage = [
          ...scaledLayeredImage,
          {
            data: offScreenCanvas.toDataURL(),
            height: scaledHeight,
            displayName: displayName,
          },
        ];
        setundoredo(
          "modifyLayeredImage",
          "_" +
            JSON.stringify(scaledLayeredImage) +
            "_" +
            JSON.stringify(rotationScaleL) +
            "_" +
            JSON.stringify(layeredImageResolution) +
            "_" +
            JSON.stringify(layeredImagePosition) +
            "_" +
            JSON.stringify(layeredImage),
          "_" +
            JSON.stringify(newScaledLayeredImage) +
            "_" +
            JSON.stringify(newRotationScaleL) +
            "_" +
            JSON.stringify(newLayeredImageResolution) +
            "_" +
            JSON.stringify(newLayeredImagePosition) +
            "_" +
            JSON.stringify(newLayeredImage)
        );
        setScaledLayeredImage(newScaledLayeredImage);
        setRotationScaleL(newRotationScaleL);
        setLayeredImageResolution(newLayeredImageResolution);
        setLayeredImagePosition(newLayeredImagePosition);
        setLayeredImage(newLayeredImage);
        deselectAllRect();
      };
    },
    [
      defImageResolution,
      layeredImageResolution,
      layeredImagePosition,
      layeredImage,
      scaledLayeredImage,
      rotationScaleL,
    ]
  );

  function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
      <PurpleTooltip
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={value}
        arrow
      >
        {children}
      </PurpleTooltip>
    );
  }
  const removeLayeredImage = useCallback(
    (index) => {
      let newRotationScaleL = rotationScaleL.filter(
        (rotationScale, i) => i !== index
      );
      let newLayeredImage = layeredImage.filter((layeredImg, i) => i !== index);
      let newLayeredImagePosition = layeredImagePosition.filter(
        (layeredImgpos, i) => i !== index
      );
      let newLayeredImageResolution = layeredImageResolution.filter(
        (layeredImgres, i) => i !== index
      );
      let newScaledLayeredImage = scaledLayeredImage.filter(
        (scaledLayeredImg, i) => i !== index
      );
      setundoredo(
        "modifyLayeredImage",
        "_" +
          JSON.stringify(scaledLayeredImage) +
          "_" +
          JSON.stringify(rotationScaleL) +
          "_" +
          JSON.stringify(layeredImageResolution) +
          "_" +
          JSON.stringify(layeredImagePosition) +
          "_" +
          JSON.stringify(layeredImage),
        "_" +
          JSON.stringify(newScaledLayeredImage) +
          "_" +
          JSON.stringify(newRotationScaleL) +
          "_" +
          JSON.stringify(newLayeredImageResolution) +
          "_" +
          JSON.stringify(newLayeredImagePosition) +
          "_" +
          JSON.stringify(newLayeredImage)
      );
      setScaledLayeredImage(newScaledLayeredImage);
      setRotationScaleL(newRotationScaleL);
      setLayeredImageResolution(newLayeredImageResolution);
      setLayeredImagePosition(newLayeredImagePosition);
      setLayeredImage(newLayeredImage);
      deselectAllRect();
    },
    [
      layeredImageResolution,
      layeredImagePosition,
      layeredImage,
      scaledLayeredImage,
      rotationScaleL,
    ]
  );
  const removeLayeredImageWCurrentValue = (index) => {
    let newRotationScaleL;
    let newLayeredImage;
    let newScaledLayeredImage;
    let currentRotationScaleL;
    let currentLayeredImage;
    let currentScaledLayeredImage;
    let currentLayeredImagePosition = layeredImagePositionRef.current;
    let currentLayeredImageResolution = layeredImageResolutionRef.current;

    setRotationScaleL((tempCurrentRotationScaleL) => {
      currentRotationScaleL = tempCurrentRotationScaleL;
      newRotationScaleL = tempCurrentRotationScaleL.filter(
        (rotationScale, i) => i !== index
      );
      return tempCurrentRotationScaleL;
    });
    setLayeredImage((tempCurrentLayeredImage) => {
      currentLayeredImage = tempCurrentLayeredImage;
      newLayeredImage = tempCurrentLayeredImage.filter(
        (layeredImg, i) => i !== index
      );
      return tempCurrentLayeredImage;
    });

    let newLayeredImagePosition = layeredImagePositionRef.current.filter(
      (layeredImgpos, i) => i !== index
    );
    let newLayeredImageResolution = layeredImageResolutionRef.current.filter(
      (layeredImgres, i) => i !== index
    );
    layeredImagePositionRef.current = newLayeredImagePosition;
    layeredImageResolutionRef.current = newLayeredImageResolution;
    setScaledLayeredImage((tempCurrentScaledLayeredImage) => {
      currentScaledLayeredImage = tempCurrentScaledLayeredImage;
      newScaledLayeredImage = tempCurrentScaledLayeredImage.filter(
        (scaledLayeredImg, i) => i !== index
      );
      return tempCurrentScaledLayeredImage;
    });
    setundoredo(
      "modifyLayeredImage",
      "_" +
        JSON.stringify(currentScaledLayeredImage) +
        "_" +
        JSON.stringify(currentRotationScaleL) +
        "_" +
        JSON.stringify(currentLayeredImageResolution) +
        "_" +
        JSON.stringify(currentLayeredImagePosition) +
        "_" +
        JSON.stringify(currentLayeredImage),
      "_" +
        JSON.stringify(newScaledLayeredImage) +
        "_" +
        JSON.stringify(newRotationScaleL) +
        "_" +
        JSON.stringify(newLayeredImageResolution) +
        "_" +
        JSON.stringify(newLayeredImagePosition) +
        "_" +
        JSON.stringify(newLayeredImage)
    );
    setLayeredImage(newLayeredImage);
    setScaledLayeredImage(newScaledLayeredImage);
    setRotationScaleL(newRotationScaleL);
    setLayeredImageResolution(newLayeredImageResolution);
    setLayeredImagePosition(newLayeredImagePosition);
    deselectAllRect();
  };

  const deleteTextWCurrentValue = (index) => {
    let newTextValue;
    let newFontWeight;
    let newFontStyle;
    let newFontFamily;
    let newFontSize;
    let newLetterSpace;
    let newColorValue;
    let newStrokeValue;
    let newIsUpperCase;
    let newHAlign;
    let newVAlign;
    let newStrokeWidth;
    let newStrokeType;
    let newShadowWidth;
    let newTextRectHeight;
    let newTextRectWidth;
    let newRectDimension;
    let newTextPlaceholder;
    let newRectPosition;
    let newRotationScaleT;
    let currentTextValue;
    let currentFontWeight;
    let currentFontStyle;
    let currentFontFamily;
    let currentFontSize;
    let currentLetterSpace;
    let currentColorValue;
    let currentStrokeValue;
    let currentIsUpperCase;
    let currentHAlign;
    let currentVAlign;
    let currentStrokeWidth;
    let currentStrokeType;
    let currentShadowWidth;
    let currentTextRectHeight;
    let currentTextRectWidth;
    let currentRectDimension;
    let currentTextPlaceholder;
    let currentRectPosition;
    let currentRotationScaleT;

    setTextValue((tempCurrentTextValue) => {
      currentTextValue = tempCurrentTextValue;
      newTextValue = tempCurrentTextValue.filter((value, i) => i !== index);
      return tempCurrentTextValue;
    });

    if (currentTextValue.length === 1) {
      return null;
    }
    setFontWeight((tempCurrentFontWeight) => {
      currentFontWeight = tempCurrentFontWeight;
      newFontWeight = tempCurrentFontWeight.filter((value, i) => i !== index);
      return tempCurrentFontWeight;
    });

    setFontStyle((tempCurrentFontStyle) => {
      currentFontStyle = tempCurrentFontStyle;
      newFontStyle = tempCurrentFontStyle.filter((value, i) => i !== index);
      return tempCurrentFontStyle;
    });

    setFontFamily((tempCurrentFontFamily) => {
      currentFontFamily = tempCurrentFontFamily;
      newFontFamily = tempCurrentFontFamily.filter((value, i) => i !== index);
      return tempCurrentFontFamily;
    });

    setFontSize((tempCurrentFontSize) => {
      currentFontSize = tempCurrentFontSize;
      newFontSize = tempCurrentFontSize.filter((value, i) => i !== index);
      return tempCurrentFontSize;
    });

    setLetterSpace((tempCurrentLetterSpace) => {
      currentLetterSpace = tempCurrentLetterSpace;
      newLetterSpace = tempCurrentLetterSpace.filter((value, i) => i !== index);
      return tempCurrentLetterSpace;
    });

    setColorValue((tempCurrentColorValue) => {
      currentColorValue = tempCurrentColorValue;
      newColorValue = tempCurrentColorValue.filter((value, i) => i !== index);
      return tempCurrentColorValue;
    });

    setStrokeValue((tempCurrentStrokeValue) => {
      currentStrokeValue = tempCurrentStrokeValue;
      newStrokeValue = tempCurrentStrokeValue.filter((value, i) => i !== index);
      return tempCurrentStrokeValue;
    });

    setIsUpperCase((tempCurrentIsUpperCase) => {
      currentIsUpperCase = tempCurrentIsUpperCase;
      newIsUpperCase = tempCurrentIsUpperCase.filter((value, i) => i !== index);
      return tempCurrentIsUpperCase;
    });

    setHAlign((tempCurrentHAlign) => {
      currentHAlign = tempCurrentHAlign;
      newHAlign = tempCurrentHAlign.filter((value, i) => i !== index);
      return tempCurrentHAlign;
    });

    setVAlign((tempCurrentVAlign) => {
      currentVAlign = tempCurrentVAlign;
      newVAlign = tempCurrentVAlign.filter((value, i) => i !== index);
      return tempCurrentVAlign;
    });

    setStrokeWidth((tempCurrentStrokeWidth) => {
      currentStrokeWidth = tempCurrentStrokeWidth;
      newStrokeWidth = tempCurrentStrokeWidth.filter((value, i) => i !== index);
      return tempCurrentStrokeWidth;
    });

    setStrokeType((tempCurrentStrokeType) => {
      currentStrokeType = tempCurrentStrokeType;
      newStrokeType = tempCurrentStrokeType.filter((value, i) => i !== index);
      return tempCurrentStrokeType;
    });

    setShadowWidth((tempCurrentShadowWidth) => {
      currentShadowWidth = tempCurrentShadowWidth;
      newShadowWidth = tempCurrentShadowWidth.filter((value, i) => i !== index);
      return tempCurrentShadowWidth;
    });

    setTextRectHeight((tempCurrentTextRectHeight) => {
      currentTextRectHeight = tempCurrentTextRectHeight;
      newTextRectHeight = tempCurrentTextRectHeight.filter(
        (value, i) => i !== index
      );
      return tempCurrentTextRectHeight;
    });

    setTextRectWidth((tempCurrentTextRectWidth) => {
      currentTextRectWidth = tempCurrentTextRectWidth;
      newTextRectWidth = tempCurrentTextRectWidth.filter(
        (value, i) => i !== index
      );
      return tempCurrentTextRectWidth;
    });

    setRectDimension((tempCurrentRectDimension) => {
      currentRectDimension = tempCurrentRectDimension;
      newRectDimension = tempCurrentRectDimension.filter(
        (value, i) => i !== index
      );
      return tempCurrentRectDimension;
    });

    setTextPlaceholder((tempCurrentTextPlaceholder) => {
      currentTextPlaceholder = tempCurrentTextPlaceholder;
      newTextPlaceholder = tempCurrentTextPlaceholder.filter(
        (value, i) => i !== index
      );
      return tempCurrentTextPlaceholder;
    });

    setRectPosition((tempCurrentRectPosition) => {
      currentRectPosition = tempCurrentRectPosition;
      newRectPosition = tempCurrentRectPosition.filter(
        (value, i) => i !== index
      );
      return tempCurrentRectPosition;
    });

    setRotationScaleT((tempCurrentRotationScaleT) => {
      currentRotationScaleT = tempCurrentRotationScaleT;
      newRotationScaleT = tempCurrentRotationScaleT.filter(
        (value, i) => i !== index
      );
      return tempCurrentRotationScaleT;
    });

    setundoredo(
      "modifyText",
      "_" +
        JSON.stringify(currentTextValue) +
        "_" +
        JSON.stringify(currentFontSize) +
        "_" +
        JSON.stringify(currentFontWeight) +
        "_" +
        JSON.stringify(currentFontStyle) +
        "_" +
        JSON.stringify(currentFontFamily) +
        "_" +
        JSON.stringify(currentLetterSpace) +
        "_" +
        JSON.stringify(currentColorValue) +
        "_" +
        JSON.stringify(currentStrokeValue) +
        "_" +
        JSON.stringify(currentIsUpperCase) +
        "_" +
        JSON.stringify(currentHAlign) +
        "_" +
        JSON.stringify(currentVAlign) +
        "_" +
        JSON.stringify(currentStrokeWidth) +
        "_" +
        JSON.stringify(currentStrokeType) +
        "_" +
        JSON.stringify(currentShadowWidth) +
        "_" +
        JSON.stringify(currentRectDimension) +
        "_" +
        JSON.stringify(currentTextPlaceholder) +
        "_" +
        JSON.stringify(currentRectPosition) +
        "_" +
        JSON.stringify(currentRotationScaleT) +
        "_" +
        JSON.stringify(currentTextRectHeight) +
        "_" +
        JSON.stringify(currentTextRectWidth),
      "_" +
        JSON.stringify(newTextValue) +
        "_" +
        JSON.stringify(newFontSize) +
        "_" +
        JSON.stringify(newFontWeight) +
        "_" +
        JSON.stringify(newFontStyle) +
        "_" +
        JSON.stringify(newFontFamily) +
        "_" +
        JSON.stringify(newLetterSpace) +
        "_" +
        JSON.stringify(newColorValue) +
        "_" +
        JSON.stringify(newStrokeValue) +
        "_" +
        JSON.stringify(newIsUpperCase) +
        "_" +
        JSON.stringify(newHAlign) +
        "_" +
        JSON.stringify(newVAlign) +
        "_" +
        JSON.stringify(newStrokeWidth) +
        "_" +
        JSON.stringify(newStrokeType) +
        "_" +
        JSON.stringify(newShadowWidth) +
        "_" +
        JSON.stringify(newRectDimension) +
        "_" +
        JSON.stringify(newTextPlaceholder) +
        "_" +
        JSON.stringify(newRectPosition) +
        "_" +
        JSON.stringify(newRotationScaleT) +
        "_" +
        JSON.stringify(newTextRectHeight) +
        "_" +
        JSON.stringify(newTextRectWidth)
    );
    setTextValue(newTextValue);
    setFontSize(newFontSize);
    setFontWeight(newFontWeight);
    setFontStyle(newFontStyle);
    setFontFamily(newFontFamily);
    setLetterSpace(newLetterSpace);
    setColorValue(newColorValue);
    setStrokeValue(newStrokeValue);
    setIsUpperCase(newIsUpperCase);
    setHAlign(newHAlign);
    setVAlign(newVAlign);
    setStrokeWidth(newStrokeWidth);
    setStrokeType(newStrokeType);
    setShadowWidth(newShadowWidth);
    setRectDimension(newRectDimension);
    setTextPlaceholder(newTextPlaceholder);
    setRectPosition(newRectPosition);
    setRotationScaleT(newRotationScaleT);
    setTextRectHeight(newTextRectHeight);
    setTextRectWidth(newTextRectWidth);
    deselectAllRect();
  };

  const addNewImage = useCallback(
    async (img, position) => {
      const getCurrentCanvas = () => {
        return new Promise(async (resolve, reject) => {
          try {
            var svg_output = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "svg"
            );
            svg_output.setAttribute(
              "width",
              Math.round(svgResolution.width / scaledImageRatio.current) + "px"
            );
            svg_output.setAttribute(
              "height",
              Math.round(svgResolution.height / scaledImageRatio.current) + "px"
            );
            svg_output.setAttribute("fill", "#eee");
            let svgText = await getSvgText(1 / scaledImageRatio.current);
            svg_output.innerHTML = `${svgText.innerHTML}`;
            var serializer = new XMLSerializer();
            var source = serializer.serializeToString(svg_output);
            var svg64 = b64EncodeUnicode(
              decodeURIComponent(encodeURIComponent(source))
            );
            var svgData = "data:image/svg+xml;base64," + svg64;
            var offScreenCanvas = document.createElement("canvas");
            var offScreenCanvasCTX = offScreenCanvas.getContext("2d");
            let height = svgResolution.height / scaledImageRatio.current;
            let width = svgResolution.width / scaledImageRatio.current;
            offScreenCanvas.height = height;
            offScreenCanvas.width = width;
            var image = new Image();
            image.src = svgData;
            image.onload = function () {
              setTimeout(() => {
                offScreenCanvasCTX.clearRect(0, 0, width, height);
                offScreenCanvasCTX.drawImage(image, 0, 0, width, height);
                resolve(offScreenCanvas.toDataURL("image/png", 1));
              }, 100);
            };
            image.onerror = (err) => {
              reject(err);
            };
          } catch (err) {
            reject(err);
          }
        });
      };
      let currentImageBase64;
      await getCurrentCanvas().then(
        (response) => {
          currentImageBase64 = response;
        },
        (err) => {
          console.log(err);
        }
      );
      var image = new Image();
      image.src = currentImageBase64;
      image.onload = function () {
        var offScreenCanvas = document.createElement("canvas");
        var offScreenCanvasCTX = offScreenCanvas.getContext("2d");
        let height = svgResolution.height / scaledImageRatio.current;
        let width = svgResolution.width / scaledImageRatio.current;
        let newSvgResolution;
        let newDefImageResolution;
        let newImageResolution;
        let newImagePosition;
        let newInitImage;
        let newInitResolution;
        if (position === 0 || position === 4) {
          let imgHeight =
            (width / img.imageResolution.width) * img.imageResolution.height;
          height = height + imgHeight;
          offScreenCanvas.height = height;
          offScreenCanvas.width = width;
          let newImg = new Image();
          newImg.src = img.data;
          newImg.onload = function () {
            const baseImageHeight =
              svgResolution.height / scaledImageRatio.current;
            offScreenCanvasCTX.clearRect(0, 0, width, height);
            if (position === 4) {
              offScreenCanvasCTX.drawImage(image, 0, 0, width, baseImageHeight);
              offScreenCanvasCTX.drawImage(
                newImg,
                0,
                baseImageHeight,
                width,
                imgHeight
              );
            }
            if (position === 0) {
              offScreenCanvasCTX.drawImage(newImg, 0, 0, width, imgHeight);
              offScreenCanvasCTX.drawImage(
                image,
                0,
                imgHeight,
                width,
                baseImageHeight
              );
            }
            let newImage = offScreenCanvas.toDataURL("image/jpeg", 1);
            let newImageHeight = height * scaledImageRatio.current;
            let newImageWidth = width * scaledImageRatio.current;
            let newDimensions = {
              width: newImageWidth,
              height: newImageHeight,
            };
            newSvgResolution = newDimensions;
            newDefImageResolution = newDimensions;
            newImageResolution = newDimensions;
            newInitResolution = newDimensions;

            newImagePosition = { x: 0, y: 0 };
            newInitImage = newImage;
            setundoredo(
              "modifyImage",
              "_" +
                JSON.stringify(svgResolution) +
                "_" +
                JSON.stringify(defImageResolution) +
                "_" +
                JSON.stringify(imageResolution) +
                "_" +
                JSON.stringify(initImage) +
                "_" +
                JSON.stringify(initResolution) +
                "_" +
                JSON.stringify(imagePosition) +
                "_" +
                JSON.stringify(spaceColor) +
                "_" +
                JSON.stringify(spaceBotEnable) +
                "_" +
                JSON.stringify(spaceTopEnable) +
                "_" +
                JSON.stringify(spaceValue) +
                "_" +
                JSON.stringify(rotationScale) +
                "_" +
                JSON.stringify(imgDimensionScale) +
                "_" +
                JSON.stringify(filterSelected) +
                "_" +
                JSON.stringify(brightnessScale) +
                "_" +
                JSON.stringify(contrastScale) +
                "_" +
                JSON.stringify(saturationScale) +
                "_" +
                JSON.stringify(warmthScale) +
                "_" +
                JSON.stringify(flipH) +
                "_" +
                JSON.stringify(flipV),
              "_" +
                JSON.stringify(newSvgResolution) +
                "_" +
                JSON.stringify(newDefImageResolution) +
                "_" +
                JSON.stringify(newImageResolution) +
                "_" +
                JSON.stringify(newInitImage) +
                "_" +
                JSON.stringify(newInitResolution) +
                "_" +
                JSON.stringify(newImagePosition) +
                "_" +
                JSON.stringify("#595959") +
                "_" +
                JSON.stringify(0) +
                "_" +
                JSON.stringify(0) +
                "_" +
                JSON.stringify(0) +
                "_" +
                JSON.stringify(0) +
                "_" +
                JSON.stringify(1) +
                "_" +
                JSON.stringify("nofilter") +
                "_" +
                JSON.stringify(0) +
                "_" +
                JSON.stringify(0) +
                "_" +
                JSON.stringify(0) +
                "_" +
                JSON.stringify(0) +
                "_" +
                JSON.stringify(1) +
                "_" +
                JSON.stringify(1)
            );
            setSvgResolution(newSvgResolution);
            setDefImageResolution(newDefImageResolution);
            setImageResolution(newImageResolution);
            setInitImage(newInitImage);
            setInitResolution(newInitResolution);
            setImagePosition(newImagePosition);
            setSpaceColor("#898989");
            setSpaceBotEnable(0);
            setSpaceTopEnable(0);
            setSpaceValue(0);
            setRotationScale(0);
            setImgDimensionScale(1);
            setFilterSelected("nofilter");
            setBrightnessScale(0);
            setContrastScale(0);
            setSaturationScale(0);
            setWarmthScale(0);
            setFlipH(1);
            setFlipV(1);
          };
        } else if (position === 1 || position === 3) {
          let imgWidth =
            (height / img.imageResolution.height) * img.imageResolution.width;
          width = imgWidth + width;
          offScreenCanvas.height = height;
          offScreenCanvas.width = width;
          let newImg = new Image();
          newImg.src = img.data;
          newImg.onload = function () {
            const baseImageWidth =
              svgResolution.width / scaledImageRatio.current;
            offScreenCanvasCTX.clearRect(0, 0, width, height);
            if (position === 1) {
              offScreenCanvasCTX.drawImage(
                image,
                imgWidth,
                0,
                baseImageWidth,
                height
              );
              offScreenCanvasCTX.drawImage(newImg, 0, 0, imgWidth, height);
            } else if (position === 3) {
              offScreenCanvasCTX.drawImage(image, 0, 0, baseImageWidth, height);
              offScreenCanvasCTX.drawImage(
                newImg,
                baseImageWidth,
                0,
                imgWidth,
                height
              );
            }
            let newImage = offScreenCanvas.toDataURL("image/jpeg", 1);
            let newImageHeight = height * scaledImageRatio.current;
            let newImageWidth = width * scaledImageRatio.current;
            let newDimensions = {
              width: newImageWidth,
              height: newImageHeight,
            };
            newSvgResolution = newDimensions;
            newDefImageResolution = newDimensions;
            newImageResolution = newDimensions;
            newInitResolution = newDimensions;
            newImagePosition = { x: 0, y: 0 };
            newInitImage = newImage;
            setundoredo(
              "modifyImage",
              "_" +
                JSON.stringify(svgResolution) +
                "_" +
                JSON.stringify(defImageResolution) +
                "_" +
                JSON.stringify(imageResolution) +
                "_" +
                JSON.stringify(initImage) +
                "_" +
                JSON.stringify(initResolution) +
                "_" +
                JSON.stringify(imagePosition) +
                "_" +
                JSON.stringify(spaceColor) +
                "_" +
                JSON.stringify(spaceBotEnable) +
                "_" +
                JSON.stringify(spaceTopEnable) +
                "_" +
                JSON.stringify(spaceValue) +
                "_" +
                JSON.stringify(rotationScale) +
                "_" +
                JSON.stringify(imgDimensionScale) +
                "_" +
                JSON.stringify(filterSelected) +
                "_" +
                JSON.stringify(brightnessScale) +
                "_" +
                JSON.stringify(contrastScale) +
                "_" +
                JSON.stringify(saturationScale) +
                "_" +
                JSON.stringify(warmthScale) +
                "_" +
                JSON.stringify(flipH) +
                "_" +
                JSON.stringify(flipV),
              "_" +
                JSON.stringify(newSvgResolution) +
                "_" +
                JSON.stringify(newDefImageResolution) +
                "_" +
                JSON.stringify(newImageResolution) +
                "_" +
                JSON.stringify(newInitImage) +
                "_" +
                JSON.stringify(newInitResolution) +
                "_" +
                JSON.stringify(newImagePosition) +
                "_" +
                JSON.stringify("#595959") +
                "_" +
                JSON.stringify(0) +
                "_" +
                JSON.stringify(0) +
                "_" +
                JSON.stringify(0) +
                "_" +
                JSON.stringify(0) +
                "_" +
                JSON.stringify(1) +
                "_" +
                JSON.stringify("nofilter") +
                "_" +
                JSON.stringify(0) +
                "_" +
                JSON.stringify(0) +
                "_" +
                JSON.stringify(0) +
                "_" +
                JSON.stringify(0) +
                "_" +
                JSON.stringify(1) +
                "_" +
                JSON.stringify(1)
            );
            setSvgResolution(newSvgResolution);
            setDefImageResolution(newDefImageResolution);
            setImageResolution(newImageResolution);
            setInitImage(newInitImage);
            setInitResolution(newInitResolution);
            setImagePosition(newImagePosition);
            setSpaceColor("#898989");
            setSpaceBotEnable(0);
            setSpaceTopEnable(0);
            setSpaceValue(0);
            setRotationScale(0);
            setImgDimensionScale(1);
            setFilterSelected("nofilter");
            setBrightnessScale(0);
            setContrastScale(0);
            setSaturationScale(0);
            setWarmthScale(0);
            setFlipH(1);
            setFlipV(1);
          };
        }
      };
    },
    [
      defImageResolution,
      imageResolution,
      imagePosition,
      initImage,
      spaceTopEnable,
      spaceBotEnable,
      spaceValue,
      brightnessScale,
      contrastScale,
      warmthScale,
      saturationScale,
      filterSelected,
      flipH,
      flipV,
      imgDimensionScale,
      rotationScale,
      spaceColor,
      svgResolution,
    ]
  );
  const setundoredo = (attr, currentV, newV) => {
    if (currentV !== newV) {
      if (functionStack.current[counter.current] === attr + "," + currentV) {
        let tempFunctionStack = [];
        for (let i = 0; i <= counter.current; i++) {
          tempFunctionStack.push(functionStack.current[i]);
        }
        functionStack.current = tempFunctionStack;
        functionStack.current.push(attr + "," + newV);
        counter.current = counter.current + 1;
      } else {
        let tempFunctionStack = [];
        for (let i = 0; i <= counter.current; i++) {
          tempFunctionStack.push(functionStack.current[i]);
        }
        functionStack.current = tempFunctionStack;
        functionStack.current.push(attr + "," + currentV, attr + "," + newV);
        counter.current = counter.current + 2;
      }
    }
  };
  const setdebouncedundoredo = (attr, newV) => {
    if (timeout.current !== undefined) {
      clearTimeout(timeout.current);
    }
    tempDebounceCounter.current = tempDebounceCounter.current + 1;
    if (tempDebounceCounter.current === 1) {
      if (attr === "textChange") {
        tempDebounceVar.current =
          "_" +
          JSON.stringify(textValue) +
          "_" +
          JSON.stringify(textRectWidth) +
          "_" +
          JSON.stringify(textRectHeight) +
          "_" +
          JSON.stringify(fontSize);
      } else if (attr === "setRotationScale") {
        tempDebounceVar.current = "_" + JSON.stringify(rotationScale);
      } else if (attr === "handleZoom") {
        tempDebounceVar.current =
          "_" +
          JSON.stringify(imgDimensionScale) +
          "_" +
          JSON.stringify(imagePosition);
      } else if (attr === "setContrastScale") {
        tempDebounceVar.current = "_" + JSON.stringify(contrastScale);
      } else if (attr === "setBrightnessScale") {
        tempDebounceVar.current = "_" + JSON.stringify(brightnessScale);
      } else if (attr === "setSaturationScale") {
        tempDebounceVar.current = "_" + JSON.stringify(saturationScale);
      } else if (attr === "setWarmthScale") {
        tempDebounceVar.current = "_" + JSON.stringify(warmthScale);
      }
    }
    timeout.current = setTimeout(() => {
      if (tempDebounceVar.current !== undefined) {
        if (
          functionStack.current[counter.current] ===
          attr + "," + tempDebounceVar.current
        ) {
          let tempFunctionStack = [];
          for (let i = 0; i <= counter.current; i++) {
            tempFunctionStack.push(functionStack.current[i]);
          }
          functionStack.current = tempFunctionStack;
          functionStack.current.push(attr + "," + newV);
          counter.current = counter.current + 1;
        } else {
          let tempFunctionStack = [];
          for (let i = 0; i <= counter.current; i++) {
            tempFunctionStack.push(functionStack.current[i]);
          }
          functionStack.current = tempFunctionStack;
          functionStack.current.push(
            attr + "," + tempDebounceVar.current,
            attr + "," + newV
          );
          counter.current = counter.current + 2;
        }
      }
      tempDebounceCounter.current = 0;
      tempDebounceVar.current = undefined;
    }, 1000);
  };
  const textChange = (value, index) => {
    let twhElement = document.getElementById("twh");
    twhElement.style.fontSize = fontSize[index] + "px";
    twhElement.style.fontFamily = fontFamily[index];
    twhElement.style.letterSpacing = letterSpace[index];
    twhElement.style.fontWeight = fontWeight[index] + "";
    twhElement.style.textTransform = isUpperCase[index] + "";
    let availableWidth = rectDimension[index].width;
    let availableHeight = rectDimension[index].height;
    let values = [];
    let stopIdx = 0;
    let counter = 0;
    let newRectWidth = 0;
    let newRectHeight = 0;
    let newFontSize = fontSize;
    if (value === "") {
      twhElement.innerHTML = "test";
      newRectHeight = fontSize[index];
      newRectWidth = fontSize[index];
    }
    for (let i = 1; i <= value.length; i++) {
      let lineChars = value.substring(stopIdx, i);
      twhElement.innerHTML = lineChars;
      let tspanWidth = twhElement.getBoundingClientRect().width;
      if (value[i - 1] === "\n") {
        counter++;
        if (i === 1) {
          stopIdx = 1;
          continue;
        } else {
          values.push("");
          stopIdx = i;
          continue;
        }
      } else {
        if (tspanWidth > newRectWidth) {
          newRectWidth = tspanWidth;
        }
        if (values.length === 0) {
          values[0] = lineChars;
        } else {
          values[values.length - 1] = lineChars;
        }
      }
      if (tspanWidth > availableWidth) {
        let previousFSize = fontSize[index];
        let newFSize = 0;
        for (let i = 0; i < previousFSize; i++) {
          twhElement.style.fontSize = fontSize[index] - i + "px";
          let tspanWidth = twhElement.getBoundingClientRect().width;
          if (tspanWidth <= availableWidth) {
            newRectWidth = tspanWidth;
            newFSize = fontSize[index] - i;
            break;
          }
        }
        newFontSize = fontSize.map((v, i) => {
          if (i === index) {
            return newFSize;
          } else {
            return v;
          }
        });
        if (tspanWidth > newRectWidth) {
          newRectWidth = tspanWidth;
        }
        values[counter] = lineChars;
      }
    }
    let k = "";
    let finalValues = [];
    for (let i = 0; i < values.length; i++) {
      k = k + `<tspan  x=0 dy="1em">${values[i]}</tspan>`;
      twhElement.innerHTML = k;
      let tSpanHeight = fontSize[index] + i * fontSize[index];
      if (tSpanHeight <= availableHeight) {
        finalValues.push(values[i]);
        newRectHeight = tSpanHeight;
      } else {
        break;
      }
    }
    let newTextRectWidth = textRectWidth.map((e) => e);
    let newTextRectHeight = textRectHeight.map((e) => e);
    let newTextValue = textValue.map((v, i) => {
      if (i === index) {
        newTextRectWidth[i] = newRectWidth;
        newTextRectHeight[i] = newRectHeight;
        return finalValues;
      } else {
        return v;
      }
    });
    setdebouncedundoredo(
      "textChange",
      "_" +
        JSON.stringify(newTextValue) +
        "_" +
        JSON.stringify(newTextRectWidth) +
        "_" +
        JSON.stringify(newTextRectHeight) +
        "_" +
        JSON.stringify(newFontSize)
    );
    setTextValue(newTextValue);
    setTextRectWidth(newTextRectWidth);
    setTextRectHeight(newTextRectHeight);
    setFontSize(newFontSize);
  };

  //For change font size for text
  const changeFontSize = (type, index, mobile_value) => {
    let step = 1;
    let fSize = fontSize[index];
    if (type === "+") {
      fSize = fSize + step;
    } else if (type === "-" && fSize > 13) {
      fSize = fSize - step;
    } else {
      let tempNumber = Number(type);
      if (typeof tempNumber === "number" && tempNumber >= 13) {
        fSize = tempNumber;
      } else if (tempNumber < 13) {
        fSize = 13;
      } else {
        return;
      }
    }
    if (type === "mobile") fSize = mobile_value;
    let twhElement = document.getElementById("twh");
    twhElement.style.fontSize = fSize + "px";
    twhElement.style.fontFamily = fontFamily[index];
    twhElement.style.letterSpacing = letterSpace[index];
    twhElement.style.fontWeight = fontWeight[index] + "";
    twhElement.style.textTransform = isUpperCase[index] + "";
    let insertedText = textValue[index];
    let k = "";
    let tspanHeight = 0;
    for (let i = 0; i < insertedText.length; i++) {
      k = k + `<tspan  x=0 dy="1em">${insertedText[i]}</tspan>`;
      twhElement.innerHTML = k;
      tspanHeight = tspanHeight + fSize;
    }
    let tspanWidth = twhElement.getBoundingClientRect().width;
    if (
      !(
        tspanHeight > rectDimension[index].height ||
        tspanWidth > rectDimension[index].width
      )
    ) {
      let newRectHeight = textRectHeight.map((e) => e);
      let newRectWidth = textRectWidth.map((e) => e);
      let newFontSize = fontSize.map((v, i) => {
        if (i === index) {
          newRectHeight[i] = tspanHeight;
          newRectWidth[i] = tspanWidth;
          return fSize;
        } else {
          return v;
        }
      });
      setundoredo(
        "changeFontSize",
        "_" +
          JSON.stringify(fontSize) +
          "_" +
          JSON.stringify(textRectWidth) +
          "_" +
          JSON.stringify(textRectHeight),
        "_" +
          JSON.stringify(newFontSize) +
          "_" +
          JSON.stringify(newRectWidth) +
          "_" +
          JSON.stringify(newRectHeight)
      );
      setFontSize(newFontSize);
      setTextRectHeight(newRectHeight);
      setTextRectWidth(newRectWidth);
    } else {
      let inputElement = document.getElementById("font-size-input" + index);
      inputElement.valueAsNumber = fontSize[index];
    }
  };

  const changeColor = (color, index) => {
    let a = colorValue.map((value, i) => {
      if (i === index) {
        return color;
      } else {
        return value;
      }
    });
    setundoredo(
      "setColorValue",
      "_" + JSON.stringify(colorValue),
      "_" + JSON.stringify(a)
    );
    setColorValue(a);
  };

  const changeStroke = (color, index) => {
    let a = strokeValue.map((value, i) => {
      if (i === index) {
        return color;
      } else {
        return value;
      }
    });
    setundoredo(
      "setStrokeValue",
      "_" + JSON.stringify(strokeValue),
      "_" + JSON.stringify(a)
    );
    setStrokeValue(a);
  };
  const changeFont = (font, index) => {
    let twhElement = document.getElementById("twh");
    twhElement.style.fontSize = fontSize[index] + "px";
    twhElement.style.fontFamily = font;
    twhElement.style.letterSpacing = letterSpace[index];
    twhElement.style.fontWeight = fontWeight[index] + "";
    twhElement.style.textTransform = isUpperCase[index] + "";
    let insertedText = textValue[index];
    let k = "";
    let tspanHeight = 0;
    for (let i = 0; i < insertedText.length; i++) {
      k = k + `<tspan  x=0 dy="1em">${insertedText[i]}</tspan>`;
      twhElement.innerHTML = k;
      tspanHeight = fontSize[index] + i * fontSize[index];
    }
    let tspanWidth = twhElement.getBoundingClientRect().width;
    if (tspanWidth > rectDimension[index].width) {
      let newFSize = 0;
      for (let i = 0; i < fontSize[index]; i++) {
        twhElement.style.fontSize = fontSize[index] - i + "px";
        tspanWidth = twhElement.getBoundingClientRect().width;
        if (tspanWidth <= rectDimension[index].width) {
          newFSize = fontSize[index] - i;
          break;
        }
      }
      let newFontSize = fontSize.map((v, i) => {
        if (i === index) {
          return newFSize;
        } else {
          return v;
        }
      });
      setFontSize(newFontSize);
    }
    let newRectHeight = textRectHeight.map((e) => e);
    let newRectWidth = textRectWidth.map((e) => e);
    let a = fontFamily.map((value, i) => {
      if (i === index) {
        newRectHeight[i] = tspanHeight;
        newRectWidth[i] = tspanWidth;
        return font;
      } else {
        return value;
      }
    });
    setundoredo(
      "changeFont",
      "_" +
        JSON.stringify(fontFamily) +
        "_" +
        JSON.stringify(textRectWidth) +
        "_" +
        JSON.stringify(textRectHeight),
      "_" +
        JSON.stringify(a) +
        "_" +
        JSON.stringify(newRectWidth) +
        "_" +
        JSON.stringify(newRectHeight)
    );
    setTextRectHeight(newRectHeight);
    setTextRectWidth(newRectWidth);
    setFontFamily(a);
  };
  const changeFontWeight = (index) => {
    let finalWeight = 0;
    if (fontWeight[index] === 400) {
      finalWeight = 800;
    } else {
      finalWeight = 400;
    }
    let twhElement = document.getElementById("twh");
    twhElement.style.fontSize = fontSize[index] + "px";
    twhElement.style.fontFamily = fontFamily[index];
    twhElement.style.letterSpacing = letterSpace[index];
    twhElement.style.fontWeight = finalWeight + "";
    twhElement.style.textTransform = isUpperCase[index] + "";
    let insertedText = textValue[index];
    let k = "";
    let tspanHeight = 0;
    for (let i = 0; i < insertedText.length; i++) {
      k = k + `<tspan  x=0 dy="1em">${insertedText[i]}</tspan>`;
      twhElement.innerHTML = k;
      tspanHeight = fontSize[index] + i * fontSize[index];
    }
    let tspanWidth = twhElement.getBoundingClientRect().width;
    if (tspanWidth > rectDimension[index].width) {
      let newFSize = 0;
      for (let i = 0; i < fontSize[index]; i++) {
        twhElement.style.fontSize = fontSize[index] - i + "px";
        tspanWidth = twhElement.getBoundingClientRect().width;
        if (tspanWidth <= rectDimension[index].width) {
          newFSize = fontSize[index] - i;
          break;
        }
      }
      let newFontSize = fontSize.map((v, i) => {
        if (i === index) {
          return newFSize;
        } else {
          return v;
        }
      });
      setFontSize(newFontSize);
    }
    let newRectHeight = textRectHeight.map((e) => e);
    let newRectWidth = textRectWidth.map((e) => e);
    let a = fontWeight.map((value, i) => {
      if (index === i) {
        newRectHeight[index] = tspanHeight;
        newRectWidth[index] = tspanWidth;
        return finalWeight;
      } else {
        return value;
      }
    });
    setundoredo(
      "changeFontWeight",
      "_" +
        JSON.stringify(fontWeight) +
        "_" +
        JSON.stringify(textRectWidth) +
        "_" +
        JSON.stringify(textRectHeight),
      "_" +
        JSON.stringify(a) +
        "_" +
        JSON.stringify(newRectWidth) +
        "_" +
        JSON.stringify(newRectHeight)
    );
    setFontWeight(a);
    setTextRectWidth(newRectWidth);
    setTextRectHeight(newRectHeight);
  };
  const changeFontStyle = (index) => {
    let finalStyle = "";
    if (fontStyle[index] === "normal") {
      finalStyle = "italic";
    } else {
      finalStyle = "normal";
    }
    let a = fontStyle.map((value, i) => {
      if (index === i) {
        return finalStyle;
      } else {
        return value;
      }
    });
    setundoredo(
      "changeFontStyle",
      "_" + JSON.stringify(fontStyle),
      "_" + JSON.stringify(a)
    );
    setFontStyle(a);
  };
  const changeHAlign = (halignval, index) => {
    let a = hAlign.map((value, i) => {
      if (i === index) {
        return halignval;
      } else {
        return value;
      }
    });
    setundoredo(
      "setHAlign",
      "_" + JSON.stringify(hAlign),
      "_" + JSON.stringify(a)
    );
    setHAlign(a);
  };
  const changeVAlign = (valignval, index) => {
    let a = vAlign.map((value, i) => {
      if (i === index) {
        return valignval;
      } else {
        return value;
      }
    });
    setundoredo(
      "setVAlign",
      "_" + JSON.stringify(vAlign),
      "_" + JSON.stringify(a)
    );
    setVAlign(a);
  };
  const changeToUpper = (index) => {
    let newIsUpperCase;
    if (isUpperCase[index] === "none") {
      newIsUpperCase = "uppercase";
    } else {
      newIsUpperCase = "none";
    }
    let a = isUpperCase.map((value, i) => {
      if (index === i) {
        return newIsUpperCase;
      } else {
        return value;
      }
    });
    setundoredo(
      "setIsUpperCase",
      "_" + JSON.stringify(isUpperCase),
      "_" + JSON.stringify(a)
    );
    setIsUpperCase(a);
  };
  const changeStrokeWidth = (type, index, mobile_value) => {
    let step = 1;
    let outLineSize = parseInt(strokeWidth[index]);
    if (type === "+") {
      outLineSize = outLineSize + step;
    } else if (type === "-" && outLineSize - step >= 0) {
      outLineSize = outLineSize - step;
    }
    if (type === "mobile") outLineSize = mobile_value;
    let a = strokeWidth.map((value, i) => {
      if (index === i) {
        return outLineSize + "px";
      } else {
        return value;
      }
    });
    setundoredo(
      "setStrokeWidth",
      "_" + JSON.stringify(strokeWidth),
      "_" + JSON.stringify(a)
    );
    setStrokeWidth(a);
  };
  const changeShadowWidth = (type, index, mobile_value) => {
    let step = 1;
    let outLineSize = parseInt(shadowWidth[index]);
    if (type === "+" && outLineSize + step <= 15) {
      outLineSize = outLineSize + step;
    } else if (type === "-" && outLineSize - step >= 0) {
      outLineSize = outLineSize - step;
    }
    if (type === "mobile") outLineSize = mobile_value;
    let a = shadowWidth.map((value, i) => {
      if (index === i) {
        return outLineSize + "px";
      } else {
        return value;
      }
    });
    setundoredo(
      "setShadowWidth",
      "_" + JSON.stringify(shadowWidth),
      "_" + JSON.stringify(a)
    );
    setShadowWidth(a);
  };
  const changeLetterSpacing = (type, index, mobile_value) => {
    let step = 1;
    let letterSpaceint = parseInt(letterSpace[index]);
    if (type === "+" && letterSpaceint + step <= 20) {
      letterSpaceint = letterSpaceint + step;
    } else if (type === "-" && letterSpaceint - step >= 0) {
      letterSpaceint = letterSpaceint - step;
    }
    if (type === "mobile") letterSpaceint = mobile_value;
    let twhElement = document.getElementById("twh");
    twhElement.style.fontSize = fontSize[index] + "px";
    twhElement.style.fontFamily = fontFamily[index];
    twhElement.style.letterSpacing = letterSpaceint + "px";
    twhElement.style.fontWeight = fontWeight[index] + "";
    twhElement.style.textTransform = isUpperCase[index] + "";
    let insertedText = textValue[index];
    let k = "";
    let tspanHeight = 0;
    for (let i = 0; i < insertedText.length; i++) {
      k = k + `<tspan  x=0 dy="1em">${insertedText[i]}</tspan>`;
      twhElement.innerHTML = k;
      tspanHeight = fontSize[index] + i * fontSize[index];
    }
    let tspanWidth = twhElement.getBoundingClientRect().width;
    if (tspanWidth > rectDimension[index].width) {
      let newFSize = 0;
      for (let i = 0; i < fontSize[index]; i++) {
        twhElement.style.fontSize = fontSize[index] - i + "px";
        tspanWidth = twhElement.getBoundingClientRect().width;
        if (tspanWidth <= rectDimension[index].width) {
          newFSize = fontSize[index] - i;
          break;
        }
      }
      let newFontSize = fontSize.map((v, i) => {
        if (i === index) {
          return newFSize;
        } else {
          return v;
        }
      });
      setFontSize(newFontSize);
    }

    let newRectHeight = textRectHeight.map((e) => e);
    let newRectWidth = textRectWidth.map((e) => e);
    let a = letterSpace.map((value, i) => {
      if (i === index) {
        newRectHeight[i] = tspanHeight;
        newRectWidth[i] = tspanWidth;
        return letterSpaceint + "px";
      } else {
        return value;
      }
    });
    setundoredo(
      "changeLetterSpacing",
      "_" +
        JSON.stringify(letterSpace) +
        "_" +
        JSON.stringify(textRectWidth) +
        "_" +
        JSON.stringify(textRectHeight),
      "_" +
        JSON.stringify(a) +
        "_" +
        JSON.stringify(newRectWidth) +
        "_" +
        JSON.stringify(newRectHeight)
    );
    setTextRectHeight(newRectHeight);
    setTextRectWidth(newRectWidth);
    setLetterSpace(a);
  };
  //Handle right click context menu event on any rect
  const handleContextMenu = (event, elementID) => {
    event.preventDefault();
    cursorPosition.current = { x: event.pageX, y: event.pageY };
  };
  //Handle rotation on rect
  const handleMouseDownOnRotation = (elementID, event, index) => {
    event.stopPropagation();
    event.preventDefault();
    isRectSelected.current = true;
    selectedElementID.current = elementID;
    textValue.map((text, i) => {
      document
        .getElementById("rect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("topleftrect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("toprightrect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("bottomleftrect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("bottomrightrect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("rotaterect" + i)
        .setAttribute("pointer-events", "none");
      return null;
    });
    layeredImagePosition.map((img, i) => {
      document
        .getElementById("rectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("topleftrectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("toprightrectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("bottomleftrectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("bottomrightrectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("rotaterectlayered" + i)
        .setAttribute("pointer-events", "none");
      return null;
    });
    selectedIndex.current = index;
    let currentElement = document.getElementById(elementID);
    currentElement.setAttribute("pointer-events", "fill");
    document
      .getElementById("topleft" + elementID)
      .setAttribute("pointer-events", "painted");
    document
      .getElementById("topright" + elementID)
      .setAttribute("pointer-events", "painted");
    document
      .getElementById("bottomleft" + elementID)
      .setAttribute("pointer-events", "painted");
    document
      .getElementById("bottomright" + elementID)
      .setAttribute("pointer-events", "painted");
    document
      .getElementById("rotate" + elementID)
      .setAttribute("pointer-events", "painted");
    let imageDiv = document.getElementById("svgCanvas");
    currentImgDivPosition = {
      x: imageDiv.getBoundingClientRect().left,
      y: imageDiv.getBoundingClientRect().top,
    };
    if (elementID.indexOf("layered") > 0) {
      setSelectedLayeredImage(index);
      document.body.addEventListener("mousemove", rotateRectL);
      document.body.addEventListener("mouseup", stopRotateL);
    } else {
      document.body.addEventListener("mousemove", rotateRectT);
      document.body.addEventListener("mouseup", stopRotateT);
    }
  };
  const rotateRectL = (event) => {
    isRectRotating.current = true;
    let newPositionX = event.clientX - currentImgDivPosition.x;
    let newPositionY = event.clientY - currentImgDivPosition.y;
    let elementPositionX =
      layeredImagePositionRef.current[selectedIndex.current].x;
    let elementPositionY =
      layeredImagePositionRef.current[selectedIndex.current].y;
    let elementDimensionWidth =
      layeredImageResolutionRef.current[selectedIndex.current].width;
    let elementDimensionHeight =
      layeredImageResolutionRef.current[selectedIndex.current].height;
    let elementCenterPointX = elementPositionX + elementDimensionWidth / 2;
    let elementCenterPointY = elementPositionY + elementDimensionHeight / 2;
    let angle =
      Math.atan2(
        newPositionY - elementCenterPointY,
        newPositionX - elementCenterPointX
      ) *
        57.296 -
      90;
    if (angle < 1.5 && angle > -1.5) {
      angle = 0;
    }
    let newRotationScale = rotationScaleL.map((value, index) => {
      if (index === selectedIndex.current) {
        return Math.round(angle);
      } else {
        return value;
      }
    });
    setRotationScaleL(newRotationScale);
    tempUndoVar1.current = newRotationScale;
  };
  const stopRotateL = (event) => {
    if (isRectRotating.current === true) {
      isRectRotating.current = false;
      deselectAllRect();
      setundoredo(
        "setRotationScaleL",
        "_" + JSON.stringify(rotationScaleL),
        "_" + JSON.stringify(tempUndoVar1.current)
      );
    }
    document.body.removeEventListener("mousemove", rotateRectL);
    document.body.removeEventListener("mouseup", stopRotateL);
  };
  const rotateRectT = (event) => {
    isRectRotating.current = true;
    let newPositionX = event.clientX - currentImgDivPosition.x;
    let newPositionY = event.clientY - currentImgDivPosition.y;
    let elementPositionX = rectPositionRef.current[selectedIndex.current].x;
    let elementPositionY = rectPositionRef.current[selectedIndex.current].y;
    let elementDimensionWidth =
      rectDimensionRef.current[selectedIndex.current].width;
    let elementDimensionHeight =
      rectDimensionRef.current[selectedIndex.current].height;
    let elementCenterPointX = elementPositionX + elementDimensionWidth / 2;
    let elementCenterPointY = elementPositionY + elementDimensionHeight / 2;
    let angle =
      Math.atan2(
        newPositionY - elementCenterPointY,
        newPositionX - elementCenterPointX
      ) *
        57.296 -
      90;
    if (angle < 2 && angle > -2) {
      angle = 0;
    }
    let newRotationScale = rotationScaleT.map((value, index) => {
      if (index === selectedIndex.current) {
        return Math.round(angle);
      } else {
        return value;
      }
    });
    setRotationScaleT(newRotationScale);
    tempUndoVar1.current = newRotationScale;
  };
  const stopRotateT = (event) => {
    if (isRectRotating.current === true) {
      isRectRotating.current = false;
      deselectAllRect();
      setundoredo(
        "setRotationScaleT",
        "_" + JSON.stringify(rotationScaleT),
        "_" + JSON.stringify(tempUndoVar1.current)
      );
    }
    document.body.removeEventListener("mousemove", rotateRectT);
    document.body.removeEventListener("mouseup", stopRotateT);
  };
  //Handle mousedown on resizers of layered images
  const handleMouseDownOnResizer = (elementID, event, position, index) => {
    event.stopPropagation();
    event.preventDefault();
    isRectSelected.current = true;
    selectedElementID.current = elementID;
    textValue.map((text, i) => {
      document
        .getElementById("rect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("topleftrect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("toprightrect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("bottomleftrect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("bottomrightrect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("rotaterect" + i)
        .setAttribute("pointer-events", "none");
      return null;
    });
    layeredImagePosition.map((img, i) => {
      document
        .getElementById("rectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("topleftrectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("toprightrectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("bottomleftrectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("bottomrightrectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("rotaterectlayered" + i)
        .setAttribute("pointer-events", "none");
      return null;
    });
    let currentElement = document.getElementById(elementID);
    currentElement.setAttribute("pointer-events", "fill");
    document
      .getElementById("topleft" + elementID)
      .setAttribute("pointer-events", "painted");
    document
      .getElementById("topright" + elementID)
      .setAttribute("pointer-events", "painted");
    document
      .getElementById("bottomleft" + elementID)
      .setAttribute("pointer-events", "painted");
    document
      .getElementById("bottomright" + elementID)
      .setAttribute("pointer-events", "painted");
    document
      .getElementById("rotate" + elementID)
      .setAttribute("pointer-events", "painted");
    let imageDiv = document.getElementById("svgCanvas");
    currentImgDivPosition = {
      x: imageDiv.getBoundingClientRect().left,
      y: imageDiv.getBoundingClientRect().top,
    };
    cursorPosition.current = {
      x: event.clientX - currentImgDivPosition.x,
      y: event.clientY - currentImgDivPosition.y,
    };
    selectedIndex.current = index;
    if (elementID.indexOf("layered") > 0) {
      setSelectedLayeredImage(index);
      if (position === 3) {
        document.body.addEventListener("mousemove", resizeRectL3);
        document.body.addEventListener("mouseup", stopResizingL3);
      } else if (position === 1) {
        document.body.addEventListener("mousemove", resizeRectL1);
        document.body.addEventListener("mouseup", stopResizingL1);
      } else if (position === 2) {
        document.body.addEventListener("mousemove", resizeRectL2);
        document.body.addEventListener("mouseup", stopResizingL2);
      } else if (position === 0) {
        document.body.addEventListener("mousemove", resizeRectL0);
        document.body.addEventListener("mouseup", stopResizingL0);
      }
    } else {
      if (position === 3) {
        document.body.addEventListener("mousemove", resizeRectT3);
        document.body.addEventListener("mouseup", stopResizingT3);
      } else if (position === 1) {
        document.body.addEventListener("mousemove", resizeRectT1);
        document.body.addEventListener("mouseup", stopResizingT1);
      } else if (position === 2) {
        document.body.addEventListener("mousemove", resizeRectT2);
        document.body.addEventListener("mouseup", stopResizingT2);
      } else if (position === 0) {
        document.body.addEventListener("mousemove", resizeRectT0);
        document.body.addEventListener("mouseup", stopResizingT0);
      }
    }
  };
  const resizeRectL2 = (e) => {
    isRectResizing.current = true;
    let newPositionX = e.clientX - currentImgDivPosition.x;
    let newPositionY = e.clientY - currentImgDivPosition.y;
    let elementWidth =
      layeredImageResolutionRef.current[selectedIndex.current].width;
    let elementHeight =
      layeredImageResolutionRef.current[selectedIndex.current].height;
    let elementPositionX =
      layeredImagePositionRef.current[selectedIndex.current].x;
    let elementPositionY =
      layeredImagePositionRef.current[selectedIndex.current].y;
    let availableWidth = elementPositionX + elementWidth;
    let availableHeight = svgResolution.height - elementPositionY;
    let minWidth = 40;
    let minHeight = 40;
    let tempWidth = 0;
    let tempHeight = 0;
    let tempPositionX = 0;
    if (newPositionX < 0) {
      tempWidth = availableWidth;
      tempPositionX = 0;
    } else if (newPositionX > elementPositionX + elementWidth - minWidth) {
      tempWidth = minWidth;
      tempPositionX = elementPositionX + elementWidth - minWidth;
    } else {
      let movedWidth = elementWidth - (newPositionX - cursorPosition.current.x);
      if (movedWidth < availableWidth) {
        tempWidth = movedWidth;
        tempPositionX = newPositionX;
      } else {
        tempWidth = availableWidth;
        tempPositionX = 0;
      }
    }
    if (newPositionY > svgResolution.height) {
      tempHeight = availableHeight;
    } else if (newPositionY < elementPositionY + minHeight) {
      tempHeight = minHeight;
    } else {
      let movedHeight =
        elementHeight + (newPositionY - cursorPosition.current.y);
      if (movedHeight < availableHeight) {
        tempHeight = movedHeight;
      } else {
        tempHeight = availableHeight;
      }
    }
    cursorPosition.current = { x: tempPositionX, y: newPositionY };
    let newLayeredImageResolution = layeredImageResolutionRef.current.map(
      (value, i) => {
        if (i === selectedIndex.current) {
          return { width: tempWidth, height: tempHeight };
        } else {
          return value;
        }
      }
    );
    setLayeredImageResolution(newLayeredImageResolution);
    let newLayeredImagePosition = layeredImagePositionRef.current.map(
      (value, i) => {
        if (i === selectedIndex.current) {
          return { x: tempPositionX, y: elementPositionY };
        } else {
          return value;
        }
      }
    );
    setLayeredImagePosition(newLayeredImagePosition);
    tempUndoVar1.current = newLayeredImageResolution;
    tempUndoVar2.current = newLayeredImagePosition;
  };
  const stopResizingL2 = (e) => {
    if (isRectResizing.current === true) {
      isRectResizing.current = false;
      deselectAllRect();
      setundoredo(
        "resizeLayeredImage",
        "_" +
          JSON.stringify(layeredImageResolution) +
          "_" +
          JSON.stringify(layeredImagePosition),
        "_" +
          JSON.stringify(tempUndoVar1.current) +
          "_" +
          JSON.stringify(tempUndoVar2.current)
      );
    }
    document.body.removeEventListener("mousemove", resizeRectL2);
    document.body.removeEventListener("mouseup", stopResizingL2);
  };
  const resizeRectL0 = (e) => {
    isRectResizing.current = true;
    let newPositionX = e.clientX - currentImgDivPosition.x;
    let newPositionY = e.clientY - currentImgDivPosition.y;
    let elementWidth =
      layeredImageResolutionRef.current[selectedIndex.current].width;
    let elementHeight =
      layeredImageResolutionRef.current[selectedIndex.current].height;
    let elementPositionX =
      layeredImagePositionRef.current[selectedIndex.current].x;
    let elementPositionY =
      layeredImagePositionRef.current[selectedIndex.current].y;
    let availableWidth = elementPositionX + elementWidth;
    let availableHeight = elementPositionY + elementHeight;
    let minWidth = 40;
    let minHeight = 40;
    let tempWidth = 0;
    let tempHeight = 0;
    let tempPositionX = 0;
    let tempPositionY = 0;
    if (newPositionX < 0) {
      tempWidth = availableWidth;
      tempPositionX = 0;
    } else if (newPositionX > elementPositionX + elementWidth - minWidth) {
      tempWidth = minWidth;
      tempPositionX = elementPositionX + elementWidth - minWidth;
    } else {
      let movedWidth = elementWidth - (newPositionX - cursorPosition.current.x);
      if (movedWidth < availableWidth) {
        tempWidth = movedWidth;
        tempPositionX = newPositionX;
      } else {
        tempWidth = availableWidth;
        tempPositionX = 0;
      }
    }
    if (newPositionY < 0) {
      tempHeight = availableHeight;
      tempPositionY = 0;
    } else if (newPositionY > elementPositionY + elementHeight - minHeight) {
      tempHeight = minHeight;
      tempPositionY = elementPositionY + elementHeight - minHeight;
    } else {
      let movedHeight =
        elementHeight - (newPositionY - cursorPosition.current.y);
      if (movedHeight < availableHeight) {
        tempHeight = movedHeight;
        tempPositionY = newPositionY;
      } else {
        tempHeight = availableHeight;
        tempPositionY = 0;
      }
    }
    cursorPosition.current = { x: tempPositionX, y: tempPositionY };
    let newLayeredImageResolution = layeredImageResolutionRef.current.map(
      (value, i) => {
        if (i === selectedIndex.current) {
          return { width: tempWidth, height: tempHeight };
        } else {
          return value;
        }
      }
    );
    setLayeredImageResolution(newLayeredImageResolution);
    let newLayeredImagePosition = layeredImagePositionRef.current.map(
      (value, i) => {
        if (i === selectedIndex.current) {
          return { x: tempPositionX, y: tempPositionY };
        } else {
          return value;
        }
      }
    );
    setLayeredImagePosition(newLayeredImagePosition);
    tempUndoVar1.current = newLayeredImageResolution;
    tempUndoVar2.current = newLayeredImagePosition;
  };
  const stopResizingL0 = (e) => {
    if (isRectResizing.current === true) {
      isRectResizing.current = false;
      deselectAllRect();
      setundoredo(
        "resizeLayeredImage",
        "_" +
          JSON.stringify(layeredImageResolution) +
          "_" +
          JSON.stringify(layeredImagePosition),
        "_" +
          JSON.stringify(tempUndoVar1.current) +
          "_" +
          JSON.stringify(tempUndoVar2.current)
      );
    }
    document.body.removeEventListener("mousemove", resizeRectL0);
    document.body.removeEventListener("mouseup", stopResizingL0);
  };
  const resizeRectL1 = (e) => {
    isRectResizing.current = true;
    let newPositionX = e.clientX - currentImgDivPosition.x;
    let newPositionY = e.clientY - currentImgDivPosition.y;
    let elementWidth =
      layeredImageResolutionRef.current[selectedIndex.current].width;
    let elementHeight =
      layeredImageResolutionRef.current[selectedIndex.current].height;
    let elementPositionX =
      layeredImagePositionRef.current[selectedIndex.current].x;
    let elementPositionY =
      layeredImagePositionRef.current[selectedIndex.current].y;
    let availableWidth = svgResolution.width - elementPositionX;
    let availableHeight = elementPositionY + elementHeight;
    let minWidth = 40;
    let minHeight = 40;
    let tempWidth = 0;
    let tempHeight = 0;
    let tempPositionY = 0;
    if (newPositionX > svgResolution.width) {
      tempWidth = availableWidth;
    } else if (newPositionX < elementPositionX + minWidth) {
      tempWidth = minWidth;
    } else {
      let movedWidth = elementWidth + (newPositionX - cursorPosition.current.x);
      if (movedWidth < availableWidth) {
        tempWidth = movedWidth;
      } else {
        tempWidth = availableWidth;
      }
    }
    if (newPositionY < 0) {
      tempHeight = availableHeight;
      tempPositionY = 0;
    } else if (newPositionY > elementPositionY + elementHeight - minHeight) {
      tempHeight = minHeight;
      tempPositionY = elementPositionY + elementHeight - minHeight;
    } else {
      let movedHeight =
        elementHeight - (newPositionY - cursorPosition.current.y);
      if (movedHeight < availableHeight) {
        tempHeight = movedHeight;
        tempPositionY = newPositionY;
      } else {
        tempHeight = availableHeight;
        tempPositionY = 0;
      }
    }
    cursorPosition.current = { x: newPositionX, y: tempPositionY };
    let newLayeredImageResolution = layeredImageResolutionRef.current.map(
      (value, i) => {
        if (i === selectedIndex.current) {
          return { width: tempWidth, height: tempHeight };
        } else {
          return value;
        }
      }
    );
    setLayeredImageResolution(newLayeredImageResolution);
    let newLayeredImagePosition = layeredImagePositionRef.current.map(
      (value, i) => {
        if (i === selectedIndex.current) {
          return { x: elementPositionX, y: tempPositionY };
        } else {
          return value;
        }
      }
    );
    setLayeredImagePosition(newLayeredImagePosition);
    tempUndoVar1.current = newLayeredImageResolution;
    tempUndoVar2.current = newLayeredImagePosition;
  };
  const stopResizingL1 = (e) => {
    if (isRectResizing.current === true) {
      isRectResizing.current = false;
      deselectAllRect();
      setundoredo(
        "resizeLayeredImage",
        "_" +
          JSON.stringify(layeredImageResolution) +
          "_" +
          JSON.stringify(layeredImagePosition),
        "_" +
          JSON.stringify(tempUndoVar1.current) +
          "_" +
          JSON.stringify(tempUndoVar2.current)
      );
    }
    document.body.removeEventListener("mousemove", resizeRectL1);
    document.body.removeEventListener("mouseup", stopResizingL1);
  };
  const resizeRectL3 = (e) => {
    isRectResizing.current = true;
    let newPositionX = e.clientX - currentImgDivPosition.x;
    let newPositionY = e.clientY - currentImgDivPosition.y;
    let elementWidth =
      layeredImageResolutionRef.current[selectedIndex.current].width;
    let elementHeight =
      layeredImageResolutionRef.current[selectedIndex.current].height;
    let elementPositionX =
      layeredImagePositionRef.current[selectedIndex.current].x;
    let elementPositionY =
      layeredImagePositionRef.current[selectedIndex.current].y;
    let availableWidth = svgResolution.width - elementPositionX;
    let availableHeight = svgResolution.height - elementPositionY;
    let minWidth = 40;
    let minHeight = 40;
    let tempWidth = 0;
    let tempHeight = 0;
    if (newPositionX > svgResolution.width) {
      tempWidth = availableWidth;
    } else if (newPositionX < elementPositionX + minWidth) {
      tempWidth = minWidth;
    } else {
      let movedWidth = elementWidth + (newPositionX - cursorPosition.current.x);
      if (movedWidth < availableWidth) {
        tempWidth = movedWidth;
      } else {
        tempWidth = availableWidth;
      }
    }
    if (newPositionY > svgResolution.height) {
      tempHeight = availableHeight;
    } else if (newPositionY < elementPositionY + minHeight) {
      tempHeight = minHeight;
    } else {
      let movedHeight =
        elementHeight + (newPositionY - cursorPosition.current.y);
      if (movedHeight < availableHeight) {
        tempHeight = movedHeight;
      } else {
        tempHeight = availableHeight;
      }
    }
    cursorPosition.current = { x: newPositionX, y: newPositionY };
    let newLayeredImageResolution = layeredImageResolutionRef.current.map(
      (value, i) => {
        if (i === selectedIndex.current) {
          return { width: tempWidth, height: tempHeight };
        } else {
          return value;
        }
      }
    );
    setLayeredImageResolution(newLayeredImageResolution);
    tempUndoVar1.current = newLayeredImageResolution;
  };
  const stopResizingL3 = (e) => {
    if (isRectResizing.current === true) {
      isRectResizing.current = false;
      deselectAllRect();
      setundoredo(
        "setLayeredImageResolution",
        "_" + JSON.stringify(layeredImageResolution),
        "_" + JSON.stringify(tempUndoVar1.current)
      );
    }
    document.body.removeEventListener("mousemove", resizeRectL3);
    document.body.removeEventListener("mouseup", stopResizingL3);
  };
  const resizeRectT2 = (e) => {
    isRectResizing.current = true;
    let newPositionX = e.clientX - currentImgDivPosition.x;
    let newPositionY = e.clientY - currentImgDivPosition.y;
    let elementWidth = rectDimensionRef.current[selectedIndex.current].width;
    let elementHeight = rectDimensionRef.current[selectedIndex.current].height;
    let elementPositionX = rectPositionRef.current[selectedIndex.current].x;
    let elementPositionY = rectPositionRef.current[selectedIndex.current].y;
    let availableWidth = elementPositionX + elementWidth;
    let availableHeight = svgResolution.height - elementPositionY;
    let minWidth = textRectWidthRef.current[selectedIndex.current];
    let minHeight = textRectHeightRef.current[selectedIndex.current];
    let tempWidth = 0;
    let tempHeight = 0;
    let tempPositionX = 0;
    if (newPositionX < 0) {
      tempWidth = availableWidth;
      tempPositionX = 0;
    } else if (newPositionX > elementPositionX + elementWidth - minWidth) {
      tempWidth = minWidth;
      tempPositionX = elementPositionX + elementWidth - minWidth;
    } else {
      let movedWidth = elementWidth - (newPositionX - cursorPosition.current.x);
      if (movedWidth < availableWidth) {
        tempWidth = movedWidth;
        tempPositionX = newPositionX;
      } else {
        tempWidth = availableWidth;
        tempPositionX = 0;
      }
    }
    if (newPositionY > svgResolution.height) {
      tempHeight = availableHeight;
    } else if (newPositionY < elementPositionY + minHeight) {
      tempHeight = minHeight;
    } else {
      let movedHeight =
        elementHeight + (newPositionY - cursorPosition.current.y);
      if (movedHeight < availableHeight) {
        tempHeight = movedHeight;
      } else {
        tempHeight = availableHeight;
      }
    }
    cursorPosition.current = { x: tempPositionX, y: newPositionY };
    let newRectDimension = rectDimensionRef.current.map((value, i) => {
      if (i === selectedIndex.current) {
        return { width: tempWidth, height: tempHeight };
      } else {
        return value;
      }
    });
    setRectDimension(newRectDimension);
    let newRectPosition = rectPositionRef.current.map((value, i) => {
      if (i === selectedIndex.current) {
        return { x: tempPositionX, y: elementPositionY };
      } else {
        return value;
      }
    });
    setRectPosition(newRectPosition);
    tempUndoVar1.current = newRectDimension;
    tempUndoVar2.current = newRectPosition;
  };
  const stopResizingT2 = (e) => {
    if (isRectResizing.current === true) {
      isRectResizing.current = false;
      deselectAllRect();
      setundoredo(
        "resizeRect",
        "_" +
          JSON.stringify(rectDimension) +
          "_" +
          JSON.stringify(rectPosition),
        "_" +
          JSON.stringify(tempUndoVar1.current) +
          "_" +
          JSON.stringify(tempUndoVar2.current)
      );
    }
    document.body.removeEventListener("mousemove", resizeRectT2);
    document.body.removeEventListener("mouseup", stopResizingT2);
  };
  const resizeRectT0 = (e) => {
    isRectResizing.current = true;
    let newPositionX = e.clientX - currentImgDivPosition.x;
    let newPositionY = e.clientY - currentImgDivPosition.y;
    let elementWidth = rectDimensionRef.current[selectedIndex.current].width;
    let elementHeight = rectDimensionRef.current[selectedIndex.current].height;
    let elementPositionX = rectPositionRef.current[selectedIndex.current].x;
    let elementPositionY = rectPositionRef.current[selectedIndex.current].y;
    let availableWidth = elementPositionX + elementWidth;
    let availableHeight = elementPositionY + elementHeight;
    let minWidth = textRectWidthRef.current[selectedIndex.current];
    let minHeight = textRectHeightRef.current[selectedIndex.current];
    let tempWidth = 0;
    let tempHeight = 0;
    let tempPositionX = 0;
    let tempPositionY = 0;
    if (newPositionX < 0) {
      tempWidth = availableWidth;
      tempPositionX = 0;
    } else if (newPositionX > elementPositionX + elementWidth - minWidth) {
      tempWidth = minWidth;
      tempPositionX = elementPositionX + elementWidth - minWidth;
    } else {
      let movedWidth = elementWidth - (newPositionX - cursorPosition.current.x);
      if (movedWidth < availableWidth) {
        tempWidth = movedWidth;
        tempPositionX = newPositionX;
      } else {
        tempWidth = availableWidth;
        tempPositionX = 0;
      }
    }
    if (newPositionY < 0) {
      tempHeight = availableHeight;
      tempPositionY = 0;
    } else if (newPositionY > elementPositionY + elementHeight - minHeight) {
      tempHeight = minHeight;
      tempPositionY = elementPositionY + elementHeight - minHeight;
    } else {
      let movedHeight =
        elementHeight - (newPositionY - cursorPosition.current.y);
      if (movedHeight < availableHeight) {
        tempHeight = movedHeight;
        tempPositionY = newPositionY;
      } else {
        tempHeight = availableHeight;
        tempPositionY = 0;
      }
    }
    cursorPosition.current = { x: tempPositionX, y: tempPositionY };
    let newRectDimension = rectDimensionRef.current.map((value, i) => {
      if (i === selectedIndex.current) {
        return { width: tempWidth, height: tempHeight };
      } else {
        return value;
      }
    });
    setRectDimension(newRectDimension);
    let newRectPosition = rectPositionRef.current.map((value, i) => {
      if (i === selectedIndex.current) {
        return { x: tempPositionX, y: tempPositionY };
      } else {
        return value;
      }
    });
    setRectPosition(newRectPosition);
    tempUndoVar1.current = newRectDimension;
    tempUndoVar2.current = newRectPosition;
  };
  const stopResizingT0 = (e) => {
    if (isRectResizing.current === true) {
      isRectResizing.current = false;
      deselectAllRect();
      setundoredo(
        "resizeRect",
        "_" +
          JSON.stringify(rectDimension) +
          "_" +
          JSON.stringify(rectPosition),
        "_" +
          JSON.stringify(tempUndoVar1.current) +
          "_" +
          JSON.stringify(tempUndoVar2.current)
      );
    }
    document.body.removeEventListener("mousemove", resizeRectT0);
    document.body.removeEventListener("mouseup", stopResizingT0);
  };
  const resizeRectT1 = (e) => {
    isRectResizing.current = true;
    let newPositionX = e.clientX - currentImgDivPosition.x;
    let newPositionY = e.clientY - currentImgDivPosition.y;
    let elementWidth = rectDimensionRef.current[selectedIndex.current].width;
    let elementHeight = rectDimensionRef.current[selectedIndex.current].height;
    let elementPositionX = rectPositionRef.current[selectedIndex.current].x;
    let elementPositionY = rectPositionRef.current[selectedIndex.current].y;
    let availableWidth = svgResolution.width - elementPositionX;
    let availableHeight = elementPositionY + elementHeight;
    let minWidth = textRectWidthRef.current[selectedIndex.current];
    let minHeight = textRectHeightRef.current[selectedIndex.current];
    let tempWidth = 0;
    let tempHeight = 0;
    let tempPositionY = 0;
    if (newPositionX > svgResolution.width) {
      tempWidth = availableWidth;
    } else if (newPositionX < elementPositionX + minWidth) {
      tempWidth = minWidth;
    } else {
      let movedWidth = elementWidth + (newPositionX - cursorPosition.current.x);
      if (movedWidth < availableWidth) {
        tempWidth = movedWidth;
      } else {
        tempWidth = availableWidth;
      }
    }
    if (newPositionY < 0) {
      tempHeight = availableHeight;
      tempPositionY = 0;
    } else if (newPositionY > elementPositionY + elementHeight - minHeight) {
      tempHeight = minHeight;
      tempPositionY = elementPositionY + elementHeight - minHeight;
    } else {
      let movedHeight =
        elementHeight - (newPositionY - cursorPosition.current.y);
      if (movedHeight < availableHeight) {
        tempHeight = movedHeight;
        tempPositionY = newPositionY;
      } else {
        tempHeight = availableHeight;
        tempPositionY = 0;
      }
    }
    cursorPosition.current = { x: newPositionX, y: tempPositionY };
    let newRectDimension = rectDimensionRef.current.map((value, i) => {
      if (i === selectedIndex.current) {
        return { width: tempWidth, height: tempHeight };
      } else {
        return value;
      }
    });
    setRectDimension(newRectDimension);
    let newRectPosition = rectPositionRef.current.map((value, i) => {
      if (i === selectedIndex.current) {
        return { x: elementPositionX, y: tempPositionY };
      } else {
        return value;
      }
    });
    setRectPosition(newRectPosition);
    tempUndoVar1.current = newRectDimension;
    tempUndoVar2.current = newRectPosition;
  };
  const stopResizingT1 = (e) => {
    if (isRectResizing.current === true) {
      isRectResizing.current = false;
      deselectAllRect();
      setundoredo(
        "resizeRect",
        "_" +
          JSON.stringify(rectDimension) +
          "_" +
          JSON.stringify(rectPosition),
        "_" +
          JSON.stringify(tempUndoVar1.current) +
          "_" +
          JSON.stringify(tempUndoVar2.current)
      );
    }
    document.body.removeEventListener("mousemove", resizeRectT1);
    document.body.removeEventListener("mouseup", stopResizingT1);
  };
  const resizeRectT3 = (e) => {
    isRectResizing.current = true;
    let newPositionX = e.clientX - currentImgDivPosition.x;
    let newPositionY = e.clientY - currentImgDivPosition.y;
    let elementWidth = rectDimensionRef.current[selectedIndex.current].width;
    let elementHeight = rectDimensionRef.current[selectedIndex.current].height;
    let elementPositionX = rectPositionRef.current[selectedIndex.current].x;
    let elementPositionY = rectPositionRef.current[selectedIndex.current].y;
    let availableWidth = svgResolution.width - elementPositionX;
    let availableHeight = svgResolution.height - elementPositionY;
    let minWidth = textRectWidthRef.current[selectedIndex.current];
    let minHeight = textRectHeightRef.current[selectedIndex.current];
    let tempWidth = 0;
    let tempHeight = 0;
    if (newPositionX > svgResolution.width) {
      tempWidth = availableWidth;
    } else if (newPositionX < elementPositionX + minWidth) {
      tempWidth = minWidth;
    } else {
      let movedWidth = elementWidth + (newPositionX - cursorPosition.current.x);
      if (movedWidth < availableWidth) {
        tempWidth = movedWidth;
      } else {
        tempWidth = availableWidth;
      }
    }
    if (newPositionY > svgResolution.height) {
      tempHeight = availableHeight;
    } else if (newPositionY < elementPositionY + minHeight) {
      tempHeight = minHeight;
    } else {
      let movedHeight =
        elementHeight + (newPositionY - cursorPosition.current.y);
      if (movedHeight < availableHeight) {
        tempHeight = movedHeight;
      } else {
        tempHeight = availableHeight;
      }
    }
    cursorPosition.current = { x: newPositionX, y: newPositionY };
    let newRectDimension = rectDimensionRef.current.map((value, i) => {
      if (i === selectedIndex.current) {
        return { width: tempWidth, height: tempHeight };
      } else {
        return value;
      }
    });
    setRectDimension(newRectDimension);
    tempUndoVar1.current = newRectDimension;
  };
  const stopResizingT3 = (e) => {
    if (isRectResizing.current === true) {
      isRectResizing.current = false;
      deselectAllRect();
      setundoredo(
        "setRectDimension",
        "_" + JSON.stringify(rectDimension),
        "_" + JSON.stringify(tempUndoVar1.current)
      );
    }
    document.body.removeEventListener("mousemove", resizeRectT3);
    document.body.removeEventListener("mouseup", stopResizingT3);
  };
  const deselectAllRect = () => {
    if (selectedElementID.current !== "") {
      isRectSelected.current = false;
      selectedElementID.current = "";
      selectedIndex.current = -1;
      setSelectedLayeredImage(-1);
      for (let i = 0; i < rectPositionRef.current.length; i++) {
        document
          .getElementById("rect" + i)
          .setAttribute("pointer-events", "fill");
        document
          .getElementById("topleftrect" + i)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("toprightrect" + i)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("bottomleftrect" + i)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("bottomrightrect" + i)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("rotaterect" + i)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("grect" + i)
          .setAttribute("visibility", "hidden");
      }
      for (let j = 0; j < layeredImagePositionRef.current.length; j++) {
        document
          .getElementById("rectlayered" + j)
          .setAttribute("pointer-events", "fill");
        document
          .getElementById("topleftrectlayered" + j)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("toprightrectlayered" + j)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("bottomleftrectlayered" + j)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("bottomrightrectlayered" + j)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("rotaterectlayered" + j)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("grectlayered" + j)
          .setAttribute("visibility", "hidden");
      }
      document.getElementById("svgsvgcanvas").setAttribute("cursor", "auto");
    }
  };

  const showAllRect = () => {
    if (selectedElementID.current === "") {
      for (let i = 0; i < rectPositionRef.current.length; i++) {
        document
          .getElementById("rect" + i)
          .setAttribute("pointer-events", "fill");
        document
          .getElementById("topleftrect" + i)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("toprightrect" + i)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("bottomleftrect" + i)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("bottomrightrect" + i)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("rotaterect" + i)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("grect" + i)
          .setAttribute("visibility", "visible");
      }
      for (let j = 0; j < layeredImagePositionRef.current.length; j++) {
        document
          .getElementById("rectlayered" + j)
          .setAttribute("pointer-events", "fill");
        document
          .getElementById("topleftrectlayered" + j)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("toprightrectlayered" + j)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("bottomleftrectlayered" + j)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("bottomrightrectlayered" + j)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("rotaterectlayered" + j)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("grectlayered" + j)
          .setAttribute("visibility", "visible");
      }
      document.getElementById("svgsvgcanvas").setAttribute("cursor", "auto");
    }
  };

  const hideAllRect = () => {
    if (selectedElementID.current === "") {
      for (let i = 0; i < rectPositionRef.current.length; i++) {
        document
          .getElementById("rect" + i)
          .setAttribute("pointer-events", "fill");
        document
          .getElementById("topleftrect" + i)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("toprightrect" + i)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("bottomleftrect" + i)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("bottomrightrect" + i)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("rotaterect" + i)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("grect" + i)
          .setAttribute("visibility", "hidden");
      }
      for (let j = 0; j < layeredImagePositionRef.current.length; j++) {
        document
          .getElementById("rectlayered" + j)
          .setAttribute("pointer-events", "fill");
        document
          .getElementById("topleftrectlayered" + j)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("toprightrectlayered" + j)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("bottomleftrectlayered" + j)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("bottomrightrectlayered" + j)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("rotaterectlayered" + j)
          .setAttribute("pointer-events", "painted");
        document
          .getElementById("grectlayered" + j)
          .setAttribute("visibility", "hidden");
      }
      document.getElementById("svgsvgcanvas").setAttribute("cursor", "auto");
    }
  };

  const selectLayeredImage = useCallback(
    (index) => {
      isRectSelected.current = true;
      selectedElementID.current = "rectlayered" + index;
      selectedIndex.current = index;
      setSelectedLayeredImage(index);
      for (let i = 0; i < rectPosition.length; i++) {
        document
          .getElementById("rect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("topleftrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("toprightrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomleftrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomrightrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("rotaterect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("grect" + i)
          .setAttribute("visibility", "hidden");
      }
      for (let j = 0; j < layeredImagePosition.length; j++) {
        document
          .getElementById("rectlayered" + j)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("topleftrectlayered" + j)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("toprightrectlayered" + j)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomleftrectlayered" + j)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomrightrectlayered" + j)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("rotaterectlayered" + j)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("grectlayered" + j)
          .setAttribute("visibility", "hidden");
      }
      let elementID = "rectlayered" + index;
      let elementGID = "grectlayered" + index;
      let currentElement = document.getElementById(elementID);
      let currentGElement = document.getElementById(elementGID);
      currentElement.setAttribute("pointer-events", "fill");
      document
        .getElementById("topleft" + elementID)
        .setAttribute("pointer-events", "painted");
      document
        .getElementById("topright" + elementID)
        .setAttribute("pointer-events", "painted");
      document
        .getElementById("bottomleft" + elementID)
        .setAttribute("pointer-events", "painted");
      document
        .getElementById("bottomright" + elementID)
        .setAttribute("pointer-events", "painted");
      document
        .getElementById("rotate" + elementID)
        .setAttribute("pointer-events", "painted");
      currentGElement.setAttribute("visibility", "visible");
    },
    [rectPosition, layeredImagePosition]
  );
  const moveLayeredImagePrevious = useCallback(
    (index) => {
      if (
        !(
          layeredImageResolution[index - 1] === undefined ||
          layeredImageResolution[index - 1] === null
        )
      ) {
        let newRotationScaleL = rotationScaleL.map((value, i) => {
          if (i === index - 1) {
            return rotationScaleL[index];
          }
          if (i === index) {
            return rotationScaleL[index - 1];
          } else {
            return value;
          }
        });
        let newLayeredImage = layeredImage.map((value, i) => {
          if (i === index - 1) {
            return layeredImage[index];
          }
          if (i === index) {
            return layeredImage[index - 1];
          } else {
            return value;
          }
        });
        let newLayeredImagePosition = layeredImagePosition.map((value, i) => {
          if (i === index - 1) {
            return layeredImagePosition[index];
          }
          if (i === index) {
            return layeredImagePosition[index - 1];
          } else {
            return value;
          }
        });
        let newLayeredImageResolution = layeredImageResolution.map(
          (value, i) => {
            if (i === index - 1) {
              return layeredImageResolution[index];
            }
            if (i === index) {
              return layeredImageResolution[index - 1];
            } else {
              return value;
            }
          }
        );
        let newScaledLayeredImage = scaledLayeredImage.map((value, i) => {
          if (i === index - 1) {
            return scaledLayeredImage[index];
          }
          if (i === index) {
            return scaledLayeredImage[index - 1];
          } else {
            return value;
          }
        });
        setundoredo(
          "moveLayeredImage",
          "_" +
            JSON.stringify(rotationScaleL) +
            "_" +
            JSON.stringify(layeredImagePosition) +
            "_" +
            JSON.stringify(layeredImageResolution) +
            "_" +
            JSON.stringify(layeredImage) +
            "_" +
            JSON.stringify(scaledLayeredImage),
          "_" +
            JSON.stringify(newRotationScaleL) +
            "_" +
            JSON.stringify(newLayeredImagePosition) +
            "_" +
            JSON.stringify(newLayeredImageResolution) +
            "_" +
            JSON.stringify(newLayeredImage) +
            "_" +
            JSON.stringify(newScaledLayeredImage)
        );
        setRotationScaleL(newRotationScaleL);
        setLayeredImagePosition(newLayeredImagePosition);
        setLayeredImageResolution(newLayeredImageResolution);
        setLayeredImage(newLayeredImage);
        setScaledLayeredImage(newScaledLayeredImage);
        deselectAllRect();
      }
    },
    [
      layeredImageResolution,
      layeredImagePosition,
      layeredImage,
      scaledLayeredImage,
      rotationScaleL,
    ]
  );

  const moveLayeredImageNext = useCallback(
    (index) => {
      if (
        !(
          layeredImageResolution[index + 1] === undefined ||
          layeredImageResolution[index + 1] === null
        )
      ) {
        let newRotationScaleL = rotationScaleL.map((value, i) => {
          if (i === index) {
            return rotationScaleL[index + 1];
          }
          if (i === index + 1) {
            return rotationScaleL[index];
          } else {
            return value;
          }
        });
        let newLayeredImage = layeredImage.map((value, i) => {
          if (i === index) {
            return layeredImage[index + 1];
          }
          if (i === index + 1) {
            return layeredImage[index];
          } else {
            return value;
          }
        });
        let newLayeredImagePosition = layeredImagePosition.map((value, i) => {
          if (i === index) {
            return layeredImagePosition[index + 1];
          }
          if (i === index + 1) {
            return layeredImagePosition[index];
          } else {
            return value;
          }
        });
        let newLayeredImageResolution = layeredImageResolution.map(
          (value, i) => {
            if (i === index) {
              return layeredImageResolution[index + 1];
            }
            if (i === index + 1) {
              return layeredImageResolution[index];
            } else {
              return value;
            }
          }
        );
        let newScaledLayeredImage = scaledLayeredImage.map((value, i) => {
          if (i === index) {
            return scaledLayeredImage[index + 1];
          }
          if (i === index + 1) {
            return scaledLayeredImage[index];
          } else {
            return value;
          }
        });
        setundoredo(
          "moveLayeredImage",
          "_" +
            JSON.stringify(rotationScaleL) +
            "_" +
            JSON.stringify(layeredImagePosition) +
            "_" +
            JSON.stringify(layeredImageResolution) +
            "_" +
            JSON.stringify(layeredImage) +
            "_" +
            JSON.stringify(scaledLayeredImage),
          "_" +
            JSON.stringify(newRotationScaleL) +
            "_" +
            JSON.stringify(newLayeredImagePosition) +
            "_" +
            JSON.stringify(newLayeredImageResolution) +
            "_" +
            JSON.stringify(newLayeredImage) +
            "_" +
            JSON.stringify(newScaledLayeredImage)
        );
        setRotationScaleL(newRotationScaleL);
        setLayeredImagePosition(newLayeredImagePosition);
        setLayeredImageResolution(newLayeredImageResolution);
        setLayeredImage(newLayeredImage);
        setScaledLayeredImage(newScaledLayeredImage);
        deselectAllRect();
      }
    },
    [
      layeredImageResolution,
      layeredImagePosition,
      layeredImage,
      scaledLayeredImage,
      rotationScaleL,
    ]
  );

  //Handle mouse down event for canvas
  const handleMouseDownOnCanvas = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isRectDragging.current === false) {
      isRectSelected.current = true;
      selectedElementID.current = "svgsvgcanvas";
      textValue.map((text, i) => {
        document
          .getElementById("rect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("topleftrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("toprightrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomleftrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomrightrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("rotaterect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("grect" + i)
          .setAttribute("visibility", "hidden");
        return null;
      });
      layeredImagePosition.map((img, i) => {
        document
          .getElementById("rectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("topleftrectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("toprightrectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomleftrectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomrightrectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("rotaterectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("grectlayered" + i)
          .setAttribute("visibility", "hidden");
        return null;
      });
      document.getElementById("svgsvgcanvas").setAttribute("cursor", "move");
      let imageDiv = document.getElementById("svgCanvas");
      currentImgDivPosition = {
        x: imageDiv.getBoundingClientRect().left,
        y: imageDiv.getBoundingClientRect().top,
      };
      cursorPosition.current = {
        x: e.clientX - currentImgDivPosition.x,
        y: e.clientY - currentImgDivPosition.y,
      };

      document.body.addEventListener("mousemove", repositionCanvas);
      document.body.addEventListener("mouseup", stopRepositioningCanvas);
    }
  };
  //Handle mousemove event for canvas area
  const repositionCanvas = (e) => {
    isRectDragging.current = true;
    let newPositionX = e.clientX - currentImgDivPosition.x;
    let newPositionY = e.clientY - currentImgDivPosition.y;
    let movementX =
      (newPositionX - cursorPosition.current.x) / imgDimensionScale +
      imagePositionRef.current.x;
    let movementY =
      (newPositionY - cursorPosition.current.y) / imgDimensionScale +
      imagePositionRef.current.y;
    let limitedXSpace =
      (defImageResolution.width * (imgDimensionScale - 1)) /
      (2 * imgDimensionScale);
    let limitedYSpace =
      (defImageResolution.height * (imgDimensionScale - 1)) /
      (2 * imgDimensionScale);
    if (movementX < limitedXSpace * -1 || movementX > limitedXSpace) {
      movementX = imagePositionRef.current.x;
    }
    if (movementY < limitedYSpace * -1 || movementY > limitedYSpace) {
      movementY = imagePositionRef.current.y;
    }
    cursorPosition.current = { x: newPositionX, y: newPositionY };
    setImagePosition({ x: movementX, y: movementY });
    tempUndoVar1.current = { x: movementX, y: movementY };
  };
  //Handle mousedown event for canvas area
  const stopRepositioningCanvas = (e) => {
    if (isRectDragging.current === true) {
      isRectDragging.current = false;
      deselectAllRect();
      setundoredo(
        "setImagePosition",
        "_" + JSON.stringify(imagePosition),
        "_" + JSON.stringify(tempUndoVar1.current)
      );
    }
    document.body.removeEventListener("mousemove", repositionCanvas);
    document.body.removeEventListener("mouseup", stopRepositioningCanvas);
  };

  // Handle mouse entering the layered image
  const handleMouseEnterOnRect = (elementID) => {
    if (isRectDragging.current === false) {
      document
        .getElementById("g" + elementID)
        .setAttribute("visibility", "visible");
    }
  };
  // Handle mouse leaving the layered image
  const handleMouseLeaveOnRect = (elementID) => {
    if (isRectDragging.current === false && isRectSelected.current === false) {
      document
        .getElementById("g" + elementID)
        .setAttribute("visibility", "hidden");
    }
  };

  // Handle mouse down event for layered image
  const handleMouseDownOnRect = (e, elementID, index) => {
    e.stopPropagation();
    e.preventDefault();
    if (isRectResizing.current === false) {
      isRectSelected.current = true;
      selectedElementID.current = elementID;
      textValue.map((text, i) => {
        document
          .getElementById("rect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("topleftrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("toprightrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomleftrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomrightrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("rotaterect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("grect" + i)
          .setAttribute("visibility", "hidden");
        return null;
      });
      layeredImagePosition.map((img, i) => {
        document
          .getElementById("rectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("topleftrectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("toprightrectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomleftrectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomrightrectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("rotaterectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("grectlayered" + i)
          .setAttribute("visibility", "hidden");
        return null;
      });
      let currentElement = document.getElementById(elementID);
      currentElement.setAttribute("pointer-events", "fill");
      document
        .getElementById("topleft" + elementID)
        .setAttribute("pointer-events", "painted");
      document
        .getElementById("topright" + elementID)
        .setAttribute("pointer-events", "painted");
      document
        .getElementById("bottomleft" + elementID)
        .setAttribute("pointer-events", "painted");
      document
        .getElementById("bottomright" + elementID)
        .setAttribute("pointer-events", "painted");
      document
        .getElementById("rotate" + elementID)
        .setAttribute("pointer-events", "painted");
      document
        .getElementById("g" + elementID)
        .setAttribute("visibility", "visible");
      let imageDiv = document.getElementById("svgCanvas");
      currentImgDivPosition = {
        x: imageDiv.getBoundingClientRect().left,
        y: imageDiv.getBoundingClientRect().top,
      };
      cursorPosition.current = {
        x: e.clientX - currentImgDivPosition.x,
        y: e.clientY - currentImgDivPosition.y,
      };
      if (elementID.indexOf("layered") > 0) {
        selectedIndex.current = index;
        setSelectedLayeredImage(index);

        document.body.addEventListener("mousemove", repositionRectL);
        document.body.addEventListener("mouseup", stopRepositioningL);
      } else {
        selectedIndex.current = index;

        document.body.addEventListener("mousemove", repositionRectT);
        document.body.addEventListener("mouseup", stopRepositioningT);
      }
    }
  };
  // Handle mousemove event for Rect area
  const repositionRectT = (e) => {
    isRectDragging.current = true;
    let newPositionX = e.clientX - currentImgDivPosition.x;
    let newPositionY = e.clientY - currentImgDivPosition.y;
    if (newPositionX > svgResolution.width) {
      newPositionX = svgResolution.width;
    } else if (newPositionX <= 0) {
      newPositionX = 0;
    }
    if (newPositionY > svgResolution.height) {
      newPositionY = svgResolution.height;
    } else if (newPositionY <= 0) {
      newPositionY = 0;
    }
    let svgWidth = Math.floor(svgResolution.width);
    let svgHeight = Math.floor(svgResolution.height);
    let rectWidth = rectDimensionRef.current[selectedIndex.current].width;
    let rectHeight = rectDimensionRef.current[selectedIndex.current].height;
    let movementX;
    let movementY;
    movementX =
      newPositionX -
      cursorPosition.current.x +
      rectPositionRef.current[selectedIndex.current].x;
    movementY =
      newPositionY -
      cursorPosition.current.y +
      rectPositionRef.current[selectedIndex.current].y;
    cursorPosition.current = { x: newPositionX, y: newPositionY };
    if (movementX + rectWidth > svgWidth) {
      movementX = svgWidth - rectWidth;
    } else if (movementX <= 0) {
      movementX = 0;
    }
    if (movementY + rectHeight > svgHeight) {
      movementY = svgHeight - rectHeight;
    } else if (movementY <= 0) {
      movementY = 0;
    }
    let tempImagePosition = rectPositionRef.current.map((value, index) => {
      if (selectedIndex.current === index) {
        return { x: movementX, y: movementY };
      } else {
        return value;
      }
    });
    setRectPosition(tempImagePosition);
    tempUndoVar1.current = tempImagePosition;
  };
  //Handle mousedown event for text area
  const stopRepositioningT = (e) => {
    if (isRectDragging.current === true) {
      isRectDragging.current = false;
      deselectAllRect();
      showAllRect();
      setundoredo(
        "setRectPosition",
        "_" + JSON.stringify(rectPosition),
        "_" + JSON.stringify(tempUndoVar1.current)
      );
    }
    document.body.removeEventListener("mousemove", repositionRectT);
    document.body.removeEventListener("mouseup", stopRepositioningT);
  };
  // Handle mousemove event for Layered Image
  const repositionRectL = (e) => {
    isRectDragging.current = true;
    let newPositionX = e.clientX - currentImgDivPosition.x;
    let newPositionY = e.clientY - currentImgDivPosition.y;
    if (newPositionX > svgResolution.width) {
      newPositionX = svgResolution.width;
    } else if (newPositionX <= 0) {
      newPositionX = 0;
    }
    if (newPositionY > svgResolution.height) {
      newPositionY = svgResolution.height;
    } else if (newPositionY <= 0) {
      newPositionY = 0;
    }
    let svgWidth = Math.floor(svgResolution.width);
    let svgHeight = Math.floor(svgResolution.height);
    let rectWidth =
      layeredImageResolutionRef.current[selectedIndex.current].width;
    let rectHeight =
      layeredImageResolutionRef.current[selectedIndex.current].height;
    let movementX;
    let movementY;
    movementX =
      newPositionX -
      cursorPosition.current.x +
      layeredImagePositionRef.current[selectedIndex.current].x;
    movementY =
      newPositionY -
      cursorPosition.current.y +
      layeredImagePositionRef.current[selectedIndex.current].y;
    cursorPosition.current = { x: newPositionX, y: newPositionY };
    if (movementX + rectWidth > svgWidth) {
      movementX = svgWidth - rectWidth;
    } else if (movementX <= 0) {
      movementX = 0;
    }
    if (movementY + rectHeight > svgHeight) {
      movementY = svgHeight - rectHeight;
    } else if (movementY <= 0) {
      movementY = 0;
    }
    let tempImagePosition = layeredImagePositionRef.current.map(
      (value, index) => {
        if (selectedIndex.current === index) {
          return { x: movementX, y: movementY };
        } else {
          return value;
        }
      }
    );
    setLayeredImagePosition(tempImagePosition);
    tempUndoVar1.current = tempImagePosition;
  };
  // Handle mousedown event for layered Image
  const stopRepositioningL = (e) => {
    if (isRectDragging.current === true) {
      isRectDragging.current = false;
      deselectAllRect();
      showAllRect();
      setundoredo(
        "setLayeredImagePosition",
        "_" + JSON.stringify(layeredImagePosition),
        "_" + JSON.stringify(tempUndoVar1.current)
      );
    }
    document.body.removeEventListener("mousemove", repositionRectL);
    document.body.removeEventListener("mouseup", stopRepositioningL);
  };
  const undo = () => {
    if (counter.current > 0) {
      switch (functionStack.current[counter.current - 1].split(",")[0]) {
        case "setWarmthScale":
          setWarmthScale(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setBrightnessScale":
          setBrightnessScale(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setSaturationScale":
          setSaturationScale(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setContrastScale":
          setContrastScale(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setFilterSelected":
          setFilterSelected(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setRotationScale":
          setRotationScale(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setImgDimensionScale":
          setImgDimensionScale(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "handleZoom":
          setImgDimensionScale(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          setImagePosition(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[2])
          );
          break;
        case "setFlipH":
          setFlipH(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setFlipV":
          setFlipV(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "handleSpaceValue":
          setSvgResolution(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          setSpaceValue(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[2])
          );
          setRectPosition(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[3])
          );
          setLayeredImagePosition(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[4])
          );
          break;
        case "handleSpaceBotEnable":
          setSvgResolution(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          setSpaceBotEnable(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[2])
          );
          break;
        case "handleSpaceTopEnable":
          setSvgResolution(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          setSpaceTopEnable(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[2])
          );
          setRectPosition(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[3])
          );
          setLayeredImagePosition(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[4])
          );
          break;
        case "handleSpaceColor":
          setSpaceColor(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "textChange":
          setTextValue(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          setTextRectWidth(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[2])
          );
          setTextRectHeight(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[3])
          );
          setFontSize(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[4])
          );
          break;
        case "setHAlign":
          setHAlign(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setVAlign":
          setVAlign(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setIsUpperCase":
          setIsUpperCase(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setRotationScaleL":
          setRotationScaleL(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setRotationScaleT":
          setRotationScaleT(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setRectDimension":
          setRectDimension(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setLayeredImageResolution":
          setLayeredImageResolution(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setLayeredImagePosition":
          setLayeredImagePosition(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setRectPosition":
          setRectPosition(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "resizeRect":
          setRectDimension(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          setRectPosition(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[2])
          );
          break;
        case "resizeLayeredImage":
          setLayeredImageResolution(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          setLayeredImagePosition(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[2])
          );
          break;
        case "moveLayeredImage":
          setRotationScaleL(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          setLayeredImagePosition(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[2])
          );
          setLayeredImageResolution(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[3])
          );
          setLayeredImage(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[4])
          );
          setScaledLayeredImage(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[5])
          );
          deselectAllRect();
          break;
        case "setColorValue":
          setColorValue(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setStrokeValue":
          setStrokeValue(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setStrokeType":
          setStrokeType(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          setStrokeWidth(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[2])
          );
          setShadowWidth(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[3])
          );
          break;
        case "changeFont":
          setFontFamily(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          setTextRectWidth(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[2])
          );
          setTextRectHeight(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[3])
          );
          break;
        case "changeFontWeight":
          setFontWeight(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          setTextRectWidth(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[2])
          );
          setTextRectHeight(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[3])
          );
          break;
        case "changeFontSize":
          let newFont = JSON.parse(
            functionStack.current[counter.current - 1].split("_")[1]
          );
          for (let i = 0; i < newFont.lenght; i++) {
            let inputElement = document.getElementById("font-size-input" + i);
            inputElement.value = newFont;
          }
          setFontSize(newFont);
          setTextRectWidth(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[2])
          );
          setTextRectHeight(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[3])
          );
          break;
        case "changeLetterSpacing":
          setLetterSpace(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          setTextRectWidth(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[2])
          );
          setTextRectHeight(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[3])
          );
          break;
        case "changeFontStyle":
          setFontStyle(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setShadowWidth":
          setShadowWidth(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setStrokeWidth":
          setStrokeWidth(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "setImagePosition":
          setImagePosition(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          break;
        case "modifyText":
          setTextValue(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          setFontSize(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[2])
          );
          setFontWeight(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[3])
          );
          setFontStyle(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[4])
          );
          setFontFamily(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[5])
          );
          setLetterSpace(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[6])
          );
          setColorValue(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[7])
          );
          setStrokeValue(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[8])
          );
          setIsUpperCase(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[9])
          );
          setHAlign(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[10]
            )
          );
          setVAlign(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[11]
            )
          );
          setStrokeWidth(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[12]
            )
          );
          setStrokeType(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[13]
            )
          );
          setShadowWidth(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[14]
            )
          );
          setRectDimension(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[15]
            )
          );
          setTextPlaceholder(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[16]
            )
          );
          setRectPosition(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[17]
            )
          );
          setRotationScaleT(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[18]
            )
          );
          setTextRectHeight(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[19]
            )
          );
          setTextRectWidth(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[20]
            )
          );
          deselectAllRect();
          break;
        case "modifyLayeredImage":
          setScaledLayeredImage(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          setRotationScaleL(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[2])
          );
          setLayeredImageResolution(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[3])
          );
          setLayeredImagePosition(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[4])
          );
          setLayeredImage(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[5])
          );
          deselectAllRect();
          break;
        case "modifyImage":
          setSvgResolution(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[1])
          );
          setDefImageResolution(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[2])
          );
          setImageResolution(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[3])
          );
          setInitImage(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[4])
          );
          setInitResolution(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[5])
          );
          setImagePosition(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[6])
          );
          setSpaceColor(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[7])
          );
          setSpaceBotEnable(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[8])
          );
          setSpaceTopEnable(
            JSON.parse(functionStack.current[counter.current - 1].split("_")[9])
          );
          setSpaceValue(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[10]
            )
          );
          setRotationScale(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[11]
            )
          );
          setImgDimensionScale(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[12]
            )
          );
          setFilterSelected(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[13]
            )
          );
          setBrightnessScale(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[14]
            )
          );
          setContrastScale(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[15]
            )
          );
          setSaturationScale(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[16]
            )
          );
          setWarmthScale(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[17]
            )
          );
          setFlipH(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[18]
            )
          );
          setFlipV(
            JSON.parse(
              functionStack.current[counter.current - 1].split("_")[19]
            )
          );
          deselectAllRect();
          break;
        default:
          console.log("default setting");
      }
      counter.current = counter.current - 1;
    }
  };

  const handleKeyboardShortcuts = (event) => {
    if (event.code === "Delete") {
      if (
        !(selectedElementID.current === "" || isRectSelected.current === false)
      ) {
        if (selectedElementID.current.indexOf("layered") > 0) {
          removeLayeredImageWCurrentValue(selectedIndex.current);
        } else {
          deleteTextWCurrentValue(selectedIndex.current);
        }
      }
    } else if (event.ctrlKey && !event.shiftKey && event.code === "KeyZ") {
      undo();
    } else if (event.ctrlKey && event.shiftKey && event.code === "KeyZ") {
      redo();
    } else if (event.ctrlKey && event.code === "KeyY") {
      redo();
    }
  };

  //Reset all features to its default value
  const reset = () => {
    if (props.sourceImg.type === "svg") {
      let dataobj = props.sourceImg.data;
      let mainDIV = document.getElementsByClassName("imageEditWrapper")[0];

      let availableHeight = mainDIV.offsetHeight - 20;
      let availableWidth = mainDIV.offsetWidth - 20;
      let originalHeight = dataobj.svgResolution.height;
      let originalWidth = dataobj.svgResolution.width;
      let scaleRatio = availableHeight / originalHeight;
      let newWidth = Math.round(originalWidth * scaleRatio);

      if (newWidth > availableWidth) {
        for (let i = availableHeight - 1; i > 0; i--) {
          let tempScaleRatio = i / originalHeight;
          newWidth = Math.round(tempScaleRatio * originalWidth);
          if (newWidth <= availableWidth) {
            availableHeight = i;
            scaleRatio = tempScaleRatio;
            break;
          }
        }
      }
      setSvgResolution({ width: newWidth, height: availableHeight });
      setDefImageResolution({
        width: dataobj.defImageResolution.width * scaleRatio,
        height: dataobj.defImageResolution.height * scaleRatio,
      });
      setImageResolution({
        width: dataobj.imageResolution.width * scaleRatio,
        height: dataobj.imageResolution.height * scaleRatio,
      });
      scaledImageRatio.current = scaleRatio;
      let newRectDimension = [];
      let newRectPosition = [];
      let newFontSize = [];
      let newShadowWidth = [];
      let newStrokeWidth = [];
      let newLetterSpace = [];
      let newTextRectWidth = [];
      let newTextRectHeight = [];

      for (let i = 0; i < dataobj.rectDimension.length; i++) {
        let tempWidth = dataobj.rectDimension[i].width * scaleRatio;
        let tempHeight = dataobj.rectDimension[i].height * scaleRatio;
        let tempPositionX = dataobj.rectPosition[i].x * scaleRatio;
        let tempPositionY = dataobj.rectPosition[i].y * scaleRatio;
        let tempFontSize = Math.round(dataobj.fontSize[i] * scaleRatio);
        let tempShadowWidth = Math.round(
          Number(dataobj.shadowWidth[i].slice(0, -2)) * scaleRatio
        );
        let tempStrokeWidth = Math.round(
          Number(dataobj.strokeWidth[i].slice(0, -2)) * scaleRatio
        );
        let tempLetterSpace = Math.round(
          Number(dataobj.letterSpace[i].slice(0, -2)) * scaleRatio
        );
        let tempTextRectWidth = Math.round(
          dataobj.textRectWidth[i] * scaleRatio
        );
        let tempTextRectHeight = Math.round(
          dataobj.textRectHeight[i] * scaleRatio
        );
        newRectDimension.push({ width: tempWidth, height: tempHeight });
        newRectPosition.push({ x: tempPositionX, y: tempPositionY });
        newFontSize.push(tempFontSize);
        newShadowWidth.push(tempShadowWidth + "px");
        newStrokeWidth.push(tempStrokeWidth + "px");
        newLetterSpace.push(tempLetterSpace + "px");
        newTextRectHeight.push(tempTextRectHeight);
        newTextRectWidth.push(tempTextRectWidth);
      }
      setRectDimension(newRectDimension);
      setRectPosition(newRectPosition);
      setFontSize(newFontSize);
      setShadowWidth(newShadowWidth);
      setStrokeWidth(newStrokeWidth);
      setLetterSpace(newLetterSpace);
      setTextRectWidth(newTextRectWidth);
      setTextRectHeight(newTextRectHeight);

      let newLayeredImageResolution = [];
      let newLayeredImagePosition = [];
      for (let i = 0; i < dataobj.layeredImageResolution.length; i++) {
        let tempWidth = dataobj.layeredImageResolution[i].width * scaleRatio;
        let tempHeight = dataobj.layeredImageResolution[i].height * scaleRatio;
        let tempPositionX = dataobj.layeredImagePosition[i].x * scaleRatio;
        let tempPositionY = dataobj.layeredImagePosition[i].y * scaleRatio;
        newLayeredImageResolution.push({
          width: tempWidth,
          height: tempHeight,
        });
        newLayeredImagePosition.push({ x: tempPositionX, y: tempPositionY });
      }
      setLayeredImageResolution(newLayeredImageResolution);
      setLayeredImagePosition(newLayeredImagePosition);

      setImagePosition({
        x: dataobj.imagePosition.x * scaleRatio,
        y: dataobj.imagePosition.y * scaleRatio,
      });

      setFilterSelected(dataobj.filterSelected);
      setContrastScale(dataobj.contrastScale);
      setBrightnessScale(dataobj.brightnessScale);
      setSaturationScale(dataobj.saturationScale);
      setWarmthScale(dataobj.warmthScale);
      setSpaceValue(dataobj.spaceValue);
      setSpaceTopEnable(dataobj.spaceTopEnable);
      setSpaceBotEnable(dataobj.spaceBotEnable);
      setSpaceColor(dataobj.spaceColor);
      setImgDimensionScale(dataobj.imgDimensionScale);
      setFlipH(dataobj.flipH);
      setFlipV(dataobj.flipV);
      setRotationScale(dataobj.rotationScale);
      setInitImage(dataobj.initImage);
      setInitResolution({
        width: dataobj.svgResolution.width,
        height: dataobj.svgResolution.height,
      });
      setRotationScaleT(dataobj.rotationScaleT);
      setVAlign(dataobj.vAlign);
      setHAlign(dataobj.hAlign);
      setFontFamily(dataobj.fontFamily);
      setFontWeight(dataobj.fontWeight);
      setFontStyle(dataobj.fontStyle);
      setStrokeValue(dataobj.strokeValue);
      setStrokeType(dataobj.strokeType);
      setIsUpperCase(dataobj.isUpperCase);
      setColorValue(dataobj.colorValue);
      setTextValue(dataobj.textValue);
      setLayeredImage(dataobj.layeredImage);
      setRotationScaleL(dataobj.rotationScaleL);
      setScaledLayeredImage(dataobj.scaledLayeredImage);
      setScaledImage(dataobj.scaledImage);
      setTextPlaceholder(dataobj.textPlaceholder);
    } else {
      let mainDIV = document.getElementsByClassName("imageEditWrapper")[0];
      let availableHeight = mainDIV.offsetHeight - 20;
      let availableWidth = mainDIV.offsetWidth - 20;
      let originalHeight = props.sourceImg.imageResolution.height;
      let originalWidth = props.sourceImg.imageResolution.width;
      let scaleRatio = availableHeight / originalHeight;
      let newWidth = Math.round(originalWidth * scaleRatio);

      if (newWidth > availableWidth) {
        for (let i = availableHeight - 1; i > 0; i--) {
          let tempScaleRatio = i / originalHeight;
          newWidth = Math.round(tempScaleRatio * originalWidth);
          if (newWidth <= availableWidth) {
            availableHeight = i;
            scaleRatio = tempScaleRatio;
            break;
          }
        }
      }
      let imageResolution = { width: newWidth, height: availableHeight };
      let imageWidth = props.sourceImg.imageResolution.width;
      let imageHeight = props.sourceImg.imageResolution.height;
      let fontSizeUpdate = Math.round(availableHeight / 12);
      if (fontSizeUpdate < 12) {
        fontSizeUpdate = 12;
      }
      setFontSize([fontSizeUpdate, fontSizeUpdate]);
      setInitImage(props.sourceImg.data);
      setInitResolution({ width: originalWidth, height: originalHeight });
      setTextValue([[""], [""]]);
      setTextPlaceholder(["TOP TEXT", "BOTTOM TEXT"]);
      setFontWeight([400, 400]);
      setFontStyle(["normal", "normal"]);
      setFontFamily(["Anton", "Anton"]);
      setLetterSpace(["0px", "0px"]);
      setColorValue(["#ffffff", "#ffffff"]);
      setStrokeValue(["#000000", "#000000"]);
      setStrokeType([0, 0]);
      setIsUpperCase(["uppercase", "uppercase"]);
      setHAlign([0.5, 0.5]);
      setVAlign([0.5, 0.5]);
      setStrokeWidth(["4px", "4px"]);
      setShadowWidth(["0px", "0px"]);
      setSvgResolution(imageResolution);
      setDefImageResolution(imageResolution);
      setImageResolution(imageResolution);
      let twhElement = document.getElementById("twh");
      twhElement.style.fontSize = fontSizeUpdate + "px";
      twhElement.style.fontFamily = "Anton";
      twhElement.style.letterSpacing = "0px";
      twhElement.innerHTML = "test";
      let tempHeight = twhElement.getBoundingClientRect().height;
      setTextRectHeight([tempHeight, tempHeight]);
      setTextRectWidth([tempHeight, tempHeight]);
      let yInitialHeight = Math.floor(fontSizeUpdate * 3);
      setRectPosition([
        {
          x: 0,
          y: 0,
        },
        {
          x: 0,
          y: imageResolution.height - (yInitialHeight + 20),
        },
      ]);
      setRectDimension([
        {
          width: imageResolution.width,
          height: yInitialHeight,
        },
        {
          width: imageResolution.width,
          height: yInitialHeight,
        },
      ]);
      setRotationScaleT([0, 0]);
      var offScreenCanvas = document.createElement("canvas");
      var offScreenCanvasCtx = offScreenCanvas.getContext("2d");
      offScreenCanvas.width = 225;
      let newHeight = (225 / imageWidth) * imageHeight;
      offScreenCanvas.height = newHeight;
      let img = document.createElement("img");
      img.src = props.sourceImg.data;
      img.onload = function () {
        offScreenCanvasCtx.scale(225 / imageWidth, newHeight / imageHeight);
        offScreenCanvasCtx.drawImage(img, 0, 0);
        setScaledImage({
          data: offScreenCanvas.toDataURL(),
          height: newHeight,
        });
      };
      setSpaceValue(0);
      setSpaceColor("#898989");
      setFilterWidth(0);
      setFilterHeight(0);
      setBrightnessScale(0);
      setContrastScale(0);
      setSaturationScale(0);
      setWarmthScale(0);
      setFlipH(1);
      setFlipV(1);
      setRotationScale(0);
      setRotationScaleL([]);
      setRotationScaleT([]);
      setImgDimensionScale(1);
      setFilterSelected("nofilter");
      setScaledLayeredImage([]);
      setLayeredImageResolution([]);
      setLayeredImagePosition([]);
      setLayeredImage([]);
      setTextRectHeight([]);
      setTextRectHeight([]);
      setSpaceBotEnable(0);
      setSpaceTopEnable(0);
      setImagePosition({ x: 0, y: 0 });
    }
    setSelectedLayeredImage(-1);
    functionStack.current = [];
    handleConfirmModalClose();
  };
  const redo = () => {
    if (counter.current < functionStack.current.length - 1) {
      switch (functionStack.current[counter.current + 1].split(",")[0]) {
        case "setWarmthScale":
          setWarmthScale(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setBrightnessScale":
          setBrightnessScale(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setSaturationScale":
          setSaturationScale(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setContrastScale":
          setContrastScale(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setFilterSelected":
          setFilterSelected(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setRotationScale":
          setRotationScale(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setImgDimensionScale":
          setImgDimensionScale(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "handleZoom":
          setImgDimensionScale(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          setImagePosition(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[2])
          );
          break;
        case "setFlipH":
          setFlipH(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setFlipV":
          setFlipV(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "handleSpaceValue":
          setSvgResolution(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          setSpaceValue(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[2])
          );
          setRectPosition(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[3])
          );
          setLayeredImagePosition(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[4])
          );
          break;
        case "handleSpaceBotEnable":
          setSvgResolution(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          setSpaceBotEnable(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[2])
          );
          break;
        case "handleSpaceTopEnable":
          setSvgResolution(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          setSpaceTopEnable(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[2])
          );
          setRectPosition(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[3])
          );
          setLayeredImagePosition(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[4])
          );
          break;
        case "handleSpaceColor":
          setSpaceColor(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "textChange":
          setTextValue(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          setTextRectWidth(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[2])
          );
          setTextRectHeight(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[3])
          );
          setFontSize(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[4])
          );
          break;
        case "setHAlign":
          setHAlign(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setVAlign":
          setVAlign(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setIsUpperCase":
          setIsUpperCase(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setRotationScaleL":
          setRotationScaleL(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setRotationScaleT":
          setRotationScaleT(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setRectDimension":
          setRectDimension(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setLayeredImageResolution":
          setLayeredImageResolution(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setLayeredImagePosition":
          setLayeredImagePosition(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setRectPosition":
          setRectPosition(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "resizeRect":
          setRectDimension(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          setRectPosition(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[2])
          );
          break;
        case "resizeLayeredImage":
          setLayeredImageResolution(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          setLayeredImagePosition(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[2])
          );
          break;
        case "moveLayeredImage":
          setRotationScaleL(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          setLayeredImagePosition(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[2])
          );
          setLayeredImageResolution(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[3])
          );
          setLayeredImage(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[4])
          );
          setScaledLayeredImage(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[5])
          );
          deselectAllRect();
          break;
        case "setColorValue":
          setColorValue(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setStrokeValue":
          setStrokeValue(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setStrokeType":
          setStrokeType(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          setStrokeWidth(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[2])
          );
          setShadowWidth(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[3])
          );
          break;
        case "changeFont":
          setFontFamily(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          setTextRectWidth(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[2])
          );
          setTextRectHeight(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[3])
          );
          break;
        case "changeFontWeight":
          setFontWeight(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          setTextRectWidth(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[2])
          );
          setTextRectHeight(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[3])
          );
          break;
        case "changeFontSize":
          let newFont = JSON.parse(
            functionStack.current[counter.current + 1].split("_")[1]
          );
          for (let i = 0; i < newFont.lenght; i++) {
            let inputElement = document.getElementById("font-size-input" + i);
            inputElement.value = newFont;
          }
          setFontSize(newFont);
          setTextRectWidth(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[2])
          );
          setTextRectHeight(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[3])
          );
          break;
        case "changeLetterSpacing":
          setLetterSpace(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          setTextRectWidth(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[2])
          );
          setTextRectHeight(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[3])
          );
          break;
        case "changeFontStyle":
          setFontStyle(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setShadowWidth":
          setShadowWidth(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setStrokeWidth":
          setStrokeWidth(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "setImagePosition":
          setImagePosition(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          break;
        case "modifyText":
          setTextValue(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          setFontSize(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[2])
          );
          setFontWeight(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[3])
          );
          setFontStyle(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[4])
          );
          setFontFamily(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[5])
          );
          setLetterSpace(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[6])
          );
          setColorValue(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[7])
          );
          setStrokeValue(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[8])
          );
          setIsUpperCase(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[9])
          );
          setHAlign(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[10]
            )
          );
          setVAlign(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[11]
            )
          );
          setStrokeWidth(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[12]
            )
          );
          setStrokeType(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[13]
            )
          );
          setShadowWidth(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[14]
            )
          );
          setRectDimension(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[15]
            )
          );
          setRectPosition(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[16]
            )
          );
          setRotationScaleT(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[17]
            )
          );
          setTextRectHeight(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[18]
            )
          );
          setTextRectWidth(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[19]
            )
          );
          deselectAllRect();
          break;
        case "modifyLayeredImage":
          setScaledLayeredImage(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          setRotationScaleL(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[2])
          );
          setLayeredImageResolution(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[3])
          );
          setLayeredImagePosition(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[4])
          );
          setLayeredImage(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[5])
          );
          deselectAllRect();
          break;
        case "modifyImage":
          setSvgResolution(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[1])
          );
          setDefImageResolution(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[2])
          );
          setImageResolution(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[3])
          );
          setInitImage(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[4])
          );
          setInitResolution(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[5])
          );
          setImagePosition(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[6])
          );
          setSpaceColor(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[7])
          );
          setSpaceBotEnable(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[8])
          );
          setSpaceTopEnable(
            JSON.parse(functionStack.current[counter.current + 1].split("_")[9])
          );
          setSpaceValue(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[10]
            )
          );
          setRotationScale(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[11]
            )
          );
          setImgDimensionScale(
            JSON.parse(
              JSON.parse(
                functionStack.current[counter.current + 1].split("_")[12]
              )
            )
          );
          setFilterSelected(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[13]
            )
          );
          setBrightnessScale(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[14]
            )
          );
          setContrastScale(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[15]
            )
          );
          setSaturationScale(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[16]
            )
          );
          setWarmthScale(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[17]
            )
          );
          setFlipH(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[18]
            )
          );
          setFlipV(
            JSON.parse(
              functionStack.current[counter.current + 1].split("_")[19]
            )
          );
          deselectAllRect();
          break;
        default:
          console.log("");
      }
      counter.current = counter.current + 1;
    }
  };

  const showRot = useCallback(() => {
    if (filterWidth === 0) {
      setFilterHeight(svgResolution.height);
      setFilterWidth(svgResolution.width);
    } else {
      setFilterHeight(0);
      setFilterWidth(0);
    }
  }, [svgResolution, filterWidth, setFilterWidth, setFilterHeight]);
  const getBase64FromUrl = (url) => {
    return new Promise((resolve, reject) => {
      var x = new XMLHttpRequest();
      x.open("GET", url);
      x.responseType = "blob";
      x.onload = function () {
        var reader = new FileReader();
        reader.readAsDataURL(x.response);
        reader.onloadend = function () {
          var base64data = reader.result;
          resolve(base64data);
        };
      };
      x.onerror = function () {
        reject(x.responseText);
      };
      x.send();
    });
  };
  const handleSpaceValue = (spaceV) => {
    let a =
      defImageResolution.height +
      (spaceTopEnable + spaceBotEnable) *
        (spaceV / 100) *
        defImageResolution.height;
    let newSvgResolution = { width: svgResolution.width, height: a };
    let newRectPosition = [];
    let newLayeredImagePosition = [];

    if (spaceTopEnable === 1) {
      let changeinSpaceValue =
        ((spaceV - spaceValue) / 100) * defImageResolution.height;
      for (let i = 0; i < rectPositionRef.current.length; i++) {
        let a = {
          x: rectPositionRef.current[i].x,
          y: rectPositionRef.current[i].y + changeinSpaceValue,
        };
        newRectPosition.push(a);
      }
      for (let i = 0; i < layeredImagePositionRef.current.length; i++) {
        let a = {
          x: layeredImagePositionRef.current[i].x,
          y: layeredImagePositionRef.current[i].y + changeinSpaceValue,
        };
        newLayeredImagePosition.push(
          layeredImagePositionRef.current[i].y + changeinSpaceValue
        );
      }
    } else {
      newRectPosition = rectPositionRef.current;
      newLayeredImagePosition = layeredImagePositionRef.current;
    }
    setundoredo(
      "handleSpaceValue",
      "_" +
        JSON.stringify(svgResolution) +
        "_" +
        JSON.stringify(spaceValue) +
        "_" +
        JSON.stringify(rectPositionRef.current) +
        "_" +
        JSON.stringify(layeredImagePositionRef.current),
      "_" +
        JSON.stringify(newSvgResolution) +
        "_" +
        JSON.stringify(spaceV) +
        "_" +
        JSON.stringify(newRectPosition) +
        "_" +
        JSON.stringify(newLayeredImagePosition)
    );
    setSvgResolution(newSvgResolution);
    setRectPosition(newRectPosition);
    setLayeredImagePosition(newLayeredImagePosition);
    setSpaceValue(spaceV);
  };

  const handleSpaceTopEnable = (spaceE) => {
    let a =
      defImageResolution.height +
      (spaceE + spaceBotEnable) *
        (spaceValue / 100) *
        defImageResolution.height;
    let newRectPosition = [];
    let newLayeredImagePosition = [];
    let changeinSpaceValue =
      Math.pow(-1, spaceE + 1) * (spaceValue / 100) * defImageResolution.height;
    for (let i = 0; i < rectPositionRef.current.length; i++) {
      let a = {
        x: rectPositionRef.current[i].x,
        y: rectPositionRef.current[i].y + changeinSpaceValue,
      };
      newRectPosition.push(a);
    }
    for (let i = 0; i < layeredImagePositionRef.current.length; i++) {
      let a = {
        x: layeredImagePositionRef.current[i].x,
        y: layeredImagePositionRef.current[i].y + changeinSpaceValue,
      };
      newLayeredImagePosition.push(
        layeredImagePositionRef.current[i].y + changeinSpaceValue
      );
    }
    setundoredo(
      "handleSpaceTopEnable",
      "_" +
        JSON.stringify(svgResolution) +
        "_" +
        JSON.stringify(spaceTopEnable) +
        "_" +
        JSON.stringify(rectPositionRef.current) +
        "_" +
        JSON.stringify(layeredImagePositionRef.current),
      "_" +
        JSON.stringify({ width: svgResolution.width, height: a }) +
        "_" +
        JSON.stringify(spaceE) +
        "_" +
        JSON.stringify(newRectPosition) +
        "_" +
        JSON.stringify(newLayeredImagePosition)
    );
    setSvgResolution({ width: svgResolution.width, height: a });
    setRectPosition(newRectPosition);
    setLayeredImagePosition(newLayeredImagePosition);
    setSpaceTopEnable(spaceE);
  };

  const handleSpaceBotEnable = (spaceE) => {
    let a =
      defImageResolution.height +
      (spaceTopEnable + spaceE) *
        (spaceValue / 100) *
        defImageResolution.height;
    setundoredo(
      "handleSpaceBotEnable",
      "_" +
        JSON.stringify(svgResolution) +
        "_" +
        JSON.stringify(spaceBotEnable),
      "_" +
        JSON.stringify({ width: svgResolution.width, height: a }) +
        "_" +
        JSON.stringify(spaceE)
    );
    setSvgResolution({ width: svgResolution.width, height: a });
    setSpaceBotEnable(spaceE);
  };
  const handleSpaceColor = (colorValue) => {
    setundoredo(
      "handleSpaceColor",
      "_" + JSON.stringify(spaceColor),
      "_" + JSON.stringify(colorValue)
    );
    setSpaceColor(colorValue);
  };
  const changeStrokeType = (type, index) => {
    let newStrokeWidth = [];
    let newShadowWidth = [];
    let a = strokeType.map((value, i) => {
      if (i === index) {
        if (type === 1) {
          newStrokeWidth.push(Math.floor(fontSize[i] / 2) + "px");
          newShadowWidth.push("0px");
        } else {
          newStrokeWidth.push("4px");
          newShadowWidth.push("4px");
        }
        return type;
      } else {
        newStrokeWidth.push(strokeWidth[i]);
        newShadowWidth.push(shadowWidth[i]);
        return value;
      }
    });
    setundoredo(
      "setStrokeType",
      "_" +
        JSON.stringify(strokeType) +
        "_" +
        JSON.stringify(strokeWidth) +
        "_" +
        JSON.stringify(shadowWidth),
      "_" +
        JSON.stringify(a) +
        "_" +
        JSON.stringify(newStrokeWidth) +
        "_" +
        JSON.stringify(newShadowWidth)
    );
    setStrokeType(a);
    setStrokeWidth(newStrokeWidth);
    setShadowWidth(newShadowWidth);
  };

  const getSvgText = (scaleRatio) => {
    return new Promise(async (resolve, reject) => {
      try {
        let newSvgResolution = {
          width: Math.round(svgResolution.width * scaleRatio),
          height: Math.round(svgResolution.height * scaleRatio),
        };
        let newDefImageResolution = {
          width: Math.round(defImageResolution.width * scaleRatio),
          height: Math.round(defImageResolution.height * scaleRatio),
        };
        let newImageResolution = {
          width: Math.round(imageResolution.width * scaleRatio),
          height: Math.round(imageResolution.height * scaleRatio),
        };
        let newRectDimension = [];
        let newRectPosition = [];
        let newFontSize = [];
        let newShadowWidth = [];
        let newStrokeWidth = [];
        let newLetterSpace = [];
        let newTextRectWidth = [];
        let newTextRectHeight = [];

        for (let i = 0; i < rectDimension.length; i++) {
          let tempWidth = rectDimension[i].width * scaleRatio;
          let tempHeight = rectDimension[i].height * scaleRatio;
          let tempPositionX = rectPosition[i].x * scaleRatio;
          let tempPositionY = rectPosition[i].y * scaleRatio;
          let tempFontSize = Math.round(fontSize[i] * scaleRatio);
          let tempShadowWidth = Math.round(
            Number(shadowWidth[i].slice(0, -2)) * scaleRatio
          );
          let tempStrokeWidth = Math.round(
            Number(strokeWidth[i].slice(0, -2)) * scaleRatio
          );
          let tempLetterSpace = Math.round(
            Number(letterSpace[i].slice(0, -2)) * scaleRatio
          );
          let tempTextRectWidth = Math.round(textRectWidth[i] * scaleRatio);
          let tempTextRectHeight = Math.round(textRectHeight[i] * scaleRatio);
          newRectDimension.push({ width: tempWidth, height: tempHeight });
          newRectPosition.push({ x: tempPositionX, y: tempPositionY });
          newFontSize.push(tempFontSize);
          newShadowWidth.push(tempShadowWidth + "px");
          newStrokeWidth.push(tempStrokeWidth + "px");
          newLetterSpace.push(tempLetterSpace + "px");
          newTextRectHeight.push(tempTextRectHeight);
          newTextRectWidth.push(tempTextRectWidth);
        }

        let newLayeredImageResolution = [];
        let newLayeredImagePosition = [];
        for (let i = 0; i < layeredImageResolution.length; i++) {
          let tempWidth = layeredImageResolution[i].width * scaleRatio;
          let tempHeight = layeredImageResolution[i].height * scaleRatio;
          let tempPositionX = layeredImagePosition[i].x * scaleRatio;
          let tempPositionY = layeredImagePosition[i].y * scaleRatio;
          newLayeredImageResolution.push({
            width: tempWidth,
            height: tempHeight,
          });
          newLayeredImagePosition.push({ x: tempPositionX, y: tempPositionY });
        }

        let newImagePosition = {
          x: imagePosition.x * scaleRatio,
          y: imagePosition.y * scaleRatio,
        };

        let svgTemplateElement = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "svg"
        );
        svgTemplateElement.setAttribute("width", newSvgResolution.width + "px");
        svgTemplateElement.setAttribute(
          "height",
          newSvgResolution.height + "px"
        );
        svgTemplateElement.setAttribute("x", "0");
        svgTemplateElement.setAttribute("y", "0");

        let filterElement = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "filter"
        );
        filterElement.setAttribute("id", "nofilter");

        if (filterSelected === "nofilter") {
          let selectedFilterElement = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "feComponentTransfer"
          );
          selectedFilterElement.setAttribute("id", "nofilter1");
          filterElement.appendChild(selectedFilterElement);
        } else if (filterSelected === "posterize") {
          let selectedFilterElement1 = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "feComponentTransfer"
          );
          selectedFilterElement1.setAttribute("id", "posterize2");
          let filterChild11 = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "feFuncR"
          );
          let filterChild12 = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "feFuncG"
          );
          let filterChild13 = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "feFuncB"
          );
          filterChild11.setAttribute("type", "discrete");
          filterChild12.setAttribute("type", "discrete");
          filterChild13.setAttribute("type", "discrete");
          filterChild11.setAttribute(
            "tableValues",
            "0.1 0.2 0.3 0.4 0.5 0.6 0.7 0.8 0.9"
          );
          filterChild12.setAttribute(
            "tableValues",
            "0.1 0.2 0.3 0.4 0.5 0.6 0.7 0.8 0.9"
          );
          filterChild13.setAttribute(
            "tableValues",
            "0.1 0.2 0.3 0.4 0.5 0.6 0.7 0.8 0.9"
          );
          let selectedFilterElement2 = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "feComponentTransfer"
          );
          selectedFilterElement2.setAttribute("id", "posterize1");
          let filterChild21 = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "feFuncR"
          );
          let filterChild22 = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "feFuncG"
          );
          let filterChild23 = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "feFuncB"
          );
          filterChild21.setAttribute("type", "linear");
          filterChild22.setAttribute("type", "linear");
          filterChild23.setAttribute("type", "linear");
          filterChild21.setAttribute("slope", "1.1");
          filterChild22.setAttribute("slope", "1.1");
          filterChild23.setAttribute("slope", "1.1");
          filterChild21.setAttribute("intercept", "-0.05");
          filterChild22.setAttribute("intercept", "-0.05");
          filterChild23.setAttribute("intercept", "-0.05");
          selectedFilterElement1.appendChild(filterChild11);
          selectedFilterElement1.appendChild(filterChild12);
          selectedFilterElement1.appendChild(filterChild13);
          selectedFilterElement2.appendChild(filterChild21);
          selectedFilterElement2.appendChild(filterChild22);
          selectedFilterElement2.appendChild(filterChild23);

          filterElement.appendChild(selectedFilterElement1);
          filterElement.appendChild(selectedFilterElement2);
        } else if (filterSelected === "blur") {
          let selectedFilterElement = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "feGaussianBlur"
          );
          selectedFilterElement.setAttribute("id", "blur1");
          selectedFilterElement.setAttribute("in", "SourceGraphic");
          selectedFilterElement.setAttribute("stdDeviation", "2");
          filterElement.appendChild(selectedFilterElement);
        } else if (filterSelected === "sharpen") {
          let selectedFilterElement = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "feConvolveMatrix"
          );
          selectedFilterElement.setAttribute("id", "sharpen1");
          selectedFilterElement.setAttribute("order", "3 3");
          selectedFilterElement.setAttribute("preserveAlpha", "true");
          selectedFilterElement.setAttribute(
            "kernelMatrix",
            "-1 0 0 0 4 0 0 0 -1"
          );
          filterElement.appendChild(selectedFilterElement);
        }

        if (filterSelected === "grayscale") {
          let selectedFilterElement = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "feColorMatrix"
          );
          selectedFilterElement.setAttribute("id", "grayscale1");
          selectedFilterElement.setAttribute("type", "matrix");
          selectedFilterElement.setAttribute(
            "values",
            "0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0"
          );
          filterElement.appendChild(selectedFilterElement);
        }

        let contrastElement = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "feComponentTransfer"
        );
        contrastElement.setAttribute("id", "contrast");
        let contrastElementChild1 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "feFuncR"
        );
        let contrastElementChild2 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "feFuncG"
        );
        let contrastElementChild3 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "feFuncB"
        );
        contrastElementChild1.setAttribute("type", "linear");
        contrastElementChild2.setAttribute("type", "linear");
        contrastElementChild3.setAttribute("type", "linear");
        contrastElementChild1.setAttribute(
          "slope",
          contrastScale * 0.005 + 1 + ""
        );
        contrastElementChild2.setAttribute(
          "slope",
          contrastScale * 0.005 + 1 + ""
        );
        contrastElementChild3.setAttribute(
          "slope",
          contrastScale * 0.005 + 1 + ""
        );
        contrastElementChild1.setAttribute(
          "intercept",
          contrastScale * -0.0025 + ""
        );
        contrastElementChild2.setAttribute(
          "intercept",
          contrastScale * -0.0025 + ""
        );
        contrastElementChild3.setAttribute(
          "intercept",
          contrastScale * -0.0025 + ""
        );
        contrastElement.appendChild(contrastElementChild1);
        contrastElement.appendChild(contrastElementChild2);
        contrastElement.appendChild(contrastElementChild3);
        filterElement.appendChild(contrastElement);

        let brightnessElement = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "feComponentTransfer"
        );
        brightnessElement.setAttribute("id", "brightness");
        let brightnessElementChild1 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "feFuncR"
        );
        let brightnessElementChild2 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "feFuncG"
        );
        let brightnessElementChild3 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "feFuncB"
        );
        brightnessElementChild1.setAttribute("type", "linear");
        brightnessElementChild2.setAttribute("type", "linear");
        brightnessElementChild3.setAttribute("type", "linear");
        brightnessElementChild1.setAttribute(
          "slope",
          brightnessScale * 0.0075 + 1 + ""
        );
        brightnessElementChild2.setAttribute(
          "slope",
          brightnessScale * 0.0075 + 1 + ""
        );
        brightnessElementChild3.setAttribute(
          "slope",
          brightnessScale * 0.0075 + 1 + ""
        );
        brightnessElement.appendChild(brightnessElementChild1);
        brightnessElement.appendChild(brightnessElementChild2);
        brightnessElement.appendChild(brightnessElementChild3);
        filterElement.appendChild(brightnessElement);

        let saturationElement = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "feColorMatrix"
        );
        saturationElement.setAttribute("id", "saturate");
        saturationElement.setAttribute("type", "saturate");
        saturationElement.setAttribute(
          "values",
          saturationScale * 0.01 + 1 + ""
        );
        filterElement.appendChild(saturationElement);

        let warmthElement = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "feComponentTransfer"
        );
        warmthElement.setAttribute("id", "warmth");
        let warmthElementChild1 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "feFuncR"
        );
        let warmthElementChild2 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "feFuncG"
        );
        let warmthElementChild3 = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "feFuncB"
        );
        warmthElementChild1.setAttribute("type", "linear");
        warmthElementChild2.setAttribute("type", "identity");
        warmthElementChild3.setAttribute("type", "identity");
        warmthElementChild1.setAttribute("slope", warmthScale * 0.005 + 1 + "");
        warmthElement.appendChild(warmthElementChild1);
        warmthElement.appendChild(warmthElementChild2);
        warmthElement.appendChild(warmthElementChild3);
        filterElement.appendChild(warmthElement);

        svgTemplateElement.appendChild(filterElement);

        if (spaceTopEnable === 1 && spaceValue !== 0) {
          let spaceElement = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "rect"
          );
          spaceElement.setAttribute("x", "0");
          spaceElement.setAttribute("y", "0");
          spaceElement.setAttribute("width", "100%");
          spaceElement.setAttribute(
            "height",
            newDefImageResolution.height *
              (spaceTopEnable * (spaceValue / 100)) +
              "px"
          );
          spaceElement.setAttribute("fill", spaceColor);
          svgTemplateElement.appendChild(spaceElement);
        }

        if (spaceBotEnable === 1 && spaceValue !== 0) {
          let spaceElement = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "rect"
          );
          spaceElement.setAttribute("x", "0");
          spaceElement.setAttribute(
            "y",
            newDefImageResolution.height *
              (spaceTopEnable * (spaceValue / 100)) +
              newDefImageResolution.height +
              ""
          );
          spaceElement.setAttribute("width", "100%");
          spaceElement.setAttribute(
            "height",
            newDefImageResolution.height *
              (spaceBotEnable * (spaceValue / 100)) +
              "px"
          );
          spaceElement.setAttribute("fill", spaceColor);
          svgTemplateElement.appendChild(spaceElement);
        }

        let imageSvgWrapperElement = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "svg"
        );
        imageSvgWrapperElement.setAttribute("x", "0");
        imageSvgWrapperElement.setAttribute(
          "y",
          newDefImageResolution.height * (spaceTopEnable * (spaceValue / 100)) +
            ""
        );
        imageSvgWrapperElement.setAttribute(
          "width",
          newDefImageResolution.width + "px"
        );
        imageSvgWrapperElement.setAttribute(
          "height",
          newDefImageResolution.height + "px"
        );

        let imageTransformElement = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "g"
        );
        imageTransformElement.setAttribute(
          "transform",
          " translate(" +
            newDefImageResolution.width / 2 +
            ", " +
            newDefImageResolution.height / 2 +
            ")" +
            " scale(" +
            imgDimensionScale +
            "," +
            imgDimensionScale +
            ") translate(-" +
            newDefImageResolution.width / 2 +
            ", -" +
            newDefImageResolution.height / 2 +
            ")"
        );

        let imageFlipElement = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "g"
        );
        imageFlipElement.setAttribute(
          "transform",
          "scale(" +
            flipH +
            ", " +
            flipV +
            ") translate(" +
            (flipH === -1 ? newDefImageResolution.width * -1 : 0) +
            ", " +
            (flipV === -1 ? newDefImageResolution.height * -1 : 0) +
            ")"
        );

        let imageRotationElement = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "g"
        );
        imageRotationElement.setAttribute(
          "transform",
          "rotate(" +
            rotationScale +
            " " +
            newDefImageResolution.width / 2 +
            " " +
            newDefImageResolution.height / 2 +
            ")"
        );
        imageRotationElement.setAttribute("filter", "url(#nofilter)");

        let imageMainElement = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "image"
        );
        imageMainElement.setAttribute("x", newImagePosition.x + "");
        imageMainElement.setAttribute("y", newImagePosition.y + "");
        imageMainElement.setAttribute("id", "svgsvgcanvas");
        imageMainElement.setAttribute("href", initImage);
        imageMainElement.setAttribute("width", newImageResolution.width + "px");
        imageMainElement.setAttribute(
          "height",
          newImageResolution.height + "px"
        );

        imageRotationElement.appendChild(imageMainElement);
        imageFlipElement.appendChild(imageRotationElement);
        imageTransformElement.appendChild(imageFlipElement);
        imageSvgWrapperElement.appendChild(imageTransformElement);
        svgTemplateElement.appendChild(imageSvgWrapperElement);
        let svgTemplateElementWrapper = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "svg"
        );
        svgTemplateElementWrapper.appendChild(svgTemplateElement);
        for (let index = 0; index < layeredImage.length; index++) {
          let imageElement = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "image"
          );
          imageElement.setAttribute("id", "imgrectlayered" + index);
          imageElement.setAttribute("x", newLayeredImagePosition[index].x + "");
          imageElement.setAttribute("y", newLayeredImagePosition[index].y + "");
          imageElement.setAttribute("href", layeredImage[index]);
          imageElement.setAttribute(
            "width",
            newLayeredImageResolution[index].width + "px"
          );
          imageElement.setAttribute(
            "height",
            newLayeredImageResolution[index].height + "px"
          );
          imageElement.setAttribute("preserveAspectRatio", "none");
          imageElement.setAttribute(
            "transform",
            "rotate(" +
              rotationScaleL[index] +
              " " +
              (newLayeredImagePosition[index].x +
                newLayeredImageResolution[index].width / 2) +
              " " +
              (newLayeredImagePosition[index].y +
                newLayeredImageResolution[index].height / 2) +
              ")"
          );
          svgTemplateElementWrapper.appendChild(imageElement);
        }
        for (let index = 0; index < textValue.length; index++) {
          let textGroupElement = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "g"
          );
          textGroupElement.setAttribute("id", "textrect" + index);
          textGroupElement.setAttribute("data-valign", vAlign[index] + "");
          textGroupElement.setAttribute("data-halign", hAlign[index] + "");
          textGroupElement.setAttribute(
            "data-posx",
            newRectPosition[index].x + ""
          );
          textGroupElement.setAttribute(
            "data-posy",
            newRectPosition[index].y + ""
          );
          textGroupElement.setAttribute(
            "data-textminwidth",
            newTextRectWidth[index]
          );
          textGroupElement.setAttribute(
            "data-textminheight",
            newTextRectHeight[index]
          );
          textGroupElement.setAttribute("data-stroketype", strokeType[index]);
          textGroupElement.setAttribute(
            "transform",
            "rotate(" +
              rotationScaleT[index] +
              " " +
              (newRectPosition[index].x + newRectDimension[index].width / 2) +
              " " +
              (newRectPosition[index].y + newRectDimension[index].height / 2) +
              ")"
          );

          let psuedoTextElement = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "text"
          );
          psuedoTextElement.setAttribute("id", "psuedo" + index);
          psuedoTextElement.setAttribute(
            "x",
            newRectPosition[index].x +
              newRectDimension[index].width * hAlign[index] +
              ""
          );
          psuedoTextElement.setAttribute(
            "y",
            newRectPosition[index].y +
              (newRectDimension[index].height -
                newFontSize[index] * textValue[index].length * 1.15) *
                vAlign[index] +
              ""
          );
          psuedoTextElement.setAttribute(
            "text-anchor",
            textAnchorMap[hAlign[index]]
          );
          psuedoTextElement.setAttribute(
            "style",
            `font-size: ${newFontSize[index] + "px"}; font-family: ${
              fontFamily[index]
            }; font-weight: ${fontWeight[index]}; font-style: ${
              fontStyle[index]
            }; text-shadow: ${
              strokeValue[index] + " 0px 0px " + newShadowWidth[index]
            }; letter-spacing: ${newLetterSpace[index]}; stroke: ${
              strokeValue[index]
            }; stroke-width: ${newStrokeWidth[index]}; text-transform: ${
              isUpperCase[index]
            }; user-select: none;`
          );

          let textTextElement = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "text"
          );
          textTextElement.setAttribute("id", "text" + index);
          textTextElement.setAttribute(
            "x",
            newRectPosition[index].x +
              newRectDimension[index].width * hAlign[index] +
              ""
          );
          textTextElement.setAttribute(
            "y",
            newRectPosition[index].y +
              (newRectDimension[index].height -
                newFontSize[index] * textValue[index].length * 1.15) *
                vAlign[index] +
              ""
          );
          textTextElement.setAttribute(
            "text-anchor",
            textAnchorMap[hAlign[index]]
          );
          textTextElement.setAttribute(
            "style",
            `font-size: ${newFontSize[index] + "px"}; font-family: ${
              fontFamily[index]
            }; font-weight: ${fontWeight[index]}; font-style: ${
              fontStyle[index]
            }; letter-spacing: ${newLetterSpace[index]}; fill: ${
              colorValue[index]
            }; text-transform: ${isUpperCase[index]}; user-select: none;`
          );

          for (let i = 0; i < textValue[index].length; i++) {
            let tspanPsuedoElement = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "tspan"
            );
            tspanPsuedoElement.setAttribute("id", "psuedotspan" + index + i);
            tspanPsuedoElement.setAttribute(
              "x",
              newRectPosition[index].x +
                newRectDimension[index].width * hAlign[index]
            );
            tspanPsuedoElement.setAttribute("dy", "1.15em");
            tspanPsuedoElement.innerHTML = textValue[index][i];
            psuedoTextElement.appendChild(tspanPsuedoElement);

            let tspanTextElement = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "tspan"
            );
            tspanTextElement.setAttribute("id", "tspan" + index + i);
            tspanTextElement.setAttribute(
              "x",
              newRectPosition[index].x +
                newRectDimension[index].width * hAlign[index]
            );
            tspanTextElement.setAttribute("dy", "1.15em");
            tspanTextElement.innerHTML = textValue[index][i];
            textTextElement.appendChild(tspanTextElement);
          }
          if (strokeType[index] === 2) {
            let textRectElement = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "rect"
            );
            textRectElement.setAttribute("id", "textbox" + index);
            textRectElement.setAttribute("fill", strokeValue[index] + "");
            textRectElement.setAttribute("x", newRectPosition[index].x + "");
            textRectElement.setAttribute("y", newRectPosition[index].y + "");
            textRectElement.setAttribute(
              "width",
              newRectDimension[index].width + "px"
            );
            textRectElement.setAttribute(
              "height",
              newRectDimension[index].height + "px"
            );
            textRectElement.setAttribute("rx", "7px");
            textRectElement.setAttribute("ry", "7px");
            textGroupElement.appendChild(textRectElement);
          }
          textGroupElement.appendChild(psuedoTextElement);
          textGroupElement.appendChild(textTextElement);
          svgTemplateElementWrapper.appendChild(textGroupElement);
        }
        resolve(svgTemplateElementWrapper);
      } catch (err) {
        reject(err);
      }
    });
  };

  const downloadImage = async (format) => {
    let selectedFont = [];
    let fontWOFFBase64 = [];
    let uniqueFontFamily = [...new Set(fontFamily)];
    for (const index of uniqueFontFamily.keys()) {
      selectedFont[index] = uniqueFontFamily[index];
      let selectedFontID = selectedFont[index].replace(" ", "+");
      let url = "https://fonts.googleapis.com/css?family=" + selectedFontID;
      fontWOFFBase64[index] = "";
      await fetch(url, { method: "GET" })
        .then((response) => {
          return response.text();
        })
        .then(async (data) => {
          let latin_subset = data.split("/* latin */")[1];
          let found_id = latin_subset.indexOf("src: url(");
          let end_id = latin_subset.indexOf("ff2");
          let found_url = latin_subset.substring(found_id + 9, end_id + 3);
          fontWOFFBase64[index] = await getBase64FromUrl(found_url);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    const wmX = 0;
    const wmY =
      Math.round(svgResolution.height / scaledImageRatio.current) - 31;

    const waterMark = document.getElementById("watermarklogo");
    console.log(svgResolution.height / scaledImageRatio.current);
    waterMark.setAttribute("x", wmX);
    waterMark.setAttribute("y", wmY);
    waterMark.setAttribute("width", "150px");
    waterMark.setAttribute("height", "30px");
    var serializer = new XMLSerializer();
    const waterMarkData = serializer.serializeToString(waterMark);

    var svg_output = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    svg_output.setAttribute(
      "width",
      Math.round(svgResolution.width / scaledImageRatio.current) + "px"
    );
    svg_output.setAttribute(
      "height",
      Math.round(svgResolution.height / scaledImageRatio.current) + "px"
    );
    svg_output.setAttribute("fill", "#eee");
    const newDefs = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "defs"
    );
    for (const index of uniqueFontFamily.keys()) {
      if (
        !(
          fontWOFFBase64 === null ||
          fontWOFFBase64 === undefined ||
          fontWOFFBase64[index] === ""
        )
      ) {
        let newStyle = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "style"
        );
        newDefs.appendChild(newStyle);
        newStyle.appendChild(
          document.createTextNode(
            `
            @font-face {
                font-family: '` +
              selectedFont[index] +
              `';
                src: url('` +
              fontWOFFBase64[index] +
              `') format('woff2');
                font-weight: normal;
                font-style: normal;
                font-display: swap;
            }
            `
          )
        );
      }
    }
    let svgText = await getSvgText(1 / scaledImageRatio.current);
    svg_output.innerHTML = `
    ${newDefs.outerHTML}
    ${svgText.innerHTML}
    ${waterMarkData}`;
    var serializer = new XMLSerializer();
    var source = serializer.serializeToString(svg_output);
    if (format === "svg+xml") {
      let download_url = "data:image/svg+xml," + encodeURIComponent(source);
      downloadFile(download_url, "svg");
    } else {
      var svg64 = b64EncodeUnicode(
        decodeURIComponent(encodeURIComponent(source))
      );
      var svgData = "data:image/svg+xml;base64," + svg64;
      var offScreenCanvas = document.createElement("canvas");
      var offScreenCanvasCTX = offScreenCanvas.getContext("2d");
      let height = svgResolution.height / scaledImageRatio.current;
      let width = svgResolution.width / scaledImageRatio.current;
      offScreenCanvas.height = height;
      offScreenCanvas.width = width;
      var image = new Image();
      image.onload = function () {
        offScreenCanvasCTX.clearRect(0, 0, width, height);
        offScreenCanvasCTX.drawImage(image, 0, 0, width, height);
        var download_url = offScreenCanvas.toDataURL("image/" + format, 1);
        downloadFile(download_url, format);
      };
      image.src = svgData;
    }
  };

  // Encoding UTF8 ⇢ base64
  const b64EncodeUnicode = (str) => {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(parseInt(p1, 16));
      })
    );
  };

  // Decoding base64 ⇢ UTF8
  const b64DecodeUnicode = (str) => {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  };

  /*
  const guid = () => {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };

    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
  };
  */
  const downloadFile = (download_url, format) => {
    let dataurl = download_url;
    if (typeof download_url === "object") {
      dataurl = URL.createObjectURL(download_url);
    }
    let downloadLink = document.createElement("a");
    downloadLink.download = `PudgyPenguins_Meme` + "." + format;
    downloadLink.href = dataurl;
    downloadLink.click();
  };
  const deleteText = (index) => {
    if (textValue.length === 1) {
      return null;
    }
    let newTextValue = textValue.filter((value, i) => i !== index);
    let newFontWeight = fontWeight.filter((value, i) => i !== index);
    let newFontStyle = fontStyle.filter((value, i) => i !== index);
    let newFontFamily = fontFamily.filter((value, i) => i !== index);
    let newFontSize = fontSize.filter((value, i) => i !== index);
    let newLetterSpace = letterSpace.filter((value, i) => i !== index);
    let newColorValue = colorValue.filter((value, i) => i !== index);
    let newStrokeValue = strokeValue.filter((value, i) => i !== index);
    let newIsUpperCase = isUpperCase.filter((value, i) => i !== index);
    let newHAlign = hAlign.filter((value, i) => i !== index);
    let newVAlign = vAlign.filter((value, i) => i !== index);
    let newStrokeWidth = strokeWidth.filter((value, i) => i !== index);
    let newStrokeType = strokeType.filter((value, i) => i !== index);
    let newShadowWidth = shadowWidth.filter((value, i) => i !== index);
    let newTextRectHeight = textRectHeight.filter((value, i) => i !== index);
    let newTextRectWidth = textRectWidth.filter((value, i) => i !== index);
    let newRectDimension = rectDimension.filter((value, i) => i !== index);
    let newTextPlaceholder = textPlaceholder.filter((value, i) => i !== index);
    let newRectPosition = rectPosition.filter((value, i) => i !== index);
    let newRotationScaleT = rotationScaleT.filter((value, i) => i !== index);
    setundoredo(
      "modifyText",
      "_" +
        JSON.stringify(textValue) +
        "_" +
        JSON.stringify(fontSize) +
        "_" +
        JSON.stringify(fontWeight) +
        "_" +
        JSON.stringify(fontStyle) +
        "_" +
        JSON.stringify(fontFamily) +
        "_" +
        JSON.stringify(letterSpace) +
        "_" +
        JSON.stringify(colorValue) +
        "_" +
        JSON.stringify(strokeValue) +
        "_" +
        JSON.stringify(isUpperCase) +
        "_" +
        JSON.stringify(hAlign) +
        "_" +
        JSON.stringify(vAlign) +
        "_" +
        JSON.stringify(strokeWidth) +
        "_" +
        JSON.stringify(strokeType) +
        "_" +
        JSON.stringify(shadowWidth) +
        "_" +
        JSON.stringify(rectDimension) +
        "_" +
        JSON.stringify(textPlaceholder) +
        "_" +
        JSON.stringify(rectPosition) +
        "_" +
        JSON.stringify(rotationScaleT) +
        "_" +
        JSON.stringify(textRectHeight) +
        "_" +
        JSON.stringify(textRectWidth),
      "_" +
        JSON.stringify(newTextValue) +
        "_" +
        JSON.stringify(newFontSize) +
        "_" +
        JSON.stringify(newFontWeight) +
        "_" +
        JSON.stringify(newFontStyle) +
        "_" +
        JSON.stringify(newFontFamily) +
        "_" +
        JSON.stringify(newLetterSpace) +
        "_" +
        JSON.stringify(newColorValue) +
        "_" +
        JSON.stringify(newStrokeValue) +
        "_" +
        JSON.stringify(newIsUpperCase) +
        "_" +
        JSON.stringify(newHAlign) +
        "_" +
        JSON.stringify(newVAlign) +
        "_" +
        JSON.stringify(newStrokeWidth) +
        "_" +
        JSON.stringify(newStrokeType) +
        "_" +
        JSON.stringify(newShadowWidth) +
        "_" +
        JSON.stringify(newRectDimension) +
        "_" +
        JSON.stringify(newTextPlaceholder) +
        "_" +
        JSON.stringify(newRectPosition) +
        "_" +
        JSON.stringify(newRotationScaleT) +
        "_" +
        JSON.stringify(newTextRectHeight) +
        "_" +
        JSON.stringify(newTextRectWidth)
    );
    setTextValue(newTextValue);
    setFontSize(newFontSize);
    setFontWeight(newFontWeight);
    setFontStyle(newFontStyle);
    setFontFamily(newFontFamily);
    setLetterSpace(newLetterSpace);
    setColorValue(newColorValue);
    setStrokeValue(newStrokeValue);
    setIsUpperCase(newIsUpperCase);
    setHAlign(newHAlign);
    setVAlign(newVAlign);
    setStrokeWidth(newStrokeWidth);
    setStrokeType(newStrokeType);
    setShadowWidth(newShadowWidth);
    setRectDimension(newRectDimension);
    setTextPlaceholder(newTextPlaceholder);
    setRectPosition(newRectPosition);
    setRotationScaleT(newRotationScaleT);
    setTextRectHeight(newTextRectHeight);
    setTextRectWidth(newTextRectWidth);
    deselectAllRect();
  };
  const addText = () => {
    let newTextValue = [...textValue, []];
    let newFontWeight = [...fontWeight, 400];
    let newFontSize = [...fontSize, Math.floor(svgResolution.height / 12)];
    let newFontStyle = [...fontStyle, "normal"];
    let newFontFamily = [...fontFamily, "Anton"];
    let newLetterSpace = [...letterSpace, "0px"];
    let newColorValue = [...colorValue, "#ffffff"];
    let newStrokeValue = [...strokeValue, "#000000"];
    let newStrokeType = [...strokeType, 0];
    let newIsUpperCase = [...isUpperCase, "uppercase"];
    let newHAlign = [...hAlign, 0.5];
    let newRectDimension = [
      ...rectDimension,
      { width: svgResolution.width, height: svgResolution.height / 4 },
    ];
    let newVAlign;
    let newTextPlaceholder;
    let newRectPosition;
    if (textValue.length === 0) {
      newVAlign = [...vAlign, 0];
      newTextPlaceholder = [...textPlaceholder, "TOP TEXT"];
      newRectPosition = [...rectPosition, { x: 0, y: 0 }];
    } else if (textValue.length === 1) {
      newVAlign = [...vAlign, 1];
      newTextPlaceholder = [...textPlaceholder, "BOTTOM TEXT"];
      newRectPosition = [
        ...rectPosition,
        { x: 0, y: Math.floor(svgResolution.height * 0.75) },
      ];
    } else {
      newVAlign = [...vAlign, 0.5];
      newTextPlaceholder = [...textPlaceholder, "TEXT"];
      newRectPosition = [
        ...rectPosition,
        { x: 0, y: Math.floor(svgResolution.height * 0.375) },
      ];
    }
    let newStrokeWidth = [...strokeWidth, "4px"];
    let newShadowWidth = [...shadowWidth, "4px"];
    let newTextRectWidth = [...textRectWidth, svgResolution.height / 12];
    let newTextRectHeight = [...textRectHeight, svgResolution.height / 12];
    let newRotationScaleT = [...rotationScaleT, 0];
    setundoredo(
      "modifyText",
      "_" +
        JSON.stringify(textValue) +
        "_" +
        JSON.stringify(fontSize) +
        "_" +
        JSON.stringify(fontWeight) +
        "_" +
        JSON.stringify(fontStyle) +
        "_" +
        JSON.stringify(fontFamily) +
        "_" +
        JSON.stringify(letterSpace) +
        "_" +
        JSON.stringify(colorValue) +
        "_" +
        JSON.stringify(strokeValue) +
        "_" +
        JSON.stringify(isUpperCase) +
        "_" +
        JSON.stringify(hAlign) +
        "_" +
        JSON.stringify(vAlign) +
        "_" +
        JSON.stringify(strokeWidth) +
        "_" +
        JSON.stringify(strokeType) +
        "_" +
        JSON.stringify(shadowWidth) +
        "_" +
        JSON.stringify(rectDimension) +
        "_" +
        JSON.stringify(textPlaceholder) +
        "_" +
        JSON.stringify(rectPosition) +
        "_" +
        JSON.stringify(rotationScaleT) +
        "_" +
        JSON.stringify(textRectHeight) +
        "_" +
        JSON.stringify(textRectWidth),
      "_" +
        JSON.stringify(newTextValue) +
        "_" +
        JSON.stringify(newFontSize) +
        "_" +
        JSON.stringify(newFontWeight) +
        "_" +
        JSON.stringify(newFontStyle) +
        "_" +
        JSON.stringify(newFontFamily) +
        "_" +
        JSON.stringify(newLetterSpace) +
        "_" +
        JSON.stringify(newColorValue) +
        "_" +
        JSON.stringify(newStrokeValue) +
        "_" +
        JSON.stringify(newIsUpperCase) +
        "_" +
        JSON.stringify(newHAlign) +
        "_" +
        JSON.stringify(newVAlign) +
        "_" +
        JSON.stringify(newStrokeWidth) +
        "_" +
        JSON.stringify(newStrokeType) +
        "_" +
        JSON.stringify(newShadowWidth) +
        "_" +
        JSON.stringify(newRectDimension) +
        "_" +
        JSON.stringify(newTextPlaceholder) +
        "_" +
        JSON.stringify(newRectPosition) +
        "_" +
        JSON.stringify(newRotationScaleT) +
        "_" +
        JSON.stringify(newTextRectHeight) +
        "_" +
        JSON.stringify(newTextRectWidth)
    );
    setTextValue(newTextValue);
    setFontWeight(newFontWeight);
    setFontSize(newFontSize);
    setFontStyle(newFontStyle);
    setFontFamily(newFontFamily);
    setLetterSpace(newLetterSpace);
    setColorValue(newColorValue);
    setStrokeValue(newStrokeValue);
    setIsUpperCase(newIsUpperCase);
    setVAlign(newVAlign);
    setHAlign(newHAlign);
    setRectDimension(newRectDimension);
    setTextPlaceholder(newTextPlaceholder);
    setRectPosition(newRectPosition);
    setStrokeWidth(newStrokeWidth);
    setStrokeType(newStrokeType);
    setShadowWidth(newShadowWidth);
    setTextRectWidth(newTextRectWidth);
    setTextRectHeight(newTextRectHeight);
    setRotationScaleT(newRotationScaleT);
    deselectAllRect();
  };

  const handleClick = (event, aRef) => {
    setAnchorEl(event.currentTarget);
    setAnchorRef(aRef);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTopToggle = () => {
    handleSpaceTopEnable(spaceTopEnable === 0 ? 1 : 0);
  };

  const handleBotToggle = () => {
    handleSpaceBotEnable(spaceBotEnable === 0 ? 1 : 0);
  };

  // const rotationValueChange = (value) => {
  //   let newvalue = Number(value);
  //   handleUndoRedo('setRotationScale', "_" + JSON.stringify(rotationScale), "_" + JSON.stringify(newvalue));
  //   rotate(newvalue);
  // };

  //for rotate image
  const rotate = (type) => {
    let newRotation = 0;
    if (type === "right") {
      newRotation = rotationScale + 90;
      if (newRotation > 180) {
        newRotation = newRotation - 360;
      }
    } else if (type === "left") {
      newRotation = rotationScale - 90;
      if (newRotation < -180) {
        newRotation = newRotation + 360;
      }
    }
    handleUndoRedo("setRotationScale", "_" + JSON.stringify(newRotation));
    setRotationScale(newRotation);
  };

  const handleRotateSliderChange = (value) => {
    setdebouncedundoredo("setRotationScale", "_" + JSON.stringify(value));
    setRotationScale(value);
  };

  const handleFlipHToggle = () => {
    let newFlipHV = flipH === 1 ? -1 : 1;
    handleUndoRedo(
      "setFlipH",
      "_" + JSON.stringify(flipH),
      "_" + JSON.stringify(newFlipHV)
    );
    setFlipH(newFlipHV);
  };

  const handleFlipVToggle = () => {
    let newFlipV = flipV === 1 ? -1 : 1;
    handleUndoRedo(
      "setFlipV",
      "_" + JSON.stringify(props.flipVV),
      "_" + JSON.stringify(newFlipV)
    );
    setFlipV(newFlipV);
  };

  const handleZoomSliderChange = (value) => {
    let movex = imagePosition.x;
    let movey = imagePosition.y;
    if (value < imgDimensionScale) {
      let maxXCurrent = ((value - 1) * defImageResolution.width) / (2 * value);
      let maxYCurrent = ((value - 1) * defImageResolution.height) / (2 * value);
      if (movex > maxXCurrent) {
        movex = maxXCurrent;
      } else if (movex < maxXCurrent * -1) {
        movex = maxXCurrent * -1;
      }
      if (movey > maxYCurrent) {
        movey = maxYCurrent;
      } else if (movey < maxYCurrent * -1) {
        movey = maxYCurrent * -1;
      }
    }
    setImgDimensionScale(value);
    setImagePosition({ x: movex, y: movey });
    setdebouncedundoredo(
      "handleZoom",
      "_" + JSON.stringify(value) + "_" + JSON.stringify({ x: movex, y: movey })
    );
  };

  const handleFilterSelect = (value) => {
    handleUndoRedo(
      "setFilterSelected",
      "_" + JSON.stringify(filterSelected),
      "_" + JSON.stringify(value)
    );
    setFilterSelected(value);
  };

  const handleContrastSliderChange = (value) => {
    setdebouncedundoredo("setContrastScale", "_" + JSON.stringify(value));
    setContrastScale(value);
  };

  const handleBrightnessSliderChange = (value) => {
    setdebouncedundoredo("setBrightnessScale", "_" + JSON.stringify(value));
    setBrightnessScale(value);
  };

  const handleSaturationSliderChange = (value) => {
    setdebouncedundoredo("setSaturationScale", "_" + JSON.stringify(value));
    setSaturationScale(value);
  };

  const handleWarmthSliderChange = (value) => {
    setdebouncedundoredo("setWarmthScale", "_" + JSON.stringify(value));
    setWarmthScale(value);
  };

  const handleUndoRedo = (attr, currentV, newV) =>
    setundoredo(attr, currentV, newV);

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const addEmoji = (e) => {
    let emoji = e.native;
    setTitle(title + emoji);
  };

  const addCommentEmoji = (e) => {
    let emoji = e.native;
    setComment(comment + emoji);
  };
  const handleTitleScroll = () => {
    let mainScrollTop =
      document.getElementsByClassName("title-input")[0].scrollTop;
    backDropRef.current.scrollTop = mainScrollTop;
  };

  useEffect(() => {
    fetch("./dictionaries/en_US/en_US.aff")
      .then((response) => response.text())
      .then((affData) => {
        fetch("./dictionaries/en_US/en_US.dic")
          .then((dicresponse) => dicresponse.text())
          .then((dicData) => {
            dictionary.current = new Typo("en_US", affData, dicData);
          });
      });
  }, []);

  useEffect(() => {
    if (btnTab) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        let a = title.length;
        let b = a / 1;
        let c = 100 - a;
        let x = document.getElementById("circularPer");
        let psuedoInput =
          document.getElementsByClassName("psuedo-title-input")[0];
        psuedoInput.innerHTML = "";
        let aa = comment.length;
        let bb = aa / 1.4;
        let cc = 140 - aa;
        let xx = document.getElementById("commentCircularPer");
        let psuedoCommentInput = document.getElementsByClassName(
          "psuedo-comment-input"
        )[0];
        let emoticonregex =
          /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu;
        if (a < 5) {
        } else if (a > 79) {
          let escapedLetters = title.substring(0, 40).replace(/\n$/g, "\n\n");
          let alphaOnlyLetters = escapedLetters.replace(
            /[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi,
            " "
          );
          alphaOnlyLetters = alphaOnlyLetters.replace(emoticonregex, (m) =>
            " ".repeat(m.length)
          );
          let nAlpha = alphaOnlyLetters.replace(/  +/g, "");
          let filteredLetters = "";
          let tempfilteredLettersList = [];
          if (nAlpha.length > 0) {
            filteredLetters = pFilter.clean(alphaOnlyLetters);
            tempfilteredLettersList = filteredLetters.split(" ");
          } else {
            filteredLetters = alphaOnlyLetters;
            tempfilteredLettersList = [""];
          }
          let filteredLettersList = tempfilteredLettersList.map(
            (value, index) => {
              let checkdic =
                dictionary.current === null
                  ? true
                  : dictionary.current.check(value);
              if (checkdic === true) {
                return value;
              } else {
                return value.replace(/./g, "q");
              }
            }
          );
          filteredLetters = filteredLettersList.join(" ");
          let filteredHighlightedLetters = filteredLetters.replace(
            /[x]{3,}/g,
            "<mark>$&</mark>"
          );
          psuedoInput.innerHTML =
            filteredHighlightedLetters +
            "<mark>" +
            title.substring(40) +
            "</mark>";
          x.className = "headerCircularFull";
        } else {
          let escapedLetters = title.replace(/\n$/g, "\n\n");
          let alphaOnlyLetters = escapedLetters.replace(
            /[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi,
            " "
          );
          alphaOnlyLetters = alphaOnlyLetters.replace(emoticonregex, (m) =>
            " ".repeat(m.length)
          );
          let nAlpha = alphaOnlyLetters.replace(/  +/g, "");
          let filteredLetters = "";
          let tempfilteredLettersList = [];
          if (nAlpha.length > 0) {
            filteredLetters = pFilter.clean(alphaOnlyLetters);
            tempfilteredLettersList = filteredLetters.split(" ");
          } else {
            filteredLetters = alphaOnlyLetters;
            tempfilteredLettersList = [""];
          }
          let filteredLettersList = tempfilteredLettersList.map(
            (value, index) => {
              let checkdic =
                dictionary.current === null
                  ? true
                  : dictionary.current.check(value);
              if (checkdic === true || value.search(/[x]{3,}/g) > -1) {
                return value;
              } else {
                return value.replace(/./g, "q");
              }
            }
          );
          filteredLetters = filteredLettersList.join(" ");
          let filteredHighlightedLetters = filteredLetters.replace(
            /[x]{3,}/g,
            '<mark class="profane-word" title="Abusive WOrd">$&</mark>'
          );
          filteredHighlightedLetters = filteredHighlightedLetters.replace(
            /[q]{3,}/g,
            '<span class="incorrect-word">$&</span>'
          );
          psuedoInput.innerHTML = filteredHighlightedLetters;
          if (c <= 40) {
            x.className = "headerCircularNearFull";
          } else {
            x.className = "headerCircular";
          }
        }
        if (aa < 5) {
        } else if (aa > 140) {
          let escapedCommentLetters = comment
            .substring(0, 140)
            .replace(/\n$/g, "\n\n");
          let alphaOnlyCommentLetters = escapedCommentLetters.replace(
            /[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi,
            " "
          );
          alphaOnlyCommentLetters = alphaOnlyCommentLetters.replace(
            emoticonregex,
            (m) => " ".repeat(m.length)
          );
          let nCommentAlpha = alphaOnlyCommentLetters.replace(/  +/g, "");
          let filteredCommentLetters = "";
          let tempfilteredCommentLettersList = [];
          if (nCommentAlpha.length > 0) {
            filteredCommentLetters = pFilter.clean(alphaOnlyCommentLetters);
            tempfilteredCommentLettersList = filteredCommentLetters.split(" ");
          } else {
            filteredCommentLetters = alphaOnlyCommentLetters;
            tempfilteredCommentLettersList = [""];
          }
          let filteredCommentLettersList = tempfilteredCommentLettersList.map(
            (value, index) => {
              let checkdic =
                dictionary.current === null
                  ? true
                  : dictionary.current.check(value);
              if (checkdic === true) {
                return value;
              } else {
                return value.replace(/./g, "q");
              }
            }
          );
          filteredCommentLetters = filteredCommentLettersList.join(" ");
          let filteredHighlightedCommentLetters =
            filteredCommentLetters.replace(/[x]{3,}/g, "<mark>$&</mark>");
          psuedoCommentInput.innerHTML =
            filteredHighlightedCommentLetters +
            "<mark>" +
            comment.substring(140) +
            "</mark>";
          xx.className = "headerCircularFull";
        } else {
          let escapedCommentLetters = comment.replace(/\n$/g, "\n\n");
          let alphaOnlyCommentLetters = escapedCommentLetters.replace(
            /[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi,
            " "
          );
          alphaOnlyCommentLetters = alphaOnlyCommentLetters.replace(
            emoticonregex,
            (m) => " ".repeat(m.length)
          );
          let nCommentAlpha = alphaOnlyCommentLetters.replace(/  +/g, "");
          let filteredCommentLetters = "";
          let tempfilteredCommentLettersList = [];
          if (nCommentAlpha.length > 0) {
            filteredCommentLetters = pFilter.clean(alphaOnlyCommentLetters);
            tempfilteredCommentLettersList = filteredCommentLetters.split(" ");
          } else {
            filteredCommentLetters = alphaOnlyCommentLetters;
            tempfilteredCommentLettersList = [""];
          }
          let filteredCommentLettersList = tempfilteredCommentLettersList.map(
            (value, index) => {
              let checkdic =
                dictionary.current === null
                  ? true
                  : dictionary.current.check(value);
              if (checkdic === true || value.search(/[x]{3,}/g) > -1) {
                return value;
              } else {
                return value.replace(/./g, "q");
              }
            }
          );
          filteredCommentLetters = filteredCommentLettersList.join(" ");
          let filteredHighlightedCommentLetters =
            filteredCommentLetters.replace(
              /[x]{3,}/g,
              '<mark class="profane-word" title="Abusive WOrd">$&</mark>'
            );
          filteredHighlightedCommentLetters =
            filteredHighlightedCommentLetters.replace(
              /[q]{3,}/g,
              '<span class="incorrect-word">$&</span>'
            );
          psuedoCommentInput.innerHTML = filteredHighlightedCommentLetters;
          if (cc <= 20) {
            xx.className = "headerCircularNearFull";
          } else {
            xx.className = "headerCircular";
          }
        }
      }, 100);
    }
  }, [btnTab, title, comment]);

  const handlemouseontextarea = (event) => {
    let x = event.clientX;
    let y = event.clientY;
    clearTimeout(mouseMoveTimeout.current);
    mouseMoveTimeout.current = setTimeout(() => {
      let elementsInsideHover = document.elementsFromPoint(x, y);
      for (let i = 0; i < elementsInsideHover.length; i++) {
        if (elementsInsideHover[i].className === "incorrect-word") {
          setAnchorRef("incorrectWord");
          setAnchorEl(elementsInsideHover[i]);
          break;
        } else if (elementsInsideHover[i].className === "profane-word") {
          setAnchorRef("profaneWord");
          setAnchorEl(elementsInsideHover[i]);
          break;
        } else {
          setAnchorEl(null);
        }
      }
    }, 200);
  };

  const onChangeAdjust = (index = 0) => {
    setAdjustSetting(index);
  };

  //Handle touch event for layered image
  const handleTouchDownOnRect = (e, elementID, index) => {
    let mainDIV = document.getElementsByClassName("imageEditWrapper");
    mainDIV[0].style.overflow = "hidden !important";
    document.body.style.overflow = "hidden !important";
    document.documentElement.style.overflow = "hidden !important";
    document.body.style.overflow = "hidden !important";
    if (isRectResizing.current === false) {
      isRectSelected.current = true;
      selectedElementID.current = elementID;
      textValue.map((text, i) => {
        document
          .getElementById("rect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("topleftrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("toprightrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomleftrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomrightrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("rotaterect" + i)
          .setAttribute("pointer-events", "none");
        return null;
      });
      layeredImagePosition.map((img, i) => {
        document
          .getElementById("rectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("topleftrectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("toprightrectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomleftrectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomrightrectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("rotaterectlayered" + i)
          .setAttribute("pointer-events", "none");
        return null;
      });
      let currentElement = document.getElementById(elementID);
      currentElement.setAttribute("pointer-events", "fill");
      document
        .getElementById("topleft" + elementID)
        .setAttribute("pointer-events", "painted");
      document
        .getElementById("topright" + elementID)
        .setAttribute("pointer-events", "painted");
      document
        .getElementById("bottomleft" + elementID)
        .setAttribute("pointer-events", "painted");
      document
        .getElementById("bottomright" + elementID)
        .setAttribute("pointer-events", "painted");
      document
        .getElementById("g" + elementID)
        .setAttribute("visibility", "visible");
      document
        .getElementById("rotate" + elementID)
        .setAttribute("pointer-events", "painted");
      let imageDiv = document.getElementById("svgCanvas");
      currentImgDivPosition = {
        x: imageDiv.getBoundingClientRect().left,
        y: imageDiv.getBoundingClientRect().top,
      };
      cursorPosition.current = {
        x: e.touches[0].clientX - currentImgDivPosition.x,
        y: e.touches[0].clientY - currentImgDivPosition.y,
      };
      if (elementID.indexOf("layered") > 0) {
        selectedIndex.current = index;
        setSelectedLayeredImage(index);
        document.body.addEventListener("touchmove", repositionRectLTouch);
        document.body.addEventListener("touchend", stopRepositioningLTouch);
        document.body.addEventListener("touchcancel", stopRepositioningLTouch);
      } else {
        selectedIndex.current = index;
        document.body.addEventListener("touchmove", repositionRectTTouch);
        document.body.addEventListener("touchend", stopRepositioningTTouch);
        document.body.addEventListener("touchcancel", stopRepositioningTTouch);
      }
    }
  };
  const resizeRectL2Touch = (e) => {
    isRectResizing.current = true;
    let newPositionX = e.touches[0].clientX - currentImgDivPosition.x;
    let newPositionY = e.touches[0].clientY - currentImgDivPosition.y;
    let elementWidth =
      layeredImageResolutionRef.current[selectedIndex.current].width;
    let elementHeight =
      layeredImageResolutionRef.current[selectedIndex.current].height;
    let elementPositionX =
      layeredImagePositionRef.current[selectedIndex.current].x;
    let elementPositionY =
      layeredImagePositionRef.current[selectedIndex.current].y;
    let availableWidth = elementPositionX + elementWidth;
    let availableHeight = svgResolution.height - elementPositionY;
    let minWidth = 40;
    let minHeight = 40;
    let tempWidth = 0;
    let tempHeight = 0;
    let tempPositionX = 0;
    if (newPositionX < 0) {
      tempWidth = availableWidth;
      tempPositionX = 0;
    } else if (newPositionX > elementPositionX + elementWidth - minWidth) {
      tempWidth = minWidth;
      tempPositionX = elementPositionX + elementWidth - minWidth;
    } else {
      let movedWidth = elementWidth - (newPositionX - cursorPosition.current.x);
      if (movedWidth < availableWidth) {
        tempWidth = movedWidth;
        tempPositionX = newPositionX;
      } else {
        tempWidth = availableWidth;
        tempPositionX = 0;
      }
    }
    if (newPositionY > svgResolution.height) {
      tempHeight = availableHeight;
    } else if (newPositionY < elementPositionY + minHeight) {
      tempHeight = minHeight;
    } else {
      let movedHeight =
        elementHeight + (newPositionY - cursorPosition.current.y);
      if (movedHeight < availableHeight) {
        tempHeight = movedHeight;
      } else {
        tempHeight = availableHeight;
      }
    }
    cursorPosition.current = { x: tempPositionX, y: newPositionY };
    let newLayeredImageResolution = layeredImageResolutionRef.current.map(
      (value, i) => {
        if (i === selectedIndex.current) {
          return { width: tempWidth, height: tempHeight };
        } else {
          return value;
        }
      }
    );
    setLayeredImageResolution(newLayeredImageResolution);
    let newLayeredImagePosition = layeredImagePositionRef.current.map(
      (value, i) => {
        if (i === selectedIndex.current) {
          return { x: tempPositionX, y: elementPositionY };
        } else {
          return value;
        }
      }
    );
    setLayeredImagePosition(newLayeredImagePosition);
    tempUndoVar1.current = newLayeredImageResolution;
    tempUndoVar2.current = newLayeredImagePosition;
  };
  const stopResizingL1Touch = (e) => {
    if (isRectResizing.current === true) {
      isRectResizing.current = false;
      deselectAllRect();
      setundoredo(
        "resizeLayeredImage",
        "_" +
          JSON.stringify(layeredImageResolution) +
          "_" +
          JSON.stringify(layeredImagePosition),
        "_" +
          JSON.stringify(tempUndoVar1.current) +
          "_" +
          JSON.stringify(tempUndoVar2.current)
      );
    }
    let mainDIV = document.getElementsByClassName("imageEditWrapper");
    mainDIV[0].style.overflow = "auto !important";
    document.documentElement.style.overflow = "auto !important";
    document.body.style.overflow = "auto !important";
    document.body.removeEventListener("touchmove", resizeRectL1Touch);
    document.body.removeEventListener("touchend", stopResizingL1Touch);
    document.body.removeEventListener("touchcancel", stopResizingL1Touch);
  };
  //Handle touchstart on resizers of layered images
  const handleTouchDownOnResizer = (elementID, event, position, index) => {
    let mainDIV = document.getElementsByClassName("imageEditWrapper");
    mainDIV[0].style.overflow = "hidden !important";
    document.body.style.overflow = "hidden !important";
    document.documentElement.style.overflow = "hidden !important";
    document.body.style.overflow = "hidden !important";
    event.stopPropagation();
    event.preventDefault();
    isRectSelected.current = true;
    selectedElementID.current = elementID;
    textValue.map((text, i) => {
      document
        .getElementById("rect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("topleftrect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("toprightrect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("bottomleftrect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("bottomrightrect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("rotaterect" + i)
        .setAttribute("pointer-events", "none");
      return null;
    });
    layeredImagePosition.map((img, i) => {
      document
        .getElementById("rectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("topleftrectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("toprightrectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("bottomleftrectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("bottomrightrectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("rotaterectlayered" + i)
        .setAttribute("pointer-events", "none");
      return null;
    });
    let currentElement = document.getElementById(elementID);
    currentElement.setAttribute("pointer-events", "fill");
    document
      .getElementById("topleft" + elementID)
      .setAttribute("pointer-events", "painted");
    document
      .getElementById("topright" + elementID)
      .setAttribute("pointer-events", "painted");
    document
      .getElementById("bottomleft" + elementID)
      .setAttribute("pointer-events", "painted");
    document
      .getElementById("bottomright" + elementID)
      .setAttribute("pointer-events", "painted");
    document
      .getElementById("rotate" + elementID)
      .setAttribute("pointer-events", "painted");
    let imageDiv = document.getElementById("svgCanvas");
    currentImgDivPosition = {
      x: imageDiv.getBoundingClientRect().left,
      y: imageDiv.getBoundingClientRect().top,
    };
    cursorPosition.current = {
      x: event.touches[0].clientX - currentImgDivPosition.x,
      y: event.touches[0].clientY - currentImgDivPosition.y,
    };
    selectedIndex.current = index;
    if (elementID.indexOf("layered") > 0) {
      setSelectedLayeredImage(index);
      if (position === 3) {
        document.body.addEventListener("touchmove", resizeRectL3Touch);
        document.body.addEventListener("touchend", stopResizingL3Touch);
        document.body.addEventListener("touchcancel", stopResizingL3Touch);
      } else if (position === 1) {
        document.body.addEventListener("touchmove", resizeRectL1Touch);
        document.body.addEventListener("touchend", stopResizingL1Touch);
        document.body.addEventListener("touchcancel", stopResizingL1Touch);
      } else if (position === 2) {
        document.body.addEventListener("touchmove", resizeRectL2Touch);
        document.body.addEventListener("touchend", stopResizingL2Touch);
        document.body.addEventListener("touchcancel", stopResizingL2Touch);
      } else if (position === 0) {
        document.body.addEventListener("touchmove", resizeRectL0Touch);
        document.body.addEventListener("touchend", stopResizingL0Touch);
        document.body.addEventListener("touchcancel", stopResizingL0Touch);
      }
    } else {
      if (position === 3) {
        document.body.addEventListener("touchmove", resizeRectT3Touch);
        document.body.addEventListener("touchend", stopResizingT3Touch);
        document.body.addEventListener("touchcancel", stopResizingT3Touch);
      } else if (position === 1) {
        document.body.addEventListener("touchmove", resizeRectT1Touch);
        document.body.addEventListener("touchend", stopResizingT1Touch);
        document.body.addEventListener("touchcancel", stopResizingT1Touch);
      } else if (position === 2) {
        document.body.addEventListener("touchmove", resizeRectT2Touch);
        document.body.addEventListener("touchend", stopResizingT2Touch);
        document.body.addEventListener("touchcancel", stopResizingT2Touch);
      } else if (position === 0) {
        document.body.addEventListener("touchmove", resizeRectT0Touch);
        document.body.addEventListener("touchend", stopResizingT0Touch);
        document.body.addEventListener("touchcancel", stopResizingT0Touch);
      }
    }
  };
  const resizeRectT0Touch = (e) => {
    isRectResizing.current = true;
    let newPositionX = e.touches[0].clientX - currentImgDivPosition.x;
    let newPositionY = e.touches[0].clientY - currentImgDivPosition.y;
    let elementWidth = rectDimensionRef.current[selectedIndex.current].width;
    let elementHeight = rectDimensionRef.current[selectedIndex.current].height;
    let elementPositionX = rectPositionRef.current[selectedIndex.current].x;
    let elementPositionY = rectPositionRef.current[selectedIndex.current].y;
    let availableWidth = elementPositionX + elementWidth;
    let availableHeight = elementPositionY + elementHeight;
    let minWidth = textRectWidthRef.current[selectedIndex.current];
    let minHeight = textRectHeightRef.current[selectedIndex.current];
    let tempWidth = 0;
    let tempHeight = 0;
    let tempPositionX = 0;
    let tempPositionY = 0;
    if (newPositionX < 0) {
      tempWidth = availableWidth;
      tempPositionX = 0;
    } else if (newPositionX > elementPositionX + elementWidth - minWidth) {
      tempWidth = minWidth;
      tempPositionX = elementPositionX + elementWidth - minWidth;
    } else {
      let movedWidth = elementWidth - (newPositionX - cursorPosition.current.x);
      if (movedWidth < availableWidth) {
        tempWidth = movedWidth;
        tempPositionX = newPositionX;
      } else {
        tempWidth = availableWidth;
        tempPositionX = 0;
      }
    }
    if (newPositionY < 0) {
      tempHeight = availableHeight;
      tempPositionY = 0;
    } else if (newPositionY > elementPositionY + elementHeight - minHeight) {
      tempHeight = minHeight;
      tempPositionY = elementPositionY + elementHeight - minHeight;
    } else {
      let movedHeight =
        elementHeight - (newPositionY - cursorPosition.current.y);
      if (movedHeight < availableHeight) {
        tempHeight = movedHeight;
        tempPositionY = newPositionY;
      } else {
        tempHeight = availableHeight;
        tempPositionY = 0;
      }
    }
    cursorPosition.current = { x: tempPositionX, y: tempPositionY };
    let newRectDimension = rectDimensionRef.current.map((value, i) => {
      if (i === selectedIndex.current) {
        return { width: tempWidth, height: tempHeight };
      } else {
        return value;
      }
    });
    setRectDimension(newRectDimension);
    let newRectPosition = rectPositionRef.current.map((value, i) => {
      if (i === selectedIndex.current) {
        return { x: tempPositionX, y: tempPositionY };
      } else {
        return value;
      }
    });
    setRectPosition(newRectPosition);
    tempUndoVar1.current = newRectDimension;
    tempUndoVar2.current = newRectPosition;
  };
  const stopResizingT0Touch = (e) => {
    if (isRectResizing.current === true) {
      isRectResizing.current = false;
      deselectAllRect();
      setundoredo(
        "resizeRect",
        "_" +
          JSON.stringify(rectDimension) +
          "_" +
          JSON.stringify(rectPosition),
        "_" +
          JSON.stringify(tempUndoVar1.current) +
          "_" +
          JSON.stringify(tempUndoVar2.current)
      );
    }
    let mainDIV = document.getElementsByClassName("imageEditWrapper");
    mainDIV[0].style.overflow = "auto !important";
    document.documentElement.style.overflow = "auto !important";
    document.body.style.overflow = "auto !important";
    document.body.removeEventListener("touchmove", resizeRectT0Touch);
    document.body.removeEventListener("touchend", stopResizingT0Touch);
    document.body.removeEventListener("touchcancel", stopResizingT0Touch);
  };
  const resizeRectT2Touch = (e) => {
    isRectResizing.current = true;
    let newPositionX = e.touches[0].clientX - currentImgDivPosition.x;
    let newPositionY = e.touches[0].clientY - currentImgDivPosition.y;
    let elementWidth = rectDimensionRef.current[selectedIndex.current].width;
    let elementHeight = rectDimensionRef.current[selectedIndex.current].height;
    let elementPositionX = rectPositionRef.current[selectedIndex.current].x;
    let elementPositionY = rectPositionRef.current[selectedIndex.current].y;
    let availableWidth = elementPositionX + elementWidth;
    let availableHeight = svgResolution.height - elementPositionY;
    let minWidth = textRectWidthRef.current[selectedIndex.current];
    let minHeight = textRectHeightRef.current[selectedIndex.current];
    let tempWidth = 0;
    let tempHeight = 0;
    let tempPositionX = 0;
    if (newPositionX < 0) {
      tempWidth = availableWidth;
      tempPositionX = 0;
    } else if (newPositionX > elementPositionX + elementWidth - minWidth) {
      tempWidth = minWidth;
      tempPositionX = elementPositionX + elementWidth - minWidth;
    } else {
      let movedWidth = elementWidth - (newPositionX - cursorPosition.current.x);
      if (movedWidth < availableWidth) {
        tempWidth = movedWidth;
        tempPositionX = newPositionX;
      } else {
        tempWidth = availableWidth;
        tempPositionX = 0;
      }
    }
    if (newPositionY > svgResolution.height) {
      tempHeight = availableHeight;
    } else if (newPositionY < elementPositionY + minHeight) {
      tempHeight = minHeight;
    } else {
      let movedHeight =
        elementHeight + (newPositionY - cursorPosition.current.y);
      if (movedHeight < availableHeight) {
        tempHeight = movedHeight;
      } else {
        tempHeight = availableHeight;
      }
    }
    cursorPosition.current = { x: tempPositionX, y: newPositionY };
    let newRectDimension = rectDimensionRef.current.map((value, i) => {
      if (i === selectedIndex.current) {
        return { width: tempWidth, height: tempHeight };
      } else {
        return value;
      }
    });
    setRectDimension(newRectDimension);
    let newRectPosition = rectPositionRef.current.map((value, i) => {
      if (i === selectedIndex.current) {
        return { x: tempPositionX, y: elementPositionY };
      } else {
        return value;
      }
    });
    setRectPosition(newRectPosition);
    tempUndoVar1.current = newRectDimension;
    tempUndoVar2.current = newRectPosition;
  };
  const stopResizingT2Touch = (e) => {
    if (isRectResizing.current === true) {
      isRectResizing.current = false;
      deselectAllRect();
      setundoredo(
        "resizeRect",
        "_" +
          JSON.stringify(rectDimension) +
          "_" +
          JSON.stringify(rectPosition),
        "_" +
          JSON.stringify(tempUndoVar1.current) +
          "_" +
          JSON.stringify(tempUndoVar2.current)
      );
    }
    let mainDIV = document.getElementsByClassName("imageEditWrapper");
    mainDIV[0].style.overflow = "auto !important";
    document.documentElement.style.overflow = "auto !important";
    document.body.style.overflow = "auto !important";
    document.body.removeEventListener("touchmove", resizeRectT2Touch);
    document.body.removeEventListener("touchend", stopResizingT2Touch);
    document.body.removeEventListener("touchcancel", stopResizingT2Touch);
  };
  const resizeRectT1Touch = (e) => {
    isRectResizing.current = true;
    let newPositionX = e.touches[0].clientX - currentImgDivPosition.x;
    let newPositionY = e.touches[0].clientY - currentImgDivPosition.y;
    let elementWidth = rectDimensionRef.current[selectedIndex.current].width;
    let elementHeight = rectDimensionRef.current[selectedIndex.current].height;
    let elementPositionX = rectPositionRef.current[selectedIndex.current].x;
    let elementPositionY = rectPositionRef.current[selectedIndex.current].y;
    let availableWidth = svgResolution.width - elementPositionX;
    let availableHeight = elementPositionY + elementHeight;
    let minWidth = textRectWidthRef.current[selectedIndex.current];
    let minHeight = textRectHeightRef.current[selectedIndex.current];
    let tempWidth = 0;
    let tempHeight = 0;
    let tempPositionY = 0;
    if (newPositionX > svgResolution.width) {
      tempWidth = availableWidth;
    } else if (newPositionX < elementPositionX + minWidth) {
      tempWidth = minWidth;
    } else {
      let movedWidth = elementWidth + (newPositionX - cursorPosition.current.x);
      if (movedWidth < availableWidth) {
        tempWidth = movedWidth;
      } else {
        tempWidth = availableWidth;
      }
    }
    if (newPositionY < 0) {
      tempHeight = availableHeight;
      tempPositionY = 0;
    } else if (newPositionY > elementPositionY + elementHeight - minHeight) {
      tempHeight = minHeight;
      tempPositionY = elementPositionY + elementHeight - minHeight;
    } else {
      let movedHeight =
        elementHeight - (newPositionY - cursorPosition.current.y);
      if (movedHeight < availableHeight) {
        tempHeight = movedHeight;
        tempPositionY = newPositionY;
      } else {
        tempHeight = availableHeight;
        tempPositionY = 0;
      }
    }
    cursorPosition.current = { x: newPositionX, y: tempPositionY };
    let newRectDimension = rectDimensionRef.current.map((value, i) => {
      if (i === selectedIndex.current) {
        return { width: tempWidth, height: tempHeight };
      } else {
        return value;
      }
    });
    setRectDimension(newRectDimension);
    let newRectPosition = rectPositionRef.current.map((value, i) => {
      if (i === selectedIndex.current) {
        return { x: elementPositionX, y: tempPositionY };
      } else {
        return value;
      }
    });
    setRectPosition(newRectPosition);
    tempUndoVar1.current = newRectDimension;
    tempUndoVar2.current = newRectPosition;
  };
  const stopResizingT1Touch = (e) => {
    if (isRectResizing.current === true) {
      isRectResizing.current = false;
      deselectAllRect();
      setundoredo(
        "resizeRect",
        "_" +
          JSON.stringify(rectDimension) +
          "_" +
          JSON.stringify(rectPosition),
        "_" +
          JSON.stringify(tempUndoVar1.current) +
          "_" +
          JSON.stringify(tempUndoVar2.current)
      );
    }
    let mainDIV = document.getElementsByClassName("imageEditWrapper");
    mainDIV[0].style.overflow = "auto !important";
    document.documentElement.style.overflow = "auto !important";
    document.body.style.overflow = "auto !important";
    document.body.removeEventListener("touchmove", resizeRectT1Touch);
    document.body.removeEventListener("touchend", stopResizingT1Touch);
    document.body.removeEventListener("touchcancel", stopResizingT1Touch);
  };
  const stopResizingT3Touch = (e) => {
    if (isRectResizing.current === true) {
      isRectResizing.current = false;
      deselectAllRect();
      setundoredo(
        "setRectDimension",
        "_" + JSON.stringify(rectDimension),
        "_" + JSON.stringify(tempUndoVar1.current)
      );
    }
    let mainDIV = document.getElementsByClassName("imageEditWrapper");
    mainDIV[0].style.overflow = "auto !important";
    document.documentElement.style.overflow = "auto !important";
    document.body.style.overflow = "auto !important";
    document.body.removeEventListener("touchmove", resizeRectT3Touch);
    document.body.removeEventListener("touchend", stopResizingT3Touch);
    document.body.removeEventListener("touchcancel", stopResizingT3Touch);
  };
  const resizeRectT3Touch = (e) => {
    isRectResizing.current = true;
    let newPositionX = e.touches[0].clientX - currentImgDivPosition.x;
    let newPositionY = e.touches[0].clientY - currentImgDivPosition.y;
    let elementWidth = rectDimensionRef.current[selectedIndex.current].width;
    let elementHeight = rectDimensionRef.current[selectedIndex.current].height;
    let elementPositionX = rectPositionRef.current[selectedIndex.current].x;
    let elementPositionY = rectPositionRef.current[selectedIndex.current].y;
    let availableWidth = svgResolution.width - elementPositionX;
    let availableHeight = svgResolution.height - elementPositionY;
    let minWidth = textRectWidthRef.current[selectedIndex.current];
    let minHeight = textRectHeightRef.current[selectedIndex.current];
    let tempWidth = 0;
    let tempHeight = 0;
    if (newPositionX > svgResolution.width) {
      tempWidth = availableWidth;
    } else if (newPositionX < elementPositionX + minWidth) {
      tempWidth = minWidth;
    } else {
      let movedWidth = elementWidth + (newPositionX - cursorPosition.current.x);
      if (movedWidth < availableWidth) {
        tempWidth = movedWidth;
      } else {
        tempWidth = availableWidth;
      }
    }
    if (newPositionY > svgResolution.height) {
      tempHeight = availableHeight;
    } else if (newPositionY < elementPositionY + minHeight) {
      tempHeight = minHeight;
    } else {
      let movedHeight =
        elementHeight + (newPositionY - cursorPosition.current.y);
      if (movedHeight < availableHeight) {
        tempHeight = movedHeight;
      } else {
        tempHeight = availableHeight;
      }
    }
    cursorPosition.current = { x: newPositionX, y: newPositionY };
    let newRectDimension = rectDimensionRef.current.map((value, i) => {
      if (i === selectedIndex.current) {
        return { width: tempWidth, height: tempHeight };
      } else {
        return value;
      }
    });
    setRectDimension(newRectDimension);
    tempUndoVar1.current = newRectDimension;
  };
  const stopResizingL0Touch = (e) => {
    if (isRectResizing.current === true) {
      isRectResizing.current = false;
      deselectAllRect();
      setundoredo(
        "resizeLayeredImage",
        "_" +
          JSON.stringify(layeredImageResolution) +
          "_" +
          JSON.stringify(layeredImagePosition),
        "_" +
          JSON.stringify(tempUndoVar1.current) +
          "_" +
          JSON.stringify(tempUndoVar2.current)
      );
    }
    let mainDIV = document.getElementsByClassName("imageEditWrapper");
    mainDIV[0].style.overflow = "auto !important";
    document.documentElement.style.overflow = "auto !important";
    document.body.style.overflow = "auto !important";
    document.body.removeEventListener("touchmove", resizeRectL0Touch);
    document.body.removeEventListener("touchend", stopResizingL0Touch);
    document.body.removeEventListener("touchcancel", stopResizingL0Touch);
  };
  const resizeRectL0Touch = (e) => {
    isRectResizing.current = true;
    let newPositionX = e.touches[0].clientX - currentImgDivPosition.x;
    let newPositionY = e.touches[0].clientY - currentImgDivPosition.y;
    let elementWidth =
      layeredImageResolutionRef.current[selectedIndex.current].width;
    let elementHeight =
      layeredImageResolutionRef.current[selectedIndex.current].height;
    let elementPositionX =
      layeredImagePositionRef.current[selectedIndex.current].x;
    let elementPositionY =
      layeredImagePositionRef.current[selectedIndex.current].y;
    let availableWidth = elementPositionX + elementWidth;
    let availableHeight = elementPositionY + elementHeight;
    let minWidth = 40;
    let minHeight = 40;
    let tempWidth = 0;
    let tempHeight = 0;
    let tempPositionX = 0;
    let tempPositionY = 0;
    if (newPositionX < 0) {
      tempWidth = availableWidth;
      tempPositionX = 0;
    } else if (newPositionX > elementPositionX + elementWidth - minWidth) {
      tempWidth = minWidth;
      tempPositionX = elementPositionX + elementWidth - minWidth;
    } else {
      let movedWidth = elementWidth - (newPositionX - cursorPosition.current.x);
      if (movedWidth < availableWidth) {
        tempWidth = movedWidth;
        tempPositionX = newPositionX;
      } else {
        tempWidth = availableWidth;
        tempPositionX = 0;
      }
    }
    if (newPositionY < 0) {
      tempHeight = availableHeight;
      tempPositionY = 0;
    } else if (newPositionY > elementPositionY + elementHeight - minHeight) {
      tempHeight = minHeight;
      tempPositionY = elementPositionY + elementHeight - minHeight;
    } else {
      let movedHeight =
        elementHeight - (newPositionY - cursorPosition.current.y);
      if (movedHeight < availableHeight) {
        tempHeight = movedHeight;
        tempPositionY = newPositionY;
      } else {
        tempHeight = availableHeight;
        tempPositionY = 0;
      }
    }
    cursorPosition.current = { x: tempPositionX, y: tempPositionY };
    let newLayeredImageResolution = layeredImageResolutionRef.current.map(
      (value, i) => {
        if (i === selectedIndex.current) {
          return { width: tempWidth, height: tempHeight };
        } else {
          return value;
        }
      }
    );
    setLayeredImageResolution(newLayeredImageResolution);
    let newLayeredImagePosition = layeredImagePositionRef.current.map(
      (value, i) => {
        if (i === selectedIndex.current) {
          return { x: tempPositionX, y: tempPositionY };
        } else {
          return value;
        }
      }
    );
    setLayeredImagePosition(newLayeredImagePosition);
    tempUndoVar1.current = newLayeredImageResolution;
    tempUndoVar2.current = newLayeredImagePosition;
  };
  const stopResizingL2Touch = (e) => {
    if (isRectResizing.current === true) {
      isRectResizing.current = false;
      deselectAllRect();
      setundoredo(
        "resizeLayeredImage",
        "_" +
          JSON.stringify(layeredImageResolution) +
          "_" +
          JSON.stringify(layeredImagePosition),
        "_" +
          JSON.stringify(tempUndoVar1.current) +
          "_" +
          JSON.stringify(tempUndoVar2.current)
      );
    }
    let mainDIV = document.getElementsByClassName("imageEditWrapper");
    mainDIV[0].style.overflow = "auto !important";
    document.documentElement.style.overflow = "auto !important";
    document.body.style.overflow = "auto !important";
    document.body.removeEventListener("touchmove", resizeRectL2Touch);
    document.body.removeEventListener("touchend", stopResizingL2Touch);
    document.body.removeEventListener("touchcancel", stopResizingL2Touch);
  };
  const resizeRectL3Touch = (e) => {
    isRectResizing.current = true;
    let newPositionX = e.touches[0].clientX - currentImgDivPosition.x;
    let newPositionY = e.touches[0].clientY - currentImgDivPosition.y;
    let elementWidth =
      layeredImageResolutionRef.current[selectedIndex.current].width;
    let elementHeight =
      layeredImageResolutionRef.current[selectedIndex.current].height;
    let elementPositionX =
      layeredImagePositionRef.current[selectedIndex.current].x;
    let elementPositionY =
      layeredImagePositionRef.current[selectedIndex.current].y;
    let availableWidth = svgResolution.width - elementPositionX;
    let availableHeight = svgResolution.height - elementPositionY;
    let minWidth = 40;
    let minHeight = 40;
    let tempWidth = 0;
    let tempHeight = 0;
    if (newPositionX > svgResolution.width) {
      tempWidth = availableWidth;
    } else if (newPositionX < elementPositionX + minWidth) {
      tempWidth = minWidth;
    } else {
      let movedWidth = elementWidth + (newPositionX - cursorPosition.current.x);
      if (movedWidth < availableWidth) {
        tempWidth = movedWidth;
      } else {
        tempWidth = availableWidth;
      }
    }
    if (newPositionY > svgResolution.height) {
      tempHeight = availableHeight;
    } else if (newPositionY < elementPositionY + minHeight) {
      tempHeight = minHeight;
    } else {
      let movedHeight =
        elementHeight + (newPositionY - cursorPosition.current.y);
      if (movedHeight < availableHeight) {
        tempHeight = movedHeight;
      } else {
        tempHeight = availableHeight;
      }
    }
    cursorPosition.current = { x: newPositionX, y: newPositionY };
    let newLayeredImageResolution = layeredImageResolutionRef.current.map(
      (value, i) => {
        if (i === selectedIndex.current) {
          return { width: tempWidth, height: tempHeight };
        } else {
          return value;
        }
      }
    );
    setLayeredImageResolution(newLayeredImageResolution);
    tempUndoVar1.current = newLayeredImageResolution;
  };
  const stopResizingL3Touch = (e) => {
    if (isRectResizing.current === true) {
      isRectResizing.current = false;
      deselectAllRect();
      setundoredo(
        "setLayeredImageResolution",
        "_" + JSON.stringify(layeredImageResolution),
        "_" + JSON.stringify(tempUndoVar1.current)
      );
    }
    let mainDIV = document.getElementsByClassName("imageEditWrapper");
    mainDIV[0].style.overflow = "auto !important";
    document.documentElement.style.overflow = "auto !important";
    document.body.style.overflow = "auto !important";
    document.body.removeEventListener("touchmove", resizeRectL3Touch);
    document.body.removeEventListener("touchend", stopResizingL3Touch);
    document.body.removeEventListener("touchcancel", stopResizingL3Touch);
  };
  const resizeRectL1Touch = (e) => {
    isRectResizing.current = true;
    let newPositionX = e.touches[0].clientX - currentImgDivPosition.x;
    let newPositionY = e.touches[0].clientY - currentImgDivPosition.y;
    let elementWidth =
      layeredImageResolutionRef.current[selectedIndex.current].width;
    let elementHeight =
      layeredImageResolutionRef.current[selectedIndex.current].height;
    let elementPositionX =
      layeredImagePositionRef.current[selectedIndex.current].x;
    let elementPositionY =
      layeredImagePositionRef.current[selectedIndex.current].y;
    let availableWidth = svgResolution.width - elementPositionX;
    let availableHeight = elementPositionY + elementHeight;
    let minWidth = 40;
    let minHeight = 40;
    let tempWidth = 0;
    let tempHeight = 0;
    let tempPositionY = 0;
    if (newPositionX > svgResolution.width) {
      tempWidth = availableWidth;
    } else if (newPositionX < elementPositionX + minWidth) {
      tempWidth = minWidth;
    } else {
      let movedWidth = elementWidth + (newPositionX - cursorPosition.current.x);
      if (movedWidth < availableWidth) {
        tempWidth = movedWidth;
      } else {
        tempWidth = availableWidth;
      }
    }
    if (newPositionY < 0) {
      tempHeight = availableHeight;
      tempPositionY = 0;
    } else if (newPositionY > elementPositionY + elementHeight - minHeight) {
      tempHeight = minHeight;
      tempPositionY = elementPositionY + elementHeight - minHeight;
    } else {
      let movedHeight =
        elementHeight - (newPositionY - cursorPosition.current.y);
      if (movedHeight < availableHeight) {
        tempHeight = movedHeight;
        tempPositionY = newPositionY;
      } else {
        tempHeight = availableHeight;
        tempPositionY = 0;
      }
    }
    cursorPosition.current = { x: newPositionX, y: tempPositionY };
    let newLayeredImageResolution = layeredImageResolutionRef.current.map(
      (value, i) => {
        if (i === selectedIndex.current) {
          return { width: tempWidth, height: tempHeight };
        } else {
          return value;
        }
      }
    );
    setLayeredImageResolution(newLayeredImageResolution);
    let newLayeredImagePosition = layeredImagePositionRef.current.map(
      (value, i) => {
        if (i === selectedIndex.current) {
          return { x: elementPositionX, y: tempPositionY };
        } else {
          return value;
        }
      }
    );
    setLayeredImagePosition(newLayeredImagePosition);
    tempUndoVar1.current = newLayeredImageResolution;
    tempUndoVar2.current = newLayeredImagePosition;
  };
  //Handle touchend event for text area
  const stopRepositioningTTouch = (e) => {
    if (isRectDragging.current === true) {
      isRectDragging.current = false;
      deselectAllRect();
      showAllRect();
      setundoredo(
        "setRectPosition",
        "_" + JSON.stringify(rectPosition),
        "_" + JSON.stringify(tempUndoVar1.current)
      );
    }
    let mainDIV = document.getElementsByClassName("imageEditWrapper");
    mainDIV[0].style.overflow = "auto !important";
    document.body.removeEventListener("touchmove", repositionRectTTouch);
    document.body.removeEventListener("touchend", stopRepositioningTTouch);
    document.body.removeEventListener("touchcancel", stopRepositioningTTouch);
    document.documentElement.style.overflow = "auto !important";
    document.body.style.overflow = "auto !important";
  };

  // Handle touchend event for layered Image
  const stopRepositioningLTouch = (e) => {
    if (isRectDragging.current === true) {
      isRectDragging.current = false;
      deselectAllRect();
      showAllRect();
      setundoredo(
        "setLayeredImagePosition",
        "_" + JSON.stringify(layeredImagePosition),
        "_" + JSON.stringify(tempUndoVar1.current)
      );
    }
    let mainDIV = document.getElementsByClassName("imageEditWrapper");
    mainDIV[0].style.overflow = "auto !important";
    document.body.removeEventListener("touchmove", repositionRectLTouch);
    document.body.removeEventListener("touchend", stopRepositioningLTouch);
    document.body.removeEventListener("touchcancel", stopRepositioningLTouch);
    document.documentElement.style.overflow = "auto !important";
    document.body.style.overflow = "auto !important";
  };

  // Handle touchmove event for Rect area
  const repositionRectTTouch = (e) => {
    isRectDragging.current = true;
    let newPositionX = e.touches[0].clientX - currentImgDivPosition.x;
    let newPositionY = e.touches[0].clientY - currentImgDivPosition.y;
    if (newPositionX > svgResolution.width) {
      newPositionX = svgResolution.width;
    } else if (newPositionX <= 0) {
      newPositionX = 0;
    }
    if (newPositionY > svgResolution.height) {
      newPositionY = svgResolution.height;
    } else if (newPositionY <= 0) {
      newPositionY = 0;
    }
    let svgWidth = Math.floor(svgResolution.width);
    let svgHeight = Math.floor(svgResolution.height);
    let rectWidth = rectDimensionRef.current[selectedIndex.current].width;
    let rectHeight = rectDimensionRef.current[selectedIndex.current].height;
    let movementX;
    let movementY;
    movementX =
      newPositionX -
      cursorPosition.current.x +
      rectPositionRef.current[selectedIndex.current].x;
    movementY =
      newPositionY -
      cursorPosition.current.y +
      rectPositionRef.current[selectedIndex.current].y;
    cursorPosition.current = { x: newPositionX, y: newPositionY };
    if (movementX + rectWidth > svgWidth) {
      movementX = svgWidth - rectWidth;
    } else if (movementX <= 0) {
      movementX = 0;
    }
    if (movementY + rectHeight > svgHeight) {
      movementY = svgHeight - rectHeight;
    } else if (movementY <= 0) {
      movementY = 0;
    }
    let tempImagePosition = rectPositionRef.current.map((value, index) => {
      if (selectedIndex.current === index) {
        return { x: movementX, y: movementY };
      } else {
        return value;
      }
    });
    setRectPosition(tempImagePosition);
    tempUndoVar1.current = tempImagePosition;
  };

  // Handle touchmove event for Layered Image
  const repositionRectLTouch = (e) => {
    isRectDragging.current = true;
    let newPositionX = e.touches[0].clientX - currentImgDivPosition.x;
    let newPositionY = e.touches[0].clientY - currentImgDivPosition.y;
    if (newPositionX > svgResolution.width) {
      newPositionX = svgResolution.width;
    } else if (newPositionX <= 0) {
      newPositionX = 0;
    }
    if (newPositionY > svgResolution.height) {
      newPositionY = svgResolution.height;
    } else if (newPositionY <= 0) {
      newPositionY = 0;
    }
    let svgWidth = Math.floor(svgResolution.width);
    let svgHeight = Math.floor(svgResolution.height);
    let rectWidth =
      layeredImageResolutionRef.current[selectedIndex.current].width;
    let rectHeight =
      layeredImageResolutionRef.current[selectedIndex.current].height;
    let movementX;
    let movementY;
    movementX =
      newPositionX -
      cursorPosition.current.x +
      layeredImagePositionRef.current[selectedIndex.current].x;
    movementY =
      newPositionY -
      cursorPosition.current.y +
      layeredImagePositionRef.current[selectedIndex.current].y;
    cursorPosition.current = { x: newPositionX, y: newPositionY };
    if (movementX + rectWidth > svgWidth) {
      movementX = svgWidth - rectWidth;
    } else if (movementX <= 0) {
      movementX = 0;
    }
    if (movementY + rectHeight > svgHeight) {
      movementY = svgHeight - rectHeight;
    } else if (movementY <= 0) {
      movementY = 0;
    }
    let tempImagePosition = layeredImagePositionRef.current.map(
      (value, index) => {
        if (selectedIndex.current === index) {
          return { x: movementX, y: movementY };
        } else {
          return value;
        }
      }
    );
    setLayeredImagePosition(tempImagePosition);
    tempUndoVar1.current = tempImagePosition;
  };

  //Handle touchstart rotation on rect
  const handleTouchDownOnRotation = (elementID, event, index) => {
    let mainDIV = document.getElementsByClassName("imageEditWrapper");
    mainDIV[0].style.overflow = "hidden !important";
    document.body.style.overflow = "hidden !important";
    document.documentElement.style.overflow = "hidden !important";
    document.body.style.overflow = "hidden !important";
    event.stopPropagation();
    event.preventDefault();
    isRectSelected.current = true;
    selectedElementID.current = elementID;
    textValue.map((text, i) => {
      document
        .getElementById("rect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("topleftrect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("toprightrect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("bottomleftrect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("bottomrightrect" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("rotaterect" + i)
        .setAttribute("pointer-events", "none");
      return null;
    });
    layeredImagePosition.map((img, i) => {
      document
        .getElementById("rectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("topleftrectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("toprightrectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("bottomleftrectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("bottomrightrectlayered" + i)
        .setAttribute("pointer-events", "none");
      document
        .getElementById("rotaterectlayered" + i)
        .setAttribute("pointer-events", "none");
      return null;
    });
    selectedIndex.current = index;
    let currentElement = document.getElementById(elementID);
    currentElement.setAttribute("pointer-events", "fill");
    document
      .getElementById("topleft" + elementID)
      .setAttribute("pointer-events", "painted");
    document
      .getElementById("topright" + elementID)
      .setAttribute("pointer-events", "painted");
    document
      .getElementById("bottomleft" + elementID)
      .setAttribute("pointer-events", "painted");
    document
      .getElementById("bottomright" + elementID)
      .setAttribute("pointer-events", "painted");
    document
      .getElementById("rotate" + elementID)
      .setAttribute("pointer-events", "painted");
    let imageDiv = document.getElementById("svgCanvas");
    currentImgDivPosition = {
      x: imageDiv.getBoundingClientRect().left,
      y: imageDiv.getBoundingClientRect().top,
    };
    if (elementID.indexOf("layered") > 0) {
      setSelectedLayeredImage(index);
      document.body.addEventListener("touchmove", rotateRectLTouch);
      document.body.addEventListener("touchend", stopRotateLTouch);
      document.body.addEventListener("touchcancel", stopRotateLTouch);
    } else {
      document.body.addEventListener("touchmove", rotateRectTTouch);
      document.body.addEventListener("touchend", stopRotateTTouch);
      document.body.addEventListener("touchcancel", stopRotateTTouch);
    }
  };

  const rotateRectLTouch = (event) => {
    isRectRotating.current = true;
    let newPositionX = event.touches[0].clientX - currentImgDivPosition.x;
    let newPositionY = event.touches[0].clientY - currentImgDivPosition.y;
    let elementPositionX =
      layeredImagePositionRef.current[selectedIndex.current].x;
    let elementPositionY =
      layeredImagePositionRef.current[selectedIndex.current].y;
    let elementDimensionWidth =
      layeredImageResolutionRef.current[selectedIndex.current].width;
    let elementDimensionHeight =
      layeredImageResolutionRef.current[selectedIndex.current].height;
    let elementCenterPointX = elementPositionX + elementDimensionWidth / 2;
    let elementCenterPointY = elementPositionY + elementDimensionHeight / 2;
    let angle =
      Math.atan2(
        newPositionY - elementCenterPointY,
        newPositionX - elementCenterPointX
      ) *
        57.296 -
      90;
    if (angle < 1.5 && angle > -1.5) {
      angle = 0;
    }
    let newRotationScale = rotationScaleL.map((value, index) => {
      if (index === selectedIndex.current) {
        return Math.round(angle);
      } else {
        return value;
      }
    });
    setRotationScaleL(newRotationScale);
    tempUndoVar1.current = newRotationScale;
  };

  const rotateRectTTouch = (event) => {
    isRectRotating.current = true;
    let newPositionX = event.touches[0].clientX - currentImgDivPosition.x;
    let newPositionY = event.touches[0].clientY - currentImgDivPosition.y;
    let elementPositionX = rectPositionRef.current[selectedIndex.current].x;
    let elementPositionY = rectPositionRef.current[selectedIndex.current].y;
    let elementDimensionWidth =
      rectDimensionRef.current[selectedIndex.current].width;
    let elementDimensionHeight =
      rectDimensionRef.current[selectedIndex.current].height;
    let elementCenterPointX = elementPositionX + elementDimensionWidth / 2;
    let elementCenterPointY = elementPositionY + elementDimensionHeight / 2;
    let angle =
      Math.atan2(
        newPositionY - elementCenterPointY,
        newPositionX - elementCenterPointX
      ) *
        57.296 -
      90;
    if (angle < 2 && angle > -2) {
      angle = 0;
    }
    let newRotationScale = rotationScaleT.map((value, index) => {
      if (index === selectedIndex.current) {
        return Math.round(angle);
      } else {
        return value;
      }
    });
    setRotationScaleT(newRotationScale);
    tempUndoVar1.current = newRotationScale;
  };

  const stopRotateTTouch = (event) => {
    if (isRectRotating.current === true) {
      isRectRotating.current = false;
      deselectAllRect();
      setundoredo(
        "setRotationScaleT",
        "_" + JSON.stringify(rotationScaleT),
        "_" + JSON.stringify(tempUndoVar1.current)
      );
    }
    let mainDIV = document.getElementsByClassName("imageEditWrapper");
    mainDIV[0].style.overflow = "auto !important";
    document.documentElement.style.overflow = "auto !important";
    document.body.style.overflow = "auto !important";
    document.body.removeEventListener("touchmove", rotateRectTTouch);
    document.body.removeEventListener("touchend", stopRotateTTouch);
    document.body.removeEventListener("touchcancel", stopRotateTTouch);
  };

  const stopRotateLTouch = (event) => {
    if (isRectRotating.current === true) {
      isRectRotating.current = false;
      deselectAllRect();
      setundoredo(
        "setRotationScaleL",
        "_" + JSON.stringify(rotationScaleL),
        "_" + JSON.stringify(tempUndoVar1.current)
      );
    }
    let mainDIV = document.getElementsByClassName("imageEditWrapper");
    mainDIV[0].style.overflow = "auto !important";
    document.documentElement.style.overflow = "auto !important";
    document.body.style.overflow = "auto !important";
    document.body.removeEventListener("touchmove", rotateRectLTouch);
    document.body.removeEventListener("touchend", stopRotateLTouch);
    document.body.removeEventListener("touchcancel", stopRotateLTouch);
  };

  //Handle touchdown on main canvas
  const handleTouchDownOnCanvas = (e) => {
    let mainDIV = document.getElementsByClassName("imageEditWrapper");
    mainDIV[0].style.overflow = "hidden !important";
    document.body.style.overflow = "hidden !important";
    document.documentElement.style.overflow = "hidden !important";
    document.body.style.overflow = "hidden !important";
    if (isRectDragging.current === false) {
      isRectSelected.current = true;
      selectedElementID.current = "svgsvgcanvas";
      textValue.map((text, i) => {
        document
          .getElementById("rect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("topleftrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("toprightrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomleftrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomrightrect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("rotaterect" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("grect" + i)
          .setAttribute("visibility", "hidden");
        return null;
      });
      layeredImagePosition.map((img, i) => {
        document
          .getElementById("rectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("topleftrectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("toprightrectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomleftrectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("bottomrightrectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("rotaterectlayered" + i)
          .setAttribute("pointer-events", "none");
        document
          .getElementById("grectlayered" + i)
          .setAttribute("visibility", "hidden");
        return null;
      });
      document.getElementById("svgsvgcanvas").setAttribute("cursor", "move");
      let imageDiv = document.getElementById("svgCanvas");
      currentImgDivPosition = {
        x: imageDiv.getBoundingClientRect().left,
        y: imageDiv.getBoundingClientRect().top,
      };
      cursorPosition.current = {
        x: e.touches[0].clientX - currentImgDivPosition.x,
        y: e.touches[0].clientY - currentImgDivPosition.y,
      };

      document.body.addEventListener("touchmove", repositionCanvasTouch);
      document.body.addEventListener("touchend", stopRepositioningCanvasTouch);
      document.body.addEventListener(
        "touchcancel",
        stopRepositioningCanvasTouch
      );
    }
  };

  //Handle touchmove event for canvas area
  const repositionCanvasTouch = (e) => {
    isRectDragging.current = true;
    let newPositionX = e.touches[0].clientX - currentImgDivPosition.x;
    let newPositionY = e.touches[0].clientY - currentImgDivPosition.y;
    let movementX =
      (newPositionX - cursorPosition.current.x) / imgDimensionScale +
      imagePositionRef.current.x;
    let movementY =
      (newPositionY - cursorPosition.current.y) / imgDimensionScale +
      imagePositionRef.current.y;
    let limitedXSpace =
      (defImageResolution.width * (imgDimensionScale - 1)) /
      (2 * imgDimensionScale);
    let limitedYSpace =
      (defImageResolution.height * (imgDimensionScale - 1)) /
      (2 * imgDimensionScale);
    if (movementX < limitedXSpace * -1 || movementX > limitedXSpace) {
      movementX = imagePositionRef.current.x;
    }
    if (movementY < limitedYSpace * -1 || movementY > limitedYSpace) {
      movementY = imagePositionRef.current.y;
    }
    cursorPosition.current = { x: newPositionX, y: newPositionY };
    setImagePosition({ x: movementX, y: movementY });
    tempUndoVar1.current = { x: movementX, y: movementY };
  };

  //Handle touchend and touchcancel event for canvas area
  const stopRepositioningCanvasTouch = (e) => {
    if (isRectDragging.current === true) {
      isRectDragging.current = false;
      deselectAllRect();
      setundoredo(
        "setImagePosition",
        "_" + JSON.stringify(imagePosition),
        "_" + JSON.stringify(tempUndoVar1.current)
      );
    }
    let mainDIV = document.getElementsByClassName("imageEditWrapper");
    mainDIV[0].style.overflow = "auto !important";
    document.documentElement.style.overflow = "auto !important";
    document.body.style.overflow = "auto !important";
    document.body.removeEventListener("touchmove", repositionCanvasTouch);
    document.body.removeEventListener("touchend", stopRepositioningCanvasTouch);
    document.body.removeEventListener(
      "touchcancel",
      stopRepositioningCanvasTouch
    );
  };

  const handleModalClose = () => {
    if (isEmpty(textValue)) {
      props.handleCloseModal(false);
    } else {
      props.handleCloseModal(true);
    }
  };
  //   const initialValue = {
  //     centerContent: false,
  //     limitToBounds: false,
  //     limitToWrapper: true
  //   }

  //   const [detials, setDetials] = useState(initialValue)

  //   const onLoad = (e) => {
  //     setDetials({
  //   ...initialValue,
  //   defoultScale: e.target.width / e.target.naturalWidth
  // })
  //   }

  const svgField = () => (
    <div
      id="imageDiv"
      style={{
        width: svgResolution.width,
        height: svgResolution.height,
        touchAction: "none",
      }}
      onMouseEnter={() => showAllRect()}
      onMouseLeave={() => hideAllRect()}
    >
      {/* {console.log('svgResolution', svgResolution)} */}
      <ContextMenuInteractionMemo />
      <div
        id="svgCanvas"
        style={{ width: svgResolution.width, height: svgResolution.height }}
      >
        {/* <TransformWrapper
      >
        {({zoomIn, zoomOut, resetTransform, ...rest}) => (
          <TransformComponent> */}
        <svg
          width={svgResolution.width + "px"}
          height={svgResolution.height + "px"}
          xmlns="http://www.w3.org/2000/svg"
          x={0}
          y={0}
          onMouseDown={() => hideAllRect()}
          // onLoad={onLoad}
        >
          <filter id="nofilter">
            {filterSelected === "nofilter" && (
              <>
                <feComponentTransfer id="nofilter1"></feComponentTransfer>
              </>
            )}
            {filterSelected === "posterize" && (
              <>
                <feComponentTransfer id="posterize2">
                  <feFuncR
                    type="discrete"
                    tableValues="0.1 0.2 0.3 0.4 0.5 0.6 0.7 0.8 0.9"
                  />
                  <feFuncG
                    type="discrete"
                    tableValues="0.1 0.2 0.3 0.4 0.5 0.6 0.7 0.8 0.9"
                  />
                  <feFuncB
                    type="discrete"
                    tableValues="0.1 0.2 0.3 0.4 0.5 0.6 0.7 0.8 0.9"
                  />
                </feComponentTransfer>
                <feComponentTransfer id="posterize1">
                  <feFuncR
                    type="linear"
                    slope={1.1 + ""}
                    intercept={-0.05 + ""}
                  />
                  <feFuncG
                    type="linear"
                    slope={1.1 + ""}
                    intercept={-0.05 + ""}
                  />
                  <feFuncB
                    type="linear"
                    slope={1.1 + ""}
                    intercept={-0.05 + ""}
                  />
                </feComponentTransfer>
              </>
            )}
            {filterSelected === "blur" && (
              <>
                <feGaussianBlur
                  id="blur1"
                  in="SourceGraphic"
                  stdDeviation="2"
                />
              </>
            )}
            {filterSelected === "sharpen" && (
              <>
                <feConvolveMatrix
                  id="sharpen1"
                  order="3 3"
                  preserveAlpha="true"
                  kernelMatrix="-1 0 0 0 4 0 0 0 -1"
                />
              </>
            )}
            {filterSelected === "grayscale" && (
              <>
                <feColorMatrix
                  id="grayscale1"
                  type="matrix"
                  values="0.2126 0.7152 0.0722 0 0
                                            0.2126 0.7152 0.0722 0 0
                                            0.2126 0.7152 0.0722 0 0
                                            0 0 0 1 0"
                />
              </>
            )}
            {/*Equation for Contrast is slope=A intercept= ((-0.5*A)+0.5) and equation for points
(S,A) => (-100,0.5), (-50, 0.75), (0, 1), (50, 0.25) and (100, 0.5)
A = ((s+200)/200) or A = s*0.005 + 1*/}
            <feComponentTransfer id="contrast">
              <feFuncR
                type="linear"
                slope={contrastScale * 0.005 + 1 + ""}
                intercept={contrastScale * -0.0025 + ""}
              />
              <feFuncG
                type="linear"
                slope={contrastScale * 0.005 + 1 + ""}
                intercept={contrastScale * -0.0025 + ""}
              />
              <feFuncB
                type="linear"
                slope={contrastScale * 0.005 + 1 + ""}
                intercept={contrastScale * -0.0025 + ""}
              />
            </feComponentTransfer>
            {/*Equation for Brightness is slope=A and equation for points
(S,A) => (-100,0.25), (-50, 0.625), (0,1), (50, 0.375) and (100,0.75)
A = ((3s+400)/400) or A = s*0.0075 + 1*/}
            <feComponentTransfer id="brightness">
              <feFuncR
                type="linear"
                slope={brightnessScale * 0.0075 + 1 + ""}
              />
              <feFuncG
                type="linear"
                slope={brightnessScale * 0.0075 + 1 + ""}
              />
              <feFuncB
                type="linear"
                slope={brightnessScale * 0.0075 + 1 + ""}
              />
            </feComponentTransfer>
            {/*Equation for Brightness is value=A and equation for points
(S,A) => (-100,0), (-50, 0.5), (0,1), (50, 1.5) and (100,2)
A = ((s+100)/100) or A = s*0.01 + 1*/}
            <feColorMatrix
              id="saturate"
              type="saturate"
              values={saturationScale * 0.01 + 1 + ""}
            />
            {/*Equation for Warmth is slope=A intercept= ((-0.5*A)+0.5) and equation for points
(S,A) => (-100,0.5), (-50, 0.75), (0, 1), (50, 0.25) and (100, 0.5)
A = ((s+200)/200) or A = s*0.005 + 1*/}
            <feComponentTransfer id="warmth">
              <feFuncR type="linear" slope={warmthScale * 0.005 + 1 + ""} />
              <feFuncG type="identity" />
              <feFuncB type="identity" />
            </feComponentTransfer>
          </filter>
          {spaceTopEnable === 1 && spaceValue !== 0 && (
            <rect
              x={0}
              y={0}
              width="100%"
              height={
                defImageResolution.height *
                  (spaceTopEnable * (spaceValue / 100)) +
                "px"
              }
              fill={spaceColor}
              onMouseDown={() => deselectAllRect()}
            />
          )}
          {spaceBotEnable === 1 && spaceValue !== 0 && (
            <rect
              x={0}
              y={
                defImageResolution.height *
                  (spaceTopEnable * (spaceValue / 100)) +
                defImageResolution.height
              }
              width="100%"
              height={
                defImageResolution.height *
                  (spaceBotEnable * (spaceValue / 100)) +
                "px"
              }
              fill={spaceColor}
              onMouseDown={() => deselectAllRect()}
            />
          )}
          <svg
            x={0}
            y={
              defImageResolution.height * (spaceTopEnable * (spaceValue / 100))
            }
            width={defImageResolution.width + "px"}
            height={defImageResolution.height + "px"}
            xmlns="http://www.w3.org/2000/svg"
          >
            {/*
The Scale value to Actual Value used in filter can be found by finding the
equation which fits the coordinates  pointed by the two variables. Example.
Let scaled value used in slider be represented by S, and let the Actual value
used in filter be represented by A. Since, A and S in the case of adjusting
contrast, represented as (A, S) can take value as (0.5, -100), (0.75,-50),
(1,0), (1.25, 50) and (1.5, 100), the resulting equation is S = 200A - 200
*/}
            <g
              transform={
                " translate(" +
                defImageResolution.width / 2 +
                ", " +
                defImageResolution.height / 2 +
                ")" +
                " scale(" +
                imgDimensionScale +
                "," +
                imgDimensionScale +
                ") translate(-" +
                defImageResolution.width / 2 +
                ", -" +
                defImageResolution.height / 2 +
                ")"
              }
            >
              <g
                transform={
                  "scale(" +
                  flipH +
                  ", " +
                  flipV +
                  ") translate(" +
                  (flipH === -1 ? defImageResolution.width * -1 : 0) +
                  ", " +
                  (flipV === -1 ? defImageResolution.height * -1 : 0) +
                  ")"
                }
              >
                <g
                  transform={
                    "rotate(" +
                    rotationScale +
                    " " +
                    defImageResolution.width / 2 +
                    " " +
                    defImageResolution.height / 2 +
                    ")"
                  }
                  filter={"url(#nofilter)"}
                >
                  <image
                    x={imagePosition.x}
                    y={imagePosition.y}
                    key={"bimage"}
                    id="svgsvgcanvas"
                    href={initImage}
                    width={imageResolution.width + "px"}
                    height={imageResolution.height + "px"}
                    onMouseDown={(event) => {
                      handleMouseDownOnCanvas(event);
                    }}
                    onTouchStart={(event) => {
                      handleTouchDownOnCanvas(event);
                    }}
                  />
                </g>
              </g>
            </g>
          </svg>
        </svg>
        {/* </TransformComponent>

      )}
      </TransformWrapper> */}
      </div>
      <svg
        id="hiddenSVG"
        className="hiddenSVG"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <text x="0" y="-0" id="twh"></text>
        <WatermarkLogo id="watermarklogo" />
      </svg>
      <svg
        id="svgId"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{ height: svgResolution.height, width: svgResolution.width }}
      >
        <>
          {layeredImage.map((layeredImg, index) => {
            return (
              <image
                key={"imgrectlayered_" + index}
                id={"imgrectlayered" + index}
                x={layeredImagePosition[index].x}
                y={layeredImagePosition[index].y}
                href={layeredImage[index]}
                width={layeredImageResolution[index].width}
                height={layeredImageResolution[index].height}
                preserveAspectRatio="none"
                transform={
                  "rotate(" +
                  rotationScaleL[index] +
                  " " +
                  (layeredImagePosition[index].x +
                    layeredImageResolution[index].width / 2) +
                  " " +
                  (layeredImagePosition[index].y +
                    layeredImageResolution[index].height / 2) +
                  ")"
                }
              />
            );
          })}
          {textValue.map((textItem, index) => {
            return (
              <>
                <g
                  key={"textrect_" + index}
                  id={"textrect" + index}
                  data-valign={vAlign[index]}
                  data-halign={hAlign[index]}
                  data-posx={rectPosition[index].x}
                  data-posy={rectPosition[index].y}
                  data-textminwidth={textRectWidth[index]}
                  data-textminheight={textRectHeight[index]}
                  data-stroketype={strokeType[index]}
                  transform={
                    "rotate(" +
                    rotationScaleT[index] +
                    " " +
                    (rectPosition[index].x + rectDimension[index].width / 2) +
                    " " +
                    (rectPosition[index].y + rectDimension[index].height / 2) +
                    ")"
                  }
                >
                  {strokeType[index] === 2 && (
                    <rect
                      id={"textbox" + index}
                      fill={strokeValue[index]}
                      x={rectPosition[index].x}
                      y={rectPosition[index].y}
                      width={rectDimension[index].width + "px"}
                      height={rectDimension[index].height + "px"}
                      rx="7px"
                      ry="7px"
                    ></rect>
                  )}
                  <text
                    key={"psuedo_" + index}
                    id={"psuedo" + index}
                    x={
                      rectPosition[index].x +
                      rectDimension[index].width * hAlign[index]
                    }
                    y={
                      rectPosition[index].y +
                      (rectDimension[index].height -
                        fontSize[index] * textItem.length * 1.15) *
                        vAlign[index]
                    }
                    textAnchor={textAnchorMap[hAlign[index]]}
                    style={{
                      fontSize: fontSize[index] + "px",
                      fontFamily: fontFamily[index],
                      fontWeight: fontWeight[index],
                      fontStyle: fontStyle[index],
                      textShadow:
                        "0 0 " + shadowWidth[index] + " " + strokeValue[index],
                      letterSpacing: letterSpace[index],
                      stroke: strokeValue[index],
                      strokeWidth: strokeWidth[index],
                      textTransform: isUpperCase[index],
                      userSelect: "none",
                    }}
                  >
                    {textItem.map((v, i) => {
                      return (
                        <tspan
                          key={"psuedotspan_" + index + i}
                          id={"psuedotspan" + index + i}
                          x={
                            rectPosition[index].x +
                            rectDimension[index].width * hAlign[index]
                          }
                          dy="1.15em"
                        >
                          {v}
                        </tspan>
                      );
                    })}
                  </text>
                  <text
                    key={"text_" + index}
                    id={"text" + index}
                    x={
                      rectPosition[index].x +
                      rectDimension[index].width * hAlign[index]
                    }
                    y={
                      rectPosition[index].y +
                      (rectDimension[index].height -
                        fontSize[index] * textItem.length * 1.15) *
                        vAlign[index]
                    }
                    textAnchor={textAnchorMap[hAlign[index]]}
                    style={{
                      fontSize: fontSize[index] + "px",
                      fontFamily: fontFamily[index],
                      fontWeight: fontWeight[index],
                      fontStyle: fontStyle[index],
                      letterSpacing: letterSpace[index],
                      fill: colorValue[index],
                      textTransform: isUpperCase[index],
                      userSelect: "none",
                    }}
                  >
                    {textItem.map((v, i) => {
                      return (
                        <tspan
                          key={"tspan_" + index + i}
                          id={"tspan" + index + i}
                          x={
                            rectPosition[index].x +
                            rectDimension[index].width * hAlign[index]
                          }
                          dy="1.15em"
                        >
                          {v}
                        </tspan>
                      );
                    })}
                  </text>
                </g>
              </>
            );
          })}
        </>
      </svg>
      <svg
        id="filtersvg"
        className="filtersvg"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0"
        y="0"
        height={svgResolution.height}
        width={svgResolution.width}
        style={{ touchAction: "none" }}
      >
        <g fill="none">
          <path stroke="#595959" d={"M0 0 " + filterWidth + " 0"} />
          <path
            stroke="#595959"
            d={
              "M0 " +
              filterHeight / 3 +
              " " +
              filterWidth +
              " " +
              filterHeight / 3
            }
          />
          <path
            stroke="#595959"
            d={
              "M0 " +
              (filterHeight * 2) / 3 +
              " " +
              filterWidth +
              " " +
              (filterHeight * 2) / 3
            }
          />
          <path
            stroke="#595959"
            d={"M0 " + filterHeight + " " + filterWidth + " " + filterHeight}
          />
          <path stroke="#595959" d={"M0 0 0 " + filterHeight} />
          <path
            stroke="#595959"
            d={
              "M" +
              filterWidth / 3 +
              " 0 " +
              filterWidth / 3 +
              " " +
              filterHeight
            }
          />
          <path
            stroke="#595959"
            d={
              "M" +
              (filterWidth * 2) / 3 +
              " 0 " +
              (filterWidth * 2) / 3 +
              " " +
              filterHeight
            }
          />
          <path
            stroke="#595959"
            d={"M" + filterWidth + " 0 " + filterWidth + " " + filterHeight}
          />
        </g>
        <>
          {layeredImage.map((layeredImg, index) => {
            return (
              <g
                id={"grectlayered" + index}
                key={"grectlayered" + index}
                visibility="hidden"
                transform={
                  "rotate(" +
                  rotationScaleL[index] +
                  " " +
                  (layeredImagePosition[index].x +
                    layeredImageResolution[index].width / 2) +
                  " " +
                  (layeredImagePosition[index].y +
                    layeredImageResolution[index].height / 2) +
                  ")"
                }
              >
                <rect
                  id={"rectlayered" + index}
                  stroke="#898989"
                  strokeWidth="2"
                  fill="none"
                  x={layeredImagePosition[index].x}
                  y={layeredImagePosition[index].y}
                  width={layeredImageResolution[index].width + "px"}
                  height={layeredImageResolution[index].height + "px"}
                  strokeDasharray="4"
                  cursor="move"
                  pointerEvents="fill"
                  onContextMenu={(event) =>
                    handleContextMenu(event, "rectlayered" + index)
                  }
                  onMouseDown={(event) =>
                    handleMouseDownOnRect(event, "rectlayered" + index, index)
                  }
                  onTouchStart={(event) =>
                    handleTouchDownOnRect(event, "rectlayered" + index, index)
                  }
                ></rect>
                <rect
                  id={"topleftrectlayered" + index}
                  stroke="#0000ff"
                  strokeWidth="2"
                  fill="#898989"
                  x={layeredImagePosition[index].x - 5}
                  y={layeredImagePosition[index].y - 5}
                  width="13px"
                  height="13px"
                  cursor="nwse-resize"
                  pointerEvents="painted"
                  onMouseDown={(event) =>
                    handleMouseDownOnResizer(
                      "rectlayered" + index,
                      event,
                      0,
                      index
                    )
                  }
                  onTouchStart={(event) =>
                    handleTouchDownOnResizer(
                      "rectlayered" + index,
                      event,
                      0,
                      index
                    )
                  }
                ></rect>

                <rect
                  id={"toprightrectlayered" + index}
                  stroke="#0000ff"
                  strokeWidth="2"
                  fill="#898989"
                  x={
                    layeredImagePosition[index].x +
                    layeredImageResolution[index].width -
                    5
                  }
                  y={layeredImagePosition[index].y - 5}
                  width="13px"
                  height="13px"
                  cursor="nesw-resize"
                  pointerEvents="painted"
                  onMouseDown={(event) =>
                    handleMouseDownOnResizer(
                      "rectlayered" + index,
                      event,
                      1,
                      index
                    )
                  }
                  onTouchStart={(event) =>
                    handleTouchDownOnResizer(
                      "rectlayered" + index,
                      event,
                      1,
                      index
                    )
                  }
                ></rect>

                <rect
                  id={"bottomrightrectlayered" + index}
                  stroke="#0000ff"
                  strokeWidth="2"
                  fill="#898989"
                  x={
                    layeredImagePosition[index].x +
                    layeredImageResolution[index].width -
                    5
                  }
                  y={
                    layeredImagePosition[index].y +
                    layeredImageResolution[index].height -
                    5
                  }
                  width="13px"
                  height="13px"
                  cursor="nwse-resize"
                  pointerEvents="painted"
                  onMouseDown={(event) =>
                    handleMouseDownOnResizer(
                      "rectlayered" + index,
                      event,
                      3,
                      index
                    )
                  }
                  onTouchStart={(event) =>
                    handleTouchDownOnResizer(
                      "rectlayered" + index,
                      event,
                      3,
                      index
                    )
                  }
                ></rect>

                <rect
                  id={"bottomleftrectlayered" + index}
                  stroke="#0000ff"
                  strokeWidth="2"
                  fill="#898989"
                  x={layeredImagePosition[index].x - 5}
                  y={
                    layeredImagePosition[index].y +
                    layeredImageResolution[index].height -
                    5
                  }
                  width="13px"
                  height="13px"
                  cursor="nesw-resize"
                  pointerEvents="painted"
                  onMouseDown={(event) =>
                    handleMouseDownOnResizer(
                      "rectlayered" + index,
                      event,
                      2,
                      index
                    )
                  }
                  onTouchStart={(event) =>
                    handleTouchDownOnResizer(
                      "rectlayered" + index,
                      event,
                      2,
                      index
                    )
                  }
                ></rect>
                <rect
                  id={"rotaterectlayered" + index}
                  stroke="#0000ff"
                  strokeWidth="2"
                  fill="#898989"
                  x={
                    layeredImagePosition[index].x +
                    layeredImageResolution[index].width / 2 -
                    10
                  }
                  y={
                    layeredImagePosition[index].y +
                    layeredImageResolution[index].height -
                    10
                  }
                  width="20px"
                  height="20px"
                  cursor="move"
                  pointerEvents="painted"
                  onMouseDown={(event) =>
                    handleMouseDownOnRotation(
                      "rectlayered" + index,
                      event,
                      index
                    )
                  }
                  onTouchStart={(event) =>
                    handleTouchDownOnRotation(
                      "rectlayered" + index,
                      event,
                      index
                    )
                  }
                ></rect>
                <FiRotateCw
                  x={
                    layeredImagePosition[index].x +
                    layeredImageResolution[index].width / 2 -
                    10
                  }
                  y={
                    layeredImagePosition[index].y +
                    layeredImageResolution[index].height -
                    10
                  }
                  fontSize="20px"
                  fontWeight="800"
                  color="#0000ff"
                />
              </g>
            );
          })}
          {textValue.map((textItem, index) => {
            return (
              <>
                <g
                  id={"grect" + index}
                  key={"grect" + index}
                  visibility="hidden"
                  transform={
                    "rotate(" +
                    rotationScaleT[index] +
                    " " +
                    (rectPosition[index].x + rectDimension[index].width / 2) +
                    " " +
                    (rectPosition[index].y + rectDimension[index].height / 2) +
                    ")"
                  }
                  onClick={() => {
                    const g = document.getElementById(`grect${index}`);
                    if (g) {
                      g.setAttribute("visibility", "visible");
                      const temp = initTrigger;
                      temp[6] = true;
                      setTrigger(temp);
                      setTextIndex(index);
                      const temp1 = [false, false, false, false, false, false];
                      temp1[index] = true;
                    }
                  }}
                >
                  <rect
                    id={"rect" + index}
                    stroke="#898989"
                    strokeWidth="2"
                    fill="none"
                    x={rectPosition[index].x}
                    y={rectPosition[index].y}
                    width={rectDimension[index].width + "px"}
                    height={rectDimension[index].height + "px"}
                    strokeDasharray="4"
                    cursor="move"
                    pointerEvents="fill"
                    onMouseDown={(event) =>
                      handleMouseDownOnRect(event, "rect" + index, index)
                    }
                    onTouchStart={(event) =>
                      handleTouchDownOnRect(event, "rect" + index, index)
                    }
                  ></rect>
                  {isDesktop ? (
                    <rect
                      id={"topleftrect" + index}
                      stroke="#0000ff"
                      strokeWidth="2"
                      fill="#898989"
                      x={rectPosition[index].x - 5}
                      y={rectPosition[index].y - 5}
                      width="13px"
                      height="13px"
                      cursor="nwse-resize"
                      pointerEvents="painted"
                      onMouseDown={(event) =>
                        handleMouseDownOnResizer(
                          "rect" + index,
                          event,
                          0,
                          index
                        )
                      }
                      onTouchStart={(event) =>
                        handleTouchDownOnResizer(
                          "rect" + index,
                          event,
                          0,
                          index
                        )
                      }
                    ></rect>
                  ) : (
                    <g
                      id={"topleftrect" + index}
                      stroke="#0000ff"
                      strokeWidth="2"
                      fill="#898989"
                      transform={
                        "translate(" +
                        (rectPosition[index].x - 15) +
                        " " +
                        (rectPosition[index].y - 15) +
                        ")"
                      }
                      pointerEvents="painted"
                      onMouseDown={() => {
                        deleteText(index);
                        setTrigger(initTrigger);
                      }}
                    >
                      <circle
                        cx="15"
                        cy="15"
                        r="9"
                        opacity="1"
                        stroke="#0000ff"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        fill="#898989"
                      ></circle>
                      <line
                        x1="10"
                        y1="10"
                        x2="20"
                        y2="20"
                        stroke="#0000ff"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                      ></line>
                      <line
                        x1="20"
                        y1="10"
                        x2="10"
                        y2="20"
                        stroke="#0000ff"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                      ></line>
                    </g>
                  )}
                  <rect
                    id={"toprightrect" + index}
                    stroke="#0000ff"
                    strokeWidth="2"
                    fill="#898989"
                    x={rectPosition[index].x + rectDimension[index].width - 5}
                    y={rectPosition[index].y - 5}
                    width="13px"
                    height="13px"
                    cursor="nesw-resize"
                    pointerEvents="painted"
                    onMouseDown={(event) =>
                      handleMouseDownOnResizer("rect" + index, event, 1, index)
                    }
                    onTouchStart={(event) =>
                      handleTouchDownOnResizer("rect" + index, event, 1, index)
                    }
                  ></rect>

                  <rect
                    id={"bottomrightrect" + index}
                    stroke="#0000ff"
                    strokeWidth="2"
                    fill="#898989"
                    x={rectPosition[index].x + rectDimension[index].width - 5}
                    y={rectPosition[index].y + rectDimension[index].height - 5}
                    width="13px"
                    height="13px"
                    cursor="nwse-resize"
                    pointerEvents="painted"
                    onMouseDown={(event) =>
                      handleMouseDownOnResizer("rect" + index, event, 3, index)
                    }
                    onTouchStart={(event) =>
                      handleTouchDownOnResizer("rect" + index, event, 3, index)
                    }
                  ></rect>

                  <rect
                    id={"bottomleftrect" + index}
                    stroke="#0000ff"
                    strokeWidth="2"
                    fill="#898989"
                    x={rectPosition[index].x - 5}
                    y={rectPosition[index].y + rectDimension[index].height - 5}
                    width="13px"
                    height="13px"
                    cursor="nesw-resize"
                    pointerEvents="painted"
                    onMouseDown={(event) =>
                      handleMouseDownOnResizer("rect" + index, event, 2, index)
                    }
                    onTouchStart={(event) =>
                      handleTouchDownOnResizer("rect" + index, event, 2, index)
                    }
                  ></rect>
                  <rect
                    id={"rotaterect" + index}
                    stroke="#0000ff"
                    strokeWidth="2"
                    fill="#898989"
                    x={
                      rectPosition[index].x +
                      rectDimension[index].width / 2 -
                      10
                    }
                    y={rectPosition[index].y + rectDimension[index].height - 10}
                    width="20px"
                    height="20px"
                    cursor="move"
                    pointerEvents="painted"
                    onMouseDown={(event) =>
                      handleMouseDownOnRotation("rect" + index, event, index)
                    }
                    onTouchStart={(event) =>
                      handleTouchDownOnRotation("rect" + index, event, index)
                    }
                  ></rect>
                  <FiRotateCw
                    x={
                      rectPosition[index].x +
                      rectDimension[index].width / 2 -
                      10
                    }
                    y={rectPosition[index].y + rectDimension[index].height - 10}
                    fontSize="20px"
                    fontWeight="800"
                    color="#0000ff"
                  />
                </g>
              </>
            );
          })}
        </>
      </svg>
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={classes.root} id="image_generator">
        <div className={classes.leftSide}>
          <>
            <Box style={{ margin: "20px 0" }}>
              <Typography
                className={classes.headTitle}
                style={{
                  fontSize: "36px",
                  fontFamily: "TTTrailers-Black",
                }}
              >
                WELCOME TO THE MEME EDITOR
              </Typography>
              <Typography className={classes.text}>
                Customize your penguins however you like.
              </Typography>
            </Box>

            <Box style={{ marginTop: 15 }}>
              <TextInteractionMemo
                textValue={textValue}
                fontSize={fontSize}
                textPlaceholder={textPlaceholder}
                fontWeight={fontWeight}
                fontStyle={fontStyle}
                fontFamily={fontFamily}
                letterSpace={letterSpace}
                colorValue={colorValue}
                strokeValue={strokeValue}
                strokeType={strokeType}
                isUpperCase={isUpperCase}
                hAlign={hAlign}
                vAlign={vAlign}
                strokeWidth={strokeWidth}
                shadowWidth={shadowWidth}
                textRectWidth={textRectWidth}
                textRectHeight={textRectHeight}
                rectDimension={rectDimension}
                rectPosition={rectPosition}
                rotationScaleT={rotationScaleT}
                textChange={(value, index) => textChange(value, index)}
                changeHAlign={(value, index) => changeHAlign(value, index)}
                changeVAlign={(value, index) => changeVAlign(value, index)}
                changeStroke={(value, index) => changeStroke(value, index)}
                changeColor={(value, index) => changeColor(value, index)}
                changeFontSize={(value, index) => changeFontSize(value, index)}
                changeFont={(value, index) => changeFont(value, index)}
                changeLetterSpacing={(value, index) =>
                  changeLetterSpacing(value, index)
                }
                changeStrokeWidth={(value, index) =>
                  changeStrokeWidth(value, index)
                }
                changeStrokeType={(value, index) =>
                  changeStrokeType(value, index)
                }
                changeShadowWidth={(value, index) =>
                  changeShadowWidth(value, index)
                }
                changeFontWeight={(index) => changeFontWeight(index)}
                changeFontStyle={(index) => changeFontStyle(index)}
                changeToUpper={(index) => changeToUpper(index)}
                deleteText={(index) => deleteText(index)}
                showTextRect={(index) => {
                  deselectAllRect();
                  selectedElementID.current = "'rect' + index";
                  handleMouseEnterOnRect("rect" + index);
                }}
                hideAllTextRect={() => {
                  deselectAllRect();
                }}
                addText={() => addText()}
                advanced={() => setShow((prev) => !prev)}
              />
            </Box>
            {show && (
              <Box className={classes.tabBox}>
                <Button
                  className={`${classes.tabBtn1} ${
                    editTab === "2" && classes.activeTabBtn
                  }`}
                  onClick={() => setEditTab("2")}
                >
                  Transform
                </Button>
                <Button
                  className={`${classes.tabBtn1} ${
                    editTab === "3" && classes.activeTabBtn
                  }`}
                  onClick={() => setEditTab("3")}
                >
                  Adjust
                </Button>
                <Button
                  className={`${classes.tabBtn1} ${
                    editTab === "4" && classes.activeTabBtn
                  }`}
                  onClick={() => setEditTab("4")}
                >
                  Filters
                </Button>
                <Button
                  className={`${classes.tabBtn1} ${
                    editTab === "5" && classes.activeTabBtn
                  }`}
                  onClick={() => setEditTab("5")}
                >
                  Images
                </Button>
                <Button
                  className={`${classes.tabBtn1} ${
                    editTab === "6" && classes.activeTabBtn
                  }`}
                  onClick={() => setEditTab("6")}
                >
                  Stickers
                </Button>
              </Box>
            )}

            {show && (
              <Box className={classes.tabwrapper} marginTop="25px">
                {editTab === "2" && (
                  <EffectsTransformMemo
                    filterWidthV={filterWidth}
                    spaceV={spaceValue}
                    rotationV={rotationScale}
                    spaceTopEnableV={spaceTopEnable}
                    spaceBotEnableV={spaceBotEnable}
                    spaceColorV={spaceColor}
                    flipHV={flipH}
                    flipVV={flipV}
                    dimensionScaleV={imgDimensionScale}
                    svgResolution={svgResolution}
                    imgScale={(a) => setImgDimensionScale(a)}
                    imagePosition={imagePosition}
                    setSpaceColorV={(a) => handleSpaceColor(a)}
                    setSpaceV={(a) => handleSpaceValue(a)}
                    showRot={() => showRot()}
                    handleTopToggle={() => handleTopToggle()}
                    handleBotToggle={() => handleBotToggle()}
                    rotate={(direction) => rotate(direction)}
                    rotationValueChange={(value) => setRotationScale(value)}
                    handleRotateSliderChange={(value) =>
                      handleRotateSliderChange(value)
                    }
                    handleFlipHToggle={() => handleFlipHToggle()}
                    handleFlipVToggle={() => handleFlipVToggle()}
                    handleZoomSliderChange={(value) =>
                      handleZoomSliderChange(value)
                    }
                  />
                )}
                {editTab === "4" && (
                  <>
                    <div className="filterImgWrapper">
                      <div
                        className={
                          "filtercard filtercardItem marginTop15px " +
                          (filterSelected === "nofilter"
                            ? "filterSelected"
                            : "")
                        }
                        onClick={() => handleFilterSelect("nofilter")}
                      >
                        <svg width={"100%"} height={"100%"}>
                          <image
                            x={0}
                            y={0}
                            href={scaledImage.data}
                            width={"100%"}
                          />
                        </svg>
                        {filterSelected === "nofilter" && (
                          <Box className={classes.filtertick}>
                            <div className={classes.iconContainer}>
                              <div className={classes.checkIconBack} />
                              <div className={classes.icon}>
                                <MaterialIcon
                                  icon="check_box"
                                  color={"var(--gradient-fall-back)"}
                                />
                              </div>
                            </div>
                          </Box>
                        )}
                      </div>
                      <div
                        className={
                          "filtercard filtercardItem marginTop15px " +
                          (filterSelected === "posterize"
                            ? "filterSelected"
                            : "")
                        }
                        onClick={() => handleFilterSelect("posterize")}
                      >
                        <svg width={"100%"} height={"100%"}>
                          <filter id="previewposter">
                            <feComponentTransfer>
                              <feFuncR
                                type="discrete"
                                tableValues="0.1 0.2 0.3 0.4 0.5 0.6 0.7 0.8 0.9"
                              />
                              <feFuncG
                                type="discrete"
                                tableValues="0.1 0.2 0.3 0.4 0.5 0.6 0.7 0.8 0.9"
                              />
                              <feFuncB
                                type="discrete"
                                tableValues="0.1 0.2 0.3 0.4 0.5 0.6 0.7 0.8 0.9"
                              />
                            </feComponentTransfer>
                          </filter>
                          <image
                            x={0}
                            y={0}
                            href={scaledImage.data}
                            filter="url(#previewposter)"
                            width="100%"
                          />
                        </svg>
                        {filterSelected === "posterize" && (
                          <Box className={classes.filtertick}>
                            <div className={classes.iconContainer}>
                              <div className={classes.checkIconBack}></div>
                              <div className={classes.icon}>
                                <MaterialIcon
                                  icon="check_box"
                                  color={"var(--gradient-fall-back)"}
                                />
                              </div>
                            </div>
                          </Box>
                        )}
                      </div>
                      <div
                        className={
                          "filtercard filtercardItem marginTop15px " +
                          (filterSelected === "blur" ? "filterSelected" : "")
                        }
                        onClick={() => handleFilterSelect("blur")}
                      >
                        <svg
                          width={"100%"}
                          height={"100%"}
                          style={{
                            border: "1px solid transparent",
                            boxSizing: "border-box",
                          }}
                        >
                          <filter
                            id="previewblur"
                            x="-5%"
                            y="-5%"
                            width="110%"
                            height="110%"
                          >
                            <feGaussianBlur
                              in="SourceGraphic"
                              stdDeviation="2"
                            />
                          </filter>
                          <image
                            x={0}
                            y={0}
                            href={scaledImage.data}
                            filter="url(#previewblur)"
                            width="100%"
                          />
                        </svg>
                        {filterSelected === "blur" && (
                          <Box className={classes.filtertick}>
                            <div className={classes.iconContainer}>
                              <div className={classes.checkIconBack} />
                              <div className={classes.icon}>
                                <MaterialIcon
                                  icon="check_box"
                                  color={"var(--gradient-fall-back)"}
                                />
                              </div>
                            </div>
                          </Box>
                        )}
                      </div>
                      <div
                        className={
                          "filtercard filtercardItem marginTop15px " +
                          (filterSelected === "sharpen" ? "filterSelected" : "")
                        }
                        onClick={() => handleFilterSelect("sharpen")}
                      >
                        <svg width={"100%"} height={"100%"}>
                          <filter id="previewsharpen">
                            <feConvolveMatrix
                              order="3 3"
                              preserveAlpha="true"
                              kernelMatrix="-1 0 0 0 4 0 0 0 -1"
                            />
                          </filter>
                          <image
                            x={0}
                            y={0}
                            href={scaledImage.data}
                            filter="url(#previewsharpen)"
                            width="100%"
                          />
                        </svg>
                        {filterSelected === "sharpen" && (
                          <Box className={classes.filtertick}>
                            <div className={classes.iconContainer}>
                              <div className={classes.checkIconBack} />
                              <div className={classes.icon}>
                                <MaterialIcon
                                  icon="check_box"
                                  color={"var(--gradient-fall-back)"}
                                />
                              </div>
                            </div>
                          </Box>
                        )}
                      </div>
                      <div
                        className={
                          "filtercard filtercardItem marginTop15px marginBot15px " +
                          (filterSelected === "grayscale"
                            ? "filterSelected"
                            : "")
                        }
                        onClick={() => handleFilterSelect("grayscale")}
                      >
                        <svg width={"100%"} height={"100%"}>
                          <filter id="previewgray">
                            <feColorMatrix
                              type="matrix"
                              values="0.2126 0.7152 0.0722 0 0
                                      0.2126 0.7152 0.0722 0 0
                                      0.2126 0.7152 0.0722 0 0
                                      0 0 0 1 0"
                            />
                          </filter>
                          <image
                            x={0}
                            y={0}
                            href={scaledImage.data}
                            filter="url(#previewgray)"
                            width="100%"
                          />
                        </svg>
                        {filterSelected === "grayscale" && (
                          <Box className={classes.filtertick}>
                            <div className={classes.iconContainer}>
                              <div className={classes.checkIconBack} />
                              <div className={classes.icon}>
                                <MaterialIcon
                                  icon="check_box"
                                  color={"var(--gradient-fall-back)"}
                                />
                              </div>
                            </div>
                          </Box>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {editTab === "3" && (
                  <Box marginTop="10px">
                    <div className="colDivCE">
                      <Typography
                        id="discrete-slider-restrict"
                        className="editText"
                      >
                        Contrast
                      </Typography>
                      <Slider
                        ValueLabelComponent={ValueLabelComponent}
                        defaultValue={0}
                        value={contrastScale}
                        step={5}
                        min={-100}
                        max={100}
                        valueLabelDisplay="auto"
                        classes={{
                          root: classes.desktopSliderRoot,
                          rail: classes.desktopRail,
                          track: classes.track,
                          thumb: classes.thumb,
                        }}
                        onChange={(event, value) =>
                          handleContrastSliderChange(value)
                        }
                      />
                    </div>
                    <div className="colDivCE">
                      <Typography
                        id="discrete-slider-restrict"
                        className="editText"
                      >
                        Brightness
                      </Typography>
                      <Slider
                        ValueLabelComponent={ValueLabelComponent}
                        defaultValue={0}
                        value={brightnessScale}
                        step={5}
                        min={-100}
                        max={100}
                        valueLabelDisplay="auto"
                        classes={{
                          root: classes.desktopSliderRoot,
                          rail: classes.desktopRail,
                          track: classes.track,
                          thumb: classes.thumb,
                        }}
                        onChange={(event, value) =>
                          handleBrightnessSliderChange(value)
                        }
                      />
                    </div>
                    <div className="colDivCE">
                      <Typography
                        id="discrete-slider-restrict"
                        className="editText"
                      >
                        Saturation
                      </Typography>
                      <Slider
                        ValueLabelComponent={ValueLabelComponent}
                        defaultValue={0}
                        value={saturationScale}
                        step={5}
                        min={-100}
                        max={100}
                        valueLabelDisplay="auto"
                        classes={{
                          root: classes.desktopSliderRoot,
                          rail: classes.desktopRail,
                          track: classes.track,
                          thumb: classes.thumb,
                        }}
                        onChange={(event, value) =>
                          handleSaturationSliderChange(value)
                        }
                      />
                    </div>
                    <div className="colDivCE">
                      <Typography
                        id="discrete-slider-restrict"
                        className="editText"
                      >
                        Warmth
                      </Typography>
                      <Slider
                        ValueLabelComponent={ValueLabelComponent}
                        defaultValue={0}
                        value={warmthScale}
                        step={5}
                        min={-100}
                        max={100}
                        valueLabelDisplay="auto"
                        classes={{
                          root: classes.desktopSliderRoot,
                          rail: classes.desktopRail,
                          track: classes.track,
                          thumb: classes.thumb,
                        }}
                        onChange={(event, value) =>
                          handleWarmthSliderChange(value)
                        }
                      />
                    </div>
                  </Box>
                )}
                <div>
                  {editTab === "5" && (
                    <ImagesInteractionMemo
                      scaledImg={scaledImage}
                      scaledLayeredImg={scaledLayeredImage}
                      selectedIndex={selectedLayeredImage}
                      defImageResolution={defImageResolution}
                      layeredImageResolution={layeredImageResolution}
                      layeredImagePosition={layeredImagePosition}
                      layeredImage={layeredImage}
                      rotationScaleL={rotationScaleL}
                      newImg={(img, position) => addNewImage(img, position)}
                      selectLayeredImg={(index) => selectLayeredImage(index)}
                      bringForward={(index) => moveLayeredImageNext(index)}
                      sendBackward={(index) => {
                        moveLayeredImagePrevious(index);
                      }}
                      newLayeredImg={(imgData, resolution, displayName) =>
                        addnewLayeredImage(
                          imgData,
                          resolution,
                          displayName,
                          false
                        )
                      }
                      removeImage={(layeredImageIndex) =>
                        removeLayeredImage(layeredImageIndex)
                      }
                    />
                  )}
                  {editTab === "6" && (
                    <StickersInteractionMemo
                      defImageResolution={defImageResolution}
                      layeredImageResolution={layeredImageResolution}
                      layeredImagePosition={layeredImagePosition}
                      layeredImage={layeredImage}
                      scaledLayeredImage={scaledLayeredImage}
                      rotationScaleL={rotationScaleL}
                      newLayeredImg={(imgData, resolution, displayName) =>
                        addnewLayeredImage(
                          imgData,
                          resolution,
                          displayName,
                          false
                        )
                      }
                    />
                  )}
                </div>
              </Box>
            )}
          </>
        </div>
        <div className="rightSide">
          <IconButton className={classes.closeIcon} onClick={handleModalClose}>
            <MaterialIcon icon="close" color={"#6C7B93"} />
          </IconButton>
          {isDesktop && (
            <>
              <Typography
                style={{
                  color: "#ffffff",
                  fontSize: "16px",
                  fontWeight: 800,
                  marginBottom: "10px",
                }}
              >
                Customize Penguin
              </Typography>
              <>
                <Box display={"flex"} maxHeight="200px" marginLeft={"-6px"}>
                  {/* <Button
                    style={{ width: "100px", height: "45px" }}
                    className={`${classes.tabBtn1} ${
                      ppPart === "Skin" && classes.activeTabBtn
                    }`}
                    onClick={() => setPpPart("Skin")}
                  >
                    Skin
                  </Button> */}
                  <Button
                    style={{ width: "100px", height: "45px" }}
                    className={`${classes.tabBtn1} ${
                      ppPart === "Body" && classes.activeTabBtn
                    }`}
                    onClick={() => setPpPart("Body")}
                  >
                    Body
                  </Button>
                  <Button
                    style={{ width: "100px", height: "45px" }}
                    className={`${classes.tabBtn1} ${
                      ppPart === "Face" && classes.activeTabBtn
                    }`}
                    onClick={() => setPpPart("Face")}
                  >
                    Eyes
                  </Button>
                  <Button
                    style={{ width: "100px", height: "45px" }}
                    className={`${classes.tabBtn1} ${
                      ppPart === "Head" && classes.activeTabBtn
                    }`}
                    onClick={() => setPpPart("Head")}
                  >
                    Head
                  </Button>
                  <Button
                    style={{ width: "100px", height: "45px" }}
                    className={`${classes.tabBtn1} ${
                      ppPart === "More" && classes.activeTabBtn
                    }`}
                    onClick={() => setPpPart("More")}
                  >
                    Faces
                  </Button>
                </Box>
              </>
              <Box>
                <PPStickers
                  ppPart={ppPart}
                  defImageResolution={defImageResolution}
                  layeredImageResolution={layeredImageResolution}
                  layeredImagePosition={layeredImagePosition}
                  layeredImage={layeredImage}
                  scaledLayeredImage={scaledLayeredImage}
                  rotationScaleL={rotationScaleL}
                  newLayeredImg={(imgData, resolution, displayName) =>
                    addnewLayeredImage(imgData, resolution, displayName, true)
                  }
                />
              </Box>
              <div className="imageEditWrapper">
                <div id="uploadImageEdit" onMouseDown={() => deselectAllRect()}>
                  <div className="topContainerDiv"></div>
                  {svgField()}
                  <div className="bottomContainerDiv"></div>
                </div>
              </div>
            </>
          )}
          <Grid container className={classes.btngroup} style={{ margin: 0 }}>
            <Grid item lg={!btnTab ? 9 : 6}>
              {!btnTab ? (
                <>
                  <Button
                    variant="contained"
                    className={classes.btnItem}
                    startIcon={<MaterialIcon icon="undo" color={"#808893"} />}
                    onClick={() => undo()}
                  >
                    {windowWidth >= 960 && windowWidth <= 1190 ? null : "Undo"}
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.btnItem}
                    startIcon={<MaterialIcon icon="redo" color={"#808893"} />}
                    onClick={() => redo()}
                    style={{ background: colors.memeGenBlue50 }}
                  >
                    {windowWidth >= 960 && windowWidth <= 1190 ? null : "Redo"}
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.btnItem}
                    startIcon={
                      <MaterialIcon icon="restart_alt" color={"#808893"} />
                    }
                    onClick={handleClickOpen}
                  >
                    {windowWidth >= 960 && windowWidth <= 1190
                      ? null
                      : "Reset to default"}
                  </Button>
                  <Dialog
                    open={openConfirmModal}
                    onClose={handleConfirmModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        borderRadius: 5,
                        border: 0,
                        backgroundColor: "#232A30",
                      },
                    }}
                  >
                    <DialogTitle
                      style={{
                        textAlign: "center",
                        borderTopRightRadius: "5px",
                        borderTopLeftRadius: "5px",
                        backgroundColor: "#232A30",
                        color: "white",
                      }}
                      id="alert-dialog-title"
                    >
                      {"You are about to reset all"}
                    </DialogTitle>
                    <DialogContent
                      style={{
                        textAlign: "center",
                        backgroundColor: "#151820",
                        border: 0,
                      }}
                    >
                      <DialogContentText
                        style={{
                          fontSize: "15px",
                          fontWeight: 700,
                          lineHeight: "22px",
                          letterSpacing: "-0.3px",
                          alignSelf: "center",
                          color: "white",
                        }}
                        id="alert-dialog-description"
                      >
                        You will come back to the initial picture without the
                        possibility to get your edits back.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        textAlign: "center",
                        borderBottomRightRadius: "5px",
                        borderBottomLeftRadius: "5px",
                        backgroundColor: "#151820",
                        color: "white",
                        border: 0,
                      }}
                    >
                      <Button
                        onClick={handleConfirmModalClose}
                        color="primary"
                        style={{
                          height: "44px",
                          width: "97px",
                          borderRadius: "7px",
                          fontWeight: "700",
                          fontSize: "14px",
                          color: "#FFFFFF",
                          boxShadow: "none",
                          border: "2px solid red",
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => reset()}
                        color="primary"
                        autoFocus
                        style={{
                          height: "44px",
                          width: "97px",
                          backgroundColor: "#35A6EB",
                          borderRadius: "7px",
                          fontWeight: "700",
                          fontSize: "14px",
                          color: "#FFFFFF",
                          boxShadow: "none",
                        }}
                      >
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Button
                    variant="contained"
                    className={classes.btnItem}
                    onClick={(e) => handleClick(e, "zoomin")}
                  >
                    <MaterialIcon icon="zoom_in" color={"#808893"} />
                  </Button>
                  <Popover
                    id={"zoomin"}
                    open={Boolean(anchorEl) && anchorRef === "zoomin"}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    classNames={classes.popover}
                    classes={{ paper: classes.popover }}
                  >
                    <div className="zoomContainer">
                      <div className="zoomIn">
                        <Slider
                          ValueLabelComponent={ValueLabelComponent}
                          defaultValue={1}
                          value={imgDimensionScale}
                          aria-labelledby="discrete-slider"
                          step={0.1}
                          classes={{
                            root: classes.desktopSliderRoot,
                            rail: classes.desktopRail,
                            track: classes.track,
                            thumb: classes.thumb,
                          }}
                          onChange={(event, value) =>
                            handleZoomSliderChange(value)
                          }
                          min={1}
                          max={3}
                          valueLabelDisplay="auto"
                        />
                      </div>
                    </div>
                  </Popover>
                  <Button
                    variant="contained"
                    className={classes.btnItem}
                    onClick={showRot}
                  >
                    <MaterialIcon icon="crop_free" color={"#6C7B93"} />
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    style={{
                      backgroundColor: colors.memeGenBlue50,
                      marginRight: 16,
                    }}
                    variant="contained"
                    className={`${classes.btnItem}  ${classes.activeTabBt}`}
                    endIcon={
                      <MaterialIcon icon="expand_more" color="#808893" />
                    }
                    onClick={() => downloadImage("jpeg")}
                  >
                    Download
                  </Button>
                </>
              )}
            </Grid>
            <Grid item lg={!btnTab ? 3 : 6} style={{ textAlign: "right" }}>
              {btnTab && (
                <Button
                  variant="contained"
                  className={classes.btnItem}
                  style={{ marginLeft: "auto", width: 106 }}
                  onClick={() => setBtnTab(false)}
                >
                  Back
                </Button>
              )}

              <Button
                style={{
                  backgroundColor: colors.memeGenBlue50,
                  marginRight: 16,
                }}
                variant="contained"
                className={`${classes.btnItem}  ${classes.activeTabBt}`}
                onClick={() => downloadImage("jpeg")}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>

      {/*mobile screen */}
      <div className={classes.mobileOnly}>
        <Box className={classes.mobile_root}>
          {trigger[0] && (
            <Box className={classes.inputContainer}>
              <Box className={`${classes.flex} ${classes.transformContainer}`}>
                <Button
                  className={`${classes.button} ${classes.trans_btn}`}
                  onClick={() => {
                    setTrigger(initTrigger);
                    textChange("", textIndex);
                  }}
                >
                  <MaterialIcon icon="close" color="#6C7B93" />
                </Button>
                <Button
                  className={`${classes.button} ${classes.trans_btn}`}
                  onClick={() => {
                    setTrigger(initTrigger);
                  }}
                >
                  <MaterialIcon icon="done" color="var(--gradient-fall-back)" />
                </Button>
              </Box>
              <InputBase
                className={classes.addTxt}
                placeholder="Type Something"
                onChange={(e) => {
                  textChange(e.target.value, textIndex);
                }}
              />
            </Box>
          )}
          <Box className={classes.topSection}>
            <Box className={`${classes.flex} ${classes.mb_7}`}>
              <Button
                className={classes.button}
                onClick={() => {
                  handleModalClose();
                  setTrigger(initTrigger);
                }}
              >
                <MaterialIcon icon="arrow_back" color="#6C7B93" />
              </Button>
              <Button
                variant="contained"
                className={classes.btnItem}
                style={{
                  background: "transparent",
                  boxShadow: "none",
                }}
                onClick={(e) => handleClick(e, "zoomin")}
              >
                <MaterialIcon icon="zoom_in" color={"#808893"} />
              </Button>
              <Popover
                id={"zoomin"}
                open={Boolean(anchorEl) && anchorRef === "zoomin"}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                classNames={classes.popover}
                classes={{ paper: classes.popover }}
              >
                <div className="zoomContainer">
                  <div className="zoomIn" style={{ paddingRight: "15px" }}>
                    <Slider
                      ValueLabelComponent={ValueLabelComponent}
                      defaultValue={1}
                      value={imgDimensionScale}
                      aria-labelledby="discrete-slider"
                      step={0.1}
                      classes={{
                        root: classes.desktopSliderRoot,
                        rail: classes.desktopRail,
                        track: classes.track,
                        thumb: classes.thumb,
                      }}
                      onChange={(event, value) => handleZoomSliderChange(value)}
                      min={1}
                      max={3}
                      valueLabelDisplay="auto"
                    />
                  </div>
                </div>
              </Popover>
              <Button
                className={`${classes.button} ${classes.m_1}`}
                onClick={() => undo()}
              >
                <MaterialIcon icon="undo" color="#6C7B93" />
              </Button>
              <Button
                className={`${classes.button} ${classes.m_1}`}
                onClick={() => redo()}
              >
                <MaterialIcon icon="redo" color="#6C7B93" />
              </Button>
              <Button
                className={`${classes.button} ${classes.m_1}`}
                onClick={() => {
                  handleClickOpen();
                }}
              >
                <MaterialIcon icon="restart_alt" color="#6C7B93" />
              </Button>
              <Button
                className={`${classes.button} ${classes.m_1}`}
                onClick={() => downloadImage("jpeg")}
              ></Button>
              <Button
                className={`${classes.button} ${classes.m_1} ${classes.continueBtn}`}
                onClick={() => downloadImage("jpeg")}
                style={{
                  borderRadius: 100,

                  color: "#FFFFFF",
                  background:
                    "linear-gradient(268.65deg, var(--gradient-fall-back) -26.32%, #35A6EB 64.97%)",
                }}
              >
                Download
              </Button>
            </Box>
            {/* <Button className={`${classes.button} ${classes.space_btn} ${classes.mb_7}`}>
              AD SPACE
            </Button> */}
            <Box>
              {!isDesktop && (
                <>
                  <Typography
                    style={{
                      color: "#ffffff",
                      fontSize: "16px",
                      fontWeight: 800,
                      marginBottom: "10px",
                    }}
                  >
                    Customize Penguin
                  </Typography>
                  <>
                    <Box
                      display={"flex"}
                      maxHeight="200px"
                      marginLeft={"-6px"}
                      zIndex={1}
                    >
                      {/* <Button
                        style={{ width: "90px", height: "40px" }}
                        className={`${classes.tabBtn1} ${
                          ppPart === "Skin" && classes.activeTabBtn
                        }`}
                        onClick={() => setPpPart("Skin")}
                      >
                        Skin
                      </Button> */}
                      <Button
                        style={{ width: "90px", height: "40px" }}
                        className={`${classes.tabBtn1} ${
                          ppPart === "Body" && classes.activeTabBtn
                        }`}
                        onClick={() => setPpPart("Body")}
                      >
                        Body
                      </Button>
                      <Button
                        style={{ width: "90px", height: "40px" }}
                        className={`${classes.tabBtn1} ${
                          ppPart === "Face" && classes.activeTabBtn
                        }`}
                        onClick={() => setPpPart("Face")}
                      >
                        Eyes
                      </Button>
                      <Button
                        style={{ width: "90px", height: "40px" }}
                        className={`${classes.tabBtn1} ${
                          ppPart === "Head" && classes.activeTabBtn
                        }`}
                        onClick={() => setPpPart("Head")}
                      >
                        Head
                      </Button>
                      <Button
                        style={{ width: "90px", height: "40px" }}
                        className={`${classes.tabBtn1} ${
                          ppPart === "More" && classes.activeTabBtn
                        }`}
                        onClick={() => setPpPart("More")}
                      >
                        Faces
                      </Button>
                    </Box>
                  </>
                  <Box>
                    <PPStickers
                      ppPart={ppPart}
                      defImageResolution={defImageResolution}
                      layeredImageResolution={layeredImageResolution}
                      layeredImagePosition={layeredImagePosition}
                      layeredImage={layeredImage}
                      scaledLayeredImage={scaledLayeredImage}
                      mobile={!isDesktop}
                      rotationScaleL={rotationScaleL}
                      newLayeredImg={(imgData, resolution, displayName) =>
                        addnewLayeredImage(
                          imgData,
                          resolution,
                          displayName,
                          true
                        )
                      }
                    />
                  </Box>
                  <Box className={classes.imageContainer}>
                    <div className="imageEditWrapper">
                      <div
                        id="uploadImageEdit"
                        onMouseDown={() => deselectAllRect()}
                      >
                        <div className="topContainerDivMobile"></div>
                        {svgField()}
                        <div className="bottomContainerDivMobile"></div>
                      </div>
                    </div>
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Box zIndex="100" className={classes.bottomScrollbar}>
            {!trigger.includes(true) && (
              <Box className={classes.bottomScroll}>
                <Box
                  className={`${classes.bottomSectionActions} ${classes.actionGroup}`}
                >
                  {mobileActionBtns.map((item, index) => (
                    <Box
                      key={`mobileAction_${index}`}
                      className={`${classes.actionBtn} ${classes.center}`}
                      onClick={() => clickAction(index)}
                    >
                      <MaterialIcon icon={item.icon} color="#C2C9D4" />
                      <Typography className={classes.actionTxt}>
                        {item.title}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
            {trigger[1] && (
              <Box className={classes.transformGroup}>
                <Box className={`${classes.decorator} ${classes.center}`} />
                <Box className={classes.transforms}>
                  <Box
                    className={classes.selectBtn}
                    onClick={() => setTrigger(new Array(6).fill(false))}
                  >
                    Cool
                  </Box>
                  <Typography className={classes.transform_title}>
                    Transform
                  </Typography>
                  <Box
                    className={`${classes.flex} ${classes.transformContainer}`}
                  >
                    {transformBtns.map((item, index) => (
                      <Box
                        key={`transform_${index}`}
                        className={`${classes.actionBtn} ${classes.center}`}
                        onClick={() => {
                          clickTransform(index);
                          if (index === 0) rotate("left");
                          if (index === 1) rotate("right");
                          if (index === 2) handleFlipHToggle();
                          if (index === 3) handleFlipVToggle();
                        }}
                      >
                        <Box className={index === 3 ? classes.transBtn : ""}>
                          <MaterialIcon icon={item.icon} color="#C2C9D4" />
                        </Box>
                        <Typography className={classes.actionTxt}>
                          {item.title}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            )}
            {trigger[2] && (
              <Box className={classes.transformGroup}>
                <Box className={`${classes.decorator} ${classes.center}`} />
                <Box className={`${classes.adjustWrapper} ${classes.h_auto}`}>
                  <Box
                    className={classes.selectBtn}
                    onClick={() => setTrigger(new Array(6).fill(false))}
                  >
                    Cool
                  </Box>
                  <Typography className={classes.transform_title}>
                    Adjust
                  </Typography>
                  {adjustSetting === 0 && (
                    <Box className={`${classes.flex} ${classes.sliderWrapper}`}>
                      <Typography className={classes.slider_title}>
                        Contrast
                      </Typography>
                      <Slider
                        ValueLabelComponent={ValueLabelComponent}
                        defaultValue={0}
                        value={contrastScale}
                        step={5}
                        min={-100}
                        max={100}
                        valueLabelDisplay="auto"
                        classes={{
                          root: classes.mobileSlderRoot,
                          rail: classes.mobileRail,
                          track: classes.track,
                          thumb: classes.thumb,
                        }}
                        onChange={(event, value) =>
                          handleContrastSliderChange(value)
                        }
                      />
                      <Typography className={classes.slider_value}>
                        {contrastScale}
                      </Typography>
                    </Box>
                  )}
                  {adjustSetting === 1 && (
                    <Box className={`${classes.flex} ${classes.sliderWrapper}`}>
                      <Typography className={classes.slider_title}>
                        Brightness
                      </Typography>
                      <Slider
                        ValueLabelComponent={ValueLabelComponent}
                        defaultValue={0}
                        value={brightnessScale}
                        step={5}
                        min={-100}
                        max={100}
                        valueLabelDisplay="auto"
                        classes={{
                          root: classes.mobileSlderRoot,
                          rail: classes.mobileRail,
                          track: classes.track,
                          thumb: classes.thumb,
                        }}
                        onChange={(event, value) =>
                          handleBrightnessSliderChange(value)
                        }
                      />
                      <Typography className={classes.slider_value}>
                        {brightnessScale}
                      </Typography>
                    </Box>
                  )}
                  {adjustSetting === 2 && (
                    <Box className={`${classes.flex} ${classes.sliderWrapper}`}>
                      <Typography className={classes.slider_title}>
                        Saturation
                      </Typography>
                      <Slider
                        ValueLabelComponent={ValueLabelComponent}
                        defaultValue={0}
                        value={saturationScale}
                        step={5}
                        min={-100}
                        max={100}
                        valueLabelDisplay="auto"
                        classes={{
                          root: classes.mobileSlderRoot,
                          rail: classes.mobileRail,
                          track: classes.track,
                          thumb: classes.thumb,
                        }}
                        onChange={(event, value) =>
                          handleSaturationSliderChange(value)
                        }
                      />
                      <Typography className={classes.slider_value}>
                        {saturationScale}
                      </Typography>
                    </Box>
                  )}
                  {adjustSetting === 3 && (
                    <Box className={`${classes.flex} ${classes.sliderWrapper}`}>
                      <Typography className={classes.slider_title}>
                        Warmth
                      </Typography>
                      <Slider
                        ValueLabelComponent={ValueLabelComponent}
                        defaultValue={0}
                        value={warmthScale}
                        step={5}
                        min={-100}
                        max={100}
                        valueLabelDisplay="auto"
                        classes={{
                          root: classes.mobileSlderRoot,
                          rail: classes.mobileRail,
                          track: classes.track,
                          thumb: classes.thumb,
                        }}
                        onChange={(event, value) =>
                          handleWarmthSliderChange(value)
                        }
                      />
                      <Typography className={classes.slider_value}>
                        {warmthScale}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    className={`${classes.flex} ${classes.transformContainer}`}
                  >
                    <Box
                      key="Contrast"
                      className={`${classes.actionBtn} ${classes.center} ${classes.adjustOption}`}
                      onClick={() => onChangeAdjust(0)}
                    >
                      <BrightnessIcon
                        style={
                          adjustSetting === 0
                            ? { fill: "var(--gradient-fall-back)" }
                            : { fill: "#C2C9D4" }
                        }
                      />
                      <Typography
                        className={`${classes.actionTxt} ${
                          classes.adjustText
                        } ${adjustSetting === 0 && classes.activeColor}`}
                      >
                        Contrast
                      </Typography>
                    </Box>
                    <Box
                      key="Brightness"
                      className={`${classes.actionBtn} ${classes.center} ${classes.adjustOption}`}
                      onClick={() => onChangeAdjust(1)}
                    >
                      <WbsunnyIcon
                        style={
                          adjustSetting === 1
                            ? { fill: "var(--gradient-fall-back)" }
                            : { fill: "#C2C9D4" }
                        }
                      />
                      <Typography
                        className={`${classes.actionTxt} ${
                          classes.adjustText
                        } ${adjustSetting === 1 && classes.activeColor}`}
                      >
                        Brightness
                      </Typography>
                    </Box>
                    <Box
                      key="Saturation"
                      className={`${classes.actionBtn} ${classes.center} ${classes.adjustOption}`}
                      onClick={() => onChangeAdjust(2)}
                    >
                      <OpacityIcon
                        style={
                          adjustSetting === 2
                            ? { fill: "var(--gradient-fall-back)" }
                            : { fill: "#C2C9D4" }
                        }
                      />
                      <Typography
                        className={`${classes.actionTxt} ${
                          classes.adjustText
                        } ${adjustSetting === 2 && classes.activeColor}`}
                      >
                        Saturation
                      </Typography>
                    </Box>
                    <Box
                      key="Warmth"
                      className={`${classes.actionBtn} ${classes.center} ${classes.adjustOption}`}
                      onClick={() => onChangeAdjust(3)}
                    >
                      <ThermostatIcon
                        style={
                          adjustSetting === 3
                            ? { fill: "var(--gradient-fall-back)" }
                            : { fill: "#C2C9D4" }
                        }
                      />
                      <Typography
                        className={`${classes.actionTxt} ${
                          classes.adjustText
                        } ${adjustSetting === 3 && classes.activeColor}`}
                      >
                        Warmth
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            {trigger[3] && (
              <Box className={classes.transformGroup}>
                <Box className={`${classes.decorator} ${classes.center}`} />
                <Box
                  className={`${classes.transforms} ${classes.h_auto} ${classes.filterWrapper}`}
                >
                  <Box
                    className={classes.selectBtn}
                    onClick={() => setTrigger(new Array(6).fill(false))}
                  >
                    Cool
                  </Box>
                  <Typography className={classes.transform_title}>
                    Filters
                  </Typography>
                  <Box
                    className={`${classes.flex} ${classes.transformContainer}`}
                  >
                    <div className="filterImgWrapper">
                      <div>
                        <div
                          className={
                            "filtercardMobile mobilefiltercardItem marginTop15px " +
                            (filterSelected === "nofilter"
                              ? "filterSelectedMobile"
                              : "")
                          }
                          onClick={() => handleFilterSelect("nofilter")}
                        >
                          <svg width={"100%"} height={"100%"}>
                            <image
                              x={0}
                              y={0}
                              href={scaledImage.data}
                              width={"100%"}
                              height={"100%"}
                            />
                          </svg>
                        </div>
                        <Typography
                          className={`${classes.actionTxt} ${
                            filterSelected === "nofilter" && classes.activeColor
                          }`}
                        >
                          Original
                        </Typography>
                      </div>
                      <div>
                        <div className={classes.verticalLine} />
                      </div>
                      <div>
                        <div
                          className={
                            "filtercardMobile mobilefiltercardItem marginTop15px " +
                            (filterSelected === "posterize"
                              ? "filterSelectedMobile"
                              : "")
                          }
                          onClick={() => handleFilterSelect("posterize")}
                        >
                          <svg width={"100%"} height={"100%"}>
                            <filter id="previewposter">
                              <feComponentTransfer>
                                <feFuncR
                                  type="discrete"
                                  tableValues="0.1 0.2 0.3 0.4 0.5 0.6 0.7 0.8 0.9"
                                />
                                <feFuncG
                                  type="discrete"
                                  tableValues="0.1 0.2 0.3 0.4 0.5 0.6 0.7 0.8 0.9"
                                />
                                <feFuncB
                                  type="discrete"
                                  tableValues="0.1 0.2 0.3 0.4 0.5 0.6 0.7 0.8 0.9"
                                />
                              </feComponentTransfer>
                            </filter>
                            <image
                              x={0}
                              y={0}
                              href={scaledImage.data}
                              filter="url(#previewposter)"
                              width="100%"
                              height={"100%"}
                            />
                          </svg>
                        </div>
                        <Typography
                          className={`${classes.actionTxt} ${
                            filterSelected === "posterize" &&
                            classes.activeColor
                          }`}
                        >
                          Posterize
                        </Typography>
                      </div>
                      <div>
                        <div
                          className={
                            "filtercardMobile mobilefiltercardItem marginTop15px " +
                            (filterSelected === "blur"
                              ? "filterSelectedMobile"
                              : "")
                          }
                          onClick={() => handleFilterSelect("blur")}
                        >
                          <svg
                            width={"100%"}
                            height={"100%"}
                            style={{
                              border: "1px solid transparent",
                              boxSizing: "border-box",
                            }}
                          >
                            <filter
                              id="previewblur"
                              x="-5%"
                              y="-5%"
                              width="110%"
                              height="110%"
                            >
                              <feGaussianBlur
                                in="SourceGraphic"
                                stdDeviation="2"
                              />
                            </filter>
                            <image
                              x={0}
                              y={0}
                              href={scaledImage.data}
                              filter="url(#previewblur)"
                              width="100%"
                              height={"100%"}
                            />
                          </svg>
                        </div>
                        <Typography
                          className={`${classes.actionTxt} ${
                            filterSelected === "blur" && classes.activeColor
                          }`}
                        >
                          Blur
                        </Typography>
                      </div>
                      <div>
                        <div
                          className={
                            "filtercardMobile mobilefiltercardItem marginTop15px " +
                            (filterSelected === "sharpen"
                              ? "filterSelectedMobile"
                              : "")
                          }
                          onClick={() => handleFilterSelect("sharpen")}
                        >
                          <svg width={"100%"} height={"100%"}>
                            <filter id="previewsharpen">
                              <feConvolveMatrix
                                order="3 3"
                                preserveAlpha="true"
                                kernelMatrix="-1 0 0 0 4 0 0 0 -1"
                              />
                            </filter>
                            <image
                              x={0}
                              y={0}
                              href={scaledImage.data}
                              filter="url(#previewsharpen)"
                              width="100%"
                              height={"100%"}
                            />
                          </svg>
                        </div>
                        <Typography
                          className={`${classes.actionTxt} ${
                            filterSelected === "sharpen" && classes.activeColor
                          }`}
                        >
                          Sharpen
                        </Typography>
                      </div>
                      <div>
                        <div
                          className={
                            "filtercardMobile mobilefiltercardItem marginTop15px marginBot15px " +
                            (filterSelected === "grayscale"
                              ? "filterSelectedMobile"
                              : "")
                          }
                          onClick={() => handleFilterSelect("grayscale")}
                        >
                          <svg width={"100%"} height={"100%"}>
                            <filter id="previewgray">
                              <feColorMatrix
                                type="matrix"
                                values="0.2126 0.7152 0.0722 0 0
                                        0.2126 0.7152 0.0722 0 0
                                        0.2126 0.7152 0.0722 0 0
                                        0 0 0 1 0"
                              />
                            </filter>
                            <image
                              x={0}
                              y={0}
                              href={scaledImage.data}
                              filter="url(#previewgray)"
                              width="100%"
                              height={"100%"}
                            />
                          </svg>
                        </div>
                        <Typography
                          className={`${classes.actionTxt} ${
                            filterSelected === "grayscale" &&
                            classes.activeColor
                          }`}
                        >
                          b&w
                        </Typography>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Box>
            )}

            {trigger[4] && (
              <Box className={classes.transformGroup}>
                <Box className={`${classes.decorator} ${classes.center}`} />
                <Box className={`${classes.transforms} ${classes.h_auto}`}>
                  <Box
                    className={classes.selectBtn}
                    onClick={() => setTrigger(new Array(6).fill(false))}
                  >
                    Cool
                  </Box>
                  <Typography className={classes.transform_title}>
                    Layers
                  </Typography>
                  <Box
                    className={`${classes.flex} ${classes.transformContainer} ${classes.bottomSection}`}
                  >
                    <ImagesInteractionMemo
                      newImg={(img, position) => addNewImage(img, position)}
                      scaledImg={scaledImage}
                      scaledLayeredImg={scaledLayeredImage}
                      selectedIndex={selectedLayeredImage}
                      selectLayeredImg={(index) => selectLayeredImage(index)}
                      bringForward={(index) => moveLayeredImageNext(index)}
                      sendBackward={(index) => {
                        moveLayeredImagePrevious(index);
                      }}
                      newLayeredImg={(imgData, resolution, displayName) =>
                        addnewLayeredImage(
                          imgData,
                          resolution,
                          displayName,
                          false
                        )
                      }
                      removeImage={(layeredImageIndex) =>
                        removeLayeredImage(layeredImageIndex)
                      }
                    />
                  </Box>
                </Box>
              </Box>
            )}
            {trigger[5] && (
              <Box className={classes.transformGroup}>
                <Box className={`${classes.stickerWrapper} ${classes.h_auto}`}>
                  <Box
                    className={classes.emoteBar}
                    onClick={() => setTrigger(new Array(6).fill(false))}
                    display="flex"
                  >
                    <IconButton>
                      <MaterialIcon
                        size="24px"
                        icon="history"
                        color={"#6C7B93"}
                      />
                    </IconButton>
                    <IconButton>
                      <MaterialIcon
                        size="24px"
                        icon="collections"
                        color={"#6C7B93"}
                      />
                    </IconButton>
                    <IconButton>
                      <MaterialIcon
                        size="24px"
                        icon="emoji_emotions"
                        color={"#FFCD4C"}
                      />
                    </IconButton>
                    <Box marginLeft="auto" display="flex">
                      <IconButton>
                        <MaterialIcon
                          size="24px"
                          icon="store"
                          color={"#2B3340"}
                        />
                      </IconButton>
                      <IconButton className={classes.tickBtn}>
                        <MaterialIcon
                          size="20px"
                          icon="check"
                          color={"#11131B"}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box
                    marginLeft="20px"
                    className={`${classes.flex} ${classes.stickerContainer}`}
                  >
                    <StickersInteractionMemo
                      defImageResolution={defImageResolution}
                      layeredImageResolution={layeredImageResolution}
                      layeredImagePosition={layeredImagePosition}
                      layeredImage={layeredImage}
                      scaledLayeredImage={scaledLayeredImage}
                      rotationScaleL={rotationScaleL}
                      newLayeredImg={(imgData, resolution, displayName) =>
                        addnewLayeredImage(
                          imgData,
                          resolution,
                          displayName,
                          false
                        )
                      }
                    />
                  </Box>
                </Box>
              </Box>
            )}
            {trigger[6] && (
              <Box className={classes.transformGroup}>
                <Box className={`${classes.decorator} ${classes.center}`} />
                <Box className={classes.textToolsWrapper}>
                  <Box
                    className={`${classes.flex} ${classes.textToolsContainer}`}
                  >
                    <Button
                      onClick={() => {
                        setTxtEditTools([true, false, false, false]);
                        setTrigger([
                          true,
                          false,
                          false,
                          false,
                          false,
                          false,
                          false,
                        ]);
                      }}
                    >
                      <MaterialIcon icon="keyboard" color="#C2C9D4" />
                    </Button>
                    <Button
                      className={`${txtEditTools[1] ? classes.button : ""}`}
                      onClick={() =>
                        setTxtEditTools([false, true, false, false])
                      }
                    >
                      <MaterialIcon icon="text_format" color="#C2C9D4" />
                    </Button>
                    <Button
                      className={`${txtEditTools[2] ? classes.button : ""}`}
                      onClick={() =>
                        setTxtEditTools([false, false, true, false])
                      }
                    >
                      <ColorIcon />
                    </Button>
                    <Button
                      className={`${txtEditTools[3] ? classes.button : ""}`}
                      onClick={() =>
                        setTxtEditTools([false, false, false, true])
                      }
                    >
                      <MaterialIcon icon="edit_note" color="#C2C9D4" />
                    </Button>
                    <Button
                      onClick={() => setTrigger(new Array(7).fill(false))}
                    >
                      <Box
                        className={`${classes.center} ${classes.txtIconContainer}`}
                      >
                        <MaterialIcon icon="done" color="#11131B" />
                      </Box>
                    </Button>
                  </Box>
                  {txtEditTools[3] && (
                    <>
                      <Box className={`${classes.flex} ${classes.txtSettings}`}>
                        <IconButton
                          className={classes.settingBtn}
                          onClick={() => changeFontWeight(textIndex)}
                        >
                          <MaterialIcon icon="format_bold" color="#C2C9D4" />
                        </IconButton>
                        <IconButton
                          className={classes.settingBtn}
                          onClick={() => changeFontStyle(textIndex)}
                        >
                          <MaterialIcon icon="format_italic" color="#C2C9D4" />
                        </IconButton>
                        <IconButton
                          className={classes.settingBtn}
                          onClick={() => changeToUpper(textIndex)}
                        >
                          <MaterialIcon icon="text_format" color="#C2C9D4" />
                        </IconButton>
                        <Box
                          className={`${classes.flex} ${classes.mobile_spacing}`}
                        >
                          <IconButton
                            className={classes.settingBtn}
                            onClick={() => changeHAlign(0, textIndex)}
                          >
                            <MaterialIcon
                              icon="format_align_left"
                              color="#C2C9D4"
                            />
                          </IconButton>
                          <IconButton
                            className={classes.settingBtn}
                            onClick={() => changeHAlign(0.5, textIndex)}
                          >
                            <MaterialIcon
                              icon="format_align_center"
                              color="#C2C9D4"
                            />
                          </IconButton>
                          <IconButton
                            className={classes.settingBtn}
                            onClick={() => changeHAlign(1, textIndex)}
                          >
                            <MaterialIcon
                              icon="format_align_right"
                              color="#C2C9D4"
                            />
                          </IconButton>
                        </Box>
                        <Box
                          className={`${classes.flex} ${classes.mobile_spacing}`}
                        >
                          <IconButton
                            className={classes.settingBtn}
                            onClick={() => changeVAlign(0, textIndex)}
                          >
                            <MaterialIcon
                              icon="vertical_align_top"
                              color="#C2C9D4"
                            />
                          </IconButton>
                          <IconButton
                            className={classes.settingBtn}
                            onClick={() => changeVAlign(0.5, textIndex)}
                          >
                            <MaterialIcon
                              icon="vertical_align_center"
                              color="#C2C9D4"
                            />
                          </IconButton>
                          <IconButton
                            className={classes.settingBtn}
                            onClick={() => changeVAlign(1, textIndex)}
                          >
                            <MaterialIcon
                              icon="vertical_align_bottom"
                              color="#C2C9D4"
                            />
                          </IconButton>
                        </Box>
                      </Box>
                      <Box className={classes.outline_btn_group}>
                        <Box
                          className={`${classes.color_btn} ${classes.center} ${
                            outlineStyle[0] && classes.active_color_btn
                          }`}
                          onClick={() => {
                            changeStrokeType(0, textIndex);
                            setOutlineStyle([true, false, false]);
                          }}
                        >
                          OUTLINE
                        </Box>
                        <Box
                          className={`${classes.color_btn} ${classes.center} ${
                            outlineStyle[1] && classes.active_color_btn
                          }`}
                          onClick={() => {
                            changeStrokeType(1, textIndex);
                            setOutlineStyle([false, true, false]);
                          }}
                        >
                          AROUND TEXT
                        </Box>
                        <Box
                          className={`${classes.color_btn} ${classes.center} ${
                            outlineStyle[2] && classes.active_color_btn
                          }`}
                          onClick={() => {
                            changeStrokeType(2, textIndex);
                            setOutlineStyle([false, false, true]);
                          }}
                        >
                          TEXT BOX
                        </Box>
                      </Box>
                      {outlineStyle[0] && (
                        <>
                          <Box
                            className={classes.flex}
                            style={{ padding: "0 10px" }}
                          >
                            <Typography className={classes.slider_title}>
                              Spacing
                            </Typography>
                            <Slider
                              ValueLabelComponent={ValueLabelComponent}
                              defaultValue={0}
                              value={letterSpacing}
                              step={1}
                              min={0}
                              max={20}
                              valueLabelDisplay="auto"
                              classes={{
                                root: classes.mobileSlderRoot,
                                rail: classes.mobileRail,
                                track: classes.track,
                                thumb: classes.thumb,
                              }}
                              onChange={(_, value) => {
                                changeLetterSpacing("mobile", textIndex, value);
                                setLetterSpacing(value);
                              }}
                            />
                            <Typography className={classes.slider_value}>
                              {letterSpacing}
                            </Typography>
                          </Box>
                          <Box
                            className={classes.flex}
                            style={{ padding: "0 10px" }}
                          >
                            <Typography className={classes.slider_title}>
                              Outline
                            </Typography>
                            <Slider
                              ValueLabelComponent={ValueLabelComponent}
                              defaultValue={0}
                              value={outline}
                              step={1}
                              min={0}
                              max={15}
                              valueLabelDisplay="auto"
                              classes={{
                                root: classes.mobileSlderRoot,
                                rail: classes.mobileRail,
                                track: classes.track,
                                thumb: classes.thumb,
                              }}
                              onChange={(_, value) => {
                                changeStrokeWidth("mobile", textIndex, value);
                                setOutline(value);
                              }}
                            />
                            <Typography className={classes.slider_value}>
                              {outline}
                            </Typography>
                          </Box>
                          <Box
                            className={classes.flex}
                            style={{ padding: "0 10px" }}
                          >
                            <Typography className={classes.slider_title}>
                              Shadow
                            </Typography>
                            <Slider
                              ValueLabelComponent={ValueLabelComponent}
                              defaultValue={0}
                              value={shadow}
                              step={1}
                              min={0}
                              max={15}
                              valueLabelDisplay="auto"
                              classes={{
                                root: classes.mobileSlderRoot,
                                rail: classes.mobileRail,
                                track: classes.track,
                                thumb: classes.thumb,
                              }}
                              onChange={(_, value) => {
                                setShadow(value);
                                changeShadowWidth("mobile", textIndex, value);
                              }}
                            />
                            <Typography className={classes.slider_value}>
                              {shadow}
                            </Typography>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                  {txtEditTools[1] && (
                    <>
                      <InputBase
                        value={font_val}
                        placeholder="Search fonts..."
                        startAdornment={
                          <MaterialIcon icon="search" color="#6C7B93" />
                        }
                        onChange={(e) => {
                          setFontVal(e.target.value);
                          const tem = fonts.filter((item) =>
                            item.toLowerCase().includes(font_val)
                          );
                          setFilterFontFamily(tem);
                        }}
                        className={classes.mobile_font_search}
                      />
                      <Box className="mobile_font_picker">
                        <Box className="font-list">
                          {filterFontFamily.map((item) => (
                            <Box
                              className="font-list-item"
                              key={`${item}_font`}
                            >
                              <Button
                                style={{
                                  fontFamily: '"' + item + '"',
                                  color: "#C2C9D4",
                                  whiteSpace: "nowrap",
                                }}
                                onClick={() =>
                                  changeFont(`"${item}"`, textIndex)
                                }
                              >
                                {item}
                              </Button>
                            </Box>
                          ))}
                        </Box>
                        <Box className="pseudo-fontpicker">
                          {/* <FontPicker apiKey={GOOGLE_FONTS_API_KEY} sort="popularity" limit={100} /> */}
                        </Box>
                      </Box>
                      <Box
                        className={`${classes.flex} ${classes.mobile_slider}`}
                      >
                        <Typography className={classes.slider_title}>
                          Font Size
                        </Typography>
                        <Slider
                          ValueLabelComponent={ValueLabelComponent}
                          defaultValue={20}
                          value={mobile_font_size}
                          step={1}
                          min={12}
                          max={100}
                          valueLabelDisplay="auto"
                          classes={{
                            rail: classes.mobileRail,
                            track: classes.track,
                            thumb: classes.thumb,
                          }}
                          style={{ width: "70%" }}
                          onChange={(_, value) => {
                            setMobileFontSize(value);
                            changeFontSize("mobile", textIndex, value);
                          }}
                        />
                        <Typography className={classes.slider_value}>
                          {mobile_font_size}
                        </Typography>
                      </Box>
                    </>
                  )}
                  {txtEditTools[2] && textColor[0] && (
                    <>
                      <Box className={classes.color_btn_group}>
                        <Box
                          className={`${classes.color_btn} ${classes.center} ${classes.active_color_btn}`}
                          onClick={() => setTextColor([true, false])}
                        >
                          Font color
                        </Box>
                        <Box
                          className={`${classes.color_btn} ${classes.center}`}
                          onClick={() => setTextColor([false, true])}
                        >
                          Border color
                        </Box>
                      </Box>
                      <Box className={classes.color_group}>
                        <Box
                          onClick={() => {
                            setCustomColorPicker(true);
                            setTrigger([
                              false,
                              false,
                              false,
                              false,
                              false,
                              false,
                              false,
                              true,
                            ]);
                            setTxtEditTools(new Array(4).fill(false));
                            setFontDefaultColor(false);
                          }}
                        >
                          <Typography className={classes.color_item_title}>
                            Customize
                          </Typography>
                          <Box className={classes.customize_color_wrapper}>
                            <Box
                              className={`${classes.customize_color} ${classes.center}`}
                            >
                              <MaterialIcon icon="add" color="#C2C9D4" />
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                          <Typography className={classes.color_item_title}>
                            Default
                          </Typography>
                          <Box
                            className={
                              fontDefaultColor
                                ? `${classes.customize_color_wrapper} ${classes.default_color_wrapper}`
                                : `${classes.customize_color_wrapper}`
                            }
                            style={{ marginRight: 20 }}
                            onClick={() => {
                              setFontDefaultColor(true);
                              changeColor("#fff", textIndex);
                            }}
                          >
                            <Box
                              className={`${classes.customize_color} ${classes.default_color} ${classes.center}`}
                            >
                              <MaterialIcon
                                icon="check_circle_outline"
                                color="#C2C9D4"
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box className={classes.color_item}>
                          <Typography className={classes.color_item_title}>
                            Recommended color
                          </Typography>
                          <Box display="flex" style={{ width: 400 }}>
                            {defaultColors.map((item) => (
                              <Box
                                key={`${item[1]}_color`}
                                className={`${classes.customize_color_wrapper} ${classes.recommend_color_wrapper}`}
                                style={{ background: `${item[0]}` }}
                                onClick={() => {
                                  changeColor(item[0], textIndex);
                                  setFontDefaultColor(false);
                                }}
                              />
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )}
                  {txtEditTools[2] && textColor[1] && (
                    <>
                      <Box className={classes.color_btn_group}>
                        <Box
                          className={`${classes.color_btn} ${classes.center}`}
                          onClick={() => setTextColor([true, false])}
                        >
                          Font color
                        </Box>
                        <Box
                          className={`${classes.color_btn} ${classes.center} ${classes.active_color_btn}`}
                          onClick={() => setTextColor([false, true])}
                        >
                          Border color
                        </Box>
                      </Box>
                      <Box className={classes.color_group}>
                        <Box
                          onClick={() => {
                            setCustomColorPicker(true);
                            setTrigger([
                              false,
                              false,
                              false,
                              false,
                              false,
                              false,
                              false,
                              true,
                            ]);
                            setTxtEditTools(new Array(4).fill(false));
                            setFontDefaultColor(false);
                          }}
                        >
                          <Typography className={classes.color_item_title}>
                            Customize
                          </Typography>
                          <Box className={classes.customize_color_wrapper}>
                            <Box
                              className={`${classes.customize_color} ${classes.center}`}
                            >
                              <MaterialIcon icon="add" color="#C2C9D4" />
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                          <Typography className={classes.color_item_title}>
                            Default
                          </Typography>
                          <Box
                            className={
                              fontDefaultColor
                                ? `${classes.customize_color_wrapper} ${classes.default_color_wrapper}`
                                : `${classes.customize_color_wrapper}`
                            }
                            style={{ marginRight: 20 }}
                            onClick={() => {
                              setFontDefaultColor(true);
                              changeStroke("#000", textIndex);
                            }}
                          >
                            <Box
                              className={`${classes.customize_color} ${classes.center}`}
                              style={{ backgroundColor: "black" }}
                            >
                              <MaterialIcon
                                icon="check_circle"
                                color="#C2C9D4"
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box className={classes.color_item}>
                          <Typography className={classes.color_item_title}>
                            Recommended border colors
                          </Typography>
                          <Box display="flex" style={{ width: 400 }}>
                            {defaultColors.map((item) => (
                              <Box
                                key={`${item[1]}_border`}
                                className={`${classes.customize_color_wrapper} ${classes.recommend_color_wrapper}`}
                                style={{
                                  border: `2px solid ${item[0]}`,
                                  background: "#1A1E28",
                                }}
                                onClick={() => {
                                  changeStroke(item[0], textIndex);
                                  setFontDefaultColor(false);
                                }}
                              />
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            )}
            {customColorPicker && (
              <Box className="color_picker_container">
                <Button
                  className={classes.readyBtn}
                  onClick={() => {
                    setCustomColorPicker(false);
                    setTxtEditTools([false, true, false, false]);
                    setTrigger([
                      false,
                      false,
                      false,
                      false,
                      false,
                      false,
                      true,
                      false,
                    ]);
                    setTxtEditTools([false, false, true, false]);
                  }}
                >
                  Ready
                </Button>
                <ChromePicker
                  color={spaceColor}
                  onChangeComplete={(color) => {
                    if (textColor[0]) changeColor(color.hex, textIndex);
                    if (textColor[1]) changeStroke(color.hex, textIndex);
                  }}
                  disableAlpha
                />
              </Box>
            )}
          </Box>
        </Box>
      </div>
    </div>
  );
}
