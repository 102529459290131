import { Hidden } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import MaterialIcon from "material-icons-react";
import { useState } from "react";
import { useAlert } from "react-alert";
import { BsUpload } from "react-icons/bs";
import { MdAddBox } from "react-icons/md";
import { getImageBase64FromUrl } from "../../../utils/utils";
import errorConfig from "../config/errorConfig.json";
import imageConfig from "../config/imageConfig.json";

function AddImageButtonInteraction(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorRef, setAnchorRef] = useState("");
  const [selectedPosition, setSelectedPosition] = useState(4);
  const [filesDragged, setFilesDragged] = useState(false);
  const [url, setUrl] = useState("");
  const [showMore, setShowMore] = useState(false);
  const alert = useAlert();
  // Show Popover Element
  const handleClick = (event, aRef) => {
    setAnchorEl(event.currentTarget);
    setAnchorRef(aRef);
  };
  const showError = (msg) => {
    alert.error(msg);
  };
  // Close Popover Element
  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => setSelectedPosition(4), 500);
  };
  const dragListener = (event) => {
    event.preventDefault();
    setFilesDragged(false);
    const files = event.dataTransfer.files;
    if (files.length === 1) {
      processImage(files[0]);
    } else {
      showError("Invalid Drop");
    }
  };
  // For checking the resolution of the uploaded image
  const checkResolution = (data) => {
    return new Promise((resolve, reject) => {
      try {
        const img = new Image();
        img.src = data;
        img.onload = () => {
          const { naturalWidth, naturalHeight } = img;
          if (
            naturalHeight > imageConfig.MIN_HEIGHT &&
            naturalWidth > imageConfig.MIN_WIDTH
          ) {
            resolve({ width: naturalWidth, height: naturalHeight });
          } else {
            showError(errorConfig.RESOLUTION_ERROR_MESSGAE);
          }
        };
        img.onerror = (e) => {
          showError("Failed to load image");
        };
      } catch (err) {
        reject(err);
      }
    });
  };
  // For checking image errors for file upload
  const processImage = (imgFile) => {
    if (imgFile) {
      const typeInfo = imgFile.type.split("/");
      if (typeInfo[0] !== "image") {
        return;
      }
      if (imageConfig.ALLOWED_TYPES.indexOf(typeInfo[1].toUpperCase()) === -1) {
        showError(errorConfig.FORMAT_ERROR_MESSAGE);
        return;
      }
      if (imgFile.size > imageConfig.MAX_SIZE) {
        showError(errorConfig.SIZE_ERROR_MESSAGE);
        return;
      }
      getBase64(imgFile, (imgData) => {
        checkResolution(imgData)
          .then((res) => {
            if (imgData) {
              const a = {
                data: imgData,
                imageResolution: res,
                type: "notSvg",
              };
              if (selectedPosition === 2) {
                props.layeredImg(a.data, a.imageResolution);
              } else {
                props.newImage(a, selectedPosition);
              }
              handleClose();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };
  // For getting base64 data for file upload
  const getBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log(error);
    };
  };
  const getImage = (event) => {
    processImage(event.target.files[0]);
  };
  // For choose file
  const openFileChooser = (e) => {
    document.getElementById("imgInput").click();
  };
  const handlePropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const processURL = (url, event) => {
    event.stopPropagation();
    event.preventDefault();
    if (url) {
      getImageBase64FromUrl(url)
        .then((urlData) => {
          checkResolution(urlData).then((res) => {
            if (urlData) {
              const a = {
                data: urlData,
                imageResolution: res,
                type: "notSvg",
              };
              if (selectedPosition === 2) {
                props.layeredImg(a.data, a.imageResolution);
              } else {
                props.newImage(a, selectedPosition);
              }
              handleClose();
            }
          });
        })
        .catch(() => {
          showError("Failed to load image");
        });
    }
  };
  return (
    <>
      <Hidden mdDown>
        <Button
          variant="contained"
          className="defaults-btn"
          onClick={(e) => handleClick(e, "addImage")}
        >
          <MaterialIcon icon="add" color="#808893" />
          <span style={{ color: "#E2E4E9" }}>Add Image</span>
        </Button>
      </Hidden>
      <Hidden lgUp>
        <Button
          variant="contained"
          className="mobile-defaults-btn"
          onClick={(e) => handleClick(e, "addImage")}
        >
          <MaterialIcon icon="add" color="#808893" />
        </Button>
      </Hidden>
      <Popover
        id="addImage"
        open={Boolean(anchorEl) && anchorRef === "addImage"}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <div
          className="colDivSE"
          onDrop={dragListener}
          onDragLeave={() => setFilesDragged(false)}
          onDragEnter={() => setFilesDragged(true)}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          style={{ width: "330px", height: "310px" }}
        >
          <input
            type="file"
            accept="image/*"
            id="imgInput"
            onChange={getImage}
            style={{ display: "none" }}
          />
          <div
            className="rowDivCE"
            style={{ pointerEvents: filesDragged ? "none" : "auto" }}
          >
            <div
              className={`mm-add-img-type mm-add-img-type-below ${
                selectedPosition === 4 ? "selected" : ""
              }`}
              onClick={() => setSelectedPosition(4)}
            >
              <div className="mm-add-img-type-title">Below</div>
              <div className="mm-add-img-type-diagram">
                <div className="mm-add-img-current" />
                <div className="mm-add-img-new" />
              </div>
            </div>
            <div
              className={`mm-add-img-type mm-add-img-type-inside ${
                selectedPosition === 2 ? "selected" : ""
              }`}
              onClick={() => setSelectedPosition(2)}
            >
              <div className="mm-add-img-type-title">Inside</div>
              <div className="mm-add-img-type-diagram">
                <div className="mm-add-img-current" />
                <div className="mm-add-img-new" />
              </div>
            </div>
            {showMore ? (
              <div
                class="mm-add-img-type-more a"
                data-swap="Less"
                onClick={() => setShowMore(false)}
              >
                Less
              </div>
            ) : (
              <div
                class="mm-add-img-type-more a"
                data-swap="Less"
                onClick={() => setShowMore(true)}
              >
                More
              </div>
            )}
          </div>
          {showMore && (
            <div className="rowDivCE">
              <div
                className={`mm-add-img-type mm-add-img-type-above ${
                  selectedPosition === 0 ? "selected" : ""
                }`}
                onClick={() => setSelectedPosition(0)}
              >
                <div className="mm-add-img-type-title">Above</div>
                <div className="mm-add-img-type-diagram">
                  <div className="mm-add-img-current" />
                  <div className="mm-add-img-new" />
                </div>
              </div>
              <div
                className={`mm-add-img-type mm-add-img-type-left ${
                  selectedPosition === 1 ? "selected" : ""
                }`}
                onClick={() => setSelectedPosition(1)}
              >
                <div className="mm-add-img-type-title">Left</div>
                <div className="mm-add-img-type-diagram">
                  <div className="mm-add-img-current" />
                  <div className="mm-add-img-new" />
                </div>
              </div>
              <div
                className={`mm-add-img-type mm-add-img-type-right ${
                  selectedPosition === 3 ? "selected" : ""
                }`}
                onClick={() => setSelectedPosition(3)}
              >
                <div className="mm-add-img-type-title">Right</div>
                <div className="mm-add-img-type-diagram">
                  <div className="mm-add-img-current" />
                  <div className="mm-add-img-new" />
                </div>
              </div>
            </div>
          )}
          {filesDragged === false ? (
            <>
              <div
                className="uploadImage"
                style={{ border: "none" }}
                onClick={openFileChooser}
              >
                <div className="rowDivSE">
                  <input
                    className="pasteURLInput"
                    placeholder="Paste URL"
                    id="urlInputUpload"
                    onChange={(e) => setUrl(e.target.value)}
                    type="url"
                    onClick={(e) => handlePropagation(e)}
                    value={url}
                    autoFocus
                  />
                  <IconButton
                    title="Add image"
                    size="large"
                    className="addImageURLBtn"
                    onClick={(event) => processURL(url, event)}
                  >
                    <MdAddBox />
                  </IconButton>
                </div>
                <p style={{ pointerEvents: "none", margin: "10px 0" }}>OR</p>
                <p className="uploadHeader">
                  Click to upload &nbsp;
                  <BsUpload />
                </p>
                <p
                  style={{
                    pointerEvents: "none",
                    fontSize: "1em",
                    margin: "0 0 10px 0",
                  }}
                >
                  or, drag and drop a file here
                </p>
              </div>
            </>
          ) : (
            <div>
              <p
                style={{
                  color: "#E2E4E9",
                  fontSize: "44px",
                  textAlign: "center",
                }}
              >
                DROP IT!!
              </p>
            </div>
          )}
        </div>
      </Popover>
    </>
  );
}
export default AddImageButtonInteraction;
