interface ColorType {
  [key: string]: string
}

export const colors: ColorType = {
  background: '#FBFBFB',
  purpleDark: '#412078',
  mainBlack: '#1A0030',
  darkBlue: '#151E31',
  darkBlue50: '#202B42',
  darkBlueActive: '#1D273C',
  sliderBtn: '#2A354ECC',
  sliderBtnShadow: '#00000080',
  sliderBtnBorder: '#8A98B41A',
  black80: '#12161A',
  black60: '#1D232B',
  mainPurple: '#A0158A',
  lightPurple: '#E867D3',
  mainInput: '#AAAAAA',
  mainInputBorder: '#DFDFDF',
  mainInputBackground: '#F5F5F5',
  mainGreen: '#20AC00',
  disabled: '#AAAAAA',
  linearMainPurple: 'linear-gradient(270deg, #A701A7, #412078 100%)',
  linearMainBlue: 'linear-gradient(285.87deg, #7075ff 3.44%, #35a6eb 71.68%, #01ffff 125.89%)',
  white: '#FFFFFF',
  mainGrey: '#808080',
  dialogColor: '#151820',
  blueBtn: '#35A6EB',
  black: '#000000',
  gray: '#E1E3E8',
  darkGray: '#8895AE',
  colorGray: '#ADB3C0',
  profileImageBackground: '#FFFFFF',
  border: '#27324A',
  memeGenBlue: '#1C263A',
  memeGenBlue50: '#2B374F',
  searchBg :"#33405C"
}
