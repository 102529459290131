import { Box, useMediaQuery } from "@mui/material";
import HeaderSlider from "./HeaderSlider";
import discord from "../../assets/images/socialMedia/pudgy-discord.png";
import twitter from "../../assets/images/socialMedia/pudgy-twitter.png";
import instagram from "../../assets/images/socialMedia/pudgy-instagram.png";
import youtube from "../../assets/images/socialMedia/pudgy-youtube.png";
import logo from "../../assets/svgs/pudgy_footer.svg";
import youmeme from "../../assets/svgs/by_youmeme.svg";
import penguin from "../../assets/images/penguin.png";

export default function Footer() {
  const responsive = useMediaQuery("(max-width: 770px)");
  return (
    <Box id="footer">
      <HeaderSlider topbar={false} />
      <footer
        className="footer"
        data-smooth
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <div
          className="footer-content c-208 mw"
          style={{
            paddingLeft: responsive ? "5%" : "",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="top">
            <div className="left">
              <img src={logo} alt="" />
            </div>
            <div className="middle">
              <div className="col">
                <a
                  href="https://pudgypenguins.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="xl-header"
                >
                  Home
                </a>
                <a
                  href="https://shop.pudgypenguins.com"
                  target="_blank"
                  rel="noreferrer"
                  className="xl-header"
                >
                  Shop
                </a>
                <a
                  href="https://media.pudgypenguins.com"
                  target="_blank"
                  rel="noreferrer"
                  className="xl-header"
                >
                  Media
                </a>
              </div>
              <div className="col">
                <a
                  href="https://marketplace.pudgypenguins.com"
                  target="_blank"
                  rel="noreferrer"
                  className="xl-header"
                >
                  Marketplace
                </a>
                <a
                  href="https://pudgypenguins.com/about-us"
                  target="_blank"
                  rel="noreferrer"
                  className="xl-header"
                >
                  The Team
                </a>
                <p className="xl-header">Quest Map</p>
              </div>
            </div>
            {!responsive && (
              <div className="right">
                <h3 className="l-header">follow us, fellow pengu</h3>
                <div className="svg-wrapper">
                  <a
                    href="https://www.youtube.com/channel/UCh884Rm4plIxpdRG00FDfVg"
                    target="_blank"
                    rel="noreferrer"
                    title="Youtube"
                  >
                    <span className="bg preload">
                      <img src={youtube} alt="" />
                    </span>
                  </a>

                  <a
                    href="https://discord.gg/pudgypenguins"
                    target="_blank"
                    rel="noreferrer"
                    title="Discord"
                  >
                    <span className="bg preload">
                      <img src={discord} alt="" />
                    </span>
                  </a>

                  <a
                    href="https://twitter.com/pudgypenguins"
                    target="_blank"
                    rel="noreferrer"
                    title="Twitter"
                  >
                    <div>
                      <span className="sr-only">Twitter</span>
                      <span className="bg preload">
                        <img src={twitter} alt="" />
                      </span>
                    </div>
                  </a>
                  <a
                    href="https://www.instagram.com/pudgypenguins"
                    target="_blank"
                    rel="noreferrer"
                    title="Instagram"
                  >
                    {" "}
                    <span className="bg preload">
                      <img src={instagram} alt="" />
                    </span>
                  </a>
                  <div>
                    <span className="sr-only">Instagram</span>
                  </div>
                </div>
              </div>
            )}
          </div>
          {!responsive ? (
            <a
              style={{
                bottom: 160,
                right: 235,
                position: "absolute",
              }}
              href="https://youmeme.com/"
              target="_blank"
              rel="noreferrer"
              title="Youmeme"
            >
              <span className="bg preload">
                <img src={youmeme} alt="Youmeme" />
              </span>
            </a>
          ) : (
            <a
              style={{
                display: "flex",
                marginBottom: "30px",
              }}
              href="https://youmeme.com/"
              target="_blank"
              rel="noreferrer"
              title="Youmeme"
            >
              <span className="bg preload">
                <img src={youmeme} alt="Youmeme" />
              </span>
            </a>
          )}
          <div className="bottom">
            <div className="top-content">
              <div className="pengu-svg">
                <img className="preload" src={penguin} alt="" />
              </div>
              <div className="utility-pages">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="caps"
                  href="https://pudgypenguins.com/privacy-policy"
                >
                  Privacy policy
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="caps"
                  href="https://pudgypenguins.com/terms-and-conditions"
                >
                  Terms of Use
                </a>
                <a
                  className="caps"
                  target="_blank"
                  rel="noreferrer"
                  href="https://pudgypenguins.com/ip-rights"
                >
                  IP Rights
                </a>
                <a
                  className="caps"
                  target="_blank"
                  rel="noreferrer"
                  href="https://pudgypenguins.com/jobs"
                >
                  Careers
                </a>
                {!responsive && (
                  <p className="tag hiring-eyes">
                    we’re hiring <span>👀</span>
                  </p>
                )}
              </div>
            </div>
            <div className="bottom-content">
              <p className="copyright caps">
                Copyright © 2022 -- LSLTTT Holdings, Inc. dba “Pudgy Penguins”
                -- All rights reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </Box>
  );
}
