import config from "../utils/config";
import { imagesJpg, imagesPng } from "../constants/images";

/**
 * These images will be used if the user has a null value for
 * the avatar and background fields, or if these fields do not
 * have a valid urlImage value.
 */
export const DEFAULT_AVATAR_IMAGE: string = `/assets/profileIcons/${imagesPng.profile}`;
export const DEFAULT_BACKGROUND_IMAGE: string = `/assets/profileIcons/${imagesJpg.banner}`;

export const MEME_CDN: string = config.memeCdn;
export const CATEGORY_CDN: string = config.categoryCdn;
export const AVATAR_CDN: string = config.avatarCdn;
export const BACKGROUND_CDN: string = config.backgroundCdn;
export const EVENT_CDN: string = config.eventCdn;
export const STICKER_CDN: string = config.stickerCdn;

export type ImageFormat = "jpeg" | "png" | "webp" | "avif" | "gif" | "svg";
export type ImageBucket =
  | "meme"
  | "category"
  | "avatar"
  | "background"
  | "event"
  | "template"
  | "sticker";

// Find the best supported image format from the browser
export async function getBestImageFormat(): Promise<ImageFormat> {
  const fallbackImageFormat = "jpeg";
  if (!window.createImageBitmap) return fallbackImageFormat;

  return await fetch(
    "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A="
  )
    .then(async (r) => await r.blob())
    .then(async (b) => await createImageBitmap(b))
    .then(
      () => "avif",
      async () =>
        await fetch(
          "data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA"
        )
          .then(async (r) => await r.blob())
          .then(async (b) => await createImageBitmap(b))
          .then(
            () => "webp",
            () => "jpeg"
          )
    );
}

export let BEST_IMAGE_FORMAT: ImageFormat = "webp";

export async function setBestImageFormat(): Promise<ImageFormat> {
  BEST_IMAGE_FORMAT = await getBestImageFormat();
  return BEST_IMAGE_FORMAT;
}

// getOptimizedImageURL takes an image and the bucket the image is from, along
// with its maximum height and width (for the box it will be rendered inside).
// It returns a URL to the optimized image, in the best format for the browser
// (if imageFormat is passed as an argment) or as an avif.
export function getOptmizedImageURL(
  bucket: ImageBucket,
  image: string,
  maxHeight: string,
  maxWidth: string,
  imageFormat: ImageFormat | "" = ""
): string {
  if (!imageFormat) {
    imageFormat = BEST_IMAGE_FORMAT;
  }
  return `${config.cdn[bucket]}/optimized/${image}.transform.${maxWidth}.${maxHeight}.${imageFormat}`;
}

// downloadMemeJPEG lets you download the JPEG of a meme image
export function downloadMemeJPEG(fkImageIdRendered: string): string {
  const imageUrl = getOptmizedImageURL(
    "meme",
    fkImageIdRendered,
    "1000",
    "1000",
    "jpeg"
  );
  return imageUrl;
}

// getOriginalImageURL takes an image and the bucket the image is from, and
// returns the URL where this image may be fetched from.
export function getOriginalImageURL(
  bucket: ImageBucket,
  image: string
): string {
  return `${config.cdn[bucket]}/source/${image}`;
}

// getImageURL takes a bucket and an image URL and returns the URL at which
// this image might be fetched from
export function getImageURL(bucket: ImageBucket, image: string): string {
  /*  if (!image) {
    switch (bucket) {
      case "avatar":
        return DEFAULT_AVATAR_IMAGE;
      case "background":
        return DEFAULT_BACKGROUND_IMAGE;
    }
    return "";
  }
  */
  return isValidURL(image)
    ? image
    : getOriginalImageURL(bucket, getImageFilename(image));
}

// getImageFilename returns the filename of an image that is
// sent to us as `/imagepurpose/filename.ext`
// while also being able to handle `imagepurpose/filename.ext`,
// `/filename.ext`, and `filename.ext`
const getImageFilename = (image: string): string => {
  const urlParts: string[] = image.split("/");
  return urlParts[urlParts.length - 1];
};

export const getAvatarImageURL = (image: string): string => {
  if (!image) {
    return DEFAULT_AVATAR_IMAGE;
  }
  return isValidURL(image) ? image : `${AVATAR_CDN}/${getImageFilename(image)}`;
};

export const getBackgroundImageURL = (image: string): string => {
  if (!image) {
    return DEFAULT_BACKGROUND_IMAGE;
  }
  return isValidURL(image)
    ? image
    : `${BACKGROUND_CDN}/${getImageFilename(image)}`;
};

export const getMemeImageURL = (image: string): string => {
  return isValidURL(image) ? image : `${MEME_CDN}/${getImageFilename(image)}`;
};

export const getCategoryImageURL = (image: string): string => {
  return isValidURL(image)
    ? image
    : `${CATEGORY_CDN}/${getImageFilename(image)}`;
};

export const getEventImageURL = (image: string): string => {
  return isValidURL(image) ? image : `${EVENT_CDN}/${getImageFilename(image)}`;
};

export const getStickerImageURL = (image: string): string => {
  return `${STICKER_CDN}/${getImageFilename(image)}`;
};

export const isIncludeFollowingExtensions = (path: string): boolean => {
  if (
    path?.includes(".jpg") ||
    path?.includes(".svg") ||
    path?.includes(".png")
  ) {
    return true;
  }

  return false;
};

export const isIncludeHttpExtension = (path: string): boolean => {
  if (path?.includes("http")) {
    return true;
  }

  return false;
};

// isValidURL simply checks if the provided string starts with
// http:// or https://. This indicates that the image field is
// a valid url. Otherwise it refers to an entry within our CDN.
const isValidURL = (url: string): boolean => {
  if (url?.startsWith("http://") || url?.startsWith("https://")) {
    return true;
  }
  return false;
};
