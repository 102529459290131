import { useState } from "react";
import errorConfig from "../config/errorConfig.json";
import imageConfig from "../config/imageConfig.json";
import toast from "react-hot-toast";
import FileUpload from "./FileUpload";
import ImageByUrl from "./ImageByUrl";
import "./imageUpload.css";
import Layout from "./Layout";
import PictureByCamera from "./PictureByCamera";
import SearchImage from "./SearchImage";
import { handleFiles } from "../../../utils/utils";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import { DialogActions, DialogContentText, useMediaQuery } from "@mui/material";
import sorryImg from "../../../assets/images/sorryPenguin.webp";
import { Box } from "@mui/system";

const txts = [
  {
    title: "CREATE",
    desc: "Here are your options. Choose one and see you in there!",
  },
  {
    title: "Browse through Unsplash for your image",
    desc: "Select the best image among a huge range of suggestions. Have fun!",
  },
  {
    title: "Use a camera to take a picture",
    desc: "Don't be shy! This is what memes are all about. Use your phone or webcam and take a picture of yourself. Make sure you give us access to your device in order to use this feature!",
  },
  {
    title: "Use a Url address",
    desc: "You have the option to copy and paste the image’s Url address in the textbox below. We have to let you know though, that, you can only use images that you own an ",
  },
];

export async function getImageBse64FromSearchEngineURL(url) {
  return new Promise((resolve, reject) => {
    const x = new XMLHttpRequest();
    x.open("GET", url);
    x.responseType = "blob";
    x.onload = function () {
      const reader = new FileReader();
      reader.readAsDataURL(x.response);
      reader.onloadend = function () {
        const base64data = reader.result;
        resolve(base64data);
      };
    };
    x.onerror = function () {
      reject(x);
    };
    x.send();
  });
}

async function checkResolution(data, setLoading, showError) {
  return new Promise((resolve, reject) => {
    try {
      const img = new Image();
      img.src = data;
      img.onload = () => {
        const { naturalWidth, naturalHeight } = img;
        if (
          naturalHeight > imageConfig.MIN_HEIGHT &&
          naturalWidth > imageConfig.MIN_WIDTH
        ) {
          resolve({ width: naturalWidth, height: naturalHeight });
        } else {
          toast.error(errorConfig.RESOLUTION_ERROR_MESSGAE);
          throw new Error(errorConfig.RESOLUTION_ERROR_MESSGAE);
        }
      };
      img.onerror = () => {
        throw new Error("Failed to load image");
      };
    } catch (err) {
      setLoading?.(false);
      reject(err);
    }
  });
}

// isMemeTemplate checks if this image file's name ends with
// a .template.svg extension.
const isMemeTemplate = (url) => {
  const parts = url.split(".");
  const extension = parts[parts.length - 1];
  // FIXME check for .template.svg; not just .svg
  if (extension.toLowerCase() === "svg") {
    return true;
  } else {
    return false;
  }
};

export async function sendImageToMemeEditor(url) {
  const urlData = await getImageBse64FromSearchEngineURL(url);
  if (!urlData) return;

  const isTemplate = isMemeTemplate(url);
  let processedSvg = null;
  if (isTemplate) {
    try {
      const blob = await fetch(urlData).then(async (res) => await res.blob());
      processedSvg = await handleFiles(blob);
    } catch (err) {
      console.error(`Error processing SVG template ${url}`, err);
    }
  }

  const imageResolution = await checkResolution(urlData);

  const notSvgRes = {
    data: urlData,
    imageResolution,
    type: "notSvg",
  };

  return isTemplate ? processedSvg : notSvgRes;
}

const ImageUpload = ({ navigateToPage, onClose }) => {
  const isMobile = useMediaQuery("(max-width: 770px)");
  const [tabValue, setTab] = useState(0);
  const [openNote, setOpenNote] = useState(true);
  return (
    <Layout
      txt={txts[tabValue]}
      navigate={navigateToPage}
      onClose={onClose}
      handleTab={setTab}
      flag={tabValue}
    >
      <FileUpload
        isOpen={tabValue === 0}
        navigate={navigateToPage}
        handleTab={setTab}
      />
      <SearchImage
        isOpen={tabValue === 1}
        navigate={navigateToPage}
        handleTab={setTab}
      />
      <PictureByCamera
        isOpen={tabValue === 2}
        navigate={navigateToPage}
        handleTab={setTab}
      />
      <ImageByUrl
        isOpen={tabValue === 3}
        navigate={navigateToPage}
        handleTab={setTab}
      />
      <Dialog
        open={isMobile && openNote}
        onClose={() => setOpenNote(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: 5,
            border: 0,
            backgroundColor: "#232A30",
          },
        }}
      >
        <DialogContent
          style={{
            textAlign: "center",
            backgroundColor: "#151820",

            border: 0,
          }}
        >
          <Box>
            <img src={sorryImg} alt="" style={{ width: "90px" }} />
          </Box>
          <DialogContentText
            style={{
              paddingTop: "15px",
              fontSize: "15px",
              fontWeight: 700,
              lineHeight: "22px",
              letterSpacing: "-0.3px",
              alignSelf: "center",
              color: "white",
            }}
            id="alert-dialog-description"
          >
            You are using a beta version, you might face slowliness.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            textAlign: "center",
            borderBottomRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            backgroundColor: "#151820",
            color: "white",
            border: 0,
          }}
        >
          <Button
            onClick={() => setOpenNote(false)}
            color="primary"
            autoFocus
            style={{
              height: "44px",
              width: "97px",
              backgroundColor: "#35A6EB",
              borderRadius: "7px",
              fontWeight: "700",
              fontSize: "14px",
              color: "#FFFFFF",
              boxShadow: "none",
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};
export default ImageUpload;
