import { Box, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FontPicker from "font-picker-react";
import MaterialIcon from "material-icons-react";
import { memo, useRef, useState } from "react";
import React, { useEffect } from "react";
import { ChromePicker } from "react-color";
import {
  AiOutlineClose,
  AiOutlineVerticalAlignBottom,
  AiOutlineVerticalAlignMiddle,
  AiOutlineVerticalAlignTop,
} from "react-icons/ai";
import { FaAlignCenter, FaAlignLeft, FaAlignRight } from "react-icons/fa";
import { colors } from "../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  popbtn: {
    background: colors.memeGenBlue50,
    borderRadius: "5px",
    color: "#E2E4E9",
    fontSize: 16,
    width: 30,
    height: 30,
    "& i": {
      fontSize: "18px !important",
    },
  },
  alignbtngroup: {
    width: 102,
    height: 36,
    display: "flex",
    alignItems: "center",
    color: "#6C7B93",
    padding: "3px",
    justifyContent: "space-around",
    background: colors.memeGenBlue50,
  },
  btnItem: {
    width: "120px",
    height: "30px",
    font: "normal normal normal 12px Inter",
    background: `${colors.darkBlue} !important`,
    color: "#6C7B93 !important",
    borderRadius: "3px !important",
    "&:hover": {
      opacity: 0.8,
      backgroundColor: colors.memeGenBlue50,
    },
  },
  textButton: {
    width: "50px",
    height: "50px",
    background: colors.darkBlue + " !important",
    color: "#6C7B93",
    borderRadius: "7px !important",
    border: 0,
    "&:hover": {
      opacity: 0.8,
      backgroundColor: colors.memeGenBlue50,
    },
  },
  setOption: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#6C7B93",
    margin: "10px 0 2px",
  },
  controlBtn: {
    minWidth: "30px",
    width: "30px",
    height: "30px",
    background: `${colors.memeGenBlue50} !important`,
    color: "#E2E4E9 !important",
    borderRadius: "5px !important",
  },
  secondaryBtn: {
    width: "120px",
    height: "30px",
    background: `${colors.memeGenBlue50} !important`,
    color: "#E2E4E9 !important",
    borderRadius: "3px !important",
  },
  display: {
    width: "50px",
    height: "30px",
    background: colors.memeGenBlue50,
    borderRadius: "5px !important",
    color: "#E2E4E9 !important",
    marginLeft: "3px !important",
    marginRight: "3px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "15px",
  },
  icon: {
    width: 20,
    height: 20,
  },
  fontIcon: {
    width: 18,
    height: 18,
  },
  alignText: {
    width: 30,
    height: 30,
    borderRadius: 3,
    color: "#6C7B93",
    background: colors.memeGenBlue50,
  },
  icon2: {
    marginRight: 9,
  },
}));

const GOOGLE_FONTS_API_KEY = "AIzaSyB_GeunegMajGY5ISuzcSvk7mACxekTnXY";
const defaultColors = [
  ["#D54177", "Pink"],
  ["#EF895B", "Orange"],
  ["#F0CD4E", " Yellow"],
  ["#5094CF", "Blue"],
  ["#8545C1", "Purple"],
  ["#FFFFFF", "White"],
  ["#000000", " Black"],
];
const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#cbcbcb"),
    backgroundColor: "#cbcbcb",
    "&:hover": {
      backgroundColor: "#bbbbbb",
    },
  },
}))(Button);
function TextInteraction(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorRef, setAnchorRef] = useState("");
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorRef2, setAnchorRef2] = useState("");
  const classes = useStyles();
  const timeout = useRef(undefined);
  useEffect(() => {
    for (let i = 0; i < props.textValue.length; i++) {
      let inputElement = document.getElementById("font-size-input" + i);
      if (props.fontSize[i] !== inputElement.valueAsNumber) {
        inputElement.valueAsNumber = props.fontSize[i];
      }
    }
  }, [props.fontSize]);

  const throttledTextColorUpdate = (hex, index) => {
    if (timeout.current !== undefined) {
      return;
    }
    timeout.current = setTimeout(() => {
      props.changeColor(hex, index);
      timeout.current = undefined;
    }, 32);
  };

  const throttledStrokeColorUpdate = (hex, index) => {
    if (timeout.current !== undefined) {
      return;
    }
    timeout.current = setTimeout(() => {
      props.changeStroke(hex, index);
      timeout.current = undefined;
    }, 32);
  };
  // Show Popover Element
  const handleClick = (event, aRef) => {
    setAnchorEl(event.currentTarget);
    setAnchorRef(aRef);
  };
  // Close Popover Element
  const handleClose = () => {
    setAnchorEl(null);
  };
  // Show Popover Element 2
  const handleClick2 = (event, aRef) => {
    setAnchorEl2(event.currentTarget);
    setAnchorRef2(aRef);
  };
  // Close Popover Element 2
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const textChange = (value, index) => {
    props.textChange(value, index);
  };

  const debouncedHandleFontChange = (type, index) => {
    if (timeout.current !== undefined) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      props.changeFontSize(type, index);
      timeout.current = undefined;
    }, 700);
  };

  return (
    <div className="textcolDivFS" style={{ overflowX: "hidden" }}>
      {props.textValue.map(function (textItem, index) {
        return (
          <div
            className="cardLowMarDivCE"
            key={"textbox" + index}
            onClick={(event) => {
              props.showTextRect(index);
            }}
            onMouseLeave={(event) => {
              props.hideAllTextRect();
            }}
          >
            <div
              className="rowDivSB centerAlignItem relativePostionDiv"
              style={{ padding: "10px 5px" }}
            >
              <Button
                variant="contained"
                onClick={() => props.changeToUpper(index)}
                size="small"
                className={classes.btnItem}
                style={{ margin: "0 3px" }}
                title="Toggle text to uppercase"
              >
                ALL CAPS
              </Button>
              <Typography className="tabPanelCardTitleText">
                Text Input {index + 1}
              </Typography>
              <Box marginRight="10px">
                <AiOutlineClose
                  title="Delete"
                  className="cardCloseButton"
                  onClick={(event) => {
                    props.deleteText(index);
                  }}
                />
              </Box>
            </div>
            <textarea
              rows={3}
              style={{ backgroundColor: colors.darkBlue, borderRadius: 7 }}
              className="textInput"
              spellCheck="false"
              placeholder={props.textPlaceholder[index]}
              value={props.textValue[index].join("\n")}
              onChange={(event) => textChange(event.target.value, index)}
            />
            <div className="rowDivSE" style={{ margin: "5px 0 5px 5px" }}>
              <div
                title="Text color"
                onClick={(e) => handleClick(e, "TextColorPop" + index)}
                style={{
                  border: `5px solid ${colors.darkBlue}`,
                  borderRadius: "7px",
                }}
              >
                <div
                  className="colorBtn"
                  style={{ backgroundColor: props.colorValue[index] }}
                />
              </div>
              <div
                title="Outline/Shadow color"
                style={{
                  border: `5px solid ${colors.darkBlue}`,
                  borderRadius: "7px",
                  marginLeft: "3px",
                }}
                onClick={(e) => handleClick(e, "StrokeColorPop" + index)}
              >
                <div
                  className="colorBtn"
                  style={{
                    backgroundColor: props.strokeValue[index],
                  }}
                />
              </div>
              <ButtonGroup className="increNumDiv">
                <ColorButton
                  className={classes.textButton}
                  onClick={() => props.changeFontSize("-", index)}
                  title="Decrease font size"
                >
                  <MaterialIcon icon="remove" color="#6C7B93" size={20} />
                </ColorButton>
                <div className="font-size-input-area-wrapper">
                  <input
                    id={"font-size-input" + index}
                    style={{ textTransform: "none" }}
                    type="number"
                    onFocus={(focusEvent) => focusEvent.target.select()}
                    onChange={(changedValueEvent) =>
                      debouncedHandleFontChange(
                        changedValueEvent.target.value,
                        index
                      )
                    }
                  />
                </div>
                <ColorButton
                  className={classes.textButton}
                  onClick={() => props.changeFontSize("+", index)}
                  title="Increase font size"
                >
                  <MaterialIcon icon="add" color="#6C7B93" size={20} />
                </ColorButton>
              </ButtonGroup>
              <Button
                title="Settings"
                className={classes.textButton}
                size="small"
                style={{
                  marginRight: "5px",
                }}
                onClick={(e) => handleClick(e, "textSettings" + index)}
              >
                <MaterialIcon icon="settings" color="#6C7B93" size={20} />
              </Button>
            </div>
            <Popover
              id={"VAlign" + index}
              open={Boolean(anchorEl) && anchorRef === "textSettings" + index}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <div className="settingsDiv">
                <div className="rowDivSE" style={{ margin: "9px" }}>
                  <Typography
                    className="tabPanelCardTitleText"
                    style={{ textAlign: "center" }}
                  >
                    Text Settings
                  </Typography>
                </div>
                <div className="rowDivSE" style={{ margin: "4px 0" }}>
                  <div className="fontPicker">
                    <FontPicker
                      apiKey={GOOGLE_FONTS_API_KEY}
                      activeFontFamily={props.fontFamily[index]}
                      pickerId={"text" + index}
                      onChange={(nextFont) => {
                        if (nextFont.family !== props.fontFamily[index]) {
                          props.changeFont(nextFont.family, index);
                        }
                      }}
                      sort="alphabet"
                      limit={100}
                      style={{ backgroundColor: "red" }}
                    />
                  </div>
                </div>
                <div
                  className="rowDivSE"
                  style={{
                    justifyContent: "space-between",
                    margin: "4px 0",
                  }}
                >
                  <IconButton
                    title="Bold"
                    size="small"
                    onClick={() => props.changeFontWeight(index)}
                    className={classes.popbtn}
                  >
                    <MaterialIcon icon="format_bold" color="#6C7B93" />
                  </IconButton>
                  <IconButton
                    title="Italic"
                    size="small"
                    onClick={() => props.changeFontStyle(index)}
                    className={classes.popbtn}
                  >
                    <MaterialIcon icon="format_italic" color="#6C7B93" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={(e) => handleClick2(e, "hAlign" + index)}
                    className={classes.popbtn}
                  >
                    {props.hAlign[index] === 0 && (
                      <MaterialIcon icon="format_align_left" color="#6C7B93" />
                    )}
                    {props.hAlign[index] === 0.5 && (
                      <MaterialIcon
                        icon="format_align_center"
                        color="#6C7B93"
                      />
                    )}
                    {props.hAlign[index] === 1 && (
                      <MaterialIcon icon="format_align_right" color="#6C7B93" />
                    )}
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={(e) => handleClick2(e, "VAlign" + index)}
                    className={classes.popbtn}
                  >
                    {props.vAlign[index] === 0 && (
                      <MaterialIcon icon="vertical_align_top" color="#6C7B93" />
                    )}
                    {props.vAlign[index] === 0.5 && (
                      <MaterialIcon
                        icon="vertical_align_center"
                        color="#6C7B93"
                      />
                    )}
                    {props.vAlign[index] === 1 && (
                      <MaterialIcon
                        icon="vertical_align_bottom"
                        color="#6C7B93"
                      />
                    )}
                  </IconButton>
                </div>
                <div className="colDivFSFS">
                  <div className="rowDivSE">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "1px",
                      }}
                    >
                      <p className={classes.setOption}>Secondary Color</p>
                      <ButtonGroup className="increNumDiv">
                        <ColorButton
                          variant="contained"
                          size="small"
                          className={classes.secondaryBtn}
                          title="Change secondary color type"
                          onClick={(e) => handleClick2(e, "strokeTypeListPop")}
                        >
                          <>
                            {props.strokeType[index] === 0 && "Outline"}
                            {props.strokeType[index] === 1 && "Around text"}
                            {props.strokeType[index] === 2 && "Text box"}
                          </>
                        </ColorButton>
                      </ButtonGroup>
                      <Popover
                        id="strokeTypeListPop"
                        open={
                          Boolean(anchorEl2) &&
                          anchorRef2 === "strokeTypeListPop"
                        }
                        anchorEl={anchorEl2}
                        onClose={handleClose2}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <ButtonGroup
                          orientation="vertical"
                          variant="outlined"
                          size="small"
                          className="downloadOptionButtonGroup"
                        >
                          <Button
                            onClick={() => {
                              props.changeStrokeType(0, index);
                              handleClose2();
                            }}
                            title="Set secondary color as outline of text"
                          >
                            Outline
                          </Button>
                          <Button
                            onClick={() => {
                              props.changeStrokeType(1, index);
                              handleClose2();
                            }}
                            title="Set secondary color around the text"
                          >
                            Around text
                          </Button>
                          <Button
                            onClick={() => {
                              props.changeStrokeType(2, index);
                              handleClose2();
                            }}
                            title="Set secondary color around the text"
                          >
                            Text box
                          </Button>
                        </ButtonGroup>
                      </Popover>
                    </div>
                  </div>
                  <div className="rowDivSE">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p className={classes.setOption}>Letter Space</p>
                      <ButtonGroup className="increNumDiv">
                        <ColorButton
                          className={classes.controlBtn}
                          onClick={() => props.changeLetterSpacing("-", index)}
                          title="Decrease letter space"
                        >
                          <MaterialIcon
                            icon="remove"
                            color="#6C7B93"
                            size={18}
                          />
                        </ColorButton>
                        <Button className={classes.display} disabled>
                          {props.letterSpace[index].replace("px", "")}
                        </Button>
                        <ColorButton
                          className={classes.controlBtn}
                          onClick={() => props.changeLetterSpacing("+", index)}
                          title="Increase letter space"
                        >
                          <MaterialIcon icon="add" color="#6C7B93" size={18} />
                        </ColorButton>
                      </ButtonGroup>
                    </div>
                  </div>
                  {props.strokeType[index] === 0 && (
                    <>
                      <div className="rowDivSE">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <p className={classes.setOption}>Outline Width</p>
                          <ButtonGroup
                            className="increNumDiv"
                            style={{ height: "28px" }}
                          >
                            <ColorButton
                              className={classes.controlBtn}
                              onClick={() =>
                                props.changeStrokeWidth("-", index)
                              }
                              title="Decrease outline width"
                            >
                              <MaterialIcon
                                icon="remove"
                                color="#6C7B93"
                                size={18}
                              />
                            </ColorButton>
                            <Button className={classes.display} disabled>
                              {props.strokeWidth[index].replace("px", "")}
                            </Button>
                            <ColorButton
                              className={classes.controlBtn}
                              onClick={() =>
                                props.changeStrokeWidth("+", index)
                              }
                              title="Increase outline width"
                            >
                              <MaterialIcon
                                icon="add"
                                color="#6C7B93"
                                size={18}
                              />
                            </ColorButton>
                          </ButtonGroup>
                        </div>
                      </div>
                      <div className="rowDivSE">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <p className={classes.setOption}>Shadow Width</p>
                          <ButtonGroup
                            className="increNumDiv"
                            style={{ height: "28px" }}
                          >
                            <ColorButton
                              className={classes.controlBtn}
                              onClick={() =>
                                props.changeShadowWidth("-", index)
                              }
                              title="Decrease shadow width"
                            >
                              <MaterialIcon
                                icon="remove"
                                color="#6C7B93"
                                size={18}
                              />
                            </ColorButton>
                            <Button className={classes.display} disabled>
                              {props.shadowWidth[index].replace("px", "")}
                            </Button>
                            <ColorButton
                              className={classes.controlBtn}
                              onClick={() =>
                                props.changeShadowWidth("+", index)
                              }
                              title="Increase shadow width"
                            >
                              <MaterialIcon
                                icon="add"
                                color="#6C7B93"
                                size={18}
                              />
                            </ColorButton>
                          </ButtonGroup>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Popover>
            <Popover
              id={"hAlign" + index}
              open={Boolean(anchorEl2) && anchorRef2 === "hAlign" + index}
              anchorEl={anchorEl2}
              onClose={handleClose2}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Box className={classes.alignbtngroup}>
                <IconButton
                  size="small"
                  title="Left Align"
                  onClick={() => props.changeHAlign(0, index)}
                  className={classes.alignText}
                  style={{
                    background:
                      props.hAlign[index] === 0 ? `${colors.blueBtn}` : "",
                  }}
                >
                  <FaAlignLeft
                    style={{
                      color: props.hAlign[index] === 0 ? "#FFFFFF" : "",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  title="Center Align"
                  onClick={() => props.changeHAlign(0.5, index)}
                  className={classes.alignText}
                  style={{
                    background:
                      props.hAlign[index] === 0.5 ? `${colors.blueBtn}` : "",
                  }}
                >
                  <FaAlignCenter
                    style={{
                      color: props.hAlign[index] === 0.5 ? "#FFFFFF" : "",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  title="Right Align"
                  onClick={() => props.changeHAlign(1, index)}
                  className={classes.alignText}
                  style={{
                    background:
                      props.hAlign[index] === 1 ? `${colors.blueBtn}` : "",
                  }}
                >
                  <FaAlignRight
                    style={{
                      color: props.hAlign[index] === 1 ? "#FFFFFF" : "",
                    }}
                  />
                </IconButton>
              </Box>
            </Popover>
            <Popover
              id={"VAlign" + index}
              open={Boolean(anchorEl2) && anchorRef2 === "VAlign" + index}
              anchorEl={anchorEl2}
              onClose={handleClose2}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Box className={classes.alignbtngroup}>
                <IconButton
                  title="Top Align"
                  size="small"
                  onClick={() => props.changeVAlign(0, index)}
                  className={classes.alignText}
                  style={{
                    background:
                      props.vAlign[index] === 0 ? `${colors.blueBtn}` : "",
                  }}
                >
                  <AiOutlineVerticalAlignTop
                    style={{
                      color: props.vAlign[index] === 0 ? "#FFFFFF" : "",
                    }}
                  />
                </IconButton>
                <IconButton
                  title="Middle Align"
                  size="small"
                  onClick={() => props.changeVAlign(0.5, index)}
                  className={classes.alignText}
                  style={{
                    background:
                      props.vAlign[index] === 0.5 ? `${colors.blueBtn}` : "",
                  }}
                >
                  <AiOutlineVerticalAlignMiddle
                    style={{
                      color: props.vAlign[index] === 0.5 ? "#FFFFFF" : "",
                    }}
                  />
                </IconButton>
                <IconButton
                  title="Bottom Align"
                  size="small"
                  onClick={() => props.changeVAlign(1, index)}
                  className={classes.alignText}
                  style={{
                    background:
                      props.vAlign[index] === 1 ? `${colors.blueBtn}` : "",
                  }}
                >
                  <AiOutlineVerticalAlignBottom
                    style={{
                      color: props.vAlign[index] === 1 ? "#FFFFFF" : "",
                    }}
                  />
                </IconButton>
              </Box>
            </Popover>
            <Popover
              id={"TextColorPop" + index}
              open={Boolean(anchorEl) && anchorRef === "TextColorPop" + index}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div className="colorPalletDiv">
                <ChromePicker
                  color={props.colorValue[index]}
                  onChange={(color) =>
                    throttledTextColorUpdate(color.hex, index)
                  }
                  disableAlpha
                />
                <Typography className="colortext">
                  Here are some suggested colors
                </Typography>
                <div className="palletColorRoot">
                  {defaultColors.map((color, i) => {
                    return (
                      <div
                        className="palletcolor"
                        key={"TextCIcon" + index + i}
                        title={color[1]}
                        style={{ background: color[0], cursor: "pointer" }}
                        onClick={() => props.changeColor(color[0], index)}
                      />
                    );
                  })}
                </div>
              </div>
            </Popover>
            <Popover
              id={"StrokeColorPop" + index}
              open={Boolean(anchorEl) && anchorRef === "StrokeColorPop" + index}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div className="colorPalletDiv">
                <ChromePicker
                  color={props.strokeValue[index]}
                  onChange={(color) =>
                    throttledStrokeColorUpdate(color.hex, index)
                  }
                  disableAlpha
                />
                <Typography className="colortext">
                  Here are some suggested colors
                </Typography>
                <div className="palletColorRoot">
                  {defaultColors.map((color, i) => {
                    return (
                      <div
                        className="palletcolor"
                        key={"StrokeCIcon" + index + i}
                        title={color[1]}
                        style={{ background: color[0], cursor: "pointer" }}
                        onClick={() => props.changeStroke(color[0], index)}
                      />
                    );
                  })}
                </div>
              </div>
            </Popover>
          </div>
        );
      })}
      <Box style={{ display: "flex", gap: 6, marginTop: 20 }}>
        <Button className="addText" onClick={() => props.addText()}>
          + Add Text
        </Button>
        <Button className="advanced" onClick={() => props.advanced()}>
          Advanced
        </Button>
      </Box>
    </div>
  );
}

export const TextInteractionMemo = memo(
  TextInteraction,
  (prevProps, nextProps) => {
    if (
      prevProps.textValue !== nextProps.textValue ||
      prevProps.fontSize !== nextProps.fontSize ||
      prevProps.textPlaceholder !== nextProps.textPlaceholder ||
      prevProps.fontWeight !== nextProps.fontWeight ||
      prevProps.fontStyle !== nextProps.fontStyle ||
      prevProps.fontFamily !== nextProps.fontFamily ||
      prevProps.letterSpace !== nextProps.letterSpace ||
      prevProps.colorValue !== nextProps.colorValue ||
      prevProps.strokeValue !== nextProps.strokeValue ||
      prevProps.strokeType !== nextProps.strokeType ||
      prevProps.isUpperCase !== nextProps.isUpperCase ||
      prevProps.hAlign !== nextProps.hAlign ||
      prevProps.vAlign !== nextProps.vAlign ||
      prevProps.strokeWidth !== nextProps.strokeWidth ||
      prevProps.shadowWidth !== nextProps.shadowWidth ||
      prevProps.textRectWidth !== nextProps.textRectWidth ||
      prevProps.textRectHeight !== nextProps.textRectHeight ||
      prevProps.rectDimension !== nextProps.rectDimension ||
      prevProps.rectPosition !== nextProps.rectPosition ||
      prevProps.rotationScaleT !== nextProps.rotationScaleT
    ) {
      return false;
    }
    return true;
  }
);
