import { Typography, useMediaQuery } from "@material-ui/core";
import { forwardRef, useEffect, useRef, useState } from "react";

import { randomSearchCheatCode } from "../../../constants/variables";
import { debounce } from "../../../utils/utils";
import pudgy1 from "../../../assets/pp_templates/pudgy1.jpg";
import pudgy2 from "../../../assets/pp_templates/pudgy2.jpg";
import pudgy3 from "../../../assets/pp_templates/pudgy3.jpg";
import pudgy4 from "../../../assets/pp_templates/pudgy4.jpg";
import pudgy5 from "../../../assets/pp_templates/pudgy5.jpg";
import pudgy6 from "../../../assets/pp_templates/pudgy6.jpg";
import pudgy7 from "../../../assets/pp_templates/pudgy7.jpg";
import pudgy8 from "../../../assets/pp_templates/pudgy8.jpg";
import trollface from "../../../assets/images/trollface.png";
import redPenguin from "../../../assets/images/Red_penguin.png";
import TemplatesMenu from "./templatesMenu";
import { MdOutlineExpandMore, MdOutlineExpandLess } from "react-icons/md";
import { CollectionsOutlined } from "@material-ui/icons";

function MemeSearchInput({
  handleSearch,
  resetSearch,
  searchDisabled,
  classes,
  handleRandomSearch,
  setPpTemp,
  ppTemp,
  anchorEl,
  setAnchorEl,
}) {
  const handleInputChange = (e) => {
    const value = e.target.value;
    setPpTemp(false);

    if (value === randomSearchCheatCode) {
      handleRandomSearch();
      return;
    }

    const escapedValue = value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
    escapedValue.length === 0 ? resetSearch() : handleSearch(escapedValue);
  };

  const mobile = useMediaQuery("(max-width: 770px)");

  const randomSearch = (e) => {
    setPpTemp(false);
    e.currentTarget.parentElement.previousElementSibling.value = "$random";
    handleRandomSearch();
  };

  return (
    <>
      <form className={classes.searchForm} onSubmit={(e) => e.preventDefault()}>
        {!mobile ? (
          <>
            <input
              style={{ maxWidth: "42%" }}
              onChange={debounce(handleInputChange, 500)}
              className={`${classes.searchBar}`}
              disabled={searchDisabled}
              placeholder="Search for memes"
            />

            <div
              style={{
                display: "flex",
                minWidth: "58%",
                justifyContent: "space-between",
              }}
            >
              <button
                style={{ maxWidth: "150px" }}
                onClick={randomSearch}
                className={classes.tabTitle}
              >
                Random
              </button>
              <button
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
                className={classes.tabTitle}
                style={{ maxWidth: "200px", marginRight: "15px" }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!ppTemp ? (
                    <img
                      style={{ width: "23px", marginRight: "5px" }}
                      src={trollface}
                      alt="Trollface"
                    />
                  ) : (
                    <img
                      style={{ width: "23px", marginRight: "5px" }}
                      src={redPenguin}
                      alt="penguin"
                    />
                  )}
                  <div>{`${ppTemp ? "See" : ""} All Templates`}</div>
                  <div
                    style={{
                      display: "flex",
                      maxWidth: "23px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    {Boolean(anchorEl) ? (
                      <MdOutlineExpandLess
                        style={{
                          fontSize: "20px",
                          marginLeft: "5px",
                        }}
                      />
                    ) : (
                      <MdOutlineExpandMore
                        style={{
                          fontSize: "20px",
                          marginLeft: "5px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </button>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <input
                style={{ maxWidth: "70%", marginRight: "15px" }}
                onChange={debounce(handleInputChange, 500)}
                className={`${classes.searchBar}`}
                disabled={searchDisabled}
                placeholder="Search for memes"
              />

              <div
                style={{
                  width: "40%",
                }}
              >
                <button
                  style={{ width: "120px" }}
                  onClick={randomSearch}
                  className={`${classes.tabTitle} ${classes.templateBtn}`}
                >
                  Random
                </button>
              </div>
            </div>
            <button
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
              className={`${classes.tabTitle} ${classes.templateBtn}`}
              style={{ maxWidth: "200px", marginRight: "15px" }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {!ppTemp ? (
                  <img
                    style={{ width: "23px", marginRight: "5px" }}
                    src={trollface}
                    alt="Trollface"
                  />
                ) : (
                  <img
                    style={{ width: "23px", marginRight: "5px" }}
                    src={redPenguin}
                    alt="penguin"
                  />
                )}
                <div>{`${ppTemp ? "See" : ""} All Templates`}</div>
                <div
                  style={{
                    display: "flex",
                    maxWidth: "23px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  {Boolean(anchorEl) ? (
                    <MdOutlineExpandLess
                      style={{
                        fontSize: "20px",
                        marginLeft: "5px",
                      }}
                    />
                  ) : (
                    <MdOutlineExpandMore
                      style={{
                        fontSize: "20px",
                        marginLeft: "5px",
                      }}
                    />
                  )}
                </div>
              </div>
            </button>
          </>
        )}
      </form>
      {anchorEl !== null && (
        <TemplatesMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          ppTemplates={ppTemp}
          setPpTemp={setPpTemp}
        />
      )}
    </>
  );
}

function MemeTemplate({ handleImageSelection, classes }, searchComponentRef) {
  const [templates, setTemplates] = useState([]);
  const [newTemplates, setNewTemplates] = useState(null);
  const [openPpTemp, setOpenPpTemp] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [Images, setImages] = useState([]);
  const searchDisabledRef = useRef(true);

  const { current: searchDisabled } = searchDisabledRef;

  useEffect(() => {
    if (openPpTemp) {
      const importAll = (r) => {
        let images = {};
        r.keys().map((item) => {
          images[item.replace("./", "")] = r(item);
        });
        return images;
      };
      let images;
      images = importAll(
        require.context(
          "../../../assets/pp_templates/new-pp-templates",
          false,
          /\.(jpg|jpeg|svg)$/
        )
      );
      setImages(images);
    }
  }, [openPpTemp]);

  useEffect(() => {
    const importAll = (r) => {
      let images = {};
      r.keys().map((item) => {
        images[item.replace("./", "")] = r(item);
      });
      return images;
    };
    let images;
    images = importAll(
      require.context("../../../assets/youmemeTemplates", false, /\.(svg)$/)
    );
    setTemplates(images);
    searchDisabledRef.current = false;
  }, []);

  const handleSearch = (value) => {
    const memeTemplateRegex = new RegExp(value, "i");
    const newTemps = Object.keys(templates).filter((temp) => {
      return memeTemplateRegex.test(temp);
    });

    setNewTemplates(newTemps);
  };

  const handleSearchReset = () => {
    setNewTemplates(null);
  };

  const handleRandomSelection = () => {
    const allTemplates = Object.keys(templates);

    // setTemplates([allTemplates[Math.floor(Math.random() * allTemplates.length)]])
    setNewTemplates(
      allTemplates
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
    );
  };

  const handleLoadEnd = (e) => {
    e.target.style.transitionDelay = "var(--meme-delay)";
    e.target.style.opacity = "1";
  };

  const handleTransitionEnd = (e) => {
    const targetElement = e.target;

    setTimeout(() => {
      targetElement.previousElementSibling.style.display = "none";
      targetElement.parentElement.style.backdropFilter = "none";
    }, 1000);
  };

  const mobile = useMediaQuery("(max-width: 770px)");
  const ppTemplates = [
    pudgy5,
    pudgy6,
    pudgy7,
    pudgy8,
    pudgy1,
    pudgy2,
    pudgy3,
    pudgy4,
  ];
  return (
    <div ref={searchComponentRef} className={`${classes.searchMemeWrapper}`}>
      <main>
        <MemeSearchInput
          classes={classes}
          searchDisabled={searchDisabled}
          handleSearch={handleSearch}
          resetSearch={handleSearchReset}
          handleRandomSearch={handleRandomSelection}
          setPpTemp={setOpenPpTemp}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          ppTemp={openPpTemp}
        />

        <div
          className="meme-grid-wrapper grid-display"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: mobile ? "center" : "flex-start",
          }}
          onClick={handleImageSelection}
        >
          {openPpTemp &&
            ppTemplates.map((img, index) => (
              <div
                className="template-wrapper"
                key={index}
                style={{ width: 230 }}
              >
                <div className="skeleton-main-color" />
                <img
                  onLoad={handleLoadEnd}
                  onClick={handleImageSelection}
                  onTransitionEnd={handleTransitionEnd}
                  src={img}
                  data-fullsize={img}
                  alt={"Pudgy Penguin"}
                />
                {index > 3 && index < 8 && (
                  <h4
                    onClick={handleImageSelection}
                    style={{
                      zIndex: 1000,
                      position: "absolute",
                      padding: "5px",
                      left: 15,
                      fontSize: "14px",
                      fontWeight: 600,
                      top: 0,
                    }}
                  >
                    {"Build your Pudgy Penguins using Pudgy stickers."}
                  </h4>
                )}
              </div>
            ))}
          {openPpTemp &&
            Images &&
            Object.keys(Images).map((imageData, index) => (
              <div
                className="template-wrapper"
                key={index}
                style={{ width: 230 }}
              >
                <div className="skeleton-main-color" />
                <img
                  onLoad={handleLoadEnd}
                  onClick={handleImageSelection}
                  onTransitionEnd={handleTransitionEnd}
                  src={Images[imageData]}
                  data-fullsize={Images[imageData]}
                  alt={"Pudgy Penguin"}
                />
              </div>
            ))}

          {!openPpTemp
            ? newTemplates === null
              ? Object.keys(templates).map((imageData, index) => (
                  <div
                    className="template-wrapper"
                    key={index}
                    style={{ width: 230 }}
                  >
                    <div className="skeleton-main-color" />
                    <img
                      onLoad={handleLoadEnd}
                      onClick={handleImageSelection}
                      onTransitionEnd={handleTransitionEnd}
                      src={templates[imageData]}
                      data-fullsize={templates[imageData]}
                      alt={""}
                    />
                  </div>
                ))
              : newTemplates?.map((imageData, index) => (
                  <div
                    className="template-wrapper"
                    key={index}
                    style={{ width: 230 }}
                  >
                    <div className="skeleton-main-color" />
                    <img
                      onLoad={handleLoadEnd}
                      onClick={handleImageSelection}
                      onTransitionEnd={handleTransitionEnd}
                      src={templates[imageData]}
                      data-fullsize={templates[imageData]}
                      alt={""}
                    />
                  </div>
                ))
            : null}
        </div>
      </main>

      <center>
        <Typography color="white" style={{ fontSize: "0.875em" }}>
          Scroll down to see more
        </Typography>
      </center>
    </div>
  );
}

const MemeTemplatesPopUp = forwardRef(MemeTemplate);

export default MemeTemplatesPopUp;
