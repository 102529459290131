import { Box, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import MaterialIcon from "material-icons-react";
import { useRef, useState } from "react";
import { getAssetsPath } from "../../../utils/path";
import { sendImageToMemeEditor } from ".";
import errorConfig from "../config/errorConfig.json";
import imageConfig from "../config/imageConfig.json";
import MemeTemplatesPopUp from "./MemeTemplate";
import { imagesSvg } from "../../../constants/images";
import { colors } from "../../../constants/colors";

const useStyles = makeStyles({
  root: {
    fontSize: "1rem",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1.4375em",
    flexDirection: "row",
    letterSpacing: "-0.3px",
    width: "100%",
    height: "87.213%",

    "& > button": {
      display: "none",
    },
  },

  switchButton: {
    order: -1,
    padding: ".7em",
    marginBottom: "2em",
    borderRadius: 7,
    border: "none",
    background: "var(--main-gradient)",
    color: "white",
    width: "100%",
  },

  dragwrapper: {
    background: "transparent",
    boxSizing: "border-box",
    borderRadius: 5,
    flexBasis: "56.02%",
    height: "56.02%",
    fontSize: "1em",
    marginBottom: 12,
    border: "2px dashed #48525F",
    textAlign: "center",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    opacity: 0,
    animation: "$appear 200ms 500ms ease both",
  },

  dragged: {
    backgroundColor: "#f0f8ff",
  },
  uploadBtn: {
    width: 209,
    height: 44,
    background: "#791EF0",
    borderRadius: 5,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: 14,
    lineHeight: "17px",
    color: "#FFFFFF",
    marginTop: 175,
    marginBottom: 6,
  },
  dragTxtOne: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "0.75em",
    lineHeight: "1.21em",
    color: "#808893",
  },
  dragTxtTwo: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "17px",
    color: "#FFFFFF",
    paddingTop: 6,
  },

  dragTitle: {
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "1.5em",
    lineHeight: "1.21em",
    marginBottom: "0.291em",
    pointerEvents: "none",
  },

  tabBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
    gap: 10,
  },
  tabItem: {
    padding: 10,
    height: "calc((100% / 4) - 10px)",
    background: colors.darkBlue50,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden !important",
    boxSizing: "border-box",
    textAlign: "justify",
    cursor: "pointer",
    transition: "transform 300ms ease, opacity 200ms ease",
    position: "relative",
    fontSize: "0.875em",
    animation:
      "$riseUp var(--delay) 200ms ease-in-out both running, gradient 10s ease infinite paused",

    "& > i": {
      fontSize: "1.5em !important",
      color: "inherit",
      opacity: 0,
      transform: "translateX(-1em)",
      transition: "opacity 300ms ease, transform 300ms ease-in",
    },

    "& > p": {
      transition: "transform 400ms ease-out",
      transform: "translateX(0em)",
      fontSize: "1em",
    },

    "&:hover": {
      background: "var(--main-gradient)",
      backgroundSize: "400% 400%",
      animationPlayState: "paused, running",
    },

    "&:hover i": {
      transitionDuration: "100ms, 150ms",
      transitionTimingFunction: "ease, ease-out",
      opacity: 1,
      transform: "translateX(0)",
    },

    "&:hover p": {
      transitionDuration: "150ms",
      transform: "translateX(1em)",
    },
  },

  "@keyframes riseUp": {
    from: {
      opacity: 0,
      transform: "translateY(3em)",
    },

    to: {
      opacity: 1,
      transform: "translateY(0)",
    },
  },

  tabTitle: {
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "1.5em",
    marginRight: "1.5em",
  },
  tabDesc: {
    fontStyle: "normal",
    fontWeight: 500,
    color: "#546277",
  },
  otherOptionsWrapper: {
    height: "100%",
    flexBasis: "23.404%",
    width: "23.404%",
    display: "flex",
    flexDirection: "column",
  },
  searchMemeWrapper: {
    display: "flex",
    flexBasis: "73.11%",
    flexDirection: "column",

    "& main": {
      width: "100%",
      height: "91.5%",

      "& > h5": {
        margin: "23px 0 12px 0",
        fontSize: "1em",
        lineHeight: "1.5em",
      },

      "& > div": {
        height: "88%",
        paddingRight: "1.2em",
        paddingTop: "1em",
        paddingLeft: ".5em",
        placeItems: "baseline",

        "&::-webkit-scrollbar": {
          width: 6,
          borderRadius: 20,
          backgroundColor: "#0E1017",
        },

        "&::-webkit-scrollbar-thumb": {
          background: "var(--main-gradient)",
          borderRadius: 20,
        },

        "& .template-wrapper": {
          "&:only-child": {
            width: "233px",
          },

          width: "100%",
          height: 200,
          transition: "outline-width 100ms ease",
          outline: "0px solid var(--gradient-fall-back)",
          position: "relative",
          overflow: "hidden !important",
          backdropFilter: "brightness(78%)",
          borderRadius: 10,

          "&:hover": {
            outlineWidth: 3,
          },

          "& img": {
            zIndex: 2,
            width: "100%",
            height: "100%",
            borderRadius: "inherit",
            cursor: "pointer",
            opacity: 0,
            transition: "opacity 300ms",
          },
        },
      },
    },
  },

  "@keyframes appear": {
    to: {
      opacity: 1,
    },
  },

  searchForm: {
    width: "calc(100% - 1.2em)",
    height: "3.143em",
    fontSize: "0.875em",
    borderRadius: 7,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "1em",
  },

  templateBtn: {
    width: "100%",
    height: "100%",
    textAlign: "center",
    background: colors.darkBlue50,
    padding: ".75em",
    color: "white",
    border: "none",
    borderRadius: "inherit",
    backdropFilter: "brightness(80%)",
    position: "relative",
    zIndex: 1,
    margin: 0,
    transition: "background 200ms, color 150ms",
  },

  searchBar: {
    fontSize: "1em",
    height: "100%",
    borderRadius: "inherit",
    background: colors.darkBlue50,
    paddingLeft: "calc(1.18em + 24.5px)",
    color: "#919AA5",
    fontWeight: 600,
    backgroundPosition: "1.18em center",
    lineHeight: "1.714em",
    backgroundImage: `url(${getAssetsPath(
      `checkIcons/${imagesSvg.lookingGlass}`
    )}))`,
    backgroundRepeat: "no-repeat",
    border: "0px solid var(--gradient-fall-back)",
    transition: "border-width 100ms ease, filter 100ms",
    boxSizing: "border-box",
    flexBasis: "80%",

    "& + div": {
      flexBasis: "20%",
      borderRadius: "inherit",
      width: "100%",

      "& > button": {
        width: "100%",
        height: "100%",
        textAlign: "center",
        background: colors.darkBlue50,
        padding: ".75em",
        color: "white",
        border: "none",
        borderRadius: "inherit",
        backdropFilter: "brightness(80%)",
        position: "relative",
        zIndex: 1,
        margin: 0,
        transition: "background 200ms, color 150ms",

        "& i": {
          fontSize: "1.7em !important",
          position: "absolute",
          zIndex: -1,
          opacity: 0,
          transform: "translateY(100%)",
          transition: "transform 200ms ease-in, opacity 150ms",
        },

        "&:disabled": {
          filter: "brightness(40%)",
        },
      },

      "&:disabled": {
        filter: "brightness(40%)",
      },

      "&:focus": {
        borderWidth: "5px",
        border: "5px solid var(--gradient-fall-back)",
        borderImage: "var(--main-gradient) 5",
        color: "#fff",
      },
    },
  },

  "@media (max-width: 1160px)": {
    otherOptionsWrapper: {
      fontSize: ".8em",
    },
    tabTitle: {
      marginRight: "2em",
    },

    uploadBtn: {
      marginTop: 87,
      height: 40,
    },

    dragTxtTwo: {
      fontSize: 10,
      lineHeight: "12px",
    },
  },
  "@media (max-width: 760px)": {
    root: {
      flexDirection: "column",
      justifyContent: "unset",

      "& > button": {
        display: "unset",
      },
    },

    otherOptionsWrapper: {
      fontSize: "2.5vmax",
      flexGrow: 1,
      width: "100%",
    },

    searchForm: {
      flexDirection: "column",
      height: "100px",
      width: "100%",
    },
    searchBar: {
      width: "100%",
    },

    dragwrapper: {
      flexBasis: "41%",
    },
    "@media (max-width: 370px)": {
      searchForm: {
        flexDirection: "column",
        height: "100px",
      },
      searchBar: {
        width: "100%",
      },
    },

    searchMemeWrapper: {
      "& > main,  & .meme-grid-wrapper": {
        height: "unset",
        paddingRight: "0.6em",
      },
    },
  },
  "@media (max-width: 550px)": {
    dragwrapper: {
      display: "none",
    },
  },
});

const FileUpload = (props) => {
  const classes = useStyles();
  const searchComponentRef = useRef();
  const otherComponentsRef = useRef();

  const [filesDragged, setFilesDragged] = useState(false);
  const { isOpen, navigate, handleTab } = props;
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");
  // const isTablet = useWindowSize().width < 768

  if (!isOpen) return null;

  const showError = (msg) => {
    setErr(true);
    setMsg(msg);
    setTimeout(() => setErr(false), 1500);
  };

  const handleSwitch = () => {
    searchComponentRef.current.classList.toggle("hide-on-mobile");
    otherComponentsRef.current.classList.toggle("hide-on-mobile");
  };

  // For getting base64 data for file upload
  const getBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };

    reader.onerror = function (error) {
      console.log(error);
    };
  };

  // For choose file
  const openFileChooser = (e) => {
    document.getElementById("imgInput").click();
  };
  const getImage = (event) => {
    processImage(event.target.files[0]);
  };
  // For checking the resolution of the uploaded image
  const checkResolution = (data) => {
    return new Promise((resolve, reject) => {
      try {
        const img = new Image();
        img.src = data;
        img.onload = () => {
          const { naturalWidth, naturalHeight } = img;
          if (
            naturalHeight > imageConfig.MIN_HEIGHT &&
            naturalWidth > imageConfig.MIN_WIDTH
          ) {
            resolve({ width: naturalWidth, height: naturalHeight });
          } else {
            showError(errorConfig.RESOLUTION_ERROR_MESSGAE);
          }
        };
        img.onerror = (e) => {
          showError("Failed to load image");
        };
      } catch (err) {
        reject(err);
      }
    });
  };

  const processImage = (imgFile) => {
    if (imgFile) {
      document.getElementById("imgInput").value = null;
      const typeInfo = imgFile.type.split("/");
      if (typeInfo[0] !== "image") {
        return;
      }
      if (imageConfig.ALLOWED_TYPES.indexOf(typeInfo[1].toUpperCase()) === -1) {
        showError(errorConfig.FORMAT_ERROR_MESSAGE);
        return;
      }
      if (imgFile.size > imageConfig.MAX_SIZE) {
        showError(errorConfig.SIZE_ERROR_MESSAGE);
        return;
      }
      getBase64(imgFile, (imgData) => {
        checkResolution(imgData)
          .then((res) => {
            if (imgData) {
              const a = {
                data: imgData,
                imageResolution: res,
                type: "notSvg",
              };
              navigate(a);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  const dragListener = (event) => {
    event.preventDefault();
    setFilesDragged(false);
    const files = event.dataTransfer.files;
    if (files.length === 1) {
      processImage(files[0]);
    } else {
      showError("Invalid Drop");
    }
  };

  const processSuggestedTemplateSelection = async (url) => {
    if (!url) return;
    try {
      const imageData = await sendImageToMemeEditor(url);
      navigate(imageData);
    } catch (error) {
      showError("Failed to load image");
    }
  };

  const handleImageSelection = async (e) => {
    const fileURL = e.target.getAttribute("data-fullsize");
    await processSuggestedTemplateSelection(fileURL);
  };

  return (
    <Box className={`${classes.root} index-pop-up`}>
      <MemeTemplatesPopUp
        classes={classes}
        handleImageSelection={handleImageSelection}
        ref={searchComponentRef}
      />

      <div
        ref={otherComponentsRef}
        className={`hide-on-mobile ${classes.otherOptionsWrapper}`}
      >
        <Box
          className={
            filesDragged
              ? `${classes.dragwrapper} ${classes.dragged}`
              : classes.dragwrapper
          }
          onDrop={dragListener}
          onDragLeave={() => setFilesDragged(false)}
          onDragEnter={() => setFilesDragged(true)}
          onDragOver={(e) => {
            e.preventDefault();
          }}
        >
          <Box
            style={{
              zIndex: 999,
              position: "absolute",
              background: "red",
              width: "100%",
              height: "100%",
              display: err === true ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 5,
            }}
          >
            <Alert severity="error">{err === true && msg}</Alert>
          </Box>
          <input
            type="file"
            accept="image/*"
            id="imgInput"
            onChange={getImage}
            style={{ display: "none" }}
          />
          <Typography className={`${classes.dragTitle} text-title`}>
            Drag & Drop
          </Typography>
          <Typography className={classes.dragTxtOne}>
            Your media in this area
          </Typography>
        </Box>
        <Box className={classes.tabBox}>
          <Box
            className={classes.tabItem}
            style={{ "--delay": ".1s" }}
            onClick={openFileChooser}
          >
            <MaterialIcon color="#fff" size="inherit" icon="perm_media" />
            <Typography className={classes.tabTitle}>Upload Image</Typography>
          </Box>

          <Box
            className={classes.tabItem}
            style={{ "--delay": ".2s" }}
            onClick={() => handleTab(1)}
          >
            <MaterialIcon color="#fff" size="inherit" icon="radar" />
            <Typography className={classes.tabTitle}>Search</Typography>
          </Box>
          <Box
            className={classes.tabItem}
            style={{ "--delay": ".3s" }}
            onClick={() => handleTab(2)}
          >
            <MaterialIcon color="#fff" size="inherit" icon="camera" />
            <Typography className={classes.tabTitle}>Camera</Typography>
          </Box>
          <Box
            className={classes.tabItem}
            style={{ "--delay": ".4s" }}
            onClick={() => handleTab(3)}
          >
            <MaterialIcon color="#fff" size="inherit" icon="add_link" />
            <Typography className={classes.tabTitle}>Url address</Typography>
          </Box>
        </Box>
      </div>
      <button
        className={`${classes.switchButton} ${classes.tabTitle}`}
        onClick={handleSwitch}
      >
        Other Options
      </button>
    </Box>
  );
};

export default FileUpload;
