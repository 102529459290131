import { Box, Hidden, InputBase, Typography } from "@material-ui/core";
import MaterialIcon from "material-icons-react";
import { memo, useState } from "react";
import { getImageBase64FromUrl } from "../../../utils/utils";
import { getStickerImageURL } from "../../../services/imageService";
import imageList from "../config/imageList.json";

function StickersInteraction(props) {
  const [filteredList, setFilteredList] = useState(imageList);
  const searchSticker = (searchString) => {
    if (searchString === "") {
      setFilteredList(imageList);
    } else {
      const tempList = imageList.filter((imageData) => {
        return (
          imageData.displayName
            .toLowerCase()
            .indexOf(searchString.toLowerCase().trim()) > -1
        );
      });
      setFilteredList(tempList);
    }
  };
  const addSticker = (stickerPath, displayName) => {
    getImageBase64FromUrl(stickerPath)
      .then(
        (base64data) => {
          getResolution(base64data)
            .then(
              (imageRes) => {
                props.newLayeredImg(base64data, imageRes, displayName);
              },
              (err) => {
                console.log(err);
              }
            )
            .catch((err) => console.log(err));
        },
        (err) => console.log(err)
      )
      .catch((err) => console.log(err));
  };

  const getResolution = (data) => {
    return new Promise((resolve, reject) => {
      try {
        const img = new Image();
        img.src = data;
        img.onload = () => {
          const { naturalWidth, naturalHeight } = img;
          resolve({ width: naturalWidth, height: naturalHeight });
        };
      } catch (err) {
        reject(err);
      }
    });
  };
  return (
    <>
      <Hidden mdDown>
        <div
          className="colDivFS"
          style={{ overflowX: "hidden", overflowY: "hidden" }}
        >
          <input
            className="searchbarInput"
            placeholder="Search Stickers..."
            onChange={(event) => searchSticker(event.target.value)}
            type="url"
          />
          <div
            style={{
              minHeight: "unset",
              width: "100%",
              justifyContent: "space-between",
              marginTop: "15px",
              marginLeft: "0px",
            }}
            className="filterImgWrapper"
          >
            {filteredList.map((imageData, index) => {
              return (
                <div
                  key={index}
                  className="filtercard marginTop15px stickerCard"
                  onClick={() => {
                    addSticker(
                      getStickerImageURL(imageData.path),
                      imageData.displayName
                    );
                  }}
                  style={{ backgroundColor: "#2B374F" }}
                >
                  <h6
                    className="noMargin centerAlignText"
                    style={{
                      fontSize: "13px",
                      fontWeight: "900",
                      fontFamily: "Inter",
                      color: "#A7A7BC",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    {imageData.displayName}
                  </h6>
                  <img
                    src={getStickerImageURL(imageData.path)}
                    style={{
                      width: "80%",
                    }}
                    alt="sticker"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </Hidden>
      <Hidden lgUp>
        <div
          className="cryptoMobile"
          style={{ overflowX: "hidden", overflowY: "hidden" }}
        >
          <InputBase
            placeholder="Search Stickers..."
            startAdornment={<MaterialIcon icon="search" color="#6C7B93" />}
            onChange={(event) => searchSticker(event.target.value)}
            className="moblieSearchInput"
          />
          <Typography className="cryptoTxt">Cryptocurrency</Typography>
          <Box className="stickerContainer">
            <div
              style={{
                minHeight: "unset",
                width: "100%",
                justifyContent: "space-between",
                marginTop: "20px",
                display: "grid",
                gridTemplateRows: "repeat(2, minmax(0,1fr))",
                gridAutoFlow: "column",
              }}
              className="filterImgWrapperMobile"
            >
              {filteredList.map((imageData, index) => {
                return (
                  <div
                    key={index}
                    className="filtercard marginTop15px stickerCardMobile"
                    onClick={() => {
                      addSticker(
                        getStickerImageURL(imageData.path),
                        imageData.displayName
                      );
                    }}
                  >
                    <img
                      src={getStickerImageURL(imageData.path)}
                      width="70px"
                      alt="sticker"
                    />
                  </div>
                );
              })}
            </div>
          </Box>
        </div>
      </Hidden>
    </>
  );
}
export default StickersInteraction;

export const StickersInteractionMemo = memo(
  StickersInteraction,
  (prevProps, nextProps) => {
    if (
      prevProps.defImageResolution !== nextProps.defImageResolution ||
      prevProps.layeredImageResolution !== nextProps.layeredImageResolution ||
      prevProps.layeredImagePosition !== nextProps.layeredImagePosition ||
      prevProps.layeredImage !== nextProps.layeredImage ||
      prevProps.scaledLayeredImage !== nextProps.scaledLayeredImage ||
      prevProps.rotationScaleL !== nextProps.rotationScaleL
    ) {
      return false;
    }
    return true;
  }
);
