import { Box, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import InfiniteLooper from "./InfiniteLooper";

const useStyles = makeStyles({
  sliderContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default function HeaderSlider(props: any) {
  const classes = useStyles();

  const topbar: boolean = props.topbar;

  return (
    <>
      <Box className={classes.sliderContainer}>
        <Box
          className="pencil-bar marquee gradiant-marquee"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <InfiniteLooper speed={9} direction="left">
            <Box className="inner" data-dur="28">
              <Typography className="group">
                <span className="group">
                  <span>
                    Our marketplace with Origin Protocol has launched!
                  </span>
                  <span className="emoji">{topbar ? "🚀" : "🐧"}</span>
                </span>
              </Typography>
            </Box>
          </InfiniteLooper>
        </Box>
      </Box>
    </>
  );
}
