import { memo, useEffect, useState } from "react";
import { getImageBase64FromUrl } from "../../../utils/utils";

function PPStickers(props) {
  const [images, setImages] = useState(null);

  useEffect(() => {
    const importAll = (r) => {
      let images = {};
      r.keys().map((item, index) => {
        images[item.replace("./", "")] = r(item);
      });
      return images;
    };
    let images;
    images =
      props.ppPart === "Body"
        ? importAll(
            require.context(
              "../../../assets/images/pp_stickers/Body",
              false,
              /\.(png)$/
            )
          )
        : props.ppPart === "Face"
        ? importAll(
            require.context(
              "../../../assets/images/pp_stickers/Face",
              false,
              /\.(png)$/
            )
          )
        : props.ppPart === "Head"
        ? importAll(
            require.context(
              "../../../assets/images/pp_stickers/Head",
              false,
              /\.(png)$/
            )
          )
        : props.ppPart === "More"
        ? importAll(
            require.context(
              "../../../assets/images/pp_stickers/More",
              false,
              /\.(png)$/
            )
          )
        : importAll(
            require.context(
              "../../../assets/images/pp_stickers/Skin",
              false,
              /\.(png)$/
            )
          );

    setImages(images);
  }, [props.ppPart]);

  const addSticker = (stickerPath, displayName) => {
    getImageBase64FromUrl(stickerPath)
      .then(
        (base64data) => {
          getResolution(base64data)
            .then(
              (imageRes) => {
                props.newLayeredImg(base64data, imageRes, displayName);
              },
              (err) => {
                console.log(err);
              }
            )
            .catch((err) => console.log(err));
        },
        (err) => console.log(err)
      )
      .catch((err) => console.log(err));
  };

  const getResolution = (data) => {
    return new Promise((resolve, reject) => {
      try {
        const img = new Image();
        img.src = data;
        img.onload = () => {
          const { naturalWidth, naturalHeight } = img;
          resolve({ width: naturalWidth, height: naturalHeight });
        };
      } catch (err) {
        reject(err);
      }
    });
  };
  return (
    <>
      <div
        style={{
          minHeight: "unset",
          width: props.mobile ? "100%" : "90%",
          justifyContent: "flex-start",
          marginTop: "10px",
        }}
        className="ppfilterImgWrapper"
      >
        {images &&
          Object.keys(images).map((imageData, index) => {
            return (
              <div
                key={index}
                className="filtercard stickerCard"
                onClick={() => {
                  addSticker(images[imageData]);
                }}
                style={{
                  backgroundColor: "#2B374F",
                  height: "69px",
                  minWidth: "102px",
                  maxWidth: "102px",
                  flexShrink: 0,
                  marginRight: "5px",
                }}
              >
                <img
                  style={{
                    marginTop:
                      props.ppPart !== "Head"
                        ? props.ppPart !== "Skin"
                          ? props.ppPart === "More"
                            ? "5px"
                            : "-15px"
                          : 0
                        : "5px",
                    width: "62%",
                  }}
                  src={images[imageData]}
                  alt="sticker"
                />
              </div>
            );
          })}
      </div>
    </>
  );
}
export default PPStickers;

export const PPStickersMemo = memo(PPStickers, (prevProps, nextProps) => {
  if (
    prevProps.defImageResolution !== nextProps.defImageResolution ||
    prevProps.layeredImageResolution !== nextProps.layeredImageResolution ||
    prevProps.layeredImagePosition !== nextProps.layeredImagePosition ||
    prevProps.layeredImage !== nextProps.layeredImage ||
    prevProps.scaledLayeredImage !== nextProps.scaledLayeredImage ||
    prevProps.rotationScaleL !== nextProps.rotationScaleL
  ) {
    return false;
  }
  return true;
});
