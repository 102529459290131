export const randomSearchCheatCode: string = '$random'

export const USER_DATA_LOCALSTORAGE_KEY: string = 'user'

export const COMING_FROM_SESSION_STORAGE_KEY: string = 'from'

export const USER_BOOKMARKS_KEY: string = 'user_bookmarks'

export const restcountriesUrl: string =
  'https://restcountries.com/v3.1/all?fields=name'

export const USER_REACTIONS_KEY: string = 'user_reactions'

export const USER_PROFILE_KEY: string = 'user_profile'

export const emptyUsername: string = 'Username is empty'

export const wrongFormat: string = 'Wrong format'

export const emptyDisplayName: string = 'Displayed name is empty'

export const placeImg: string = 'http://placeimg.com/640/480'

export const USER_PROFILE_TTL: number = 120

export const USER_DATA_TTL: number = 600

export const USERNAME_CHAR_LIMIT: number = 15

const jwtRegexFunc = (): string | RegExp => {
  return /^([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_\-\+\/=]*)/
}

export const inputRegex: RegExp = /^\w+$/

export const jwtRegex: RegExp = new RegExp(jwtRegexFunc())

export const removeSpacesRegex: RegExp = /\s+/g

export const removeLineBreaks: RegExp = /\n\s*\n/g

export const siteRegex: RegExp =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

export const validURLRegex: RegExp = new RegExp(
  '^(https?:\\/\\/)?' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$',
  'i'
)
