import HeaderSlider from "./layout/HeaderSlider";
import { Box } from "@mui/material";
import HeaderNav from "./layout/HeaderNav";
import Banner from "./layout/Banner";
import Footer from "./layout/Footer";
import MobileMenu from "./MobileMenu/MobileMenu";

export default function HomePage() {
  return (
    <>
      <Box>
        <HeaderSlider topbar={true} />
        <HeaderNav />
        <Banner />
        <Footer />
        <MobileMenu />
      </Box>
    </>
  );
}
