import { useEffect, useState } from "react";
import { Alert } from "@material-ui/lab";
import ImageUpload from "./meme-generator/imageUpload";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import ImageEdit from "./meme-generator/imageEdit/imageedit";
import { Provider as AlertProvider } from "react-alert";
import { Dialog } from "@material-ui/core";
import AlertDialog from "./meme-generator/AlertDialog";

const options: object = {
  position: "bottom center",
  timeout: 5000,
  offset: "30px",
  transition: "scale",
};

const useStyles = makeStyles({
  paper: {
    fontFamily: "fancy",
    padding: "0px 0px",
    maxWidth: 1504,
    borderRadius: 10,
    maxHeight: 917,
    background: "transparent",
    boxShadow: "none",
    alignItems: "center",
    margin: 0,
    overflow: "visible",
    "@media (max-width: 760px)": {
      margin: 0,
      overflow: "initial",
    },
  },
  background: {
    background: "rgba(0,0,0,0.8)",
  },
  widthSm: {
    maxWidth: "fit-content",
    backgroundColor: "transparent",
    margin: 0,
  },
  container: {
    height: "auto",
  },
});

export interface PublishMemeModalProp {
  open: boolean;
  imageData?: any;
  flag?: any;
  onClose: (value: boolean) => void;
  onParentClose?: boolean;
}

const PublishMemeModal = ({
  open,
  flag,
  onClose,
  onParentClose,
  imageData,
}: PublishMemeModalProp): JSX.Element => {
  const classes = useStyles();

  const [sourceImg, setSourceImg] = useState(null);
  const [isAlert, setAlert] = useState(false);
  const [meme, setMeme] = useState(false);
  const navigate = useNavigate();

  console.log(sourceImg);
  const handleClose = (
    isEdited: boolean = false,
    isRedirected: boolean = false
  ): any => {
    if (sourceImg !== null) {
      if (isEdited) {
        setAlert(true);
      } else {
        onClose(false);
        setSourceImg(null);
      }
    } else {
      onClose(false);
      setSourceImg(null);
    }
    if (isRedirected) {
      navigate("/auth");
    }
  };

  useEffect(() => {
    if (meme) {
      onClose(false);
      setSourceImg(null);
      setAlert(false);
      setMeme(false);
    }
  }, [isAlert, meme, onClose]);

  if (imageData) {
    return (
      <AlertProvider template={Alert} {...options}>
        <ImageEdit
          flag={flag}
          sourceImg={imageData}
          handleCloseModal={handleClose}
          parentClose={onParentClose}
        />
        <AlertDialog
          open={isAlert}
          handleAlert={setAlert}
          handleMeme={setMeme}
        />
      </AlertProvider>
    );
  }
  return (
    <Dialog
      onClose={() => handleClose}
      open={open}
      classes={{
        paper: classes.paper,
        container: sourceImg ? classes.container : "",
      }}
      BackdropProps={{
        classes: {
          root: classes.background,
        },
      }}
    >
      <AlertProvider template={Alert} {...options}>
        {sourceImg ? (
          <ImageEdit sourceImg={sourceImg} handleCloseModal={handleClose} />
        ) : (
          <>
            <ImageUpload navigateToPage={setSourceImg} onClose={handleClose} />
          </>
        )}
        <AlertDialog
          open={isAlert}
          handleAlert={setAlert}
          handleMeme={setMeme}
        />
      </AlertProvider>
    </Dialog>
  );
};

export default PublishMemeModal;
