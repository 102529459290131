import { Box, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MaterialIcon from "material-icons-react";
import { memo, useRef, useState } from "react";
import { ChromePicker } from "react-color";
import { colors } from "../../../constants/colors";

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#cbcbcb"),
    backgroundColor: "#cbcbcb",
    "&:hover": {
      backgroundColor: "#bbbbbb",
    },
  },
}))(Button);
const spacingDefaultValues = [0, 5, 10, 15];
const defaultColors = [
  ["#D54177", "Pink"],
  ["#EF895B", "Orange"],
  ["#F0CD4E", " Yellow"],
  ["#5094CF", "Blue"],
  ["#8545C1", "Purple"],
  ["#FFFFFF", "White"],
  ["#000000", " Black"],
];
const useStyles = makeStyles(() => ({
  iconBtn: {
    width: 48,
    height: 28,
    background: colors.darkBlue,
    borderRadius: "7px",
    color: "#ADB3C0",
    margin: "2px 0px",
  },
  spacingbtn: {
    width: 64,
    height: 50,
    background: colors.darkBlue,
    borderRadius: "7px !important",
    border: 0,
    color: "#6C7B93",
    "&:hover": {
      backgroundColor: colors.darkBlueActive,
    },
    margin: "auto",
  },

  rotationBtn: {
    width: 60,
    height: 60,
    background: colors.darkBlue,
    borderRadius: "5px",
    color: "#6C7B93",
    margin: "auto",
    marginLeft: 3,
    marginRight: 3,
  },
  flipBtn: {
    width: 99,
    height: 60,
    background: colors.darkBlue,
    borderRadius: "5px",
    color: "#6C7B93",
    margin: "auto",
    marginRight: 5,
  },
}));
export default function EffectsTransform(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorRef, setAnchorRef] = useState("");
  const timeout = useRef(undefined);
  const handleClick = (event, aRef) => {
    setAnchorEl(event.currentTarget);
    setAnchorRef(aRef);
  };
  const classes = useStyles();
  // Close Popover Element
  const handleClose = () => {
    setAnchorEl(null);
  };
  const throttledSpacingColorUpdate = (hex) => {
    if (timeout.current !== undefined) {
      return;
    }
    timeout.current = setTimeout(() => {
      props.setSpaceColorV(hex);
      timeout.current = undefined;
    }, 32);
  };
  return (
    <>
      <div className="cardDivCE">
        <Box className="transformItem">
          <Typography className="transformTitle" color="textSecondary">
            SPACING
          </Typography>
          <Typography className="transformSummary" color="textSecondary">
            Add spacing top and bottom
          </Typography>
        </Box>
        <div className="rowDivSE">
          <ColorButton
            variant="contained"
            size="small"
            className="generalBtn"
            title="Change spacing"
            onClick={(e) => handleClick(e, "SpaceListPop")}
          >
            {props.spaceV}%
          </ColorButton>
          <Popover
            id="SpaceListPop"
            open={Boolean(anchorEl) && anchorRef === "SpaceListPop"}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <ButtonGroup
              orientation="vertical"
              variant="outlined"
              size="small"
              alignItems="center"
            >
              {spacingDefaultValues.map((value, index) => {
                return (
                  <Button
                    key={index}
                    onClick={() => {
                      props.setSpaceV(value);
                      handleClose();
                    }}
                    title={"Set spacing to " + value + "%"}
                    className={classes.spacingbtn}
                  >
                    {value + ""}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Popover>
          <div className="columnDivSE">
            <IconButton
              size="small"
              title="Toggle top spacing"
              className={classes.iconBtn}
              onClick={() => props.handleTopToggle()}
            >
              <MaterialIcon
                icon="expand_less"
                color={props.spaceBotEnableV === 1 ? "#3fa9f5" : "#6C7B93"}
              />
            </IconButton>
            <IconButton
              size="small"
              title="Toggle bottom spacing"
              className={classes.iconBtn}
              onClick={() => props.handleBotToggle()}
            >
              <MaterialIcon
                icon="expand_more"
                color={props.spaceBotEnableV === 1 ? "#3fa9f5" : "#6C7B93"}
              />
            </IconButton>
          </div>
          <div
            className="transformcolorBtn"
            title="Spacing color"
            onClick={(e) => handleClick(e, "SpaceColorPop")}
          >
            <div
              style={{
                width: "72px",
                height: "60px",
                borderRadius: "7px",
                backgroundColor: props.spaceColorV,
              }}
            />
          </div>
          <Popover
            id="SpaceColorPop"
            open={Boolean(anchorEl) && anchorRef === "SpaceColorPop"}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className="colorPalletDiv">
              <ChromePicker
                color={props.spaceColorV}
                onChange={(color) => {
                  throttledSpacingColorUpdate(color.hex);
                }}
                disableAlpha
              />
              <Typography className="colortext">
                Here are some suggested colors
              </Typography>
              <div className="palletColorRoot">
                {defaultColors.map((color, i) => {
                  return (
                    <div
                      className="color_selector"
                      key={"SpaceIcon" + i}
                      title={color[1]}
                      style={{
                        background: color[0],
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        props.setSpaceColorV(color[0]);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <div className="cardDivCE">
        <Box className="transformItem">
          <Typography className="transformTitle" color="textSecondary">
            ROTATION
          </Typography>
          <Typography className="transformSummary" color="textSecondary">
            Rotate your image
          </Typography>
        </Box>
        <div className="rowDivSE">
          <input
            type="number"
            max="180"
            min="-180"
            style={{
              alignSelf: "center",
              color: "#6C7B93",
              textAlign: "center",
              marginRight: 3,
              border: 0,
              width: "72px",
              height: "60px",
              background: colors.darkBlue,
              borderRadius: "5px",
              fontSize: 18,
              fontWeight: 600,
            }}
            onChange={(event) => props.rotationValueChange(event.target.value)}
            placeholder="0"
            value={props.rotationV}
          />
          <IconButton
            size="small"
            title="Rotate 90 &deg; counterclockwise"
            onClick={() => props.rotate("right")}
            className={classes.rotationBtn}
          >
            <MaterialIcon icon="rotate_right" color="#6C7B93" />
          </IconButton>
          <IconButton
            size="small"
            title="Rotate 90 &deg; clockwise"
            className={classes.rotationBtn}
            onClick={() => props.rotate("left")}
          >
            <MaterialIcon icon="rotate_left" color="#6C7B93" />
          </IconButton>
        </div>
      </div>
      <div className="cardDivCE">
        <Box className="transformItem">
          <Typography className="transformTitle" color="textSecondary">
            FLIP
          </Typography>
          <Typography className="transformSummary" color="textSecondary">
            Flip your image
          </Typography>
        </Box>
        <div className="rowDivSE">
          <IconButton
            size="medium"
            title="Flip horizontally"
            onClick={() => props.handleFlipHToggle()}
            className={classes.flipBtn}
          >
            <MaterialIcon icon="flip" color="#6C7B93" />
          </IconButton>
          <IconButton
            size="medium"
            title="Flip horizontally"
            onClick={() => props.handleFlipVToggle()}
            className={classes.flipBtn}
          >
            <MaterialIcon icon="flip" color="#6C7B93" />
          </IconButton>
        </div>
      </div>
    </>
  );
}
export const EffectsTransformMemo = memo(
  EffectsTransform,
  (prevProps, nextProps) => {
    if (
      prevProps.spaceV !== nextProps.spaceV ||
      prevProps.rotationV !== nextProps.rotationV ||
      prevProps.spaceTopEnableV !== nextProps.spaceTopEnableV ||
      prevProps.spaceBotEnableV !== nextProps.spaceBotEnableV ||
      prevProps.spaceColorV !== nextProps.spaceColorV ||
      prevProps.flipHV !== nextProps.flipHV ||
      prevProps.flipVV !== nextProps.flipVV
    ) {
      return false;
    }
    return true;
  }
);
