import { Box, Button, InputBase, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import Alert from "@material-ui/lab/Alert";
import ReactLoading from "react-loading";
import { getImageBase64FromUrl } from "../../../utils/utils";
import errorConfig from "../config/errorConfig.json";
import imageConfig from "../config/imageConfig.json";

const CORS_API_URL = "https://cors-anywhere-01061993.herokuapp.com/";
const useStyles = makeStyles({
  urlImageTxt: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: "24px",
    marginBottom: 14,
    color: "#C2C9D4",
  },
  searchInput: {
    width: "100%",
    height: 45,
    borderRadius: 5,
    backgroundColor: "#1A1E28",
    fontSize: 14,
    padding: "0 15px",
    color: "white",
    "& input": {
      "&::placeholder": {
        color: "#546277",
        opacity: 1,
      },
    },
  },
  selectBtn: {
    color: "white",
    backgroundColor: "var(--gradient-fall-back)",
    borderRadius: 5,
    height: 44,
    width: "100%",
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontWeight: "bold",
    justifyContent: "center",
    lineHeight: "17px",
    "&:hover": {
      backgroundColor: "var(--gradient-fall-back)",
      opacity: 0.95,
    },
  },
  mobileBtn: {
    display: "none",
  },
  desktopOnly: {
    display: "block",
  },
  urlWrapper: {
    width: "100%",
    padding: 15,
  },
  tickBox: {
    display: "none",
  },
  "@media (max-width: 1160px)": {
    urlWrapper: {
      padding: 0,
    },
  },
  "@media (max-width: 760px)": {
    urlImageTxt: {
      marginTop: 35,
      marginBottom: 20,
    },
    desktopOnly: {
      display: "none",
    },
    mobileBtn: {
      display: "block",
    },
    urlWrapper: {
      padding: 0,
    },
    tickBox: {
      display: "flex",
      background: "#884BFF",
      borderRadius: 5,
      marginLeft: 6,
      width: 57,
      padding: 8,
      boxSizing: "border-box",
      justifyContent: "center",
      alignItems: "center",
    },
  },
});

const ImageByUrl = (props) => {
  const classes = useStyles();
  const [url, setUrl] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [sysError, setsysError] = useState("");
  const { isOpen, navigate } = props;

  const setURL = (event) => {
    setUrl(event.target.value);
  };

  const processURL = (url) => {
    if (url) {
      setLoading(true);
      getImageBase64FromUrl(CORS_API_URL + url)
        .then((urlData) => {
          checkResolution(urlData).then((res) => {
            if (urlData) {
              const a = {
                data: urlData,
                imageResolution: res,
                type: "notSvg",
              };
              navigate(a);
            }
          });
        })
        .catch(() => {
          setLoading(false);
          showError("Failed to load image");
        });
    }
  };

  const showError = (msg) => {
    setsysError(msg);
    setTimeout(() => setsysError(""), 1500);
  };

  const checkResolution = (data) => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      try {
        const img = new Image();
        img.src = data;
        img.onload = () => {
          setLoading(false);
          const { naturalWidth, naturalHeight } = img;
          if (
            naturalHeight > imageConfig.MIN_HEIGHT &&
            naturalWidth > imageConfig.MIN_WIDTH
          ) {
            resolve({ width: naturalWidth, height: naturalHeight });
          } else {
            showError(errorConfig.RESOLUTION_ERROR_MESSGAE);
          }
        };
        img.onerror = (e) => {
          showError("Failed to load image");
          setLoading(false);
        };
      } catch (err) {
        setLoading(false);
        reject(err);
      }
    });
  };

  if (!isOpen) return null;
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      marginTop="30px"
      flexDirection="column"
    >
      <div className="loadingContainer">
        {isLoading && (
          <>
            <ReactLoading className="loadingIcon" type="balls" color="black" />
          </>
        )}
        {sysError !== "" && (
          <>
            <Alert severity="error">{sysError}</Alert>
          </>
        )}
      </div>
      <Box className={classes.urlWrapper}>
        <Typography className={classes.urlImageTxt}>
          Paste the image Url below:
        </Typography>
        <Box display="flex">
          <InputBase
            type="url"
            placeholder="Image Url goes here..."
            onChange={setURL}
            className={classes.searchInput}
            value={url}
          />
        </Box>
        <Box marginTop="30px" className={classes.desktopOnly}>
          <Button
            className={classes.selectBtn}
            onClick={() => {
              processURL(url);
            }}
            disabled={isLoading || url === ""}
          >
            Ready for the next step!
          </Button>
        </Box>
        <Box marginTop="25px" className={classes.mobileBtn}>
          <Button
            className={classes.selectBtn}
            onClick={() => {
              processURL(url);
            }}
          >
            Pasted it
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageByUrl;
