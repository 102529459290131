import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Box, Menu, MenuItem, Typography } from "@material-ui/core";
import { colors } from "../../../constants/colors";
import circle from "../../../assets/svgs/whiteCircle.svg";

const StyledMenu = withStyles({
  paper: {
    width: "292px",
    height: "275px",
    borderRadius: "12px",
    boxShadow: "0px 3px 6px #00000029",
    marginTop: "11px",
    boxSizing: "border-box",
    background: `${colors.darkBlue} 0% 0% no-repeat padding-box !important`,
    padding: 6,
    zIndex: 1000,
    border: `1px solid ${colors.border}`,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    PaperProps={{
      style: {
        left: "0%",
      },
    }}
    {...props}
  />
));

const useStyles = makeStyles({
  menucontainer: {
    right: "30px",
    display: "grid",
    gridRowGap: 10,
    maxWidth: "100%",
  },
  mainTitle: {
    fontSize: "17px",
    color: "#ffffff",
    marginTop: "16px",
    marginLeft: "16px",
    marginBottom: "8px",
    fontWeight: 800,
  },
  title: {
    fontSize: "16px",
    color: "#ffffff",
    marginTop: "6px",
    fontWeight: 800,
    lineHeight: "24px",
    color: "#ffffff",
  },
  menuItem: {
    margin: "0 8px",
    color: "#E2E4E9",
    position: "relative",
    maxWidth: "100%",
    height: "95px",
    borderRadius: "10px",
    transition: "0.3s",
    "&:hover": {
      background: colors.darkBlueActive,
    },
  },
  menuItemActive: {
    margin: "0 8px",
    color: "#E2E4E9",
    position: "relative",
    maxWidth: "100%",
    height: "95px",
    backgroundColor: "#80ABFF",
    borderRadius: "10px",
    transition: "0.3s",
    "&:hover": {
      background: "#80ABFF",
    },
  },
});

const TemplatesMenu = ({ anchorEl, setAnchorEl, ppTemplates, setPpTemp }) => {
  const classes = useStyles();
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <StyledMenu
      id="profile-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <Typography className={classes.mainTitle}>Templates</Typography>
      <Box className={classes.menucontainer}>
        <MenuItem
          onClick={() => {
            setPpTemp(false);
            handleClose();
          }}
          className={!ppTemplates ? classes.menuItemActive : classes.menuItem}
        >
          <Box display={"flex"} flexDirection={"column"}>
            <Typography className={classes.title}>General Memes</Typography>
            {!ppTemplates && (
              <img
                style={{
                  width: "16px",
                  height: "16px",
                  marginTop: "6px",
                  position: "absolute",
                  right: 20,
                  top: 16,
                }}
                src={circle}
                alt=""
              />
            )}

            <Typography
              style={{ fontSize: "13px", color: "rgba(224, 235, 255, 0.7)" }}
            >
              We will show you general memes <br /> from the YouMeme Library.
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setPpTemp(true);
            handleClose();
          }}
          className={ppTemplates ? classes.menuItemActive : classes.menuItem}
          style={{ marginTop: "5px" }}
        >
          <Box display={"flex"} flexDirection={"column"}>
            <Typography className={classes.title}>Pudgy Penguins</Typography>
            {ppTemplates && (
              <img
                style={{
                  width: "16px",
                  height: "16px",
                  marginTop: "6px",
                  position: "absolute",
                  right: 20,
                  top: 16,
                }}
                src={circle}
                alt=""
              />
            )}

            <Typography
              style={{ fontSize: "13px", color: "rgba(224, 235, 255, 0.7)" }}
            >
              We will show you the Pudgy penguins <br /> memes collection
              templates.
            </Typography>
          </Box>
        </MenuItem>
      </Box>
    </StyledMenu>
  );
};

export default TemplatesMenu;
