import { Box, Hidden, IconButton, Typography } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import CloseIcon from "@material-ui/icons/Close";
import MaterialIcon from "material-icons-react";
import { memo } from "react";
import AddImageButtonInteraction from "./AddImageButtonInteraction";

function ImagesInteraction(props) {
  const removeImage = (event, index) => {
    event.stopPropagation();
    event.preventDefault();
    props.removeImage(index);
  };
  const bringForward = (event, index) => {
    event.stopPropagation();
    event.preventDefault();
    props.bringForward(index);
  };
  const sendBackward = (event, index) => {
    event.stopPropagation();
    event.preventDefault();
    props.sendBackward(index);
  };
  return (
    <>
      <Hidden mdDown>
        <div className="image-box" style={{ overflowX: "hidden" }}>
          <div className="origin-image marginTop15px ">
            <Typography className="image-text">Base Image</Typography>
            <img
              width="371px"
              height="173px"
              style={{ objectFit: "contain" }}
              src={props.scaledImg.data}
            />
          </div>
          {props.scaledLayeredImg.map((scaledImage, index) => {
            return (
              <div
                key={index}
                className={
                  "filtercard cardDivCEImage marginTop15px " +
                  (props.selectedIndex === index ? "layeredImageSelected" : "")
                }
                style={{ width: "100%" }}
                onClick={() => props.selectLayeredImg(index)}
                onKeyDown={(event) => {
                  if (event.code === "Delete") {
                    removeImage(event, index);
                  }
                }}
                tabIndex={-1}
              >
                <div className="rowDivCEImage centerAlignItem relativePostionDiv">
                  <IconButton>
                    <ArrowUpwardIcon
                      className="cardTopArrowButton"
                      title="Send backward"
                      onClick={(event) => {
                        sendBackward(event, index);
                      }}
                    />
                  </IconButton>
                  <IconButton>
                    <ArrowDownwardIcon
                      className="cardBottomArrowButton"
                      title="Bring forward"
                      onClick={(event) => {
                        bringForward(event, index);
                      }}
                    />
                  </IconButton>
                  <Typography
                    className="tabPanelCardTitle"
                    color="textSecondary"
                  >
                    {scaledImage.displayName}
                  </Typography>
                  <svg height="100%">
                    <image
                      x={0}
                      y={0}
                      href={scaledImage.data}
                      height="100%"
                      width="100%"
                      preserveAspectRatio="none"
                    />
                  </svg>
                </div>
                <IconButton>
                  <CloseIcon
                    className="cardCloseButton"
                    title="Remove image"
                    onClick={(event) => {
                      removeImage(event, index);
                    }}
                  />
                </IconButton>
              </div>
            );
          })}
          <Box marginTop="15px">
            <AddImageButtonInteraction
              newImage={(img, position) => props.newImg(img, position)}
              layeredImg={(imgData, resolution) =>
                props.newLayeredImg(imgData, resolution)
              }
            />
          </Box>
        </div>
      </Hidden>
      <Hidden lgUp>
        <Box width="100%" display="flex">
          <Box width="110px" marginRight="8px">
            <Box className="layerImage">
              <svg width="100%" height="100%" style={{ borderRadius: "7px" }}>
                <image
                  x={0}
                  y={0}
                  href={props.scaledImg.data}
                  width="100%"
                  height="100%"
                  preserveAspectRatio="none"
                />
              </svg>
            </Box>
            <Typography className="image-text">Base Image</Typography>
          </Box>
          {props.scaledLayeredImg.map((scaledImage, index) => {
            return (
              <Box
                key={index}
                width="110px"
                marginRight="8px"
                onClick={() => props.selectLayeredImg(index)}
              >
                <Box className="layerImage addedLayer">
                  <svg width="100%" height="100%">
                    <image
                      x={0}
                      y={0}
                      href={scaledImage.data}
                      height="100%"
                      width="100%"
                      preserveAspectRatio="none"
                    />
                  </svg>
                  <IconButton
                    onClick={(event) => {
                      removeImage(event, index);
                    }}
                    className="removeInteraction"
                  >
                    <MaterialIcon icon="delete" color="#FF4E62" />
                  </IconButton>
                </Box>
              </Box>
            );
          })}
          <Box width="110px" marginRight="8px">
            <AddImageButtonInteraction
              newImage={(img, position) => props.newImg(img, position)}
              layeredImg={(imgData, resolution) =>
                props.newLayeredImg(imgData, resolution)
              }
            />
          </Box>
        </Box>
      </Hidden>
    </>
  );
}
export default ImagesInteraction;

export const ImagesInteractionMemo = memo(
  ImagesInteraction,
  (prevProps, nextProps) => {
    if (
      prevProps.scaledImage !== nextProps.scaledImage ||
      prevProps.scaledLayeredImg !== nextProps.scaledLayeredImg ||
      prevProps.selectedLayeredImage !== nextProps.selectedLayeredImage ||
      prevProps.defImageResolution !== nextProps.defImageResolution ||
      prevProps.layeredImageResolution !== nextProps.layeredImageResolution ||
      prevProps.layeredImagePosition !== nextProps.layeredImagePosition ||
      prevProps.layeredImage !== nextProps.layeredImage ||
      prevProps.rotationScaleL !== nextProps.rotationScaleL
    ) {
      return false;
    }
    return true;
  }
);
