import { Box, Link, useMediaQuery } from "@mui/material";
import logo from "../../assets/svgs/main_logo.svg";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import PublishMemeModal from "../PublishMemeModal";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";

const useStyles = makeStyles({
  navContainer: {
    width: "75%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "50px auto",
    paddingRight: "5px",
    boxSizing: "border-box",
    position: "fixed",
    zIndex: 10,
  },
});

export default function HeaderNav() {
  const [openMeme, setOpenMeme] = useState(false);
  const responsive = useMediaQuery("(max-width: 770px)");
  const classes = useStyles();

  return (
    <>
      {!responsive ? (
        <Box width={"100%"} display="flex" justifyContent={"center"}>
          <nav className={classes.navContainer}>
            <Link
              className="btn small white"
              href="https://youmeme.com/categories/pudgypenguins"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <span>MORE MEMES</span>
            </Link>
            <a href="/" className="logo">
              <img src={logo} alt="" />
            </a>
            <a
              className="btn small white"
              href="https://marketplace.pudgypenguins.com/#/marketplace/pudgypenguins"
              target="_blank"
              rel="noreferrer"
            >
              <span>Marketplace</span>
            </a>
          </nav>
        </Box>
      ) : (
        <Box
          width={"100%"}
          display="flex"
          justifyContent={"center"}
          margin="50px auto"
          marginTop={"30px"}
        >
          <a href="/" className="logo">
            <img style={{ width: "150px" }} src={logo} alt="" />
          </a>
        </Box>
      )}
      <PublishMemeModal open={openMeme} onClose={() => setOpenMeme(false)} />
    </>
  );
}
