import { createApi } from "unsplash-js";

export const api = createApi({
  accessKey: "2j6awvc4FzagFErNkroZ3gyX5PSFUG1QOOrfCg45CgM",
});
export const getImageBase64FromUrl = async (url: string): Promise<any> => {
  return await new Promise((resolve, reject) => {
    const x = new XMLHttpRequest();
    x.open("GET", url);
    x.responseType = "blob";
    x.onload = () => {
      const reader = new FileReader();
      reader.readAsDataURL(x.response);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    };
    x.onerror = () => {
      reject(x.responseText);
    };
    x.send();
  });
};
export const debounce = (
  fn: any,
  ms: any
): ((this: Window, ev: UIEvent) => any) => {
  let timeoutId: any = null;

  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(debounce(fn, ms), args), ms);
  };
};

export const handleFiles = async (file: any): Promise<any> => {
  const reader = new FileReader();
  return await new Promise((resolve, reject) => {
    reader.onload = (e: any) => {
      try {
        const svgData: any = e.target.result;
        const parser = new DOMParser();
        const doc = parser.parseFromString(svgData, "image/svg+xml");
        const allChildren = doc.children[0].children;
        const defChild = [];
        const svgChild = [];
        const textChildren: any = [];
        const layeredImagesChildren: any[] = [];
        for (let i = 0; i < allChildren.length - 1; i++) {
          if (allChildren[i].tagName === "defs") {
            defChild.push(allChildren[i]);
          } else if (allChildren[i].tagName === "svg") {
            svgChild.push(allChildren[i]);
          } else if (allChildren[i].tagName === "g") {
            textChildren.push(allChildren[i]);
          } else if (allChildren[i].tagName === "image") {
            if (i < allChildren.length - 2) {
              layeredImagesChildren.push(allChildren[i]);
            }
          }
        }
        const svgResolution = {
          width: parseInt(svgChild[0].getAttribute("width") ?? ""),
          height: parseInt(svgChild[0].getAttribute("height") ?? ""),
        };
        const filterChildren = svgChild[0].children[0].children;
        const filterSelected = filterChildren[0].id.substring(
          0,
          filterChildren[0].id.length - 1
        );
        const filterSelectedLength = Number(filterChildren[0].id.slice(-1));
        const tempContrastScale = Math.round(
          Number(
            filterChildren[filterSelectedLength].children[0].getAttribute(
              "intercept"
            )
          ) * -400
        );
        const contrastScale = tempContrastScale === 0 ? 0 : tempContrastScale;
        const brightnessScale = Math.round(
          (Number(
            filterChildren[filterSelectedLength + 1].children[0].getAttribute(
              "slope"
            )
          ) -
            1) *
            133.33
        );
        const saturationScale = Math.round(
          (Number(
            filterChildren[filterSelectedLength + 2].getAttribute("values")
          ) -
            1) *
            100
        );
        const warmthScale = Math.round(
          (Number(
            filterChildren[filterSelectedLength + 3].children[0].getAttribute(
              "slope"
            )
          ) -
            1) *
            200
        );
        let rectOffsetValue: number = 0;
        let spaceValue: number = 0;
        let spaceTopEnable: number = 0;
        let spaceBotEnable: number = 0;
        let spaceColor: any = "#898989";
        let defImageResolution: { width: number; height: number } = {
          width: 0,
          height: 0,
        };
        for (let i = 0; i < svgChild[0].children.length; i++) {
          if (svgChild[0].children[i].tagName === "rect") {
            if (svgChild[0].children[i].getAttribute("y") === "0") {
              spaceTopEnable = 1;
            } else {
              spaceBotEnable = 1;
            }

            spaceValue = Math.round(
              (parseInt(svgChild[0].children[i].getAttribute("height") ?? "") *
                100) /
                defImageResolution.height
            );
            spaceColor = svgChild[0].children[2].getAttribute("fill");
          } else if (svgChild[0].children[i].tagName === "svg") {
            defImageResolution.height = parseInt(
              svgChild[0].children[i].getAttribute("height")?.slice(0, -2)!
            );
            defImageResolution.width = parseInt(
              svgChild[0].children[i].getAttribute("width")?.slice(0, -2)!
            );
            rectOffsetValue = i;
          }
        }
        if (spaceTopEnable === 1 || spaceBotEnable === 1) {
          spaceColor =
            svgChild[0].children[rectOffsetValue - 1].getAttribute("fill");
          if (spaceTopEnable === 1) {
            spaceValue = Math.round(
              (parseInt(
                svgChild[0].children[rectOffsetValue].getAttribute("y") ?? ""
              ) *
                100) /
                defImageResolution.height
            );
          } else if (spaceBotEnable === 1) {
            spaceValue = Math.round(
              (parseInt(
                svgChild[0].children[rectOffsetValue - 1]
                  .getAttribute("height")
                  ?.slice(0, -2)!
              ) *
                100) /
                defImageResolution.height
            );
          }
        }
        const scaleString: any =
          svgChild[0].children[rectOffsetValue].children[0].getAttribute(
            "transform"
          );
        const imgDimensionScale = parseFloat(
          scaleString.substring(
            scaleString.indexOf("scale") + 6,
            scaleString.indexOf("scale") + 9
          )
        );
        const flipString: any =
          svgChild[0].children[
            rectOffsetValue
          ].children[0].children[0].getAttribute("transform");
        const flipH = parseFloat(
          flipString
            .substring(
              flipString.indexOf("scale") + 6,
              flipString.indexOf("translate") - 2
            )
            .split(",")[0]
        );
        const flipV = parseFloat(
          flipString
            .substring(
              flipString.indexOf("scale") + 6,
              flipString.indexOf("translate") - 2
            )
            .split(",")[1]
        );
        const rotateString: any =
          svgChild[0].children[
            rectOffsetValue
          ].children[0].children[0].children[0].getAttribute("transform");
        const rotationScale: any = parseInt(
          rotateString.substring(7, rotateString.length - 1).split(" ")[0]
        );
        const imageList =
          svgChild[0].children[rectOffsetValue].children[0].children[0]
            .children[0].children[0];
        let imagePosition = { x: 0, y: 0 };
        let initImage: any = "";
        let imageResolution = { width: 0, height: 0 };
        let scaledImage = { data: "", height: 0 };
        imagePosition = {
          x: parseInt(imageList.getAttribute("x") ?? ""),
          y: parseInt(imageList.getAttribute("y") ?? ""),
        };
        initImage = imageList.getAttribute("href");
        imageResolution = {
          width: parseInt(imageList.getAttribute("width") ?? ""),
          height: parseInt(imageList.getAttribute("height") ?? ""),
        };
        const rotationScaleT: any[] = [];
        const rectPosition: any[] = [];
        const vAlign: any[] = [];
        const hAlign: any[] = [];
        const rectDimension: any[] = [];
        const fontSize: any[] = [];
        const fontFamily: any[] = [];
        const fontWeight: any[] = [];
        const fontStyle: any[] = [];
        const shadowWidth: any[] = [];
        const strokeValue: any[] = [];
        const strokeType: any[] = [];
        const letterSpace: any[] = [];
        const strokeWidth: any[] = [];
        const isUpperCase: any[] = [];
        const colorValue: any[] = [];
        const textValue: any[] = [];
        const textRectWidth: any[] = [];
        const textRectHeight: any[] = [];
        const textPlaceholder: any[] = [];
        for (let i = 0; i < textChildren.length; i++) {
          const rotationString: any = textChildren[i].getAttribute("transform");
          const rotationValue = Number(
            rotationString.substring(7, rotationString.length - 1).split(" ")[0]
          );
          rotationScaleT.push(rotationValue);
          const rectPositionValue = {
            x: Number(textChildren[i].getAttribute("data-posx")),
            y: Number(textChildren[i].getAttribute("data-posy")),
          };
          rectPosition.push(rectPositionValue);
          const hAlignValue = parseFloat(
            textChildren[i].getAttribute("data-halign") ?? ""
          );
          const vAlignValue = parseFloat(
            textChildren[i].getAttribute("data-valign") ?? ""
          );
          const strokeTypeValue = Number(
            textChildren[i].getAttribute("data-stroketype")
          );
          hAlign.push(hAlignValue);
          vAlign.push(vAlignValue);
          strokeType.push(strokeTypeValue);
          let textChildrenIndex = 0;
          if (strokeTypeValue === 2) {
            textChildrenIndex = 1;
          }
          textRectWidth.push(
            Number(textChildren[i].getAttribute("data-textminwidth"))
          );
          textRectHeight.push(
            Number(textChildren[i].getAttribute("data-textminheight"))
          );
          const rectposxplusdimensionby2 = Number(
            rotationString.substring(7, rotationString.length - 1).split(" ")[1]
          );
          const rectposyplusdimensionby2 = Number(
            rotationString.substring(7, rotationString.length - 1).split(" ")[2]
          );
          const rectDimensionValue = {
            width: (rectposxplusdimensionby2 - rectPositionValue.x) * 2,
            height: (rectposyplusdimensionby2 - rectPositionValue.y) * 2,
          };
          rectDimension.push(rectDimensionValue);
          fontSize.push(
            parseInt(
              textChildren[i].children[textChildrenIndex]
                .getAttribute("style")
                .split(";")[0]
                .split(":")[1]
            )
          );
          fontFamily.push(
            textChildren[i].children[textChildrenIndex]
              .getAttribute("style")
              .split(";")[1]
              .split(":")[1]
              .replaceAll('"', "")
              .trim()
          );
          fontWeight.push(
            Number(
              textChildren[i].children[textChildrenIndex]
                .getAttribute("style")
                .split(";")[2]
                .split(":")[1]
            )
          );
          fontStyle.push(
            textChildren[i].children[textChildrenIndex]
              .getAttribute("style")
              .split(";")[3]
              .split(":")[1]
              .trim()
          );
          const shadowString: any = textChildren[i].children[textChildrenIndex]
            .getAttribute("style")
            .split(";")[4]
            .split(":")[1]
            .trim();
          shadowWidth.push(
            shadowString.substring(
              shadowString.indexOf("0px 0px ") + 8,
              shadowString.length
            )
          );
          letterSpace.push(
            textChildren[i].children[textChildrenIndex]
              .getAttribute("style")
              .split(";")[5]
              .split(":")[1]
              .trim()
          );
          strokeValue.push(
            textChildren[i].children[textChildrenIndex]
              .getAttribute("style")
              .split(";")[6]
              .split(":")[1]
              .trim()
          );
          strokeWidth.push(
            textChildren[i].children[textChildrenIndex]
              .getAttribute("style")
              .split(";")[7]
              .split(":")[1]
              .trim()
          );
          isUpperCase.push(
            textChildren[i].children[textChildrenIndex]
              .getAttribute("style")
              .split(";")[8]
              .split(":")[1]
              .trim()
          );
          colorValue.push(
            textChildren[i].children[textChildrenIndex + 1]
              .getAttribute("style")
              .split(";")[5]
              .split(":")[1]
              .trim()
          );
          const ntspan =
            textChildren[i].children[textChildrenIndex].children.length;
          const tspanarray = [];
          for (let j = 0; j < ntspan; j++) {
            tspanarray.push(
              textChildren[i].children[textChildrenIndex].children[j].innerHTML
            );
          }
          textValue.push(tspanarray);
          if (i === 0) {
            textPlaceholder.push("TOP TEXT");
          } else if (i === 1) {
            textPlaceholder.push("BOTTOM TEXT");
          } else {
            textPlaceholder.push("TEXT");
          }
        }

        const layeredImagePosition: any[] = [];
        const layeredImage: any[] = [];
        const layeredImageResolution: any[] = [];
        const rotationScaleL: any[] = [];
        const scaledLayeredImage: any[] = [];
        for (let i = 0; i < layeredImagesChildren.length; i++) {
          layeredImagePosition.push({
            x: Number(layeredImagesChildren[i].getAttribute("x")),
            y: Number(layeredImagesChildren[i].getAttribute("y")),
          });
          layeredImage.push(layeredImagesChildren[i].getAttribute("href"));
          layeredImageResolution.push({
            width: Number(layeredImagesChildren[i].getAttribute("width")),
            height: Number(layeredImagesChildren[i].getAttribute("height")),
          });
          const rotationString =
            layeredImagesChildren[i].getAttribute("transform");
          const rotationValue = Number(
            rotationString.substring(7, rotationString.length - 1).split(" ")[0]
          );
          rotationScaleL.push(rotationValue);
          const img = new Image();
          img.src = layeredImagesChildren[i].getAttribute("href");
          img.onload = () => {
            const imgWidth = img.width;
            const imgHeight = img.height;
            const offScreenCanvas = document.createElement("canvas");
            const offScreenCanvasCtx: any = offScreenCanvas.getContext("2d");
            const scaledWidth = 225;
            const scaledHeight = (scaledWidth / imgWidth) * imgHeight;
            offScreenCanvas.width = 225;
            offScreenCanvas.height = scaledHeight;
            const scaleValueWidth = scaledWidth / imgWidth;
            const scaleValueHeight = scaledHeight / imgHeight;
            const img2 = document.createElement("img");
            img2.src = layeredImagesChildren[i].getAttribute("href");
            img2.onload = () => {
              offScreenCanvasCtx.scale(scaleValueWidth, scaleValueHeight);
              offScreenCanvasCtx.drawImage(img, 0, 0);
              scaledLayeredImage.push({
                data: offScreenCanvas.toDataURL(),
                height: scaledHeight,
              });
            };
          };
        }
        const img: any = document.createElement("img");
        img.src = imageList.getAttribute("href");
        img.onload = () => {
          const offScreenCanvas = document.createElement("canvas");
          const offScreenCanvasCtx: any = offScreenCanvas.getContext("2d");
          offScreenCanvas.width = 225;
          const newHeight =
            (225 / parseInt(imageList.getAttribute("width") ?? "")) *
            parseInt(imageList.getAttribute("height") ?? "");
          offScreenCanvas.height = newHeight;
          offScreenCanvasCtx.scale(
            225 / parseInt(imageList.getAttribute("width") ?? ""),
            newHeight / parseInt(imageList.getAttribute("height") ?? "")
          );
          offScreenCanvasCtx.drawImage(img, 0, 0);
          scaledImage = {
            data: offScreenCanvas.toDataURL(),
            height: newHeight,
          };
          const dataobj = {
            svgResolution: svgResolution,
            filterSelected: filterSelected,
            contrastScale: contrastScale,
            brightnessScale: brightnessScale,
            saturationScale: saturationScale,
            warmthScale: warmthScale,
            spaceValue: spaceValue,
            spaceTopEnable: spaceTopEnable,
            spaceBotEnable: spaceBotEnable,
            spaceColor: spaceColor,
            defImageResolution: defImageResolution,
            imgDimensionScale: imgDimensionScale,
            flipH: flipH,
            flipV: flipV,
            rotationScale: rotationScale,
            imagePosition: imagePosition,
            initImage: initImage,
            imageResolution: imageResolution,
            rotationScaleT: rotationScaleT,
            rectPosition: rectPosition,
            vAlign: vAlign,
            hAlign: hAlign,
            rectDimension: rectDimension,
            fontSize: fontSize,
            fontFamily: fontFamily,
            fontWeight: fontWeight,
            fontStyle: fontStyle,
            shadowWidth: shadowWidth,
            strokeValue: strokeValue,
            strokeType: strokeType,
            letterSpace: letterSpace,
            strokeWidth: strokeWidth,
            isUpperCase: isUpperCase,
            colorValue: colorValue,
            textValue: textValue,
            layeredImagePosition: layeredImagePosition,
            layeredImage: layeredImage,
            layeredImageResolution: layeredImageResolution,
            rotationScaleL: rotationScaleL,
            scaledLayeredImage: scaledLayeredImage,
            textRectWidth: textRectWidth,
            textRectHeight: textRectHeight,
            scaledImage: scaledImage,
            textPlaceholder: textPlaceholder,
          };
          const a = {
            data: dataobj,
            type: "svg",
          };
          resolve(a);
        };
      } catch (err) {
        console.log(err);
        console.log("Invalid SVG file");
      }
    };
    reader.readAsText(file);
  });
};

export const isEmpty = (array: any): boolean => {
  return (
    Array.isArray(array) &&
    (array.length == 0 ||
      array.every(isEmpty) ||
      array.every((val) => val === ""))
  );
};
