import {
  Box,
  Button,
  IconButton,
  Popover,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MaterialIcon from "material-icons-react";
import { useCallback, useEffect, useRef, useState } from "react";

import ReactLoading from "react-loading";
import Webcam from "react-webcam";
import errorConfig from "../config/errorConfig.json";
import imageConfig from "../config/imageConfig.json";

const useStyles = makeStyles({
  cameraContainer: {
    display: "flex",
    marginTop: 40,
    fontSize: "1rem",
    justifyContent: "space-between",
    padding: "0 15px 15px",
  },
  webcamDiv: {
    position: "relative",
    width: 520,
    height: 390,
    borderRadius: 5,
  },
  cameraA: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  capturedImageOptionsDiv: {
    width: 454,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  selectedTxt: {
    fontSize: 18,
    color: "#C2C9D4",
    margin: "12px 0",
  },
  generalBtn: {
    width: "100%",
    height: 45,
    borderRadius: 5,
    color: "#C2C9D4",
    backgroundColor: "#1A1E28",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px 0 20px",
    boxSizing: "border-box",
    fontSize: 14,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#1A1E28",
      opacity: 0.8,
    },
  },
  deviceBtn: {
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: 14,
    color: "#546277",
    paddingLeft: 20,
    paddingRight: 13,
    borderRadius: 5,
    height: 45,
  },
  selectedBtn: {
    backgroundColor: "#14161E",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "#14161E",
    },
  },
  OptionButtonGroup: {
    borderRadius: 3,
    width: 454,
    background: "#1A1E28",
    boxSizing: "border-box",
    color: "white",
    display: "flex",
    flexDirection: "column",
    padding: 10,
  },
  cameraCaptureBtn: {
    position: "absolute",
    bottom: 20,
    right: 20,
    "& .material-icons": {
      color: "white",
    },
  },
  "@media screen and (max-width: 760px)": {
    desktopOnly: {
      display: "none",
    },
    cameraA: {
      flexDirection: "column",
      width: "100vw",
    },
    webcamDiv: {
      borderRadius: 0,
    },
    cameraContainer: {
      padding: 0,
      height: "100%",
      marginLeft: "-20px",
      marginTop: "-20px",

      "& > .cameraNA": {
        margin: "auto",
        padding: "1em",
      },
    },
    capturedImageOptionsDiv: {
      position: "fixed",
      top: 50,
      right: 10,
      width: "10px",
    },
    cameraCaptureBtn: {
      position: "fixed",
      left: "calc((100% - 70px))",
      width: "50px",
      borderRadius: "50%",
      height: "50px",
    },
  },
  disableBtn: {
    opacity: 0.6,
  },
});

const PictureByCamera = (props) => {
  const [devices, setDevices] = useState([]);
  const [isDisable, setDisable] = useState(true);
  const webcamRef = useRef(null);
  const initDevice = {
    id: "",
    label: "Default",
  };
  const [selectedCamera, setSelectedCamera] = useState(initDevice);
  const [webcamImgSrc, setWebcamImgSrc] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorRef, setAnchorRef] = useState("");
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  const { navigate, isOpen } = props;

  const showError = (msg) => {};

  const capture = useCallback(() => {
    const imageSrc = webcamRef?.current.getScreenshot();
    setWebcamImgSrc(imageSrc);
    setDisable(false);
  }, [webcamRef, setWebcamImgSrc]);

  const handleDevices = useCallback(
    (mediaDevices) => {
      setDevices(
        mediaDevices.filter(
          ({ kind, deviceId }) => kind === "videoinput" && deviceId !== ""
        )
      );
    },
    [setDevices]
  );
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
    setInterval(() => {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }, 3000);
  }, [handleDevices]);

  useEffect(() => {
    if (!webcamImgSrc) return;
    processCameraCapture();
  }, [webcamImgSrc]);

  const handleClick = (event, aRef) => {
    setAnchorEl(event.currentTarget);
    setAnchorRef(aRef);
  };

  // For checking the resolution of the uploaded image
  const checkResolution = (data) => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      try {
        const img = new Image();
        img.src = data;
        img.onload = () => {
          setLoading(false);
          const { naturalWidth, naturalHeight } = img;
          if (
            naturalHeight > imageConfig.MIN_HEIGHT &&
            naturalWidth > imageConfig.MIN_WIDTH
          ) {
            resolve({ width: naturalWidth, height: naturalHeight });
          } else {
            showError(errorConfig.RESOLUTION_ERROR_MESSGAE);
          }
        };
        img.onerror = (e) => {
          showError("Failed to load image");
          setLoading(false);
        };
      } catch (err) {
        setLoading(false);
        reject(err);
      }
    });
  };

  const processCameraCapture = () => {
    checkResolution(webcamImgSrc).then((res) => {
      if (webcamImgSrc) {
        const a = {
          data: webcamImgSrc,
          imageResolution: res,
          type: "notSvg",
        };
        navigate(a);
      }
    });
  };
  if (!isOpen) return null;
  return (
    <Box
      className={classes.cameraContainer}
      display="flex"
      justifyContent="space-between"
      marginTop="40px"
    >
      {isLoading && (
        <>
          <div className="loadingContainer" />
          <ReactLoading className="loadingIcon" type="balls" color="black" />
        </>
      )}
      <div
        className="cameraNA"
        style={{ display: devices.length !== 0 && "none" }}
      >
        <p
          style={{
            fontWeight: 700,
            fontSize: "1.25em",
            marginTop: "1.75em",
            color: "#E2E4E9",
          }}
        >
          We are not able to detect your camera :/
        </p>
        <p>
          Make sure your camera is connected and not used by any another app,
          then reload the page!
        </p>
      </div>
      <div
        className={classes.cameraA}
        style={{ display: devices.length === 0 ? "none" : "flex" }}
      >
        <div className={classes.webcamDiv}>
          <Webcam
            ref={webcamRef}
            audio={false}
            screenshotFormat="image/jpeg"
            screenshotQuality={1}
            className="webcamComp"
            videoConstraints={{
              deviceId: selectedCamera.id,
            }}
          />
          <IconButton
            onClick={capture}
            size="medium"
            className={classes.cameraCaptureBtn}
            style={{ background: "var(--gradient-fall-back)", color: "white" }}
            title="Capture"
          >
            <MaterialIcon icon="camera" size="30px" />
          </IconButton>
        </div>
        <Box className={classes.capturedImageOptionsDiv}>
          <Box className={classes.desktopOnly}>
            <Typography className={classes.selectedTxt}>
              Choose a camera input:
            </Typography>
            <Button
              onClick={(e) => handleClick(e, "SpaceListPop")}
              className={classes.generalBtn}
              title="Camera lists"
              disabled={devices.length === 0}
            >
              <Typography component="span">{selectedCamera.label}</Typography>
            </Button>
            <Popover
              id="SpaceListPop"
              open={Boolean(anchorEl) && anchorRef === "SpaceListPop"}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              style={{ marginTop: 6 }}
            >
              {devices.length > 0 && (
                <Box
                  orientation="vertical"
                  className={classes.OptionButtonGroup}
                >
                  {[initDevice, ...devices].map((device, key) => {
                    return (
                      <Button
                        key={key}
                        className={`${classes.deviceBtn} ${
                          device.label === selectedCamera.label &&
                          classes.selectedBtn
                        }`}
                        onClick={() => {
                          setSelectedCamera({
                            id: device.deviceId,
                            label: device.label,
                          });
                          setAnchorEl(null);
                        }}
                        title={device.label || `Device ${key + 1}`}
                      >
                        {device.label || `Device ${key + 1}`}
                      </Button>
                    );
                  })}
                </Box>
              )}
            </Popover>
            {webcamImgSrc && (
              <>
                <img
                  alt="Webcam capture"
                  className="capturedImageDiv"
                  src={webcamImgSrc}
                  title="Click to crop"
                />
              </>
            )}
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export default PictureByCamera;
