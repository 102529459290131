export const fonts: string[] = [
  'Abel',
  'Abril Fatface',
  'Antic Slab',
  'Anton',
  'Archivo Narrow',
  'Arimo',
  'Arvo',
  'Asap',
  'Assistant',
  'Barlow',
  'Barlow Condensed',
  'Bebas Neue',
  'Bitter',
  'Cabin',
  'Cairo',
  'Caveat',
  'Comfortaa',
  'Cormorant Garamond',
  'Crimson Text',
  'Dancing Script',
  'DM Sans',
  'Domine',
  'Dosis',
  'EB Garamond',
  'Exo',
  'Exo 2',
  'Fira Sans',
  'Fira Sans Condensed',
  'Fjalla One',
  'Hammersmith One',
  'Heebo',
  'Hind',
  'Hind Madurai',
  'Hind Siliguri',
  'IBM Plex Sans',
  'IBM Plex Serif',
  'Inconsolata',
  'Indie Flower',
  'Inter',
  'Josefin Sans',
  'Kanit',
  'Karla',
  'Lato',
  'Libre Baskerville',
  'Libre Franklin',
  'Lobster',
  'Lora',
  'Martel',
  'Maven Pro',
  'Merriweather',
  'Merriweather Sans',
  'Montserrat',
  'Mukta',
  'Mulish',
  'Nanum Gothic',
  'Noto Sans',
  'Noto Sans HK',
  'Noto Sans JP',
  'Noto Sans KR',
  'Noto Sans SC',
  'Noto Sans TC',
  'Noto Serif',
  'Noto Serif JP',
  'Nunito',
  'Nunito Sans',
  'Open Sans',
  'Oswald',
  'Overpass',
  'Oxygen',
  'Pacifico',
  'Padauk',
  'Play',
  'Playfair Display',
  'Poppins',
  'Prompt',
  'PT Sans',
  'PT Sans Narrow',
  'PT Serif',
  'Questrial',
  'Quicksand',
  'Rajdhani',
  'Raleway',
  'Roboto',
  'Roboto Condensed',
  'Roboto Mono',
  'Roboto Slab',
  'Rubik',
  'Shadows Into Light',
  'Slabo 27px',
  'Source Code Pro',
  'Source Sans Pro',
  'Source Serif Pro',
  'Staatliches',
  'Teko',
  'Titillium Web',
  'Ubuntu',
  'Varela Round',
  'Work Sans',
  'Yanone Kaffeesatz',
  'Zilla Slab'
]
